import { LocalDate } from 'src/utilities/local-date.js';

/**
 * The types, interfaces and utility functions for lists are defined here so that the view model builders do
 * not depend on lit specific code.
 */

export interface TaskEvent {
  timeOfDayDisplay: string;
  overdue: boolean;
  doneDate?: string;
  time: string;
  sortTime: string;
  uuid: string;
  href: string;
  eventCurrentUser: boolean;
  secondaryLabel: string;
  rightLabel: string;
  rightLabelClass: string;
  notes?: string;
  eventDone?: boolean;
  updateStatus?: string;
  hasDraft: boolean;
  eventName?: string;
}

export function displayTime(item: TaskEvent): string {
  if (item.time) {
    const string = LocalDate.fromString(item.sortTime).toStringForDisplayWithDayOfWeekAndYear();
    return string[0].toUpperCase() + string.slice(1) + ' ' + item.timeOfDayDisplay;
  }
  return 'Snarest';
}

export type UpdateStatus = 'none' | 'templateDeleted' | 'templateUpdated' | 'documentUpdated' | 'draft';
export type RightLabelClasses = 'assignee';
export type AssetCheckStatus = 'NONE' | 'OK' | 'NOT_OK';
export type RiskLevels = 0 | 1 | 2 | 3 | 4 | undefined;
export type ListItemIcon =
  | 'suggestion'
  | 'checkBlue'
  | 'checkGray'
  | 'xOrange'
  | 'info'
  | 'alert'
  | 'lock'
  | 'risk0'
  | 'risk1'
  | 'risk2'
  | 'risk3'
  | 'risk4'
  | 'sickLeave'
  | 'vacation'
  | 'leave'
  | 'timeOff'
  | 'seminar'
  | 'jobTravel'
  | 'homeOffice'
  | 'sickSelf'
  | 'sickChildren'
  | 'OTHER'
  | 'events'
  | 'meetings'
  | 'reports'
  | 'tasks'
  | 'guidelines'
  | 'documents'
  | 'constitutionalDocuments'
  | 'contracts'
  | 'functions'
  | 'assets'
  | 'substances'
  | 'riskAssessments'
  | 'issues'
  | 'employees'
  | 'partners'
  | 'contacts';

export interface ListSectionItemInput {
  writeAccess?: boolean;
  // text content
  label?: string;
  secondaryLabel?: string;
  labelItems?: string[]; // when label includes a list, e.g. employee functions on organization page
  field?: string;
  subfield?: string;
  rightLabel?: string;
  notes?: string;
  // functional
  href?: string;
  target?: string;
  clickHandler?: () => void | Promise<void>;
  secondaryClickHandler?: () => void | Promise<void>;
  clickData?: string;
  eventDone?: boolean;
  /**
   * If true the item is available for the current viewer.
   */
  accessible: boolean;
  mainIcon?: ListItemIcon;
  hasDraft?: boolean;
  updateStatus?: UpdateStatus;
  preItem?: boolean;
  postItem?: boolean;
  hidden?: boolean;
  rightLabelClass?: RightLabelClasses;
  hideNotes?: boolean;
  sublistToggle?: boolean;
  sublistHidden?: boolean;
  limitItems?: number;
  noItemText?: string;
  suggestion?: boolean;
  // function specific
  functionRotated?: boolean;
  // event specific
  eventCurrentUser?: boolean;
  // asset specific
  assetCheckStatus?: AssetCheckStatus;
  assetCheck?: boolean;
  assetLocation?: string;
  assetCheckDoneText?: string;
  assetCheckComments?: string;
  // timekeeping specific
  timekeepingCorrection?: boolean;
  // sublist
  items?: ListSectionItemInput[];
}
