import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { BaseListSectionElement } from 'src/library/lists/base-list-section-element.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';

import '../elements/d-label.js';
import { DListSection } from './d-list-section.js';
import './d-list-section-item.js';
import { uuid } from 'src/utilities/text';
import { CreateEntityInput, CreateRiskAssessmentInput } from 'src/layout/parts/d-new-documents-list';
import { pageIds } from 'src/store';

export interface RelatedRiskAssessment {
  uuid: string;
  name: string;
  suggestion: boolean;
  templateId?: number;
  risk: number;
  hazardLabel?: string;
  hasDraft: boolean;
}

/**
 *
 * This list is used to show related risk assessments for substances. To extend to other entity types requires check of assumptions in this element.
 *
 */
@customElement('d-list-section-related-risk-assessments')
export class DListSectionRelatedRiskAssessments extends BaseListSectionElement<ListSectionItemInput> {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
        margin-bottom: 6px;
      }
    `,
  ];
  @property({ type: String })
  field = '';
  @property({ type: String })
  relatedItemUuid = '';
  @property({ type: String })
  relatedItemHref = '';
  private get actions(): ActionInput[] {
    return [
      {
        name: 'Legg til',
        action: 'new',
        slot: 'top-right',
      },
    ];
  }

  render() {
    return html`
      <d-list-section
        field="${this.field}"
        label="Risikovurderinger"
        icon="riskAssessments"
        .items=${this.items}
        .actions=${this.actions}
        no-item-text="Ingen risikovurderinger"
        .contentStickyTop=${this.contentStickyTop}
        @action=${this.onAction}
      ></d-list-section>
    `;
  }

  renderItemsEmpty(): TemplateResult | typeof nothing {
    return html`<d-list-section-item label="Ingen risikovurderinger" class="noItems"> </d-list-section-item>`;
  }

  private onAction(e: CustomEvent<string>) {
    if (e.detail === 'new') {
      const id = uuid();
      const input: CreateRiskAssessmentInput = {
        entityType: 'riskAssessments',
        entityUuid: id,
        pageId: pageIds.riskAssessments,
        targetUrl: this.relatedItemHref + '/riskAssessments/' + id + '?edit',

        substanceUuid: this.relatedItemUuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-related-risk-assessments': DListSectionRelatedRiskAssessments;
  }
}
