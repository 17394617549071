import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import './d-list-section';
import './../elements/d-label';
import { classMap } from 'lit/directives/class-map.js';
import { FileViewerDocument } from 'src/layout/parts/file-viewer-dialog';
import { signatureStatusStyles } from 'src/library/signature-status-styles';

export interface SigningOrderListItem {
  status: string;
  asDocumentItem: () => Promise<FileViewerDocument>;
  label: string;
  secondaryLabel: string;
  uuid: string;
}

/**
 *
 * USAGE:
 *    d-contract-view
 *
 */
@customElement('d-list-section-signing-orders')
export class DListSectionSigningOrders extends LitElement {
  static styles = [
    signatureStatusStyles,
    css`
      :host {
        display: block;
      }

      .header {
        display: flex;
        flex-direction: row-reverse;
        align-items: baseline;
        flex-wrap: wrap;
      }

      .actions {
        margin-top: -6px;
        margin-right: -8px;
      }

      .header > d-label {
        flex: 1;
      }

      .list {
        margin: 2px 0 6px 0;
      }

      .item {
        display: flex;
        padding: 5px 0;
        color: var(--linkColorGray);
        line-height: var(--lineHeightDefault);
      }

      .item[disabled] {
        color: black;
      }

      .item:not([disabled]):hover {
        color: black;
        cursor: pointer;
      }

      .item .content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
      }

      .primary {
        margin-right: 6px;
      }

      .secondary {
        font-size: 14px;
        font-weight: 200;
      }
    `,
  ];
  @property({ type: String })
  label = 'Elektronisk signering';
  @property({ type: Array })
  items: SigningOrderListItem[] = [];
  @property({ type: Boolean })
  signingDisabled = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Boolean })
  noAnimation = false;

  requestSignatures() {
    if (!this.signingDisabled) {
      this.dispatchEvent(
        new CustomEvent('request-signatures', {
          bubbles: true,
          composed: true,
          detail: {},
        }),
      );
    }
  }

  itemClick(e, item) {
    e.preventDefault();
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent('signing-item-click', {
        bubbles: true,
        composed: true,
        detail: { value: item },
      }),
    );
  }

  render() {
    return html`
      <div class="header">
        <div class="actions">
          <d-action @click=${() => this.requestSignatures()} ?disabled="${this.signingDisabled}"
            >${this.items.length ? 'Send på nytt' : 'Send til signering'}</d-action
          >
        </div>
        <d-label field="contracts_electronicSignature"></d-label>
      </div>
      ${this.items.length
        ? html`
            <div class="list">
              ${this.items.map(
                (item) =>
                  html` <div
                    class="item"
                    @click=${(e) => {
                      this.itemClick(e, item);
                    }}
                  >
                    <div
                      class="icon${classMap({
                        idle: item.status === 'OPEN',
                        signed: item.status === 'CLOSED',
                        error: item.status === 'ERROR',
                        noAnimation: this.noAnimation,
                      })}"
                    ></div>
                    <div class="content">
                      <span class="primary">${item.label}</span>
                      <span class="secondary">${item.secondaryLabel}</span>
                    </div>
                  </div>`,
              )}
            </div>
          `
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-signing-orders': DListSectionSigningOrders;
  }
}
