import type { ReportView, ReportViewEditItem } from 'src/content/reports/d-report-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import {
  contactsNotDeleted,
  currentUserIsOwner,
  getClassificationText,
  getOrganizationId,
  getStore,
  signingAttachment,
  ApiClient,
  currentEmployeeUuid,
  currentUserUuid,
} from 'src/store';
import { BASE_PATH, EmployeeViewModelStatusEnum } from 'src/store/api';
import { employeesNotDeleted, getOrganization, key, toReportViewModel } from 'src/store/selectors';
import type { State } from 'src/store/types';
import { LocalDate } from 'src/utilities/local-date';
import { dateTimeDescription, sortedByName } from 'src/store/utilities';
import { assertIsDefined } from 'src/lib';
import { getMeetingMessageRecipients } from 'src/models/content/build-meeting-occurrence-view';
import { CommonDataEntityView } from 'src/content/entity-content';
import { getFeatureStates } from 'src/store/selectors/features';
import { sortBy } from 'lodash';

export async function buildReportView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<ReportView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availablePersons = employeesNotDeleted(state)
    .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated && e.isConfirmedEntity)
    .map((p) => ({ value: p.uuid, text: p.name }));
  const availablePages = pagesAsSelectTagOptions(state);
  const item = organization.reportsById[uuid];
  assertIsDefined(item);
  const entity = toReportViewModel(item, organization);
  const resultItem: ReportView = {
    ...commonData,
    activeEmployeesCount: employeesNotDeleted(state).filter((e) => {
      return e.status === 'ACTIVE';
    }).length,
    currentUserIsOwner: currentUserIsOwner(state),
    hasSigningModule: false, // only if meeting report
    availablePartners: [],
    organizationName: organization.name,
    availableEmployees: sortedByName(employeesNotDeleted(state))
      .filter((e) => e.status !== 'TERMINATED' && e.isConfirmedEntity)
      .map((p) => ({
        uuid: p.uuid,
        email: p.email,
        personalIdentifier: p.personalIdentifier,
        firstName: p.firstName,
        lastName: p.lastName,
      })),
    availableContacts: [],
    signingOrders:
      entity.signingOrders?.map((s) => {
        return {
          ...s,
          asDocumentItem: async () => {
            const organizationId = getOrganizationId(getStore().getState());
            const a2 = await signingAttachment(api, 'reports', entity.uuid, organizationId, s.uuid);
            return {
              name: a2.name || 'uten navn',
              url: a2.urlForInline || '',
              urlForDownload: a2.url || '',
              entityType: 'contracts',
              attachmentId: s.uuid,
              uuid: entity.uuid,
            };
          },
        };
      }) || [],
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess:
      entity.classification === 'NONE' || (entity.accessControl ?? []).includes(currentUserUuid(state) ?? ''),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/reports/' + uuid,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/reports/' + uuid + '.pdf?key=' + key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'reports',
    name: entity.name ?? '',
    classification: entity.classification ?? 'NONE',
    accessControl: entity.accessControl ?? [],
    classificationText: getClassificationText(
      entity.classification ?? 'NONE',
      entity.accessControl ?? [],
      commonData.accessControlOptions,
    ),
    revisions: await fetchRevisions(
      api,
      'reports',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems(api, 'reports', entity.uuid, entity.attachments),
    content: entity.content ?? '',
    reportDate: entity.reportDate ?? LocalDate.now().toString(),
    writtenByName: availablePersons.find((x) => x.value === entity.employeeUuid)?.text ?? '',
    availablePages: availablePages,
    availablePersons: availablePersons,
    pages: (entity.pages ?? []).map((x) => '' + x),
    employee: entity.employeeUuid ?? currentEmployeeUuid(state) ?? '',
    hasRelatedMeetingEvent:
      entity.meetingOccurrenceUuid !== undefined &&
      entity.meetingOccurrenceUuid !== null &&
      entity.meetingOccurrenceUuid !== '',
    today: LocalDate.fromString(state.today),
    isMeetingReport:
      entity.meetingOccurrenceUuid !== undefined &&
      entity.meetingOccurrenceUuid !== null &&
      entity.meetingOccurrenceUuid !== '',
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'reports', uuid, organization.organizationId);
      return command.draft as ReportViewEditItem;
    },
    currentEmployeeUuid: currentEmployeeUuid(state) ?? '',
    employees: [],
    partners: [],
    contacts: [],
  };
  if (resultItem.isMeetingReport) {
    const meetingOccurrence = organization.meetingOccurrences.find((m) => m.uuid === entity.meetingOccurrenceUuid);
    assertIsDefined(meetingOccurrence);
    const responsibleFunction = organization.functionsById[meetingOccurrence.responsibleUuid ?? ''];
    const responsibleEmployeeUuid = responsibleFunction?.employees[0];
    const messageRecipients = getMeetingMessageRecipients(
      state,
      responsibleEmployeeUuid,
      meetingOccurrence.participants ?? [],
      meetingOccurrence.externalParticipants,
    );
    resultItem.meetingData = {
      uuid: meetingOccurrence.uuid,
      name: meetingOccurrence.name || '',
      messageRecipients,
    };
    resultItem.name = 'Referat fra ' + meetingOccurrence.name;
    resultItem.meetingOccurrenceDisplay = dateTimeDescription(
      meetingOccurrence.date,
      meetingOccurrence.time,
      meetingOccurrence.durationMinutes,
    );
    resultItem.secondaryLabel = meetingOccurrence.name?.toLowerCase().includes('medarbeidersamtale')
      ? 'med ' + organization.employeesById[meetingOccurrence.participants[0]].name
      : '';

    resultItem.hasSigningModule = getFeatureStates(state).signing;

    resultItem.employees = meetingOccurrence.participants ?? [];
    resultItem.partners = []; // TODO avhengig av endringer i dialoger.
    resultItem.contacts = meetingOccurrence.externalParticipants ?? [];

    resultItem.availableEmployees = sortedByName(employeesNotDeleted(state))
      .filter((e) => e.status !== 'TERMINATED' && e.isConfirmedEntity)
      .map((p) => ({
        uuid: p.uuid,
        email: p.email,
        personalIdentifier: p.personalIdentifier,
        firstName: p.firstName,
        lastName: p.lastName,
      }));
    resultItem.availableContacts = sortBy(contactsNotDeleted(state), 'firstName', 'lastName')
      .filter((e) => e.isConfirmedEntity)
      .map((p) => ({
        uuid: p.uuid,
        email: p.email,
        personalIdentifier: p.personalIdentifier,
        firstName: p.firstName,
        lastName: p.lastName,
      }));
  }
  return resultItem;
}
