import type { AssetsPageViewModel } from 'src/pages/assets-page/assets-page-view-model.js';
import type { Properties, TableItemsAssets } from 'src/pages/assets-page/d-assets-table.js';
import type { State } from 'src/store/types.js';
import { getOrganizationId } from 'src/store/selectors/organization.js';
import { key } from 'src/store/selectors/selectors';
import {
  assetsDiscardedWithTasks,
  assetsNotDiscardedWithTasks,
  AssetViewModelWithEvents,
} from 'src/store/selectors/organization-assets.js';
import { variantSimplifiedAssets } from 'src/store/selectors/variants.js';
import { BASE_PATH, getPage, getPages } from 'src/store';
import { entityNameForLists } from 'src/utilities/text';
import { PageLinks } from 'src/pages/overview-page/overview-page-view-model';
import { AbstractPageView } from 'src/pages/abstract-page-view';

function toTableItemsAssets(hrefPrefix: string, item: AssetViewModelWithEvents): TableItemsAssets {
  let href = hrefPrefix + 'assets/' + item.uuid;
  if (!item.isConfirmedEntity) {
    href += '?edit';
  }
  return {
    uuid: item.uuid,
    href,
    name: entityNameForLists(item.name, item.draftName, item.isConfirmedEntity),
    number: item.number ?? '',
    category: item.category ?? '',
    location: item.location ?? '',
    requiresElectricity: item.requiresElectricity ?? false,
    hasDraft: item.hasDraft,
    tasksAndEvents: item.events.map((t) => {
      const properties: Properties[] = [];
      if (t.prev !== undefined) {
        properties.push({
          name: t.prev.dateTimeDisplay,
          label: 'Forrige',
          field: '',
          href: t.prev.href !== '' ? hrefPrefix + t.prev.href : '',
          value: t.prev.dateTimeDisplay,
          classes: '', // TODO t.prev.classes,
        });
      } else {
        properties.push({
          name: 'Ingen',
          label: 'Forrige',
          field: '',
          href: '',
          value: 'Ingen',
          classes: '', // TODO t.prev.classes,
        });
      }
      if (t.next !== undefined) {
        properties.push({
          name: t.next.dateTimeDisplay,
          label: 'Neste',
          field: '',
          href: t.next.href !== '' ? hrefPrefix + t.next.href : '',
          value: t.next.dateTimeDisplay,
          classes: '', // TODO t.next.classes,
        });
      } else {
        properties.push({
          name: 'Ingen',
          label: 'Neste',
          field: '',
          href: '',
          value: 'Ingen',
          classes: '', // TODO t.prev.classes,
        });
      }

      return {
        classes: '', // TODO + ' ' + t.classes,
        name: t.name,
        href: '', // TODO Skal dette kunne klikkes på?
        properties: properties,
      };
    }),
  };
}

export function assetsPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): AssetsPageViewModel {
  const linkedPage = getPage(355, getPages(state));
  const pageLinks: PageLinks = {
    type: 'page-links',
    items: [
      {
        name: 'Gå til temasiden ' + linkedPage?.name || '',
        type: '',
        href: '/account/' + getOrganizationId(state) + '/' + 355,
      },
    ],
  };
  return {
    ...viewModel,
    type: 'assets-page',
    href: hrefPrefix,
    icon: 'assets',
    key: key(state),
    assetsNotDiscarded: assetsNotDiscardedWithTasks(state).map((i) => toTableItemsAssets(hrefPrefix, i)),
    assetsDiscarded: assetsDiscardedWithTasks(state).map((i) => toTableItemsAssets(hrefPrefix, i)),
    variantSimplifiedAssets: variantSimplifiedAssets(state),
    pdfHref: BASE_PATH + '/organizations/' + getOrganizationId(state) + '/assets/assets-list.pdf?key=' + key(state),
    pageLinks,
  };
}
