/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface AssetViewModel
 */
export interface AssetViewModel {
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  number?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  owner?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  manufacturer?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  model?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  userManual?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  supplier?: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  radiationType: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  acquiredYear?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  discardedYear?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  accessory?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  createdDateTime: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  competency?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  maintenance?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  maintenanceInterval?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  lastMaintenanceResultOk?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  complianceDeclaration?: string;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  uuid: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  requiresElectricity?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  storesSensitiveInformation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  radiation: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  dsaApproved: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  dsaRegistered: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  maintenanceRequired?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  assetVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  documentationVerified?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  documented?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  lastMaintenanceComment?: boolean;
  /**
   *
   * @type {string}
   * @memberof AssetViewModel
   */
  lastMaintenanceDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof AssetViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof AssetViewModel
   */
  tasks?: Array<string>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof AssetViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * Check if a given object implements the AssetViewModel interface.
 */
export function instanceOfAssetViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'radiationType' in value;
  isInstance = isInstance && 'createdDateTime' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'radiation' in value;
  isInstance = isInstance && 'dsaApproved' in value;
  isInstance = isInstance && 'dsaRegistered' in value;

  return isInstance;
}

export function AssetViewModelFromJSON(json: any): AssetViewModel {
  return AssetViewModelFromJSONTyped(json, false);
}

export function AssetViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    number: !exists(json, 'number') ? undefined : json['number'],
    category: !exists(json, 'category') ? undefined : json['category'],
    owner: !exists(json, 'owner') ? undefined : json['owner'],
    manufacturer: !exists(json, 'manufacturer') ? undefined : json['manufacturer'],
    model: !exists(json, 'model') ? undefined : json['model'],
    location: !exists(json, 'location') ? undefined : json['location'],
    userManual: !exists(json, 'userManual') ? undefined : json['userManual'],
    supplier: !exists(json, 'supplier') ? undefined : json['supplier'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    radiationType: json['radiationType'],
    acquiredYear: !exists(json, 'acquiredYear') ? undefined : json['acquiredYear'],
    discardedYear: !exists(json, 'discardedYear') ? undefined : json['discardedYear'],
    accessory: !exists(json, 'accessory') ? undefined : json['accessory'],
    createdDateTime: json['createdDateTime'],
    competency: !exists(json, 'competency') ? undefined : json['competency'],
    maintenance: !exists(json, 'maintenance') ? undefined : json['maintenance'],
    maintenanceInterval: !exists(json, 'maintenanceInterval') ? undefined : json['maintenanceInterval'],
    lastMaintenanceResultOk: !exists(json, 'lastMaintenanceResultOk') ? undefined : json['lastMaintenanceResultOk'],
    complianceDeclaration: !exists(json, 'complianceDeclaration') ? undefined : json['complianceDeclaration'],
    uuid: json['uuid'],
    requiresElectricity: !exists(json, 'requiresElectricity') ? undefined : json['requiresElectricity'],
    storesSensitiveInformation: !exists(json, 'storesSensitiveInformation')
      ? undefined
      : json['storesSensitiveInformation'],
    radiation: json['radiation'],
    dsaApproved: json['dsaApproved'],
    dsaRegistered: json['dsaRegistered'],
    maintenanceRequired: !exists(json, 'maintenanceRequired') ? undefined : json['maintenanceRequired'],
    assetVerified: !exists(json, 'assetVerified') ? undefined : json['assetVerified'],
    documentationVerified: !exists(json, 'documentationVerified') ? undefined : json['documentationVerified'],
    documented: !exists(json, 'documented') ? undefined : json['documented'],
    lastMaintenanceComment: !exists(json, 'lastMaintenanceComment') ? undefined : json['lastMaintenanceComment'],
    lastMaintenanceDate: !exists(json, 'lastMaintenanceDate') ? undefined : json['lastMaintenanceDate'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    tasks: !exists(json, 'tasks') ? undefined : json['tasks'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function AssetViewModelToJSON(value?: AssetViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    number: value.number,
    category: value.category,
    owner: value.owner,
    manufacturer: value.manufacturer,
    model: value.model,
    location: value.location,
    userManual: value.userManual,
    supplier: value.supplier,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    radiationType: value.radiationType,
    acquiredYear: value.acquiredYear,
    discardedYear: value.discardedYear,
    accessory: value.accessory,
    createdDateTime: value.createdDateTime,
    competency: value.competency,
    maintenance: value.maintenance,
    maintenanceInterval: value.maintenanceInterval,
    lastMaintenanceResultOk: value.lastMaintenanceResultOk,
    complianceDeclaration: value.complianceDeclaration,
    uuid: value.uuid,
    requiresElectricity: value.requiresElectricity,
    storesSensitiveInformation: value.storesSensitiveInformation,
    radiation: value.radiation,
    dsaApproved: value.dsaApproved,
    dsaRegistered: value.dsaRegistered,
    maintenanceRequired: value.maintenanceRequired,
    assetVerified: value.assetVerified,
    documentationVerified: value.documentationVerified,
    documented: value.documented,
    lastMaintenanceComment: value.lastMaintenanceComment,
    lastMaintenanceDate: value.lastMaintenanceDate === undefined ? undefined : value.lastMaintenanceDate,
    deleted: value.deleted,
    tasks: value.tasks,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
