import { css, html, nothing, TemplateResult } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/editors/index.js';
import '../../library/elements/d-tooltip.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import './d-edit-consequence.js';
import './d-edit-probability.js';
import './d-view-risk.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';
import { LocalDate } from 'src/utilities/local-date';
import { ListSectionItemInput } from 'src/library/lists/utilities';

export interface RiskAssessmentView
  extends AbstractEntityViewWithRevisions<RiskAssessmentViewEditItem>,
    UpdateSectionItem {
  violatesNonRepudiationRequirement: boolean;
  violatesIntegrityRequirement: boolean;
  violatesInformationSecurityRequirements: boolean;
  violatesConfidentialityRequirement: boolean;
  violatesAvailabilityRequirement: boolean;
  responsibleBy: string;
  pages: string[];
  determinedBy: string;
  responsibleByDisplayName: string;
  determinedByDisplayName: string;
  measures: string;
  probability: number;
  consequence: number;
  calculatedRisk: number;
  probabilityDisplayText: string;
  consequenceDisplayText: string;
  calculatedRiskDisplayText: string;
  riskDescription: string;
  description: string;
  assessmentDate: string;
  substanceRelated: boolean;
  relatedSubstance?: string;
  relatedSubstanceName: string;
  relatedSubstanceHref: string;
  type: 'riskAssessments';
  name: string;
  availablePages: SelectTagOption[];
  availableEmployees: SelectDropdownOption[];
  informationSecurityDescription: string;
  createdFromTemplate?: number;
  previousAssessments: ListSectionItemInput[];
  isNewest: boolean;
}

export interface RiskAssessmentViewEditItem {
  name: string;
  measures: string;
  probability: number;
  consequence: number;
  riskDescription: string;
  description: string;
  assessmentDate: string;
  violatesInformationSecurityRequirements: boolean;
  violatesConfidentialityRequirement: boolean;
  violatesIntegrityRequirement: boolean;
  violatesAvailabilityRequirement: boolean;
  violatesNonRepudiationRequirement: boolean;
  determinedBy: string;
  responsibleBy: string;
  pages: string[];
  createdFromTemplate?: number;
  relatedSubstance?: string;
}

/**
 *
 */
@customElement('d-risk-assessment-view')
export class DRiskAssessmentView extends EntityContent<RiskAssessmentView, RiskAssessmentViewEditItem> {
  static readonly styles = EntityContent.styles.concat(css`
    .substance {
      display: block;
      border-top: 1px solid var(--borderColor);
      border-bottom: 1px solid var(--borderColor);
      padding: 12px 0 12px calc(var(--listPaddingLeft) + 12px);
      background-image: url(/images/substances-color.svg);
      background-size: 30px 30px;
      background-position: 10px 8px;
      background-repeat: no-repeat;
      color: var(--themeColorDarkerOne);
      text-decoration: none;
    }
    @media (hover: hover) {
      .substance:hover {
        color: black;
      }
    }
  `);
  @property({ type: Boolean })
  duplicatable = true;
  @property({ type: Object })
  fieldsByCode = {};
  @property({ type: String })
  defaultHelpForSubstancesRiskAssessment = '';
  @state()
  editingNewRepeated = false;

  async onDone() {
    this.editingNewRepeated = false;
    return super.onDone();
  }

  protected get currentItemLabel() {
    let name = this.entityView.name ?? '';
    if (this.entityView.relatedSubstanceName) {
      name = this.entityView.relatedSubstanceName + ': ' + name;
    }
    return name;
  }
  protected get editName() {
    if (this.entityView.relatedSubstanceName) {
      const name = this.entityView.name ?? '';
      return this.entityView.relatedSubstanceName + ': ' + name;
    }
    return super.editName;
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'assessmentDate',
        type: 'view-date',
        format: 'date-short',
        class: 'fullWidth',
      },
      {
        field: 'description',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'informationSecurityDescription',
        type: 'view-text',
        class: 'fullWidth',
        condition: !this.entityView.substanceRelated,
      },
      {
        field: 'probabilityDisplayText',
        fieldForLabel: 'probability',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'consequenceDisplayText',
        fieldForLabel: 'consequence',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'riskDescription',
        fieldForLabel: 'consequenceDescription',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'calculatedRiskDisplayText',
        fieldForLabel: 'calculatedRisk',
        type: 'view-text',
        class: 'fullWidth',
        condition: false,
      },
      {
        field: 'calculatedRisk',
        type: 'view-risk',
        class: 'fullWidth',
        excludeFromPdf: true,
      },
      {
        field: 'measures',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.consequence * this.entityView.probability > 4,
      },
      {
        field: 'determinedBy',
        options: this.entityView.availableEmployees,
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.consequence * this.entityView.probability > 4,
      },
      {
        field: 'responsibleBy',
        options: this.entityView.availableEmployees,
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.consequence * this.entityView.probability > 4,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'riskAssessments_previousAssessments',
        icon: 'riskAssessments',
        items: 'previousAssessments',
        condition: this.entityView.previousAssessments.length > 0,
      },
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html`${this.renderFieldSection('riskAssessments', this.viewFields(), this.entityView)} `;
  }

  _helpContentTODO(
    item,
    templateIdForNew,
    suggestedRiskAssessments,
    substanceRelated,
    defaultHelpForSubstancesRiskAssessment,
  ) {
    if (item.createdFromTemplate || templateIdForNew) {
      let templateHelp = '';
      const templates = suggestedRiskAssessments.filter(function (r) {
        return r.pageId === item.createdFromTemplate || r.pageId === templateIdForNew;
      });
      if (templates.length) {
        templateHelp = templates[0].helpContent;
      }
      if (templateHelp) {
        return templateHelp;
      }
    }
    if (substanceRelated) {
      return defaultHelpForSubstancesRiskAssessment;
    }
    return item.helpContent;
  }

  _helpOpened(templateIdForNew) {
    return !!templateIdForNew;
  }

  _computeValue(consequence, probability) {
    return consequence * probability;
  }

  _computeHidden(consequence, probability) {
    return consequence * probability < 5;
  }

  async initializeEditItem() {
    let assessmentDate = this.entityView.assessmentDate;
    if (assessmentDate === '') {
      assessmentDate = LocalDate.now().toString();
    }
    this.editItem = {
      assessmentDate,
      consequence: this.entityView.consequence,
      description: this.entityView.description,
      determinedBy: this.entityView.determinedBy,
      measures: this.entityView.measures,
      pages: this.entityView.pages,
      probability: this.entityView.probability,
      responsibleBy: this.entityView.responsibleBy,
      riskDescription: this.entityView.riskDescription,
      violatesAvailabilityRequirement: this.entityView.violatesAvailabilityRequirement,
      violatesConfidentialityRequirement: this.entityView.violatesConfidentialityRequirement,
      violatesInformationSecurityRequirements: this.entityView.violatesInformationSecurityRequirements,
      violatesIntegrityRequirement: this.entityView.violatesIntegrityRequirement,
      violatesNonRepudiationRequirement: this.entityView.violatesNonRepudiationRequirement,
      name: this.entityView.name,
      createdFromTemplate: this.entityView.createdFromTemplate,
      relatedSubstance: this.entityView.relatedSubstance,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  renderPreContent() {
    if (this.entityView.substanceRelated) {
      return html` <d-section
        ><a class="substance" href="${this.entityView.relatedSubstanceHref}"
          >${this.entityView.relatedSubstanceName}</a
        ></d-section
      >`;
    }
    return html``;
  }

  async repeatRiskAssessment() {
    this.editingNewRepeated = true;
    const editItem: RiskAssessmentViewEditItem = {
      name: this.entityView.name,
      assessmentDate: LocalDate.now().toString(),
      consequence: 0,
      description: this.entityView.description,
      determinedBy: '',
      measures: '',
      pages: this.entityView.pages,
      probability: 0,
      responsibleBy: '',
      riskDescription: this.entityView.riskDescription,
      violatesAvailabilityRequirement: this.entityView.violatesAvailabilityRequirement,
      violatesConfidentialityRequirement: this.entityView.violatesConfidentialityRequirement,
      violatesInformationSecurityRequirements: this.entityView.violatesInformationSecurityRequirements,
      violatesIntegrityRequirement: this.entityView.violatesIntegrityRequirement,
      violatesNonRepudiationRequirement: this.entityView.violatesNonRepudiationRequirement,
      createdFromTemplate: this.entityView.createdFromTemplate,
      relatedSubstance: this.entityView.relatedSubstance,
    };
    this.dispatchEvent(
      new CustomEvent('save-as-new', {
        bubbles: true,
        composed: true,
        detail: {
          editItem,
        },
      }),
    );
  }

  renderEditItem(item: RiskAssessmentViewEditItem): TemplateResult<1> {
    return html`
      ${(item.createdFromTemplate === undefined || item.createdFromTemplate === null) && !this.editingNewRepeated
        ? html` <d-section
            ><d-edit-text
              field="riskAssessments_name"
              .autofocus=${!this.entityView.isConfirmedEntity}
              .selectOnFocus=${!this.entityView.isConfirmedEntity}
              placeholder="Dette feltet må fylles ut"
              mark-if-empty
              .value=${item.name}
              @value-changed=${(e: CustomEvent<{ value: string }>) => {
                this.editItem = { ...item, name: e.detail.value };
              }}
            >
            </d-edit-text
          ></d-section>`
        : html`<d-section>
            <d-view-text field="riskAssessments_name" .value=${this.entityView.name}></d-view-text>
          </d-section>`}

      <d-section>
        <d-select-date
          field="riskAssessments_assessmentDate"
          .value=${item.assessmentDate}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, assessmentDate: e.detail.value };
          }}
        >
        </d-select-date>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="riskAssessments_description"
          .value=${item.description}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, description: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      ${this.entityView.substanceRelated
        ? nothing
        : html`<div>
            <d-section>
              <d-checkbox
                ?checked=${item.violatesInformationSecurityRequirements}
                option-field="riskAssessments_violatesInformationSecurityRequirements"
                @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                  this.editItem = { ...item, violatesInformationSecurityRequirements: e.detail.checked };
                }}
              >
              </d-checkbox>
            </d-section>

            ${item.violatesInformationSecurityRequirements
              ? html`<d-section field="riskAssessments_informationSecurityDescription">
                  <d-checkbox
                    ?checked=${item.violatesConfidentialityRequirement}
                    option-field="riskAssessments_violatesConfidentialityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesConfidentialityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesIntegrityRequirement}
                    option-field="riskAssessments_violatesIntegrityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesIntegrityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesAvailabilityRequirement}
                    option-field="riskAssessments_violatesAvailabilityRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesAvailabilityRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                  <d-checkbox
                    ?checked=${item.violatesNonRepudiationRequirement}
                    option-field="riskAssessments_violatesNonRepudiationRequirement"
                    @checked-changed=${(e: CustomEvent<{ checked: boolean }>) => {
                      this.editItem = { ...item, violatesNonRepudiationRequirement: e.detail.checked };
                    }}
                  >
                  </d-checkbox>
                </d-section>`
              : nothing}
          </div>`}

      <d-section>
        <d-edit-probability
          .value=${item.probability}
          @value-changed=${(e: CustomEvent<{ value: number }>) => {
            this.editItem = { ...item, probability: e.detail.value };
          }}
        ></d-edit-probability>
      </d-section>

      <d-section>
        <d-edit-consequence
          .value=${item.consequence}
          @value-changed=${(e: CustomEvent<{ value: number }>) => {
            this.editItem = { ...item, consequence: e.detail.value };
          }}
        ></d-edit-consequence>
      </d-section>

      <d-section>
        <d-edit-textarea
          field="riskAssessments_consequenceDescription"
          .value=${item.riskDescription}
          @value-changed=${(e: CustomEvent<{ value: string }>) => {
            this.editItem = { ...item, riskDescription: e.detail.value };
          }}
        >
        </d-edit-textarea>
      </d-section>

      <d-section>
        <d-view-risk .value=${this._computeValue(item.consequence, item.probability)}> </d-view-risk>
      </d-section>

      ${this._computeHidden(item.consequence, item.probability)
        ? nothing
        : html`<d-section>
              <d-edit-textarea
                field="riskAssessments_measures"
                .value=${item.measures}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, measures: e.detail.value };
                }}
              >
              </d-edit-textarea>
            </d-section>
            <d-section>
              <d-select-dropdown
                class="minWidth300"
                field="riskAssessments_determinedBy"
                placeholder="Ingen"
                .options=${this.entityView.availableEmployees}
                .value=${item.determinedBy}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, determinedBy: e.detail.value };
                }}
              >
              </d-select-dropdown>

              <d-select-dropdown
                class="minWidth300"
                field="riskAssessments_responsibleBy"
                placeholder="Ingen"
                .options=${this.entityView.availableEmployees}
                .value=${item.responsibleBy}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.editItem = { ...item, responsibleBy: e.detail.value };
                }}
              >
              </d-select-dropdown>
            </d-section>`}
    `;
  }

  protected renderEditSelectPages(item: any): TemplateResult<1> {
    if (this.entityView.isNewest && !this.editingNewRepeated) {
      return super.renderEditSelectPages(item);
    }
    return html``;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-risk-assessment-view': DRiskAssessmentView;
  }
}
