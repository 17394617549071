import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { OrganizationEditItem } from 'src/pages/organization-page/d-organization-edit.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import '../../library/components/d-field-section';
import type { PageViewModel } from 'src/store/api';
import './d-organization-edit.js';
import type { OrganizationPageViewModel } from './organization-page-view-model.js';
import { SectionField } from 'src/library/components/d-field-section';
import { sectorOptions } from 'src/store';
import { ListDefinition } from 'src/content/entity-content';

/**
 *
 */
@customElement('d-organization-page-content')
export class DOrganizationPageContent extends PageContent<OrganizationPageViewModel> {
  @property({ type: Object })
  queryParams = {};
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Object })
  editItem?: OrganizationEditItem;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  protected get currentItemLabel() {
    return this.pageView.name;
  }

  _queryModeChanged(mode) {
    if (mode === 'edit') {
      setTimeout(() => {
        window.history.replaceState(null, '', window.location.pathname);
        this.editModeOn();
      }, 50);
    }
  }

  editModeOn() {
    this.editMode = true;
    // TODO this.scroller.scrollTop = 0;
  }

  editModeOff() {
    this.editItem = undefined;
    this.editMode = false;
  }

  _codes(codes, activityCodes) {
    let result = '';

    if (activityCodes && codes) {
      const names: string[] = [];

      codes.forEach((code) => {
        if (activityCodes.indexOf(code.code) !== -1) {
          if (code.codes) {
            const r = this._codes(code.codes, activityCodes);

            if (r && r !== '') {
              names.push(r);
            } else {
              names.push(code.custom || code.name);
            }
          } else {
            names.push(code.custom || code.name);
          }
        }
      });

      result = names.join(', ');
    }

    return result;
  }

  onShareContent() {
    this.dispatchEvent(new CustomEvent('open-send-message', { bubbles: true, composed: true, detail: {} }));
  }

  renderEditItem(item: OrganizationEditItem): TemplateResult<1> {
    return html`<d-organization-edit
      theme-page
      .contentStickyTop=${this.contentStickyTop}
      .editItem=${item}
      .featureStates=${this.featureStates}
      .docsForLinking=${this.pageView.docsForLinking}
      @edit-item-changed=${this.onValueChanged}
    ></d-organization-edit>`;
  }

  renderEditMode() {
    if (this.editItem === undefined) {
      this.initializeEditItem();
    }

    if (this.editItem === undefined) {
      return html``;
    }

    return this.renderEditItem(this.editItem);
  }

  private singleEmployeeValue() {
    if (this.pageView.singleEmployee) {
      return this.pageView.singleEmployee.profession + ' ' + this.pageView.singleEmployee.name;
    }
    return '';
  }
  protected viewFields(): SectionField[] {
    return [
      {
        field: 'sector',
        type: 'view-text',
        options: sectorOptions,
        class: 'minWidth300',
      },
      {
        field: 'activityCodesDisplayText',
        fieldForLabel: 'activityCodes',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'businessEntityType',
        type: 'view-text',
        class: 'minWidth300',
        noValueValue: 'Ikke angitt',
      },
      {
        field: 'organizationNumber',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'herNumber',
        type: 'view-text',
        class: 'minWidth300',
        hideIfEmpty: true,
        condition: this.featureStates.core && this.pageView.sector === 'HEALTH',
      },
      {
        field: 'phone',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'url',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'email',
        type: 'view-text',
        class: 'minWidth200',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'officeAddress',
        type: 'view-text',
        class: 'fullWidth',
        noWrapLabel: true,
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: 'mailAddress',
        type: 'view-text',
        class: 'fullWidth',
        noWrapLabel: true,
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
      {
        field: '',
        label: this.pageView.singleEmployee ? this.pageView.singleEmployee.associationType : '',
        value: () => this.singleEmployeeValue(),
        type: 'view-text',
        class: 'fullWidth',
        action: {
          name: 'Mer om meg',
          href: this.pageView.singleEmployee ? this.pageView.singleEmployee.href : '',
        },
        condition: this.singleUserVersion,
      },
      {
        field: 'notes',
        type: 'view-html',
        class: 'fullWidth',
        hideIfEmpty: true,
        condition: this.featureStates.core,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'organization_constitutionalDocuments',
        icon: 'constitutionalDocuments',
        items: 'constitutionalDocuments',
        condition: this.featureStates.core,
      },
    ];
  }

  renderContent() {
    return html`
      ${this.editMode
        ? this.renderEditMode()
        : html`${this.renderFieldSection('organization', this.viewFields(), this.pageView)} ${this.renderListSections()} `}
    `;
  }

  showEdit() {
    this.editModeOn();
  }

  cancelEdit() {
    this.editModeOff();
  }

  doneEdit() {
    if (this.editItem !== undefined) {
      this.dispatchEvent(
        new CustomEvent<OrganizationEditItem>('save-organization', {
          composed: true,
          bubbles: true,
          detail: this.editItem,
        }),
      );
    }
    this.editModeOff();
  }

  protected renderHelp() {
    if (this.featureStates.core) {
      return super.renderHelp();
    }
    return html``;
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    if (this.editMode) {
      return this.renderEditModeActions();
    }
    return this.renderViewModeActions();
  }

  private renderEditModeActions() {
    return html`<d-action @click=${() => this.cancelEdit()}>Avbryt</d-action
      ><d-action @click=${() => this.doneEdit()}>Ferdig</d-action>`;
  }

  private renderEditAction(): TemplateResult | typeof nothing {
    if (this.writeAccess) {
      return html` <d-action @click=${() => this.editModeOn()}>Rediger</d-action>`;
    }
    return nothing;
  }

  private renderShareAction(): TemplateResult | typeof nothing {
    if (!this.singleUserVersion) {
      return html` <d-action @click=${() => this.onShareContent()}>Del</d-action>`;
    }
    return nothing;
  }

  private renderPdfAction() {
    return html`<d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>`;
  }

  private renderViewModeActions() {
    return html` ${this.renderPdfAction()} ${this.renderEditAction()} ${this.renderShareAction()}`;
  }

  private onValueChanged(e: CustomEvent<OrganizationEditItem>) {
    e.stopPropagation();
    this.editItem = { ...e.detail };
  }

  private initializeEditItem() {
    this.editItem = {
      notes: this.pageView.notes ?? '',
      organizationNumber: this.pageView.organizationNumber ?? '',
      activityCodes: [...this.pageView.activityCodes],
      businessEntityType: this.pageView.businessEntityType ?? '',
      mailAddress: this.pageView.mailAddress ?? '',
      officeAddress: this.pageView.officeAddress ?? '',
      url: this.pageView.url ?? '',
      phone: this.pageView.phone ?? '',
      name: this.pageView.name ?? '',
      herNumber: this.pageView.herNumber ?? '',
      fax: this.pageView.fax ?? '',
      email: this.pageView.email ?? '',
      sector: this.pageView.sector,
    };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-page-content': DOrganizationPageContent;
  }
}
