/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  CreateMeetingOccurrenceCommand,
  CreateMeetingOccurrenceReportCommand,
  DeleteMeetingOccurrenceCommand,
  MarkMeetingOccurrenceDoneMessage,
  MarkMeetingOccurrenceNotDoneMessage,
  MeetingOccurrenceViewModel,
  RepeatMeetingOccurrenceCommand,
  RequestRoomCodeCommand,
  RequestRoomCodeResponse,
  SendMeetingOccurrenceNoticeCommand,
  SendMeetingOccurrenceReportCommand,
  UpdateMeetingOccurrenceCommand,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  CreateMeetingOccurrenceCommandFromJSON,
  CreateMeetingOccurrenceCommandToJSON,
  CreateMeetingOccurrenceReportCommandFromJSON,
  CreateMeetingOccurrenceReportCommandToJSON,
  DeleteMeetingOccurrenceCommandFromJSON,
  DeleteMeetingOccurrenceCommandToJSON,
  MarkMeetingOccurrenceDoneMessageFromJSON,
  MarkMeetingOccurrenceDoneMessageToJSON,
  MarkMeetingOccurrenceNotDoneMessageFromJSON,
  MarkMeetingOccurrenceNotDoneMessageToJSON,
  MeetingOccurrenceViewModelFromJSON,
  MeetingOccurrenceViewModelToJSON,
  RepeatMeetingOccurrenceCommandFromJSON,
  RepeatMeetingOccurrenceCommandToJSON,
  RequestRoomCodeCommandFromJSON,
  RequestRoomCodeCommandToJSON,
  RequestRoomCodeResponseFromJSON,
  RequestRoomCodeResponseToJSON,
  SendMeetingOccurrenceNoticeCommandFromJSON,
  SendMeetingOccurrenceNoticeCommandToJSON,
  SendMeetingOccurrenceReportCommandFromJSON,
  SendMeetingOccurrenceReportCommandToJSON,
  UpdateMeetingOccurrenceCommandFromJSON,
  UpdateMeetingOccurrenceCommandToJSON,
} from '../models';

export interface CreateMeetingOccurrenceRequest {
  organizationId: string;
  createMeetingOccurrenceCommand: CreateMeetingOccurrenceCommand;
}

export interface CreateMeetingOccurrenceReportRequest {
  organizationId: string;
  createMeetingOccurrenceReportCommand: CreateMeetingOccurrenceReportCommand;
}

export interface DeleteAttachmentForMeetingOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteMeetingOccurrenceRequest {
  organizationId: string;
  deleteMeetingOccurrenceCommand: DeleteMeetingOccurrenceCommand;
}

export interface FetchAttachmentForMeetingOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchMeetingOccurrenceRequest {
  meetingOccurrenceId: string;
  organizationId: string;
}

export interface ListAttachmentsForMeetingOccurrenceRequest {
  entityId: string;
  organizationId: string;
}

export interface ListMeetingOccurrencesRequest {
  organizationId: string;
}

export interface MarkMeetingOccurrenceDoneRequest {
  organizationId: string;
  markMeetingOccurrenceDoneMessage: MarkMeetingOccurrenceDoneMessage;
}

export interface MarkMeetingOccurrenceNotDoneRequest {
  organizationId: string;
  markMeetingOccurrenceNotDoneMessage: MarkMeetingOccurrenceNotDoneMessage;
}

export interface RenameAttachmentForMeetingOccurrenceRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface RepeatMeetingOccurrenceRequest {
  organizationId: string;
  repeatMeetingOccurrenceCommand: RepeatMeetingOccurrenceCommand;
}

export interface RequestRoomCodeRequest {
  organizationId: string;
  requestRoomCodeCommand: RequestRoomCodeCommand;
}

export interface SendMeetingOccurrenceNoticeRequest {
  organizationId: string;
  sendMeetingOccurrenceNoticeCommand: SendMeetingOccurrenceNoticeCommand;
}

export interface SendMeetingOccurrenceReportRequest {
  organizationId: string;
  sendMeetingOccurrenceReportCommand: SendMeetingOccurrenceReportCommand;
}

export interface UpdateMeetingOccurrenceRequest {
  organizationId: string;
  updateMeetingOccurrenceCommand: UpdateMeetingOccurrenceCommand;
}

export interface UploadAttachmentForMeetingOccurrenceRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class MeetingOccurrencesApi extends runtime.BaseAPI {
  /**
   * Oppretter et nytt møte. Der er tre typer møter, basert på en mal, medarbeidersamtaler eller frittstående møter.  Dette er avhengig av den angitte verdien for `meetingUuid` som kan referere til enten en møtemal, en ansatt eller  en annen møteinstans. Dersom møtet er basert på en mal vil standardverdiene overføres fra det sist tilgjengelige  møtet som gjeldende bruker har tilgang til. Dersom der ikke er et slikt møte i fortiden vil det første neste møtet  gjelde som mal.  Dersom møtet er basert på en modelhendelse vil feltene for `modelOccurrenceName` og `modelOccurrenceDate` være  utfylt.
   */
  async createMeetingOccurrenceRaw(
    requestParameters: CreateMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createMeetingOccurrence.',
      );
    }

    if (
      requestParameters.createMeetingOccurrenceCommand === null ||
      requestParameters.createMeetingOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'createMeetingOccurrenceCommand',
        'Required parameter requestParameters.createMeetingOccurrenceCommand was null or undefined when calling createMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:create-meeting-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateMeetingOccurrenceCommandToJSON(requestParameters.createMeetingOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppretter et nytt møte. Der er tre typer møter, basert på en mal, medarbeidersamtaler eller frittstående møter.  Dette er avhengig av den angitte verdien for `meetingUuid` som kan referere til enten en møtemal, en ansatt eller  en annen møteinstans. Dersom møtet er basert på en mal vil standardverdiene overføres fra det sist tilgjengelige  møtet som gjeldende bruker har tilgang til. Dersom der ikke er et slikt møte i fortiden vil det første neste møtet  gjelde som mal.  Dersom møtet er basert på en modelhendelse vil feltene for `modelOccurrenceName` og `modelOccurrenceDate` være  utfylt.
   */
  async createMeetingOccurrence(
    requestParameters: CreateMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Opprettet et nytt møtereferat. Møtereferatet navngies i henhold til den underliggende møtehendelsen. Referatet  fylles ut med kopi av agendaen, og også med angivelse av deltagerne på møtet. Dersom møtet har begrenset tilgang vil også referatet få det.
   */
  async createMeetingOccurrenceReportRaw(
    requestParameters: CreateMeetingOccurrenceReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createMeetingOccurrenceReport.',
      );
    }

    if (
      requestParameters.createMeetingOccurrenceReportCommand === null ||
      requestParameters.createMeetingOccurrenceReportCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'createMeetingOccurrenceReportCommand',
        'Required parameter requestParameters.createMeetingOccurrenceReportCommand was null or undefined when calling createMeetingOccurrenceReport.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:create-meeting-occurrence-report`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateMeetingOccurrenceReportCommandToJSON(requestParameters.createMeetingOccurrenceReportCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Opprettet et nytt møtereferat. Møtereferatet navngies i henhold til den underliggende møtehendelsen. Referatet  fylles ut med kopi av agendaen, og også med angivelse av deltagerne på møtet. Dersom møtet har begrenset tilgang vil også referatet få det.
   */
  async createMeetingOccurrenceReport(
    requestParameters: CreateMeetingOccurrenceReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createMeetingOccurrenceReportRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deleteAttachmentForMeetingOccurrenceRaw(
    requestParameters: DeleteAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async deleteAttachmentForMeetingOccurrence(
    requestParameters: DeleteAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Sletter et møte. Denne metoden kan også slette tidligere møter og møter med innkallelse og / eller referat. Dersom `saveType` har verdien `FUTURE` vil også fremtidige møter slettes dersom brukeren har tilgang til dem og  forutsatt at der ikke er sendt innkallelse eller laget referat.
   */
  async deleteMeetingOccurrenceRaw(
    requestParameters: DeleteMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteMeetingOccurrence.',
      );
    }

    if (
      requestParameters.deleteMeetingOccurrenceCommand === null ||
      requestParameters.deleteMeetingOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'deleteMeetingOccurrenceCommand',
        'Required parameter requestParameters.deleteMeetingOccurrenceCommand was null or undefined when calling deleteMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:delete-meeting-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DeleteMeetingOccurrenceCommandToJSON(requestParameters.deleteMeetingOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sletter et møte. Denne metoden kan også slette tidligere møter og møter med innkallelse og / eller referat. Dersom `saveType` har verdien `FUTURE` vil også fremtidige møter slettes dersom brukeren har tilgang til dem og  forutsatt at der ikke er sendt innkallelse eller laget referat.
   */
  async deleteMeetingOccurrence(
    requestParameters: DeleteMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   */
  async fetchAttachmentForMeetingOccurrenceRaw(
    requestParameters: FetchAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   */
  async fetchAttachmentForMeetingOccurrence(
    requestParameters: FetchAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForMeetingOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async fetchMeetingOccurrenceRaw(
    requestParameters: FetchMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MeetingOccurrenceViewModel>> {
    if (requestParameters.meetingOccurrenceId === null || requestParameters.meetingOccurrenceId === undefined) {
      throw new runtime.RequiredError(
        'meetingOccurrenceId',
        'Required parameter requestParameters.meetingOccurrenceId was null or undefined when calling fetchMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{meetingOccurrenceId}`
          .replace(`{${'meetingOccurrenceId'}}`, encodeURIComponent(String(requestParameters.meetingOccurrenceId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => MeetingOccurrenceViewModelFromJSON(jsonValue));
  }

  /**
   */
  async fetchMeetingOccurrence(
    requestParameters: FetchMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MeetingOccurrenceViewModel> {
    const response = await this.fetchMeetingOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listAttachmentsForMeetingOccurrenceRaw(
    requestParameters: ListAttachmentsForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   */
  async listAttachmentsForMeetingOccurrence(
    requestParameters: ListAttachmentsForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForMeetingOccurrenceRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async listMeetingOccurrencesRaw(
    requestParameters: ListMeetingOccurrencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<MeetingOccurrenceViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listMeetingOccurrences.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingOccurrenceViewModelFromJSON));
  }

  /**
   */
  async listMeetingOccurrences(
    requestParameters: ListMeetingOccurrencesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<MeetingOccurrenceViewModel>> {
    const response = await this.listMeetingOccurrencesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Oppdaterer en ikke utført oppgave som utført.
   */
  async markMeetingOccurrenceDoneRaw(
    requestParameters: MarkMeetingOccurrenceDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling markMeetingOccurrenceDone.',
      );
    }

    if (
      requestParameters.markMeetingOccurrenceDoneMessage === null ||
      requestParameters.markMeetingOccurrenceDoneMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'markMeetingOccurrenceDoneMessage',
        'Required parameter requestParameters.markMeetingOccurrenceDoneMessage was null or undefined when calling markMeetingOccurrenceDone.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:mark-meeting-occurrence-done`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MarkMeetingOccurrenceDoneMessageToJSON(requestParameters.markMeetingOccurrenceDoneMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer en ikke utført oppgave som utført.
   */
  async markMeetingOccurrenceDone(
    requestParameters: MarkMeetingOccurrenceDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markMeetingOccurrenceDoneRaw(requestParameters, initOverrides);
  }

  /**
   * Oppdaterer en utført oppgave som ikke utført.
   */
  async markMeetingOccurrenceNotDoneRaw(
    requestParameters: MarkMeetingOccurrenceNotDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling markMeetingOccurrenceNotDone.',
      );
    }

    if (
      requestParameters.markMeetingOccurrenceNotDoneMessage === null ||
      requestParameters.markMeetingOccurrenceNotDoneMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'markMeetingOccurrenceNotDoneMessage',
        'Required parameter requestParameters.markMeetingOccurrenceNotDoneMessage was null or undefined when calling markMeetingOccurrenceNotDone.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:mark-meeting-occurrence-not-done`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MarkMeetingOccurrenceNotDoneMessageToJSON(requestParameters.markMeetingOccurrenceNotDoneMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer en utført oppgave som ikke utført.
   */
  async markMeetingOccurrenceNotDone(
    requestParameters: MarkMeetingOccurrenceNotDoneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markMeetingOccurrenceNotDoneRaw(requestParameters, initOverrides);
  }

  /**
   */
  async renameAttachmentForMeetingOccurrenceRaw(
    requestParameters: RenameAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForMeetingOccurrence.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async renameAttachmentForMeetingOccurrence(
    requestParameters: RenameAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Gjentar et gitt møte fremover. Alle datoer for nye møter og instanser av møter som fjernes må være etter det  gitte møte.  Nye møter som opprettes kan ikke opprettes på samme dag som eksisterende møter, selv om brukeren f.eks. ikke har  tilgang til disse. Møter som fjernes med denne metoden kan ikke ha sendt innkallelse eller ha møtereferat. Gjeldende bruker må også  ha tilgang til disse møtene.
   */
  async repeatMeetingOccurrenceRaw(
    requestParameters: RepeatMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling repeatMeetingOccurrence.',
      );
    }

    if (
      requestParameters.repeatMeetingOccurrenceCommand === null ||
      requestParameters.repeatMeetingOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'repeatMeetingOccurrenceCommand',
        'Required parameter requestParameters.repeatMeetingOccurrenceCommand was null or undefined when calling repeatMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:repeat-meeting-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RepeatMeetingOccurrenceCommandToJSON(requestParameters.repeatMeetingOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Gjentar et gitt møte fremover. Alle datoer for nye møter og instanser av møter som fjernes må være etter det  gitte møte.  Nye møter som opprettes kan ikke opprettes på samme dag som eksisterende møter, selv om brukeren f.eks. ikke har  tilgang til disse. Møter som fjernes med denne metoden kan ikke ha sendt innkallelse eller ha møtereferat. Gjeldende bruker må også  ha tilgang til disse møtene.
   */
  async repeatMeetingOccurrence(
    requestParameters: RepeatMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.repeatMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   * Henter \"room-code\" for en gitt bruker. Vil eventuelt opprette et rom for møtet og en kode for deltageren. Vil  også sjekke at nøkkelen er \"enabled\"
   */
  async requestRoomCodeRaw(
    requestParameters: RequestRoomCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<RequestRoomCodeResponse>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling requestRoomCode.',
      );
    }

    if (requestParameters.requestRoomCodeCommand === null || requestParameters.requestRoomCodeCommand === undefined) {
      throw new runtime.RequiredError(
        'requestRoomCodeCommand',
        'Required parameter requestParameters.requestRoomCodeCommand was null or undefined when calling requestRoomCode.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:request-room-code`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RequestRoomCodeCommandToJSON(requestParameters.requestRoomCodeCommand),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => RequestRoomCodeResponseFromJSON(jsonValue));
  }

  /**
   * Henter \"room-code\" for en gitt bruker. Vil eventuelt opprette et rom for møtet og en kode for deltageren. Vil  også sjekke at nøkkelen er \"enabled\"
   */
  async requestRoomCode(
    requestParameters: RequestRoomCodeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RequestRoomCodeResponse> {
    const response = await this.requestRoomCodeRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Sender møteinnkallelse til deltagerne på møtet. Kun deltagere som har tilgang til trinnvis (og derved også har registrert en e-post) vil motta innkallelsen.  Avsenderen mottar ikke selv innkallelse. Dersom møtet har begrenset tilgang vil allikevel alle deltagerne på møtet motta innkallelse, eventuelt også inkludert agenda.
   */
  async sendMeetingOccurrenceNoticeRaw(
    requestParameters: SendMeetingOccurrenceNoticeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendMeetingOccurrenceNotice.',
      );
    }

    if (
      requestParameters.sendMeetingOccurrenceNoticeCommand === null ||
      requestParameters.sendMeetingOccurrenceNoticeCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'sendMeetingOccurrenceNoticeCommand',
        'Required parameter requestParameters.sendMeetingOccurrenceNoticeCommand was null or undefined when calling sendMeetingOccurrenceNotice.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:send-meeting-occurrence-notice`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendMeetingOccurrenceNoticeCommandToJSON(requestParameters.sendMeetingOccurrenceNoticeCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sender møteinnkallelse til deltagerne på møtet. Kun deltagere som har tilgang til trinnvis (og derved også har registrert en e-post) vil motta innkallelsen.  Avsenderen mottar ikke selv innkallelse. Dersom møtet har begrenset tilgang vil allikevel alle deltagerne på møtet motta innkallelse, eventuelt også inkludert agenda.
   */
  async sendMeetingOccurrenceNotice(
    requestParameters: SendMeetingOccurrenceNoticeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendMeetingOccurrenceNoticeRaw(requestParameters, initOverrides);
  }

  /**
   * Sender link til møtereferatet til deltagerne på møtet. Kun deltagere som har tilgang til trinnvis (og derved også har registrert en e-post) vil motta referatet.  Avsenderen mottar ikke selv referatet. Dersom møtet eller referatet har begrenset tilgang vil allikevel alle deltagerne på møtet motta referatet, eventuelt også inkludert selve teksten.
   */
  async sendMeetingOccurrenceReportRaw(
    requestParameters: SendMeetingOccurrenceReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling sendMeetingOccurrenceReport.',
      );
    }

    if (
      requestParameters.sendMeetingOccurrenceReportCommand === null ||
      requestParameters.sendMeetingOccurrenceReportCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'sendMeetingOccurrenceReportCommand',
        'Required parameter requestParameters.sendMeetingOccurrenceReportCommand was null or undefined when calling sendMeetingOccurrenceReport.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:send-meeting-occurrence-report`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendMeetingOccurrenceReportCommandToJSON(requestParameters.sendMeetingOccurrenceReportCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Sender link til møtereferatet til deltagerne på møtet. Kun deltagere som har tilgang til trinnvis (og derved også har registrert en e-post) vil motta referatet.  Avsenderen mottar ikke selv referatet. Dersom møtet eller referatet har begrenset tilgang vil allikevel alle deltagerne på møtet motta referatet, eventuelt også inkludert selve teksten.
   */
  async sendMeetingOccurrenceReport(
    requestParameters: SendMeetingOccurrenceReportRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendMeetingOccurrenceReportRaw(requestParameters, initOverrides);
  }

  /**
   * Oppdaterer et møte.  Dersom `saveType`har verdien `FUTURE` vil også etterfølgende møter oppdateres. Etterfølgende møter beregnes basert  på den nye dato i møte. Dersom datoen endres vil fremtidige møter endres dato med tilsvarende periode. Klokkeslett endres ikke relativt.  Denne blir satt til samme verdi som det lagrede møte.
   */
  async updateMeetingOccurrenceRaw(
    requestParameters: UpdateMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling updateMeetingOccurrence.',
      );
    }

    if (
      requestParameters.updateMeetingOccurrenceCommand === null ||
      requestParameters.updateMeetingOccurrenceCommand === undefined
    ) {
      throw new runtime.RequiredError(
        'updateMeetingOccurrenceCommand',
        'Required parameter requestParameters.updateMeetingOccurrenceCommand was null or undefined when calling updateMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/:update-meeting-occurrence`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateMeetingOccurrenceCommandToJSON(requestParameters.updateMeetingOccurrenceCommand),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Oppdaterer et møte.  Dersom `saveType`har verdien `FUTURE` vil også etterfølgende møter oppdateres. Etterfølgende møter beregnes basert  på den nye dato i møte. Dersom datoen endres vil fremtidige møter endres dato med tilsvarende periode. Klokkeslett endres ikke relativt.  Denne blir satt til samme verdi som det lagrede møte.
   */
  async updateMeetingOccurrence(
    requestParameters: UpdateMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateMeetingOccurrenceRaw(requestParameters, initOverrides);
  }

  /**
   */
  async uploadAttachmentForMeetingOccurrenceRaw(
    requestParameters: UploadAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForMeetingOccurrence.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForMeetingOccurrence.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/meetingOccurrences/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async uploadAttachmentForMeetingOccurrence(
    requestParameters: UploadAttachmentForMeetingOccurrenceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForMeetingOccurrenceRaw(requestParameters, initOverrides);
  }
}
