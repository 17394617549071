import { LocalDate } from 'src/utilities/local-date.js';
import type { UpdateStatus } from 'src/library/lists/utilities.js';

export interface BaseTemplateItem {
  templateDeleted?: string;
  hasTemplateUpdate?: boolean;
  templateUpdated?: string;
  contentLastModified?: string;
  templateDeclined?: string;
  unchangedTemplate?: boolean;
  uuid: string;
  hasDraft?: boolean;
}

export function calculateUpdateStatus<T extends BaseTemplateItem>(item: T, today: LocalDate): UpdateStatus {
  if (
    item.templateDeleted &&
    (!item.templateDeclined ||
      LocalDate.fromString(item.templateDeclined).isBefore(LocalDate.fromString(item.templateDeleted)))
  ) {
    return 'templateDeleted';
  } else if (
    item.hasTemplateUpdate &&
    (!item.templateDeclined ||
      LocalDate.fromString(item.templateDeclined).isBefore(LocalDate.fromString(item.templateUpdated)))
  ) {
    return 'templateUpdated';
  } else if (
    item.contentLastModified &&
    item.contentLastModified !== '' &&
    today.minusDays(30).isBefore(LocalDate.fromString(item.contentLastModified))
  ) {
    return 'documentUpdated';
  }

  return 'none';
}
