import { _sortByFirstAndLastName, _sortByName, flatMap, toList } from './utilities.js';
import type { OrganizationState, State } from '../types.js';
import { createSelector } from '@reduxjs/toolkit';
import type { ContactPersonViewModel, ContactPersonViewModelAccessLevelEnum, PartnerViewModel } from '../api';
import { getOrganization, getOrganizationId } from './organization.js';
import { getCurrentUserEmail } from './session.js';
import { entityNameForLists, joinWithAnd } from 'src/utilities/text.js';
import { sortBy } from 'lodash';
import { ListSectionItemInput } from 'src/library/lists/utilities';
import { currentPageId } from 'src/store/selectors/path';

export interface ContactsAsUsers {
  partnerName: string;
  name: string;
  accessLevel: ContactPersonViewModelAccessLevelEnum | undefined;
  uuid: string;
  email: string | undefined;
  partnerUuid: string;
}

function getPartners(state: State): PartnerViewModel[] {
  return state.organization !== undefined ? toList(state.organization.partnersById) : [];
}

export function contactsNotDeleted(state: State): ContactPersonViewModel[] {
  if (state.organization === undefined) return [];
  return sortBy(
    toList(state.organization.contactsById).filter(function (e) {
      return !e.deleted;
    }),
    (e) => e.firstName.toLowerCase() + ' ' + e.lastName.toLowerCase(),
  );
}

export function partnersNotDeleted(state: State): PartnerViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.partnersById)
    .filter(function (e) {
      return !e.deleted;
    })
    .sort(_sortByName);
}

/**
 * Removes unedited template partners
 */
export function partnersEdited(state: State): PartnerViewModel[] {
  return partnersNotDeleted(state).filter((p) => {
    return (p.templateId !== null && !p.contentLastModifiedWasCreate) || p.templateId === null;
  });
}

export function partnersDeleted(state: State): PartnerViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.partnersById)
    .filter(function (e) {
      return e.deleted;
    })
    .sort(_sortByName);
}

export function contactsDeleted(state: State): ContactPersonViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.contactsById)
    .filter(function (e) {
      return e.deleted;
    })
    .sort(_sortByFirstAndLastName);
}

export function listContactNamesWithPartnerNames(contactUuids: string[], contactsAsUsers: ContactsAsUsers[]) {
  const contactNamesList = contactsAsUsers
    .filter((c) => {
      return contactUuids.indexOf(c.uuid) > -1;
    })
    .map((c) => {
      return c.name + ' (' + c.partnerName + ')';
    });
  return joinWithAnd(contactNamesList);
}

export function contactNamesWithPartnerNames(contactUuids: string[], contactsAsUsers: ContactsAsUsers[]): string[] {
  return contactsAsUsers
    .filter((c) => {
      return contactUuids.indexOf(c.uuid) > -1;
    })
    .map((c) => {
      return c.name + ' (' + c.partnerName + ')';
    });
}

export function partnerNamesAsText(item: { partners?: string[] }, o: OrganizationState): string {
  if (item.partners === undefined) return 'Ingen';
  const a = item.partners
    .map(function (id) {
      return o.partnersById[id];
    })
    .filter(function (e) {
      return e && !e.deleted;
    })
    .map(function (e) {
      return e.name;
    })
    .sort();

  if (a.length === 0) {
    return 'Ingen';
  } else {
    return a.join(', ');
  }
}

export const partnerNamesById = createSelector(getOrganization, function (organization) {
  const result: { [uuid: string]: string } = {};

  if (organization) {
    toList(organization.partnersById).forEach(function (e) {
      result[e.uuid] = e.name;
    });
  }

  return result;
});

export const partnersWithContacts = createSelector(getOrganization, getPartners, function (m, partners) {
  if (m) {
    const contacts = toList(m.contactsById);
    return partners.map(function (p) {
      return Object.assign({}, p, {
        contacts: contacts.filter(function (c) {
          return c.partnerUuid === p.uuid;
        }),
      });
    });
  } else {
    return [];
  }
});

export interface PartnerContactAsUser {
  accessLevel: 'READONLY' | 'USER' | 'ASSETS' | 'NONE';
  partnerName: string;
  accessExpires: string | undefined;
  name: string;
  partnerUuid: string;
  uuid: string;
  email: string | undefined;
}

export const getContactsAsUsers = createSelector(partnersWithContacts, function (partners): PartnerContactAsUser[] {
  const p = partners
    .filter(function (e) {
      return !e.deleted;
    })
    .sort(_sortByName);
  return flatMap(p, function (e) {
    return e.contacts
      .filter(function (c) {
        return !c.deleted;
      })
      .sort(_sortByFirstAndLastName)
      .map(function (c) {
        return {
          partnerName: e.name,
          name: c.firstName + ' ' + c.lastName,
          accessLevel: c.accessLevel,
          uuid: c.uuid,
          email: c.email,
          partnerUuid: e.uuid,
          accessExpires: c.accessExpires,
        };
      });
  });
});

export const currentContactUuid = createSelector(
  getContactsAsUsers,
  getCurrentUserEmail,
  function (contacts, currentUserEmail) {
    const contact = contacts.filter(function (c) {
      return c.email === currentUserEmail;
    });
    if (contact.length) {
      return contact[0].uuid;
    }
    return undefined;
  },
);

export const getPartnersAndContacts = createSelector(
  partnersNotDeleted,
  contactsNotDeleted,
  getOrganizationId,
  currentPageId,
  function (partners, contacts, organizationId, pageId): ListSectionItemInput[] {
    const partnersAndContacts: ListSectionItemInput[] = partners.map((p) => {
      const items: ListSectionItemInput[] = contacts
        .filter((c) => {
          return c.partnerUuid === p.uuid;
        })
        .map((c) => {
          let name = c.firstName;
          if (!name) {
            name = c.lastName;
          } else {
            name = c.firstName + ' ' + c.lastName;
          }
          return {
            label: entityNameForLists(name, c.draftName, c.isConfirmedEntity),
            href: '/account/' + organizationId + '/' + pageId + '/contacts/' + c.uuid,
            hasDraft: c.hasDraft,
            accessible: true,
          };
        });
      return {
        label: entityNameForLists(p.name, p.draftName, p.isConfirmedEntity),
        href: '/account/' + organizationId + '/' + pageId + '/partners/' + p.uuid,
        hasDraft: p.hasDraft,
        accessible: true,
        items: sortBy(items, ['label']),
      };
    });
    return sortBy(partnersAndContacts, ['label']);
  },
);
