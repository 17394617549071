/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewTopic } from './NewTopic';
import { NewTopicFromJSON, NewTopicFromJSONTyped, NewTopicToJSON } from './NewTopic';

/**
 *
 * @export
 * @interface UpdateEntityTopicsCommand
 */
export interface UpdateEntityTopicsCommand {
  /**
   * The event occurrence uuid.
   * @type {string}
   * @memberof UpdateEntityTopicsCommand
   */
  uuid: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateEntityTopicsCommand
   */
  existingTopics: Array<string>;
  /**
   *
   * @type {Array<NewTopic>}
   * @memberof UpdateEntityTopicsCommand
   */
  newTopics: Array<NewTopic>;
}

/**
 * Check if a given object implements the UpdateEntityTopicsCommand interface.
 */
export function instanceOfUpdateEntityTopicsCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'existingTopics' in value;
  isInstance = isInstance && 'newTopics' in value;

  return isInstance;
}

export function UpdateEntityTopicsCommandFromJSON(json: any): UpdateEntityTopicsCommand {
  return UpdateEntityTopicsCommandFromJSONTyped(json, false);
}

export function UpdateEntityTopicsCommandFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateEntityTopicsCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    existingTopics: json['existingTopics'],
    newTopics: (json['newTopics'] as Array<any>).map(NewTopicFromJSON),
  };
}

export function UpdateEntityTopicsCommandToJSON(value?: UpdateEntityTopicsCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    existingTopics: value.existingTopics,
    newTopics: (value.newTopics as Array<any>).map(NewTopicToJSON),
  };
}
