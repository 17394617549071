/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopicSuggestion } from './TopicSuggestion';
import { TopicSuggestionFromJSON, TopicSuggestionFromJSONTyped, TopicSuggestionToJSON } from './TopicSuggestion';

/**
 *
 * @export
 * @interface TopicTemplate
 */
export interface TopicTemplate {
  /**
   *
   * @type {number}
   * @memberof TopicTemplate
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof TopicTemplate
   */
  helpContent: string;
  /**
   *
   * @type {Array<TopicSuggestion>}
   * @memberof TopicTemplate
   */
  suggestions?: Array<TopicSuggestion>;
}

/**
 * Check if a given object implements the TopicTemplate interface.
 */
export function instanceOfTopicTemplate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'helpContent' in value;

  return isInstance;
}

export function TopicTemplateFromJSON(json: any): TopicTemplate {
  return TopicTemplateFromJSONTyped(json, false);
}

export function TopicTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    helpContent: json['helpContent'],
    suggestions: !exists(json, 'suggestions')
      ? undefined
      : (json['suggestions'] as Array<any>).map(TopicSuggestionFromJSON),
  };
}

export function TopicTemplateToJSON(value?: TopicTemplate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    helpContent: value.helpContent,
    suggestions:
      value.suggestions === undefined ? undefined : (value.suggestions as Array<any>).map(TopicSuggestionToJSON),
  };
}
