import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../content/d-entity-header';
import '../library/components/d-help-section';
import '../library/fields/d-view-html';

export interface SideContent {
  type: string;
  target: string;
  startTask: boolean;
  name: string;
  helpContent: string;
  content: string;
}

/**
 *
 *
 *
 */
@customElement('d-side-content')
export class DSideContent extends LitElement {
  static readonly styles = css`
    :host {
      position: relative;
      display: block;
      background: white;
    }

    .header {
      position: -webkit-sticky;
      position: sticky;
      top: var(--safe-area-inset-top);
      width: 100%;
      min-height: 42px;
      background: white;
      box-sizing: border-box;
      z-index: 1;
      opacity: 1;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
      transition: all 0.5s;
    }

    .header.no-shadow {
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0);
    }

    .buttonsWrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 6px;
      transition: all 0.5s 0.5s;
    }

    .buttonsWrapper > div:first-child {
      display: flex;
    }

    .buttonsWrapper > div > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      cursor: pointer;
      z-index: 3;
    }

    .buttonsWrapper > div > div svg {
      width: 15px;
      height: 15px;
    }

    .buttonsWrapper > div > div[disabled] {
      cursor: default;
      opacity: 0.4;
    }

    .closer {
      width: 42px;
    }

    .buttonsWrapper > div > div:not([disabled]):hover svg rect,
    .buttonsWrapper > div > div:not([disabled]):hover svg polygon {
      fill: black;
    }

    d-entity-header {
      padding-top: 4px;
      background: white;
    }

    .content {
      padding: 0 20px 70px 20px;
      font-family: var(--mainSerif);
      line-height: 160%;
      color: hsl(0, 0%, 20%);
    }
  `;
  @property({ type: Object })
  item?: SideContent | undefined;
  @property({ type: Boolean })
  show = true;
  @property({ type: Boolean })
  showContent = false;
  @property({ type: Number })
  organizationId = 0;

  private headerActions = [
    {
      name: 'Lukk',
      action: 'close',
    },
    {
      name: 'Rediger',
      action: 'edit',
    },
  ];

  handleHeaderAction(e) {
    if (this.item && e.detail === 'edit') {
      this.dispatchEvent(
        new CustomEvent<{ href: string }>('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: '/account/' + this.organizationId + '/' + this.item.target + '?edit' },
        }),
      );
      this.close();
    } else if (e.detail === 'close') {
      this.close();
    }
  }

  private close() {
    this.dispatchEvent(new CustomEvent<{ value: boolean }>('close-viewer', { bubbles: true, composed: true }));
    setTimeout(() => {
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-side-content', {
          bubbles: true,
          composed: true,
          detail: { value: false },
        }),
      );
    }, 500);
  }

  render() {
    if (this.item) {
      return html`
        <d-entity-header
          icon="${this.item.type}"
          .label=${this.item.name}
          .mainHeaderActions=${this.headerActions}
          @action=${async (e) => this.handleHeaderAction(e)}
        ></d-entity-header>
        <div class="content">
          <d-help-section .content=${this.item.helpContent}></d-help-section>
          <d-view-html .value=${this.item.content} absolute-internal-links></d-view-html>
        </div>
      `;
    }
    return html``;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-side-content': DSideContent;
  }
}
