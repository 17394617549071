import { html, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { ActivityCode, type ItemGroup } from 'src/pages/organization-page/organization-page-view-model';
import { features, promo } from 'src/store/selectors/features';
import { registerItemsPromptStyles } from 'src/library/register-items-prompt-styles';

export interface IssuesPageViewModel extends AbstractPageView {
  type: 'issues-page';
  icon: 'issues';
  availableActivityCodes: ActivityCode[];
  issues: ItemGroup[];
  pdfHref: string;
}

/**
 *
 */
@customElement('d-issues-page-content')
export class DIssuesPageContent extends PageContent<IssuesPageViewModel> {
  static readonly styles = [...super.styles, registerItemsPromptStyles];
  @property({ type: Object })
  currentPageItem: any = {};
  @property({ type: Object })
  route = {};
  @property({ type: Object })
  queryParams = {};
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`${this.pageView.writeAccess
      ? html`<d-action class="action" @click=${() => this.onCreateIssue()}>Nytt avvik</d-action>`
      : nothing}
    ${!this.singleUserVersion
      ? html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`
      : nothing}`;
  }

  onCreateIssue() {
    this.dispatchEvent(new CustomEvent('display-new-issue', { composed: true, bubbles: true, detail: {} }));
  }

  private asListItem(list: ItemGroup[]): ListSectionItemInput[] {
    return list.map((v) => ({
      label: v.label,
      accessible: false,
      sublistToggle: v.filtered,
      sublistHidden: v.filtered,
      items: v.items.map((item) => ({
        label: item.dateDisplay + ' ' + item.label,
        href: item.href,
        mainIcon: item.locked ? 'lock' : undefined,
        accessible: item.accessible,
        hasDraft: item.hasDraft,
      })),
    }));
  }

  renderContent() {
    if (!this.pageView.featureStates.core && !this.pageView.featureStates.issues) {
      const promoData = promo.find((p) => {
        return p.id === 'issues';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    if (this.pageView.issues.length) {
      return html`
        <d-list-section
          theme-page
          no-header
          .items=${this.asListItem(this.pageView.issues)}
          icon="issues"
          organization-id="${this.pageView.organizationId}"
          write-access="${this.writeAccess}"
          .contentStickyTop=${this.contentStickyTop}
        >
        </d-list-section>
      `;
    }
    return html`
      <div class="register-items-prompt issues" @click=${() => this.onCreateIssue()}>
        <h2>Ingen avvik er registrert.</h2>
        ${this.pageView.writeAccess ? html` <p>Trykk for å sende ny avviksmelding.</p>` : nothing}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-issues-page-content': DIssuesPageContent;
  }
}
