import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { AssetsPageViewModel } from './assets-page/assets-page-view-model.js';
import type { ComputersPageViewModel } from './computers-page/computers-page-view-model.js';
import type { EventsPageViewModel } from 'src/pages/events-page/events-page-view-model';
import type { MeetingsPageViewModel } from './meetings-page/meetings-page-view-model.js';
import type { OrganizationPageViewModel } from './organization-page/organization-page-view-model.js';
import type { OverviewPageViewModel } from './overview-page/overview-page-view-model.js';
import type { ReferencesPageViewModel } from './references-page/references-page-view-model.js';
import type { StaffingPageViewModel } from './staffing-page/staffing-page-view-model.js';
import type { SubstancesPageViewModel } from './substances-page/substances-page-view-model.js';
import type { TrashPageViewModel } from './trash-page/trash-page-view-model.js';
import '../pages/status-page/d-status-page-content.js';
import 'src/pages/events-page/d-events-page-content';
import '../pages/organization-page/d-organization-page-content.js';
import '../pages/employees-page/d-employees-page-content.js';
import '../pages/people-page/d-people-page-content.js';
import '../pages/partners-page/d-partners-page-content.js';
import '../pages/tutorials-page/d-tutorials-page-content.js';
import '../pages/issues-page/d-issues-page-content.js';
import '../pages/risk-assessments-page/d-risk-assessments-page-content.js';
import '../pages/computers-page/d-computers-page-content.js';
import '../pages/assets-page/d-assets-page-content.js';
import '../pages/substances-page/d-substances-page-content.js';
import '../pages/references-page/d-references-page-content.js';
import '../pages/trash-page/d-trash-page-content.js';
import '../pages/overview-page/d-overview-page-content.js';
import '../pages/staffing-page/d-staffing-page-content.js';
import '../pages/meetings-page/d-meetings-page-content.js';
import '../pages/collections-page/d-collections-page-content';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';
import { EmployeesPageViewModel } from 'src/pages/employees-page/d-employees-page-content';
import { PartnersPageViewModel } from 'src/pages/partners-page/d-partners-page-content';
import { IssuesPageViewModel } from 'src/pages/issues-page/d-issues-page-content';
import { RiskAssessmentsPageViewModel } from 'src/pages/risk-assessments-page/d-risk-assessments-page-content';
import { StatusPageViewModel } from 'src/pages/status-page/d-status-page-content';
import { PeoplePageViewModel } from 'src/pages/people-page/d-people-page-content';
import { TutorialsPageViewModel } from 'src/pages/tutorials-page/d-tutorials-page-content';
import type { FeatureStates } from 'src/store/selectors/features';
import { CollectionsPageViewModel } from 'src/pages/collections-page/d-collections-page-content';

export type PageView =
  | StatusPageViewModel
  | EmployeesPageViewModel
  | PeoplePageViewModel
  | PartnersPageViewModel
  | IssuesPageViewModel
  | RiskAssessmentsPageViewModel
  | EventsPageViewModel
  | OrganizationPageViewModel
  | StaffingPageViewModel
  | MeetingsPageViewModel
  | ComputersPageViewModel
  | AssetsPageViewModel
  | SubstancesPageViewModel
  | ReferencesPageViewModel
  | TrashPageViewModel
  | OverviewPageViewModel
  | CollectionsPageViewModel
  | TutorialsPageViewModel;

/**
 * The page. Displays a theme page.
 *
 *
 * STATUS OK
 */
@customElement('d-page')
export class DPage extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: block;
      height: 100%;
    }

    #page {
      display: block;
      height: 100%;
      background-color: var(--backgroundGray);
      border-top-left-radius: 12px;
      position: relative;
      transition: border-top-left-radius var(--transition);
    }

    :host([fit-content]) #page {
      width: fit-content;
    }

    #page.narrow {
      border-top-left-radius: 0;
    }

    #page > * {
      height: 100%;
    }
  `;
  @property({ type: Object })
  pageView!: PageView;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Number })
  pageWidth = 1000;
  @property({ type: Number })
  appWidth = 1000;
  @property({ type: Boolean })
  covered = false;
  @property({ type: Number })
  setScrolltop = 0;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Boolean, attribute: 'prevent-header-shrink' })
  preventHeaderShrink = false;

  private get pageClass() {
    if (this.appWidth < 845) {
      return 'narrow';
    }
    return '';
  }

  private get showClose() {
    return this.appWidth < 1024;
  }

  onResize() {
    this.pageWidth = this.offsetWidth;
  }

  renderPage() {
    switch (this.pageView.type) {
      case 'status-page':
        return html`<d-status-page-content
          wide-page
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-status-page-content>`;
      case 'employees-page':
        return html`<d-employees-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-employees-page-content>`;
      case 'people-page':
        return html`<d-people-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-people-page-content>`;
      case 'partners-page':
        return html`<d-partners-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-partners-page-content>`;
      case 'tutorials-page':
        return html`<d-tutorials-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-tutorials-page-content>`;
      case 'issues-page':
        return html`<d-issues-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-issues-page-content>`;
      case 'risk-assessments-page':
        return html`<d-risk-assessments-page-content
          wide-page
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-risk-assessments-page-content>`;
      case 'events-page':
        return html`<d-events-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-events-page-content>`;
      case 'organization-page':
        return html`<d-organization-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-organization-page-content>`;
      case 'staffing-page':
        return html`<d-staffing-page-content
          .appWidth=${this.appWidth}
          .pageWidth=${this.pageWidth}
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${true}
          .setScrolltop=${this.setScrolltop}
          .scrollLeft=${this.scrollLeft}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-staffing-page-content>`;
      case 'meetings-page':
        return html`<d-meetings-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-meetings-page-content>`;
      case 'computers-page':
        return html`<d-computers-page-content
          ?covered=${this.covered}
          .pageView=${this.pageView}
          write-access="${this.writeAccess}"
          wide-page
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
        >
        </d-computers-page-content>`;
      case 'assets-page':
        return html`<d-assets-page-content
          ?covered=${this.covered}
          .pageView=${this.pageView}
          ?writeAccess=${this.writeAccess}
          wide-page
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-assets-page-content>`;
      case 'substances-page':
        return html` <d-substances-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          wide-page
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-substances-page-content>`;
      case 'collections-page':
        return html` <d-collections-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .singleUserVersion=${this.singleUserVersion}
          .featureStates=${this.featureStates}
        >
        </d-collections-page-content>`;
      case 'trash-page':
        return html`<d-trash-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-trash-page-content>`;
      case 'references-page':
        return html`<d-references-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          .singleUserVersion=${this.singleUserVersion}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-references-page-content>`;
      case 'overview-page':
        return html`<d-overview-page-content
          .pageView=${this.pageView}
          ?covered=${this.covered}
          ?writeAccess=${this.writeAccess}
          .singleUserVersion=${this.singleUserVersion}
          ?showClose=${this.showClose}
          .setScrolltop=${this.setScrolltop}
          .preventHeaderShrink=${this.preventHeaderShrink}
          .featureStates=${this.featureStates}
        >
        </d-overview-page-content>`;
    }
  }

  render() {
    return html`<div id="page" class="${this.pageClass}">${this.renderPage()}</div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-page': DPage;
  }
}
