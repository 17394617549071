import { createSelector } from '@reduxjs/toolkit';
import type { QueryParamsType, State } from '../types.js';

export function splitPath(state: State): string[] {
  try {
    return state.path.split('/');
  } catch (e) {
    return [];
  }
}

function path(state: State): string {
  return state.path;
}

export const getCurrentPath = createSelector(path, function (path) {
  return path;
});

function queryParams(state: State): QueryParamsType {
  return state.queryParams || {};
}

export const showSubstancesUploader = createSelector(queryParams, (params) => params.showSubstancesUploader);

export const paramParentId = createSelector(queryParams, function (params) {
  return params.parent;
});

export const referral = createSelector(queryParams, function (params) {
  return params.referral;
});

export const getFilterStartDate = createSelector(queryParams, (params) =>
  params.filterStartDate ? params.filterStartDate : '',
);

export const getFilterEndDate = createSelector(queryParams, (params) =>
  params.filterEndDate ? params.filterEndDate : '',
);

export const getFilterPeople = createSelector(queryParams, (params): string[] =>
  params.filterPeople ? JSON.parse(params.filterPeople) : [],
);

export const currentPathArray = splitPath;

export const currentPageId = createSelector(splitPath, (a) => {
  if (a.length < 4) return undefined;
  return Number(a[3]);
});
export const currentOrganizationId = createSelector(splitPath, (a) => {
  if (a.length < 3) return undefined;
  return Number(a[2]);
});

export const currentEntityType = createSelector(splitPath, (a) => {
  if (a.length < 6) return undefined;
  return a[a.length - 2];
});

export const currentEntityId = createSelector(splitPath, (a) => {
  if (a.length < 6) return undefined;
  const element = a[a.length - 1];
  return element.split(':')[0];
});

export const currentEntityInstance = createSelector(splitPath, (a) => {
  if (a.length < 6) return undefined;
  const element = a[a.length - 1];
  return element.split(':')[1] ?? '';
});

export const currentEntityTypeName = createSelector(currentEntityType, function (type) {
  if (type === 'employees') return 'personale';
  if (type === 'partners') return 'samarbeidspartner';
  if (type === 'contacts') return 'kontaktperson';
  if (type === 'assets') return 'utstyrsenhet';
  if (type === 'constitutionalDocuments') return 'stiftelsesdokument';
  if (type === 'functions') return 'funksjon';
  if (type === 'tasks') return 'oppgave';
  if (type === 'guidelines') return 'retningslinje';
  if (type === 'documents') return 'måldokument';
  if (type === 'contracts') return 'avtale';
  if (type === 'reports') return 'referat/rapport';
  if (type === 'issues') return 'avviksmelding';
  if (type === 'riskAssessments') return 'risikovurdering';
  if (type === 'events') return 'hendelse';
  return '';
});

export const currentEntityTypeNameDetermined = createSelector(currentEntityType, function (type) {
  if (type === 'employees') return 'personen';
  if (type === 'partners') return 'samarbeidspartneren';
  if (type === 'contacts') return 'kontaktpersonen';
  if (type === 'assets') return 'utstyrsenheten';
  if (type === 'constitutionalDocuments') return 'stiftelsesdokumentet';
  if (type === 'functions') return 'funksjonen';
  if (type === 'tasks') return 'oppgaven';
  if (type === 'guidelines') return 'retningslinjen';
  if (type === 'documents') return 'måldokumentet';
  if (type === 'contracts') return 'avtalen';
  if (type === 'reports') return 'referatet/rapporten';
  if (type === 'issues') return 'avviksmeldingen';
  if (type === 'riskAssessments') return 'risikovurderingen';
  if (type === 'events') return 'hendelsen';
  return '';
});
