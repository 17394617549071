import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import '../../library/elements/d-label';
import '../../library/editors/elements/d-select-dropdown';
import '../../library/elements/d-smooth-resize';

/**
 * Assigns events to employees, functions and contacts *
 * STATUS OK
 */
@customElement('d-edit-assignees')
export class DEditAssignees extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      line-height: 120%;
      padding-bottom: 1px;
    }
    header {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 12px;
    }
    .selectors {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 12px;
    }
    main {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      padding-top: 12px;
      padding-bottom: 2px;
    }
    main > div {
      display: flex;
      box-sizing: border-box;
      height: 32px;
      border: 1px solid var(--borderColor);
      border-radius: 4px;
      padding: 6px 6px 0 10px;
      color: var(--text-color);
      cursor: pointer;
    }
    main > div:after {
      content: '';
      display: block;
      width: 19px;
      height: 19px;
      margin-left: 4px;
      background: url(/images/x-thin-orange.svg) 50% 50% no-repeat;
      background-size: 20px;
    }
    @media (hover: hover) {
      main > div:hover {
        text-decoration: line-through;
      }
      main > div:hover:after {
        background: url(/images/x-thin-black.svg) 50% 50% no-repeat;
        background-size: 20px;
      }
    }
  `;

  @property({ type: Array })
  employees: SelectDropdownOption[] = [];
  @property({ type: Array })
  functions: SelectDropdownOption[] = [];
  @property({ type: Array })
  contacts: SelectDropdownOption[] = [];
  @property({ type: Array })
  assignedToEmployees: string[] = [];
  @property({ type: String })
  assignedToFunction: string = '';
  @property({ type: Array })
  assignedToContacts: string[] = [];
  @property({ type: String })
  field = 'events_assignee';
  @property({ type: Boolean })
  single = false;
  @state()
  selectorValue = '';

  private get functionText(): string {
    return (
      this.functions.find((item) => {
        return item.value === this.assignedToFunction;
      })?.text ?? ''
    );
  }

  private get functionOptions() {
    return this.functions.filter((item) => {
      return item.value !== this.assignedToFunction;
    });
  }

  private get employeeOptions() {
    return [{ value: 'ALL', text: 'Alle' }].concat(
      this.employees.filter((item) => {
        return !this.assignedToEmployees.includes(item.value);
      }),
    );
  }

  private get contactOptions() {
    return this.contacts.filter((item) => {
      return !this.assignedToContacts.includes(item.value);
    });
  }

  private fireAssignmentChanged() {
    this.dispatchEvent(
      new CustomEvent<{ assignedToEmployees: string[]; assignedToFunction: string; assignedToContacts: string[] }>(
        'assignment-changed',
        {
          bubbles: true,
          composed: true,
          detail: {
            assignedToEmployees: this.assignedToEmployees,
            assignedToFunction: this.assignedToFunction,
            assignedToContacts: this.assignedToContacts,
          },
        },
      ),
    );
  }

  addFunction(e) {
    e.stopPropagation();
    this.assignedToFunction = e.detail.value;
    this.selectorValue = e.detail.value;
    setTimeout(() => {
      this.selectorValue = '';
    }, 0);
    this.fireAssignmentChanged();
  }

  addEmployee(e) {
    e.stopPropagation();
    if (e.detail.value === 'ALL') {
      this.assignedToEmployees = this.employees.map((item) => {
        return item.value;
      });
    } else {
      const assignedToEmployees = [...this.assignedToEmployees];
      assignedToEmployees.push(e.detail.value);
      this.assignedToEmployees = assignedToEmployees;
    }
    this.selectorValue = e.detail.value;
    setTimeout(() => {
      this.selectorValue = '';
    }, 0);
    this.fireAssignmentChanged();
  }

  addContact(e) {
    e.stopPropagation();
    const assignedToContacts = [...this.assignedToContacts];
    assignedToContacts.push(e.detail.value);
    this.assignedToContacts = assignedToContacts;
    this.selectorValue = e.detail.value;
    setTimeout(() => {
      this.selectorValue = '';
    }, 0);
    this.fireAssignmentChanged();
  }

  private asOptions(assigned: string[], list: SelectDropdownOption[]): SelectDropdownOption[] {
    return assigned.map((uuid) => {
      return (
        list.find((item) => {
          return item.value === uuid;
        }) ?? { value: '', text: '' }
      );
    });
  }

  private onAssignedClick(uuid: string, listName: string) {
    this[listName] = this[listName].filter((item) => {
      return item !== uuid;
    });
    this.fireAssignmentChanged();
  }

  renderSelected() {
    return html` <main>
      ${this.assignedToFunction
        ? html`<div
            @click=${() => {
              this.assignedToFunction = '';
              this.fireAssignmentChanged();
            }}
          >
            ${this.functionText}
          </div>`
        : nothing}
      ${this.asOptions(this.assignedToEmployees, this.employees).map(
        (item) => html`<div @click=${() => this.onAssignedClick(item.value, 'assignedToEmployees')}>${item.text}</div>`,
      )}
      ${this.asOptions(this.assignedToContacts, this.contacts).map(
        (item) => html`<div @click=${() => this.onAssignedClick(item.value, 'assignedToContacts')}>${item.text}</div>`,
      )}
    </main>`;
  }

  render() {
    return html`
      <header>
        <d-label field="${this.field}"></d-label>
        <div class="selectors">
          ${this.functions.length
            ? html` <d-select-dropdown
                style="width: 176px"
                action-style
                placeholder="Velg ansvarsområde"
                ?disabled="${this.functionOptions.length === 1}"
                .options=${this.functionOptions}
                .value=${this.selectorValue}
                @value-changed=${(e) => this.addFunction(e)}
              ></d-select-dropdown>`
            : nothing}
          ${this.employees.length
            ? html` <d-select-dropdown
                style="width: 160px"
                action-style
                placeholder="Legg til personale"
                ?disabled="${this.employeeOptions.length === 1}"
                .options=${this.employeeOptions}
                .value=${this.selectorValue}
                @value-changed=${(e) => this.addEmployee(e)}
              ></d-select-dropdown>`
            : nothing}
          ${this.contacts.length
            ? html` <d-select-dropdown
                style="width: 196px"
                action-style
                placeholder="Legg til kontaktperson"
                ?disabled="${!this.contactOptions.length}"
                .options=${this.contactOptions}
                .value=${this.selectorValue}
                @value-changed=${(e) => this.addContact(e)}
              ></d-select-dropdown>`
            : nothing}
        </div>
      </header>
      <d-smooth-resize>
        ${this.assignedToFunction !== '' || this.assignedToEmployees.length > 0 || this.assignedToContacts.length > 0
          ? this.renderSelected()
          : nothing}
      </d-smooth-resize>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-assignees': DEditAssignees;
  }
}
