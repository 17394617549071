import { css, html, nothing } from 'lit';
import 'src/library/elements/d-section';
import 'src/library/fields/d-view-info';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-select-dropdown';
import 'src/library/fields/d-expansion';
import 'src/library/fields/d-spinner-robot';
import { customElement, property, query, state } from 'lit/decorators.js';
import { BaseDialog } from 'src/library/components/BaseDialog';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import * as dabihStore from 'src/store';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { DEditText } from 'src/library/editors/elements/d-edit-text';

export interface NewContactAndPartnerDialogInput {
  partners: SelectDropdownOption[];
  userEmails: string[];
  collectionUuid: string | undefined;
  create: () => void;
}

export interface CreateContactAndPartnerData {
  partnerUuid: string;
  newPartnerName: string;
  contactUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}
export interface NewContactAndPartnerDialogResult {
  action: 'cancel' | 'done';
  contactAndPartnerData: CreateContactAndPartnerData;
  collectionUuid?: string;
}

export interface CreateContactAndPartnerInput {
  data: CreateContactAndPartnerData;
  complete: () => void;
}

/**
 *
 * STATUS OK
 */
@customElement('d-new-contact-and-partner-dialog')
export class DNewContactAndPartnerDialog extends BaseDialog<
  NewContactAndPartnerDialogInput,
  NewContactAndPartnerDialogResult
> {
  static readonly styles = [
    BaseDialog.styles[0],
    css`
      d-label {
        padding-bottom: 6px;
      }
      .cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: white;
        z-index: 1;
      }
    `,
  ];
  @property({ type: String })
  title = 'Ny kontaktperson';
  @property({ type: String })
  content = '';
  @property({ type: String })
  contactUuid = '';
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  email = '';
  @property({ type: String })
  partnerUuid = '';
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  userEmails: string[] = [];
  @property({ type: String })
  phone = '';
  @property({ type: String })
  collectionUuid: string | undefined = undefined;
  @property({ type: Number })
  width = 400;
  @state()
  private spinner = false;
  @state()
  private newPartnerName = '';
  @state()
  private partnerSelectValue = '';
  @query('#partnerNameInput')
  partnerNameInput!: DEditText;

  private get doneDisabled() {
    return (
      this.noName ||
      !this.emailInputIsValidAndUnique ||
      ((this.partnerSelectValue === '' || this.partnerSelectValue === 'new') && this.newPartnerName === '')
    );
  }

  get calculatedFooterActions() {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done', disabled: this.doneDisabled },
    ];
  }

  private get noName(): boolean {
    return isEmptyOrInvalidString(this.firstName) && isEmptyOrInvalidString(this.lastName);
  }

  private get emailInputIsUnique() {
    return (
      this.userEmails.filter((e) => {
        return e.toUpperCase() === this.email.toUpperCase();
      }).length === 0
    );
  }

  private get emailInputIsValid() {
    return dabihStore.isEmailValid(this.email);
  }

  private get emailInputIsValidAndUnique() {
    return this.email === '' || (this.emailInputIsUnique && this.emailInputIsValid);
  }

  private get partnerSelectOptions(): SelectDropdownOption[] {
    const result = [...this.partners];
    result.push({ value: 'new', text: 'Annet firma' });
    return result;
  }

  private get newPartner() {
    return this.partners.length === 0 || this.partnerSelectValue === 'new';
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${`<p>Legg til en ny ekstern kontaktperson.</p>`}></d-view-info>
        <d-edit-text
          class="fullWidth"
          field="contacts_firstName"
          .autofocus=${true}
          .selectOnFocus=${true}
          ?mark-if-empty="${this.noName}"
          .value=${this.firstName}
          @value-changed=${(e) => {
            this.firstName = e.detail.value;
          }}
        ></d-edit-text>
        <d-edit-text
          class="fullWidth"
          field="contacts_lastName"
          ?mark-if-empty="${this.noName}"
          .value=${this.lastName}
          @value-changed=${(e) => {
            this.lastName = e.detail.value;
          }}
        ></d-edit-text>
        <d-edit-text
          class="fullWidth"
          ?invalid=${!this.emailInputIsValidAndUnique}
          label="Epost"
          .value=${this.email}
          @value-changed=${(e) => {
            this.email = e.detail.value;
          }}
        ></d-edit-text>
        <d-edit-text
          class="fullWidth"
          field="employees_phone"
          .value=${this.phone}
          @value-changed=${(e) => {
            this.phone = e.detail.value;
          }}
        ></d-edit-text>
        <div>
          <d-label style="padding-bottom: 6px" label="Samarbeidspartner" sublabel="(firma)"></d-label>
          ${this.partners.length
            ? html` <d-select-dropdown
                style="padding-bottom: 8px"
                placeholder="Velg samarbeidspartner"
                ?unselectablePlaceholder=${true}
                .options=${this.partnerSelectOptions}
                .value=${this.partnerSelectValue}
                mark-if-empty
                @value-changed=${(e) => {
                  this.partnerSelectValue = e.detail.value;
                  if (this.partnerSelectValue === 'new') {
                    this.partnerNameInput._focus();
                  } else {
                    this.newPartnerName = '';
                  }
                }}
              ></d-select-dropdown>`
            : nothing}
          <d-expansion .opened=${this.newPartner}>
            <d-edit-text
              id="partnerNameInput"
              placeholder="Firmanavn"
              class="fullWidth"
              .value=${this.newPartnerName}
              mark-if-empty
              @value-changed=${(e) => {
                this.newPartnerName = e.detail.value;
              }}
            ></d-edit-text>
          </d-expansion>
        </div>
      </d-section>
      ${this.spinner
        ? html` <div class="cover">
            <d-spinner-robot></d-spinner-robot>
          </div>`
        : nothing}
    `;
  }

  fireCreate() {
    this.contactUuid = uuid();
    if (this.newPartnerName) {
      this.partnerUuid = uuid();
    } else {
      this.partnerUuid = this.partnerSelectValue;
    }
    this.dispatchEvent(
      new CustomEvent<CreateContactAndPartnerInput>('create-contact-and-partner', {
        bubbles: true,
        composed: true,
        detail: {
          data: {
            contactUuid: this.contactUuid,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            partnerUuid: this.partnerUuid,
            newPartnerName: this.newPartnerName,
          },
          complete: () => {
            if (this.collectionUuid) {
              this.dispatchEvent(
                new CustomEvent<{ collectionUuid: string; entityUuid: string; entityType: string }>(
                  'add-to-collection',
                  {
                    bubbles: true,
                    composed: true,
                    detail: {
                      collectionUuid: this.collectionUuid,
                      entityUuid: this.contactUuid,
                      entityType: 'contacts',
                    },
                  },
                ),
              );
            }
            super.onDispatchAction(false, 'done');
          },
        },
      }),
    );
  }

  protected async onDispatchAction(disabled: boolean, action: string) {
    if (!disabled && action === 'done') {
      this.spinner = true;
      this.fireCreate();
    } else {
      await super.onDispatchAction(disabled, action);
    }
  }

  protected fetchResult(detail: string | undefined): NewContactAndPartnerDialogResult {
    return {
      action: detail === 'done' ? 'done' : 'cancel',
      contactAndPartnerData: {
        partnerUuid: this.partnerUuid,
        newPartnerName: this.newPartnerName,
        contactUuid: this.contactUuid,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
      },
      collectionUuid: this.collectionUuid,
    };
  }

  protected initializeDialog(input: NewContactAndPartnerDialogInput) {
    this.userEmails = input.userEmails;
    this.partners = input.partners;
    this.collectionUuid = input.collectionUuid;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-new-contact-and-partner-dialog': DNewContactAndPartnerDialog;
  }
}
