import { RiskAssessmentView, RiskAssessmentViewEditItem } from 'src/content/risk-assessments/d-risk-assessment-view.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH, EmployeeViewModelStatusEnum, RiskAssessmentViewModel } from 'src/store/api';
import {
  employeesNotDeleted,
  getOrganization,
  key,
  pageIds,
  riskAssessmentsNotDeleted,
  toRiskAssessmentViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { commonFieldsByCode } from 'src/store/fields.js';
import { assertIsDefined } from 'src/lib';
import { shortenPath } from 'src/utilities/path';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';
import { ListItemIcon, ListSectionItemInput } from 'src/library/lists/utilities';
import { riskLevelFromRisk } from 'src/models/risk';
import { orderBy } from 'lodash';

function informationSecurityDescription(entity: RiskAssessmentViewModel) {
  if (!entity.violatesInformationSecurityRequirements) {
    return 'Ingen';
  }

  const v: string[] = [];

  if (entity.violatesConfidentialityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesConfidentialityRequirement'].label);
  }
  if (entity.violatesIntegrityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesIntegrityRequirement'].label);
  }
  if (entity.violatesAvailabilityRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesAvailabilityRequirement'].label);
  }
  if (entity.violatesNonRepudiationRequirement) {
    v.push(commonFieldsByCode()['riskAssessments_violatesNonRepudiationRequirement'].label);
  }

  if (v.length === 0) {
    return 'Ingen';
  }

  return v.join(', ');
}

function isEgual(a: string | undefined | null, b: string | undefined | null): boolean {
  if ((a === undefined || a === null) && (b === undefined || b === null)) {
    return true;
  }
  return a === b;
}

function getSameNameAssessments(
  riskAssessments: RiskAssessmentViewModel[],
  entity: RiskAssessmentViewModel,
): RiskAssessmentViewModel[] {
  const sameNameAssessments = riskAssessments.filter(
    (r) => !r.unchangedTemplate && r.name === entity.name && isEgual(r.relatedSubstance, entity.relatedSubstance),
  );
  return orderBy(sameNameAssessments, [(i) => i.assessmentDate, (i) => i.createdDateTime], ['desc', 'desc']);
}

function getPreviousAssessmentsAsListItems(
  riskAssessments: RiskAssessmentViewModel[],
  entity: RiskAssessmentViewModel,
  currentParent: string,
): ListSectionItemInput[] {
  if (getIsNewest(riskAssessments, entity)) {
    return getSameNameAssessments(riskAssessments, entity)
      .filter((r) => {
        return r.uuid !== entity.uuid;
      })
      .map((r) => {
        let href = currentParent + '/riskAssessments/' + entity.uuid + '/riskAssessments/' + r.uuid;
        if (!r.isConfirmedEntity || !r.name || r.unchangedTemplate) {
          href += '?edit';
        }
        let mainIcon: ListItemIcon | undefined;
        if (r.mainTemplateId !== undefined && r.unchangedTemplate) {
          mainIcon = 'suggestion';
        } else if (r.consequence && r.probability) {
          mainIcon = ('risk' + riskLevelFromRisk(r.consequence * r.probability)) as ListItemIcon;
        } else {
          mainIcon = 'risk0';
        }
        return {
          locked: false,
          accessible: true,
          updateStatus: calculateUpdateStatus(r, LocalDate.now()),
          hasDraft: r.hasDraft,
          label: LocalDate.fromString(r.assessmentDate).toStringForDisplay(),
          secondaryLabel: '',
          href,
          mainIcon,
        };
      });
  }
  return [];
}

function getIsNewest(riskAssessments: RiskAssessmentViewModel[], entity: RiskAssessmentViewModel): boolean {
  const sameNameAssessments = getSameNameAssessments(riskAssessments, entity);
  if (sameNameAssessments.length) {
    return sameNameAssessments[0].uuid === entity.uuid;
  }
  return true;
}

function getProbabilityDisplayText(value: number): string {
  if (value === 1) {
    return 'Lav';
  } else if (value === 2) {
    return 'Moderat';
  } else if (value === 3) {
    return 'Høy';
  } else if (value === 4) {
    return 'Svært høy';
  }
  return 'Ikke vurdert';
}

function getConsequenceDisplayText(value: number): string {
  if (value === 1) {
    return 'Ubetydelige';
  } else if (value === 2) {
    return 'Moderate';
  } else if (value === 3) {
    return 'Alvorlige';
  } else if (value === 4) {
    return 'Katastrofale';
  }
  return 'Ikke vurdert';
}

function getCalculatedRiskDisplayText(value: number): string {
  if (value > 12) {
    return 'Svært høy – Tiltak må besluttes';
  } else if (value > 8) {
    return 'Høy – Tiltak bør besluttes';
  } else if (value > 4) {
    return 'Moderat – Tiltak skal vurderes';
  } else if (value > 0) {
    return 'Lav – Ingen tiltak nødvendig';
  }
  return 'Ikke vurdert';
}

export async function buildRiskAssessmentView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<RiskAssessmentView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const employeeDisplayName = (id: string | undefined) => {
    if (id === undefined) {
      return '';
    }
    const e = state.organization?.employeesById[id];
    if (e === undefined) {
      return '';
    }

    return e.name;
  };
  const item = organization.riskAssessmentsById[uuid];
  assertIsDefined(item);

  const entity = toRiskAssessmentViewModel(item);
  const substance = entity.relatedSubstance ? organization.substancesById[entity.relatedSubstance] : undefined;

  const relatedSubstanceHref = substance
    ? shortenPath(currentParent + '/riskAssessments/' + uuid + '/substances/' + substance?.uuid)
    : '';

  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: currentParent + '/riskAssessments/' + uuid,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/riskAssessments/' +
      uuid +
      '.pdf?key=' +
      key(state),
    createdFromTemplate: entity.createdFromTemplate,
    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'riskAssessments',
    name: entity.name ?? '',
    revisions: await fetchRevisions(
      api,
      'riskAssessments',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
    ),
    attachments: toAttachmentItems(api, 'riskAssessments', entity.uuid, entity.attachments),
    responsibleByDisplayName: employeeDisplayName(entity.responsibleBy),
    determinedByDisplayName: employeeDisplayName(entity.determinedBy),
    probability: entity.probability ?? 0,
    consequence: entity.consequence ?? 0,
    calculatedRisk: (entity.consequence ?? 0) * (entity.probability ?? 0),
    probabilityDisplayText: getProbabilityDisplayText(entity.probability ?? 0),
    consequenceDisplayText: getConsequenceDisplayText(entity.consequence ?? 0),
    calculatedRiskDisplayText: getCalculatedRiskDisplayText((entity.consequence ?? 0) * (entity.probability ?? 0)),
    measures: entity.measures ?? '',
    substanceRelated: substance !== undefined,
    relatedSubstance: substance?.uuid ?? '',
    relatedSubstanceName: substance?.name ?? '',
    relatedSubstanceHref: relatedSubstanceHref ?? '',
    informationSecurityDescription: informationSecurityDescription(entity),
    riskDescription: entity.riskDescription ?? '',
    description: entity.description ?? '',
    assessmentDate: entity.assessmentDate ?? '',
    availablePages: pagesAsSelectTagOptions(state).map((p) => ({
      ...p,
      disabled: p.value === pageIds.riskAssessments + '',
    })),
    pages: (entity.pages ?? []).map((x) => '' + x),
    violatesInformationSecurityRequirements: entity.violatesInformationSecurityRequirements === true,
    violatesConfidentialityRequirement: entity.violatesConfidentialityRequirement === true,
    violatesIntegrityRequirement: entity.violatesIntegrityRequirement === true,
    violatesNonRepudiationRequirement: entity.violatesNonRepudiationRequirement === true,
    violatesAvailabilityRequirement: entity.violatesAvailabilityRequirement === true,
    responsibleBy: entity.responsibleBy ?? '',
    determinedBy: entity.determinedBy ?? '',
    today: LocalDate.fromString(state.today),
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated && e.isConfirmedEntity)
      .map((p) => ({ value: p.uuid, text: p.name })),
    deletable: true,
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'riskAssessments', uuid, organization.organizationId);
      return command.draft as RiskAssessmentViewEditItem;
    },
    previousAssessments: getPreviousAssessmentsAsListItems(riskAssessmentsNotDeleted(state), item, currentParent),
    isNewest: getIsNewest(riskAssessmentsNotDeleted(state), item),
  };
}
