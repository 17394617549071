import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-label.js';
import '../elements/d-select-radio.js';
import './d-select-time.js';
import './d-edit-time.js';
import '../elements/d-select-date.js';
import '../elements/d-checkbox.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { convertToMinutes, formatDuration } from 'src/utilities/text';

export interface TodoTiming {
  eventType: 'TODO';
}

export interface DateTiming {
  eventType: 'DATE';
  date: string;
  time: string;
  durationMinutes: number;
  persistent: boolean;
}

export type EventTiming = TodoTiming | DateTiming;

/**
 *
 * STATUS OK
 */
@customElement('d-edit-event-timing')
export class DEditEventTiming extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host {
        display: block;
      }

      .flexbox-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-right: -12px;
        margin-bottom: -12px;
        position: relative;
        overflow: visible;
      }
      .flexbox-wrap > * {
        margin-right: 12px;
        margin-bottom: 12px;
      }
      .flexbox-wrap > *:last-child {
        margin-right: 0;
        margin-bottom: 12px;
      }
      .flexbox-wrap > .flexbox-wrap {
        margin-right: 0;
        margin-bottom: 0;
      }
    `,
  ];
  @property({ type: String })
  field = 'events_time';
  @property({ type: String })
  date = '';
  @property({ type: String })
  time = 'NONE';
  @property({ type: Number })
  durationMinutes = 0;
  @property({ type: Boolean })
  persistent = false;
  @property({ type: Boolean })
  disableTodo = false;
  @property({ type: Boolean, attribute: 'is-meeting' })
  isMeeting = false;
  private eventTypeOptions = [
    { value: 'TODO', text: 'Snarest' },
    { value: 'DATE', text: 'På dato' },
  ];

  private get currentEventTiming(): EventTiming {
    return this.date === ''
      ? { eventType: 'TODO' }
      : {
          eventType: 'DATE',
          persistent: this.persistent,
          date: this.date,
          time: this.time,
          durationMinutes: this.durationMinutes,
        };
  }

  private get endTime() {
    if (this.time !== 'NONE' && this.time !== '') {
      return formatDuration(convertToMinutes(this.time) + this.durationMinutes);
    }
    return 'NONE';
  }

  renderContent() {
    return html`
      <div class="flexbox-wrap">
        ${this.isMeeting || this.disableTodo
          ? nothing
          : html`
              <d-select-radio
                .options=${this.eventTypeOptions}
                .value=${this.currentEventTiming.eventType}
                @value-changed=${this.onEventTypeChanged}
              ></d-select-radio>
            `}
        ${this.currentEventTiming.eventType === 'TODO'
          ? nothing
          : html`
              <div class="flexbox-wrap">
                <d-select-date
                  .value=${this.currentEventTiming.date}
                  @value-changed=${this.onFromDateChanged}
                ></d-select-date>
                <d-edit-time
                  whole-day-option
                  .time=${this.time}
                  .durationMinutes=${this.durationMinutes}
                  @value-changed=${this.onTimeChanged}
                ></d-edit-time>
                ${this.isMeeting
                  ? nothing
                  : html`
                      <d-checkbox
                        style="margin-right:-10px"
                        ?checked="${this.currentEventTiming.persistent}"
                        option-field="events_persistent"
                        @checked-changed=${this.onPersistentChanged}
                      ></d-checkbox>
                    `}
              </div>
            `}
      </div>
    `;
  }

  private fireValueChanged() {
    this.dispatchEvent(
      new CustomEvent<EventTiming>('value-changed', {
        bubbles: true,
        composed: true,
        detail: this.currentEventTiming,
      }),
    );
  }

  private onEventTypeChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    const eventType = e.detail.value === 'TODO' ? 'TODO' : 'DATE';
    if (eventType === 'TODO') {
      this.date = '';
      this.time = 'NONE';
      this.durationMinutes = 0;
      this.persistent = false;
    } else {
      this.date = LocalDate.now().plusDays(1).toString();
    }
    this.fireValueChanged();
  }

  private onFromDateChanged(e: CustomEvent<{ value: string }>) {
    e.stopPropagation();
    this.date = e.detail.value;
    this.fireValueChanged();
  }

  private onTimeChanged(e: CustomEvent<{ time: string; durationMinutes: number }>) {
    e.stopPropagation();
    this.time = e.detail.time;
    this.durationMinutes = e.detail.durationMinutes;
    this.fireValueChanged();
  }

  private onPersistentChanged(e: CustomEvent<{ checked: boolean }>) {
    e.stopPropagation();
    this.persistent = e.detail.checked;
    this.fireValueChanged();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-event-timing': DEditEventTiming;
  }
}
