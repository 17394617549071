/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface MeetingOccurrenceViewModel
 */
export interface MeetingOccurrenceViewModel {
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  date: string;
  /**
   *
   * @type {boolean}
   * @memberof MeetingOccurrenceViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof MeetingOccurrenceViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {boolean}
   * @memberof MeetingOccurrenceViewModel
   */
  published: boolean;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  reminder: MeetingOccurrenceViewModelReminderEnum;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  time?: string;
  /**
   *
   * @type {number}
   * @memberof MeetingOccurrenceViewModel
   */
  durationMinutes: number;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  agenda: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  responsibleUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  responsibleEmployeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  meetingUuid: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  reportUuid?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingOccurrenceViewModel
   */
  participants: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingOccurrenceViewModel
   */
  externalParticipants: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  classification: MeetingOccurrenceViewModelClassificationEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof MeetingOccurrenceViewModel
   */
  accessControl: Array<string>;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  noticeLastSent?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  reportLastSent?: string;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof MeetingOccurrenceViewModel
   */
  attachments: Array<AttachmentViewModel>;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  lastModified: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneByEmployeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneByContactPersonUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneDate?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneUpdatedByEmployeeUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneUpdatedByContactPersonUuid?: string;
  /**
   *
   * @type {string}
   * @memberof MeetingOccurrenceViewModel
   */
  doneUpdatedAt?: string;
}

/**
 * @export
 */
export const MeetingOccurrenceViewModelReminderEnum = {
  None: 'NONE',
  AtStart: 'AT_START',
  OneHourBefore: 'ONE_HOUR_BEFORE',
  TwoHoursBefore: 'TWO_HOURS_BEFORE',
  ThreeHoursBefore: 'THREE_HOURS_BEFORE',
  FiveHoursBefore: 'FIVE_HOURS_BEFORE',
} as const;
export type MeetingOccurrenceViewModelReminderEnum =
  (typeof MeetingOccurrenceViewModelReminderEnum)[keyof typeof MeetingOccurrenceViewModelReminderEnum];

/**
 * @export
 */
export const MeetingOccurrenceViewModelClassificationEnum = {
  None: 'NONE',
  Confidential: 'CONFIDENTIAL',
  Private: 'PRIVATE',
} as const;
export type MeetingOccurrenceViewModelClassificationEnum =
  (typeof MeetingOccurrenceViewModelClassificationEnum)[keyof typeof MeetingOccurrenceViewModelClassificationEnum];

/**
 * Check if a given object implements the MeetingOccurrenceViewModel interface.
 */
export function instanceOfMeetingOccurrenceViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'date' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'published' in value;
  isInstance = isInstance && 'reminder' in value;
  isInstance = isInstance && 'durationMinutes' in value;
  isInstance = isInstance && 'agenda' in value;
  isInstance = isInstance && 'meetingUuid' in value;
  isInstance = isInstance && 'participants' in value;
  isInstance = isInstance && 'externalParticipants' in value;
  isInstance = isInstance && 'classification' in value;
  isInstance = isInstance && 'accessControl' in value;
  isInstance = isInstance && 'attachments' in value;
  isInstance = isInstance && 'lastModified' in value;

  return isInstance;
}

export function MeetingOccurrenceViewModelFromJSON(json: any): MeetingOccurrenceViewModel {
  return MeetingOccurrenceViewModelFromJSONTyped(json, false);
}

export function MeetingOccurrenceViewModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MeetingOccurrenceViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    date: json['date'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    published: json['published'],
    reminder: json['reminder'],
    name: !exists(json, 'name') ? undefined : json['name'],
    time: !exists(json, 'time') ? undefined : json['time'],
    durationMinutes: json['durationMinutes'],
    agenda: json['agenda'],
    responsibleUuid: !exists(json, 'responsibleUuid') ? undefined : json['responsibleUuid'],
    responsibleEmployeeUuid: !exists(json, 'responsibleEmployeeUuid') ? undefined : json['responsibleEmployeeUuid'],
    meetingUuid: json['meetingUuid'],
    reportUuid: !exists(json, 'reportUuid') ? undefined : json['reportUuid'],
    participants: json['participants'],
    externalParticipants: json['externalParticipants'],
    classification: json['classification'],
    accessControl: json['accessControl'],
    noticeLastSent: !exists(json, 'noticeLastSent') ? undefined : json['noticeLastSent'],
    reportLastSent: !exists(json, 'reportLastSent') ? undefined : json['reportLastSent'],
    attachments: (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
    lastModified: json['lastModified'],
    doneByEmployeeUuid: !exists(json, 'doneByEmployeeUuid') ? undefined : json['doneByEmployeeUuid'],
    doneByContactPersonUuid: !exists(json, 'doneByContactPersonUuid') ? undefined : json['doneByContactPersonUuid'],
    doneDate: !exists(json, 'doneDate') ? undefined : json['doneDate'],
    doneUpdatedByEmployeeUuid: !exists(json, 'doneUpdatedByEmployeeUuid')
      ? undefined
      : json['doneUpdatedByEmployeeUuid'],
    doneUpdatedByContactPersonUuid: !exists(json, 'doneUpdatedByContactPersonUuid')
      ? undefined
      : json['doneUpdatedByContactPersonUuid'],
    doneUpdatedAt: !exists(json, 'doneUpdatedAt') ? undefined : json['doneUpdatedAt'],
  };
}

export function MeetingOccurrenceViewModelToJSON(value?: MeetingOccurrenceViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    date: value.date,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    published: value.published,
    reminder: value.reminder,
    name: value.name,
    time: value.time,
    durationMinutes: value.durationMinutes,
    agenda: value.agenda,
    responsibleUuid: value.responsibleUuid,
    responsibleEmployeeUuid: value.responsibleEmployeeUuid,
    meetingUuid: value.meetingUuid,
    reportUuid: value.reportUuid,
    participants: value.participants,
    externalParticipants: value.externalParticipants,
    classification: value.classification,
    accessControl: value.accessControl,
    noticeLastSent: value.noticeLastSent,
    reportLastSent: value.reportLastSent,
    attachments: (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
    lastModified: value.lastModified,
    doneByEmployeeUuid: value.doneByEmployeeUuid,
    doneByContactPersonUuid: value.doneByContactPersonUuid,
    doneDate: value.doneDate === undefined ? undefined : value.doneDate,
    doneUpdatedByEmployeeUuid: value.doneUpdatedByEmployeeUuid,
    doneUpdatedByContactPersonUuid: value.doneUpdatedByContactPersonUuid,
    doneUpdatedAt: value.doneUpdatedAt,
  };
}
