import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ReassignFunctionsDialog, ReassignFunctionsResult } from 'src/content/employees/reassign-functions-dialog.js';
import { AbstractEntityView, EntityContent, ListDefinition, toLookup } from 'src/content/entity-content.js';
import type { UserForAccess } from 'src/library/editors/components/d-edit-access.js';
import type { EmployeeFunctionItem } from 'src/library/lists/d-list-section-employee-functions.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import {
  EditWorkSchedulesDialog,
  EditWorkSchedulesResult,
} from 'src/pages/staffing-page/edit-work-schedules-dialog.js';
import { accessProfiles, EmployeeForStaffingCalendar, TimekeepingPeriod } from 'src/store/selectors';
import { EmployeeViewModelGenderEnum, FunctionViewModel, WorkSchedule } from 'src/store/api';
import { associationTypes } from 'src/store/selectors/selectors';
import 'src/utilities/display-alert';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-access.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/lists/d-list-section-employee-functions.js';
import '../../library/lists/d-list-section-tutorials.js';
import './d-select-profession.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { ActionInput } from 'src/library/elements/d-action';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import { FeatureStates } from 'src/store/selectors/features';
import { SectionField } from 'src/library/components/d-field-section';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface EmployeeViewEditItem {
  lastName: string;
  expertise: string;
  firstName: string;
  profession: string;
  associationType: string;
  accessLevel: string;
  personalIdentifier: string | undefined;
  hprNumber: string;
  herNumber: string;
  status: string;
  nextOfKin: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  address: string;
  gender: EmployeeViewModelGenderEnum;
  notes: string;
  functionAssignments: { functionUuid: string; employeeUuid: string }[];
}

export interface EmployeeView extends AbstractEntityView<EmployeeViewEditItem> {
  speciality: string;
  lastName: string;
  firstName: string;
  expertise: string;
  nextOfKin: string;
  type: 'employees';
  name: string;
  profession: string;
  associationType: string;
  accessLevel: string;
  personalIdentifier: string | undefined;
  hprNumber: string;
  herNumber: string;
  status: string;
  phone: string;
  secondaryPhone: string;
  email: string;
  address: string;
  gender: EmployeeViewModelGenderEnum;
  notes: string;
  workHoursDescription: string;
  assignedFunctions: EmployeeFunctionItem[];
  unassignedFunctions: EmployeeFunctionItem[];
  contracts: ListSectionItemInput[];
  tutorials: ListSectionItemInput[];
  isCurrentOwner: boolean;
  isCurrentUser: boolean;
  employeesForReassignment: { uuid: string; firstName: string; lastName: string }[];
  functionsForReassignment: { uuid: string; name: string; type: string }[];
  inactiveEmployeeIdForReassignment: string;
  otherUserEmails: string[];
  forStaffing?: EmployeeForStaffingCalendar;
  currentUserHasStaffingAccess: boolean;
  timekeepingPeriods: TimekeepingPeriod[];
  timekeepingBalance: number;
  timekeepingEmployee?: EmployeeForStaffingCalendar;
  leavePeriodEditRestriction: boolean;
  featureStates: FeatureStates;
}

/**
 *
 *
 *
 *
 *
 */
@customElement('d-employee-view')
export class DEmployeeView extends EntityContent<EmployeeView, EmployeeViewEditItem> {
  static readonly styles = EntityContent.styles;
  genderOptions = [
    { value: EmployeeViewModelGenderEnum.Female.valueOf(), text: 'Kvinne' },
    { value: EmployeeViewModelGenderEnum.Male.valueOf(), text: 'Mann' },
  ];
  accessLevelsNoAccess = [{ uuid: 'NONE', value: 'Ingen tilgang' }];
  @property({ type: String })
  entityType = 'employees';
  @property({ type: Boolean })
  hideProfessionsSelect = false;
  @property({ type: Object })
  user: any = {};
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Array })
  employees = [];
  @property({ type: Array })
  functions: FunctionViewModel[] = [];
  private accessLevelLookup = toLookup({
    NONE: 'Ingen',
    READONLY: 'Lesetilgang',
    USER: 'Skrivetilgang',
  });
  private genderLookup = toLookup({
    FEMALE: 'Kvinne',
    MALE: 'Mann',
  });
  private statusLookup = toLookup({
    ACTIVE: 'Aktiv',
    LEAVE: 'Permisjon/sykmeldt',
    TERMINATED: 'Sluttet',
  });

  private get statusOptions() {
    if (this.entityView.isCurrentOwner) {
      return [
        { value: 'ACTIVE', text: 'Aktiv' },
        { value: 'LEAVE', text: 'Permisjon/sykmeldt' },
      ];
    }
    return [
      { value: 'ACTIVE', text: 'Aktiv' },
      { value: 'LEAVE', text: 'Permisjon/sykmeldt' },
      { value: 'TERMINATED', text: 'Sluttet' },
    ];
  }

  private get noName(): boolean {
    return !!(
      this.editItem &&
      isEmptyOrInvalidString(this.editItem.firstName) &&
      isEmptyOrInvalidString(this.editItem.lastName)
    );
  }

  private get accessLevelOptions(): SelectDropdownOption[] {
    return accessProfiles().map((a) => {
      return { value: a.name, text: a.displayName };
    });
  }

  isCorrection(l: TimekeepingPeriod) {
    return l.type === 'correction' || l.type === 'correctionPlus';
  }

  isAccessible(l: TimekeepingPeriod) {
    return (
      !this.isCorrection(l) &&
      (this.entityView.currentUserHasStaffingAccess || !this.entityView.leavePeriodEditRestriction || !l.confirmed)
    );
  }

  asListSectionItemInput(list: TimekeepingPeriod[]): ListSectionItemInput[] {
    return list.map((l) => ({
      label: l.timeDisplay,
      accessible: this.isAccessible(l),
      rightLabel: l.hoursDisplay,
      updateStatus: 'none',
      clickData: this.isCorrection(l) ? undefined : JSON.stringify(l),
    }));
  }

  async initializeEditItem() {
    this.editItem = {
      accessLevel: this.entityView.accessLevel,
      address: this.entityView.address,
      associationType: this.entityView.associationType,
      email: this.entityView.email,
      expertise: this.entityView.expertise,
      firstName: this.entityView.firstName,
      gender: this.entityView.gender,
      personalIdentifier: this.entityView.personalIdentifier,
      herNumber: this.entityView.herNumber,
      hprNumber: this.entityView.hprNumber,
      lastName: this.entityView.lastName,
      notes: this.entityView.notes,
      phone: this.entityView.phone,
      profession: this.entityView.profession,
      secondaryPhone: this.entityView.secondaryPhone,
      status: this.entityView.status,
      nextOfKin: this.entityView.nextOfKin,
      functionAssignments: [],
    };
  }

  editUser(item: EmployeeViewEditItem): UserForAccess {
    return {
      uuid: this.entityView.uuid,
      email: item.email,
      accessLevel: item.accessLevel,
      accessExpires: '',
      name: this.entityView.name,
      partnerName: '',
    };
  }

  doneDisabled(): boolean {
    return this.noName;
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('employees', this.viewFields(), this.entityView)} `;
  }

  renderEditAccess(item: EmployeeViewEditItem): TemplateResult<1> {
    return html`<d-edit-access
      style="margin-top: 0px; border: none; box-shadow: none;"
      class="fullWidth"
      .isSelf=${this.entityView.isCurrentUser}
      .isOwner=${this.entityView.isCurrentOwner}
      .originalEmail=${this.entityView.email}
      .emailValue=${item.email}
      field="contacts_accessLevel"
      .value=${item.accessLevel}
      .editUser=${this.editUser(item)}
      .otherUserEmails=${this.entityView.otherUserEmails}
      @value-changed=${(e: CustomEvent<{ accessLevel: 'NONE' | 'USER'; email: string }>) => {
        this.editItem = { ...item, accessLevel: e.detail.accessLevel, email: e.detail.email };
      }}
    >
    </d-edit-access>`;
  }

  renderEditProfession(item: EmployeeViewEditItem): TemplateResult<1> {
    return html`
      ${this.hideProfessionsSelect
        ? html` <d-edit-text
            class="minWidth300"
            field="employees_professionalTitle"
            .value=${item.profession}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              this.editItem = {
                ...item,
                profession: e.detail.value,
                expertise: '',
              };
            }}
          ></d-edit-text>`
        : html` <d-select-profession
            class="minWidth300"
            speciality="${item.expertise}"
            .value=${item.profession}
            @value-changed=${(e: CustomEvent<{ value: string; speciality: string }>) => {
              this.editItem = {
                ...item,
                profession: e.detail.value,
                expertise: e.detail.speciality,
              };
            }}
          >
          </d-select-profession>`}
    `;
  }

  renderEditStatus(item: EmployeeViewEditItem): TemplateResult<1> {
    return html`
      <d-select-dropdown
        field="employees_status"
        .options=${this.statusOptions}
        .value=${item.status}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.onStatusChanged(e, item);
        }}
      >
      </d-select-dropdown>
    `;
  }

  renderEditItem(item: EmployeeViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('employees', this.editFields(item), item)} `;
  }

  renderFunctions() {
    if (this.entityView.featureStates.core && this.entityView.status !== 'TERMINATED') {
      return html`
        <d-list-section-employee-functions
          .assignedFunctions=${this.entityView.assignedFunctions}
          .unassignedFunctions=${this.entityView.unassignedFunctions}
          .employeeStatus=${this.entityView.status}
          .writeAccess=${this.entityView.currentUserHasWriteAccess}
          .contentStickyTop=${this.contentStickyTop}
          @assign-function=${this.onAssignFunction}
          @create-responsibility=${this.onCreateResponsibility}
        ></d-list-section-employee-functions>
      `;
    }
    return html``;
  }

  renderContracts() {
    if (this.entityView.featureStates.core) {
      const actions: ActionInput[] = [
        {
          name: 'Legg til',
          action: 'create-contract',
          slot: 'top-right',
        },
      ];
      return html`
        <d-list-section
          field="organization_contracts"
          icon="contracts"
          .items=${this.entityView.contracts}
          .writeAccess=${this.entityView.currentUserHasWriteAccess}
          .actions=${actions}
          .contentStickyTop=${this.contentStickyTop}
          @action=${(e: CustomEvent) => this.onListActionForContract(e.detail)}
        ></d-list-section>
      `;
    }
    return html``;
  }

  renderTimekeeping() {
    if (
      this.entityView.timekeepingEmployee &&
      (this.entityView.featureStates.staffing || this.entityView.featureStates.core)
    ) {
      return html`
        <d-timekeeping-list-section
          .defaultDate=${this.entityView.today.toString()}
          .employeeUuid=${this.entityView.uuid}
          .employee=${this.entityView.timekeepingEmployee}
          .periods=${this.asListSectionItemInput(this.entityView.timekeepingPeriods)}
          .saldo=${this.entityView.timekeepingBalance}
          ?currentUserHasAccess=${this.entityView.currentUserHasAccess}
          .leavePeriodEditRestriction=${this.entityView.leavePeriodEditRestriction}
          .currentUserHasAccess=${this.entityView.currentUserHasStaffingAccess}
          .contentStickyTop=${this.contentStickyTop}
        ></d-timekeeping-list-section>
      `;
    }
    return html``;
  }

  renderTutorials() {
    if (this.entityView.tutorials.length > 0) {
      return html`
        <d-list-section
          label="Kurs"
          icon="contracts"
          .items=${this.entityView.tutorials}
          .writeAccess=${this.entityView.currentUserHasWriteAccess}
          .contentStickyTop=${this.contentStickyTop}
          @item-clicked=${this.tutorialClicked}
        ></d-list-section>
      `;
    }
    return html``;
  }

  protected renderAttachments() {
    if (this.entityView.featureStates.core) {
      return super.renderAttachments();
    }
    return html``;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected viewFields(): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'profession',
        type: 'view-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'associationType',
        type: 'view-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'accessLevel',
        options: this.accessLevelOptions,
        type: 'view-text',
        class: 'minWidth200',
        condition: !this.singleUserVersion,
      },
      {
        field: 'hprNumber',
        type: 'view-text',
        class: 'minWidth200',
        condition:
          this.entityView.sector === 'HEALTH' && this.entityView.hprNumber !== undefined && (m.staffing || m.core),
      },
      {
        field: 'herNumber',
        type: 'view-text',
        class: 'minWidth200',
        condition: this.entityView.sector === 'HEALTH' && (m.staffing || m.core),
      },
      {
        field: 'status',
        options: this.statusOptions,
        type: 'view-text',
        class: 'minWidth200',
        condition: !this.singleUserVersion && (m.staffing || m.core),
      },
      {
        field: 'phone',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'secondaryPhone',
        type: 'view-text',
        class: 'minWidth200',
        condition: m.staffing || m.core,
      },
      {
        field: 'email',
        type: 'view-text',
        class: 'minWidth200',
      },
      {
        field: 'personalIdentifier',
        label: 'Fødselsnummer',
        type: 'view-text',
        class: 'minWidth200',
        condition: m.signing,
      },
      {
        field: 'address',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.staffing || m.core,
      },
      {
        field: 'gender',
        type: 'view-text',
        class: 'minWidth200',
        options: this.genderOptions,
        condition: !this.singleUserVersion && (m.staffing || m.core),
      },
      {
        field: 'nextOfKin',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
      {
        field: 'notes',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
      {
        field: 'workHoursDescription',
        action: { name: 'Rediger', action: 'editWorkSchedules' },
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    let contractActions: ActionInput[] = [];
    if (this.entityView.currentUserHasWriteAccess) {
      contractActions = [
        {
          name: 'Legg til',
          action: 'createContract',
          slot: 'top-right',
        },
      ];
    }
    return [
      {
        field: 'organization_functions',
        type: 'custom',
        icon: 'functions',
        items: 'assignedFunctions',
        render: () => this.renderFunctions(),
        condition: this.entityView.featureStates.core && this.entityView.status !== 'TERMINATED',
      },
      {
        field: 'organization_contracts',
        icon: 'contracts',
        actions: contractActions,
        items: 'contracts',
        condition: this.entityView.featureStates.core,
      },
      {
        field: '',
        type: 'custom',
        icon: '',
        items: '',
        render: () => this.renderTimekeeping(),
        condition:
          this.entityView.timekeepingEmployee &&
          (this.entityView.featureStates.staffing || this.entityView.featureStates.core),
      },
      {
        field: '',
        type: 'custom',
        icon: '',
        items: '',
        render: () => this.renderTutorials(),
        condition: this.entityView.tutorials.length > 0,
      },
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  private editFields(item): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'firstName',
        type: 'edit-text',
        placeholder: this.noName ? 'Personen må ha et navn' : '',
        markIfEmpty: this.noName,
        class: 'minWidth200',
      },
      {
        field: 'lastName',
        type: 'edit-text',
        placeholder: this.noName ? 'Personen må ha et navn' : '',
        markIfEmpty: this.noName,
        class: 'minWidth200',
      },
      {
        field: 'gender',
        type: 'edit-tags',
        options: this.genderOptions,
        single: true,
        storedAsSingle: true,
        condition: m.staffing || m.core,
      },
      {
        type: 'custom',
        render: () => this.renderEditAccess(item),
        condition: !this.singleUserVersion,
      },
      {
        field: 'phone',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'secondaryPhone',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.staffing || m.core,
      },
      {
        type: 'custom',
        render: () => this.renderEditProfession(item),
        condition: m.staffing || m.core,
      },
      {
        field: 'associationType',
        type: 'edit-dropdown',
        class: 'minWidth300',
        placeholder: 'Velg',
        options: associationTypes.map((t) => ({ value: t, text: t })),
        condition: m.core,
      },
      {
        type: 'custom',
        render: () => this.renderEditStatus(item),
        condition: !this.singleUserVersion && (m.staffing || m.core),
      },
      {
        field: 'hprNumber',
        type: 'edit-text',
        class: 'minWidth200',
        condition: this.entityView.sector === 'HEALTH' && (m.staffing || m.core),
      },
      {
        field: 'herNumber',
        type: 'edit-text',
        class: 'minWidth200',
        condition: this.entityView.sector === 'HEALTH' && (m.staffing || m.core),
      },
      {
        field: 'personalIdentifier',
        label: 'Fødselsnummer',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.signing,
      },
      {
        field: 'address',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.staffing || m.core,
      },
      {
        field: 'nextOfKin',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.staffing || m.core,
      },
      {
        field: 'notes',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.staffing || m.core,
      },
    ];
  }

  private async onStatusChanged(evt: CustomEvent<{ value: string }>, item: EmployeeViewEditItem) {
    const selectedStatus = evt.detail.value;
    const currentEditItem = item;
    if (currentEditItem && selectedStatus !== currentEditItem.status) {
      if (selectedStatus !== 'ACTIVE' && this.entityView.functionsForReassignment.length > 0) {
        await this.reassignForStatusNotActive(currentEditItem, selectedStatus);
      } else {
        this.editItem = {
          ...currentEditItem,
          status: selectedStatus,
          functionAssignments: [],
          accessLevel: selectedStatus === 'TERMINATED' ? 'NONE' : currentEditItem.accessLevel,
        };
      }
    }
  }

  private async reassignForStatusNotActive(currentEditItem: EmployeeViewEditItem, selectedStatus: string) {
    const result: ReassignFunctionsResult = await ReassignFunctionsDialog.open({
      employees: this.entityView.employeesForReassignment,
      functions: this.entityView.functionsForReassignment,
      inactiveEmployeeId: this.entityView.uuid,
    });
    if (result.action === 'confirm') {
      this.editItem = {
        ...currentEditItem,
        functionAssignments: result.assignments,
        status: selectedStatus,
        accessLevel: selectedStatus === 'TERMINATED' ? 'NONE' : currentEditItem.accessLevel,
      };
    } else {
      const previousStatus = currentEditItem.status;
      this.editItem = {
        ...currentEditItem,
        status: selectedStatus,
      };
      setTimeout(() => {
        this.editItem = {
          ...currentEditItem,
          status: previousStatus ?? 'ACTIVE',
        };
        this.requestUpdate();
      }, 0);
    }
  }

  private async editWorkSchedules() {
    const result: EditWorkSchedulesResult = await EditWorkSchedulesDialog.open({
      employee: this.entityView.forStaffing,
      defaultDate: this.entityView.today.toString(),
    });
    if (result.action === 'done') {
      this.dispatchEvent(
        new CustomEvent<{
          employeeUuid: string;
          workSchedules: WorkSchedule[];
        }>('update-employee-work-schedules', {
          bubbles: true,
          composed: true,
          detail: {
            employeeUuid: this.entityView.uuid,
            workSchedules: result.workSchedules,
          },
        }),
      );
    }
  }

  private tutorialClicked(e: CustomEvent<ListSectionItemInput>) {
    e.preventDefault();
    if (e.detail.clickData !== undefined) {
      this.dispatchEvent(
        new CustomEvent<{ value: string }>('update-tutorial', {
          composed: true,
          bubbles: true,
          detail: { value: e.detail.clickData },
        }),
      );
    }
  }

  private onAssignFunction(e: CustomEvent<{ functionUuid: string }>) {
    e.stopPropagation();
    this.dispatchEvent(
      new CustomEvent<{ functionUuid: string; employeeUuid: string }>('assign-function', {
        bubbles: true,
        composed: true,
        detail: {
          functionUuid: e.detail.functionUuid,
          employeeUuid: this.entityView.uuid,
        },
      }),
    );
  }

  protected createContract() {
    const id = uuid();
    const input: CreateEntityInput = {
      entityType: 'contracts',
      entityUuid: id,
      targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
      pageId: 63,
      employeeId: this.entityView.uuid,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private onCreateResponsibility(e: CustomEvent) {
    e.stopPropagation();
    const id = uuid();
    const input: CreateEntityInput = {
      entityType: 'functions',
      entityUuid: id,
      targetUrl: this.entityView.href + '/functions/' + id + '?edit',
      employeeId: this.entityView.uuid,
      pageId: 65,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  private onListActionForContract(action: string) {
    if (action === 'create-contract') {
      const id = uuid();
      const input: CreateEntityInput = {
        entityType: 'contracts',
        entityUuid: id,
        targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
        pageId: 63,
        employeeId: this.entityView.uuid,
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-employee-view': DEmployeeView;
  }
}
