import { css, html, nothing } from 'lit';
import '../../elements/d-section';
import '../../fields/d-expansion';
import '../../elements/d-smooth-resize';
import { DEditText } from '../elements/d-edit-text';
import { customElement, property, query } from 'lit/decorators.js';
import type { DPopup } from 'src/library/components/d-popup';
import { BaseDialog } from 'src/library/components/BaseDialog';
import { Option } from 'src/library/editors/elements/d-select-radio';
import { DocForLinking } from 'src/models/internal-linking';

export interface EditLinkInput {
  href: string;
  target: string;
  linkText: string;
  docsForLinking: DocForLinking[];
}
export interface EditLinkResult {
  action: 'cancel' | 'setExternal' | 'setInternal';
  selectedDoc: DocForLinking | undefined;
  linkText: string;
  href: string;
  target: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-edit-link-dialog')
export class DEditLinkDialog extends BaseDialog<EditLinkInput, void> {
  static readonly styles = [
    ...BaseDialog.styles,
    css`
      .pseudo-link {
        margin: 12px 0;
        border: 1px solid var(--backgroundGray);
        border-radius: 6px;
        background-color: var(--backgroundLightGray);
        padding: 8px 14px 10px 14px;
        font-size: 16px;
        font-weight: 500;
        color: var(--themeColorDarkerOne);
        cursor: pointer;
      }
      .pseudo-link:hover {
        background-color: var(--themeColor);
        border: 1px solid var(--themeColor);
        color: white;
      }
      .doc-list {
        max-height: 400px;
        overflow: auto;
      }
      .doc-list > div {
        display: block;
        background-size: 24px;
        background-position: 0 2px;
        background-repeat: no-repeat;
        padding: 6px 0 6px 30px;
        color: var(--linkColorGray);
        cursor: pointer;
      }
      @media (hover: hover) {
        .doc-list > div:hover,
        .doc-list > div[selected] {
          color: black;
        }
      }
    `,
  ];

  @property({ type: String })
  dialogType = 'edit';
  @property({ type: String })
  href = 'https://';
  @property({ type: String })
  target = '_self';
  @property({ type: String })
  linkText = '';
  @property({ type: Array })
  documents = [];
  @property({ type: String })
  filterString = '';
  @query('d-popup')
  popup!: DPopup;
  width = 600;
  title = 'Rediger lenke';
  @query('#externalInput')
  externalInput!: HTMLElement;
  @query('#internalInput')
  internalInput!: HTMLElement;

  @property({ type: Array })
  docsForLinking: DocForLinking[] = [];
  @property({ type: Object })
  selectedDoc: DocForLinking | undefined = undefined;
  private typeOptions: Option[] = [
    { value: '_self', text: 'Lenke til TrinnVis-dokument' },
    { value: '_blank', text: 'Lenke til ekstern nettside' },
  ];

  protected get calculatedHeaderActions() {
    const result: { name: string; action: string; disabled?: boolean }[] = [];
    result.push({ name: 'Avbryt', action: 'cancel' });
    return result;
  }

  private get saveDisabled() {
    return !this.validateUrl(this.href) || this.linkText.trim() === '';
  }

  private get filteredDocuments() {
    if (this.filterString.length) {
      return this.docsForLinking.filter(
        (document) =>
          this.filterString === '' || document.name.toLocaleLowerCase().includes(this.filterString.toLocaleLowerCase()),
      );
    }
    return [];
  }

  docTypeStyle(docType) {
    return 'background-image: url(/images/' + docType.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase()) + '-gray.svg)';
  }

  docClick(doc) {
    this.selectedDoc = doc;
    this.filterString = doc.name;
    this.onDispatchAction(false, 'setInternal');
  }

  renderBody() {
    return html`
      ${this.docsForLinking.length
        ? html`
            <d-section>
              <d-select-radio
                .value=${this.target}
                .options=${this.typeOptions}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.typeChanged(e);
                }}
              ></d-select-radio>
            </d-section>
          `
        : nothing}
      <d-expansion .opened=${this.target === '_self'}>
        <d-section topless>
          <d-edit-text
            id="internal-input"
            type="search"
            placeholder="Søk etter dokument"
            .value=${this.filterString}
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.filterString = e.detail.value;
            }}
          ></d-edit-text>
        </d-section>
        <d-section topless>
          <d-smooth-resize>
            <div class="doc-list">
              ${this.filteredDocuments.map((doc) => {
                return html`<div
                  style="${this.docTypeStyle(doc.docType)}"
                  @click=${() => this.docClick(doc)}
                  ?selected=${this.selectedDoc && doc.uuid === this.selectedDoc.uuid}
                >
                  ${doc.name}
                </div>`;
              })}
            </div>
          </d-smooth-resize>
        </d-section>
      </d-expansion>
      <d-expansion .opened=${this.target === '_blank'}>
        <d-section topless>
          <d-edit-text
            label="Tekst"
            .value=${this.linkText}
            placeholder="Skriv teksten som skal vises"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.linkText = e.detail.value;
            }}
          ></d-edit-text>
        </d-section>
        <d-section topless>
          <d-edit-text
            label="Nettadresse"
            id="external-input"
            button
            .buttonDisabled=${this.saveDisabled}
            @button-click=${() => this.onDispatchAction(this.saveDisabled, 'setExternal')}
            .value=${this.href}
            placeholder="Skriv eller lim inn nettadressen"
            @value-changed=${(e: CustomEvent<{ value: string }>) => {
              e.stopPropagation();
              this.href = e.detail.value;
            }}
          ></d-edit-text>
        </d-section>
      </d-expansion>
    `;
  }

  protected fetchResult(detail: 'cancel'): EditLinkResult {
    return {
      action: detail,
      selectedDoc: this.selectedDoc,
      linkText: this.linkText,
      href: this.href,
      target: this.target,
    };
  }

  protected initializeDialog(input: EditLinkInput) {
    this.href = '';
    if (input.href) {
      this.href = input.href;
      this.target = input.target;
      this.linkText = input.linkText;
      this.dialogType = 'edit';
      this.title = 'Rediger lenke';
    } else {
      this.href = '';
      this.dialogType = 'insert';
      this.title = 'Sett inn lenke';
    }
    if (input.docsForLinking.length) {
      this.docsForLinking = input.docsForLinking;
    } else {
      this.target = '_blank';
    }
  }

  private validateUrl(value) {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      value,
    );
  }

  private typeChanged(e) {
    e.stopPropagation();
    this.target = e.detail.value;
    if (e.detail.value === '_self') {
      const elm = this.shadowRoot?.getElementById('internal-input') as DEditText;
      console.log('elm', elm);
      if (elm) {
        elm._focus();
      }
    }
    if (e.detail.value === '_blank') {
      const elm = this.shadowRoot?.getElementById('external-input') as DEditText;
      console.log('elm', elm);
      if (elm) {
        elm._focus();
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-edit-link-dialog': DEditLinkDialog;
  }
}
