import { html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../library/components/d-popup.js';
import '../../library/elements/d-section.js';
import '../../library/fields/d-view-info.js';
import { BaseDialog } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { numberWord } from 'src/utilities/text';

export interface RecurringEventInCollectionDialogInput {
  collectionName: string;
  eventsCount: number;
  removing: boolean;
  isMeeting: boolean;
}

export interface RecurringEventInCollectionDialogResult {
  action: 'cancel' | 'all' | 'single';
}

@customElement('recurring-event-in-collection-dialog')
export class RecurringEventInCollectionDialog extends BaseDialog<
  RecurringEventInCollectionDialogInput,
  RecurringEventInCollectionDialogResult
> {
  @property({ type: String })
  collectionName = '';
  @property({ type: Number })
  eventsCount = 0;
  @property({ type: Boolean })
  removing = false;
  @property({ type: Boolean })
  isMeeting = false;
  width = 400;

  protected get calculatedTitle(): string {
    if (this.removing) {
      return this.calculatedDeletingTitle();
    } else {
      return this.calculatedEditingTitle();
    }
  }

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Alle', action: 'all' },
      { name: this.isMeeting ? 'Bare det ene' : 'Bare den ene', action: 'single' },
    ];
  }

  private get text() {
    const bothAll = this.eventsCount === 2 ? 'begge' : 'alle';
    const meetingsCount = numberWord(this.eventsCount, true);
    const eventsCount = numberWord(this.eventsCount);
    if (this.removing) {
      if (this.isMeeting) {
        return `<p>Det finnes ${meetingsCount} gjentakelser av dette møtet i ${this.collectionName}. Vil du fjerne ${bothAll}, eller bare det ene?</p>`;
      } else {
        return `<p>Det finnes ${eventsCount} gjentakelser av denne oppgaven i ${this.collectionName}. Vil du fjerne ${bothAll}, eller bare den ene?</p>`;
      }
    } else if (this.isMeeting) {
      return `<p>Det finnes ${meetingsCount} gjentakelser av dette møtet. Vil du legge til ${bothAll}, eller bare det ene?</p>`;
    } else {
      return `<p>Det finnes ${eventsCount} gjentakelser av denne oppgaven. Vil du legge til ${bothAll}, eller bare den ene?</p>`;
    }
  }

  renderBody() {
    return html`
      <d-section>
        <d-view-info .content=${this.text}></d-view-info>
      </d-section>
    `;
  }

  protected fetchResult(detail: string | undefined): RecurringEventInCollectionDialogResult {
    switch (detail) {
      case 'cancel':
        return { action: 'cancel' };
      case 'all':
        return { action: 'all' };
      case 'single':
        return { action: 'single' };
    }
    throw new Error('Method not implemented.');
  }

  protected initializeDialog(input: RecurringEventInCollectionDialogInput) {
    this.collectionName = input.collectionName;
    this.eventsCount = input.eventsCount;
    this.removing = input.removing;
    this.isMeeting = input.isMeeting;
  }

  private calculatedEditingTitle() {
    if (this.isMeeting) {
      return 'Legge til gjentatt møte';
    } else {
      return 'Legge til gjentatt oppgave';
    }
  }

  private calculatedDeletingTitle() {
    if (this.isMeeting) {
      return 'Fjerne gjentatt møte';
    } else {
      return 'Fjerne gjentatt oppgave';
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'recurring-event-in-collection-dialog': RecurringEventInCollectionDialog;
  }
}
