import type { State } from 'src/store/types.js';
import {
  currentEmployeeUuid,
  employeesNotTerminatedCount,
  flattenPageGroups,
  getOrganizationId,
  sector,
  PageGroup,
  pageViewModel,
  writeAccess,
  pageIds,
  ApiClient,
} from 'src/store';
import { staffingPageView } from 'src/models/pages/staffing-page-view.js';
import type { PageView } from 'src/pages/d-page.js';
import { computersPageView } from 'src/models/pages/computers-page-view.js';
import { meetingsPageView } from 'src/models/pages/meetings-page-view.js';
import { overviewPageView } from 'src/models/pages/overview-page-view.js';
import { assetsPageView } from 'src/models/pages/assets-page-view.js';
import { substancesPageView } from 'src/models/pages/substances-page-view.js';
import { referencesPageView } from 'src/models/pages/references-page-view.js';
import { organizationPageView } from 'src/models/pages/organization-page-view.js';
import { eventsPageView } from 'src/models/pages/events-page-view';
import { trashPageView } from 'src/models/pages/trash-page-view.js';
import { statusPageView } from 'src/models/pages/status-page-view';
import { employeesPageView } from 'src/models/pages/employees-page-view';
import { partnersPageView } from 'src/models/pages/partners-page-view';
import { issuesPageView } from 'src/models/pages/issues-page-view';
import { riskAssessmentsPageView } from 'src/models/pages/risk-assessments-page-view';
import { peoplePageView } from 'src/models/pages/people-page-view';
import { tutorialsPageView } from 'src/models/pages/tutorials-page-view';
import { getFeatureStates, getSpecialTerms } from 'src/store/selectors/features';
import { AbstractPageView } from 'src/pages/abstract-page-view';
import { collectionsPageView } from 'src/models/pages/collections-page-view';

function addInternalLinks(helpContent: string, pageGroups: PageGroup[], organizationId: number) {
  let newContent = helpContent;
  const pages = flattenPageGroups(pageGroups);
  for (const element of pages) {
    newContent = fixLinks(newContent, element.pageId, element.name, organizationId);
  }
  return newContent;
}

/**
 *
 * @param content
 * @param pageId
 * @param pageName
 * @param organizationId
 */
function fixLinks(content: string, pageId: number, pageName: string, organizationId: number) {
  const regex = new RegExp('href="' + pageName + '"', 'gi');
  const replacement = 'href="/account/' + organizationId + '/' + pageId + '"';
  return content.replace(regex, replacement);
}

export function pageView(
  api: ApiClient,
  hrefPrefix: string,
  currentPageId: number,
  pageGroups: PageGroup[],
  state: State,
): PageView {
  const viewModelWithInternalLinks = pageViewModel(currentPageId, pageGroups);
  const viewModel: AbstractPageView = {
    ...viewModelWithInternalLinks,
    href: '',
    organizationId: getOrganizationId(state),
    writeAccess: writeAccess(state),
    helpContent: addInternalLinks(viewModelWithInternalLinks.helpContent, pageGroups, getOrganizationId(state)),
    specialTerms: getSpecialTerms(state),
    featureStates: getFeatureStates(state),
    employeesCount: employeesNotTerminatedCount(state),
    currentEmployeeUuid: currentEmployeeUuid(state),
    sector: sector(state),
  };
  switch (currentPageId) {
    case pageIds.status:
      return statusPageView(hrefPrefix, viewModel, state);
    case pageIds.events:
      return eventsPageView(hrefPrefix, viewModel, state);
    case pageIds.staffing:
      return staffingPageView(hrefPrefix, viewModel, state);
    case pageIds.meetings:
      return meetingsPageView(hrefPrefix, viewModel, state);

    case pageIds.assets:
      return assetsPageView(hrefPrefix, viewModel, state);
    case pageIds.substances:
      return substancesPageView(hrefPrefix, viewModel, state);
    case pageIds.dataSystems:
      return computersPageView(api, hrefPrefix, viewModel, state);
    case pageIds.issues:
      return issuesPageView(hrefPrefix, viewModel, state);
    case pageIds.riskAssessments:
      return riskAssessmentsPageView(hrefPrefix, viewModel, state);
    case pageIds.collections:
      return collectionsPageView(hrefPrefix, viewModel, state);

    case pageIds.company:
      return organizationPageView(hrefPrefix, viewModel, state);
    case pageIds.employees:
      return employeesPageView(hrefPrefix, viewModel, state);
    case pageIds.partners:
      return partnersPageView(hrefPrefix, viewModel, state);
    case pageIds.people:
      return peoplePageView(hrefPrefix, viewModel, state);
    case pageIds.tutorials:
      return tutorialsPageView('tutorials', hrefPrefix, viewModel, state);

    case pageIds.references:
      return referencesPageView(hrefPrefix, viewModel, state);
    case pageIds.trash:
      return trashPageView(hrefPrefix, viewModel, state);
    default:
      return overviewPageView(hrefPrefix, viewModel, state);
  }
}
