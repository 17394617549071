import { createSelector } from '@reduxjs/toolkit';
import type { CompleteOrganizationViewModel, TaskViewModel } from '../api/models/index.js';
import { currentEmployeeUuid, employeesNotDeleted } from './organization-employees.js';
import { contactsNotDeleted, currentContactUuid } from './organization-partners.js';
import { sortBy } from 'lodash';
import { joinWithAnd } from 'src/utilities/text';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

interface CompleteOrganizationViewModelWithTasks extends CompleteOrganizationViewModel {
  tasks: TaskViewModel[];
}
interface EntityForAccess {
  uuid: string;
  accessType: string;
}

interface EntityScopeForaccess {
  accessType: string;
  scope: string;
  entities?: EntityForAccess[];
}

interface EntityTypeForAccess {
  name: keyof CompleteOrganizationViewModelWithTasks;
  scopes: EntityScopeForaccess[];
}

interface AccessProfile {
  name: string;
  displayName: string;
  description: string;
  userTypes: string[];
  accessType: string;
  pages?: number[];
  newDocMenu?: string[];
  entityTypes?: EntityTypeForAccess[];
}

export function accessProfiles(): AccessProfile[] {
  return [
    {
      name: 'NONE',
      displayName: 'Ingen tilgang',
      description: '<p>Ingen tilgang til TrinnVis.</p>',
      userTypes: ['employees', 'contacts'],
      accessType: 'none',
    },
    {
      name: 'READONLY',
      displayName: 'Lesetilgang',
      description:
        '<p>Brukere med lesetilgang kan lese alle slags dokumenter i TrinnVis, ' +
        'unntatt enkeltdokumenter med begrenset tilgang.</p>' +
        '<p>Brukere med lesetilgang kan ikke opprette eller endre dokumenter, men de kan melde avvik.</p>',
      userTypes: ['employees', 'contacts'],
      accessType: 'read',
    },
    {
      name: 'USER',
      displayName: 'Skrivetilgang',
      description:
        '<p>Brukere med skrivetilgang kan opprette, lese, redigere og slette alle ' +
        'slags dokumenter i TrinnVis, unntatt enkeltdokumenter med begrenset tilgang.</p>',
      userTypes: ['employees', 'contacts'],
      accessType: 'write',
    },
    {
      name: 'ADMIN',
      displayName: 'Administratortilgang',
      description:
        '<p>Administratortilgang kan bare endres av administratoren selv, ' + 'i Innstillinger > Abonnement.</p>',
      userTypes: ['employees'],
      accessType: 'write',
    },
    {
      name: 'ASSETS',
      displayName: 'Utstyrstilgang',
      description:
        '<p>Brukere med utstyrstilgang har bare tilgang til <i>Oppgaver</i> og ' +
        '<i>Utstyrsoversikt</i>. De kan  opprette, lese, redigere og slette utstyrsenheter ' +
        'og utstyrsrelaterte oppgaver. De kan også lese og redigere ' +
        'oppgaver tildelt dem, og tildele oppgaver til seg selv og andre.</p>',
      userTypes: ['contacts'],
      accessType: 'specified',
      pages: [318434, 110],
      newDocMenu: ['assets'],
      entityTypes: [
        {
          name: 'employees',
          scopes: [
            {
              scope: 'all',
              accessType: 'minimal',
            },
          ],
        },
        {
          name: 'partners',
          scopes: [
            {
              scope: 'all',
              accessType: 'read',
            },
          ],
        },
        {
          name: 'assets',
          scopes: [
            {
              scope: 'all',
              accessType: 'write',
            },
          ],
        },
        {
          name: 'functions',
          scopes: [
            {
              scope: 'all',
              accessType: 'read',
            },
          ],
        },
        {
          name: 'tasks',
          scopes: [
            {
              scope: 'assetRelated',
              accessType: 'read',
            },
          ],
        },
      ],
    },
  ];
}
createSelector(
  currentEmployeeUuid,
  currentContactUuid,
  employeesNotDeleted,
  contactsNotDeleted,
  accessProfiles,
  function (employeeUuid, contactUuid, employees, contacts, profiles): AccessProfile {
    let profileName = '';
    if (employeeUuid) {
      profileName = employees.find((x) => x.uuid === employeeUuid)?.accessLevel ?? 'NONE';
    }
    if (contactUuid) {
      profileName = contacts.find((x) => x.uuid === contactUuid)?.accessLevel ?? 'NONE';
    }
    if (profileName) {
      return profiles.filter((p) => {
        return p.name === profileName;
      })[0];
    }
    return {
      name: 'default',
      description: '',
      userTypes: [],
      displayName: 'Skrivetilgang',
      accessType: 'write',
    };
  },
);

export function getClassificationText(
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE',
  accessControl: string[],
  accessControlOptions: SelectDropdownOption[],
): string {
  if (classification !== 'NONE') {
    let s = accessControlOptions
      .filter(function (x) {
        return (accessControl ?? []).indexOf(x.value) >= 0;
      })
      .map(function (x) {
        return x.text;
      });
    s = sortBy(s, (e) => e.toLowerCase());
    return joinWithAnd(s);
  }
  return '';
}
