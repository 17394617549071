import { css, html, LitElement, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../library/elements/d-menu.js';
import './d-infosec-view-header.js';
import { InfosecAsset, InfosecExternalConnection, sortSelectedCategories } from './defaults.js';
import './editors/d-edit-computers-network.js';
import './editors/d-edit-networks-computers.js';
import './editors/d-edit-networks-external-connections.js';
import type {
  InfosecNetwork,
  InfosecDataItem,
  InfosecStorageUnit,
} from 'src/pages/computers-page/infosec-procedure/defaults.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import {
  NewNetworkDialog,
  NewNetworkResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-network-dialog';
import { uuid } from 'src/utilities/text';
import {
  NewComputerDialog,
  NewComputerResult,
} from 'src/pages/computers-page/infosec-procedure/editors/new-computer-dialog';

/**
 *
 */
@customElement('d-infosec-computers-networks')
export class DInfosecComputersNetworks extends LitElement {
  static readonly styles = [css``];
  @property({ type: String })
  tab = 'networks';
  @property({ type: Array })
  dataItems: InfosecDataItem[] = [];
  @property({ type: Array })
  employees: SelectDropdownOption[] = [];
  @property({ type: Array })
  partners: SelectDropdownOption[] = [];
  @property({ type: Array })
  externalConnections: InfosecExternalConnection[] = [];

  @property({ type: Array })
  computers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  remoteServers: InfosecStorageUnit[] = [];
  @property({ type: Array })
  cloudServices: InfosecStorageUnit[] = [];
  @property({ type: Array })
  locations: InfosecStorageUnit[] = [];
  @property({ type: Array })
  assets: InfosecAsset[] = [];
  @property({ type: Array })
  networks: InfosecNetwork[] = [];

  private get menuItems() {
    return [
      {
        value: 'networks',
        text: 'Nettverk',
      },
      {
        value: 'computers',
        text: 'Lokale enheter',
      },
      {
        value: 'externalConnections',
        text: 'Eksterne tilkoblinger',
      },
    ];
  }

  _networksChanged(detail) {
    this.computers = detail.computers;
    this.networks = detail.network;
  }

  render() {
    let networkTerm = 'nettverket';
    if (this.networks.length > 1) {
      networkTerm = 'nettverkene';
    }
    let networksInfo = `<p>Velg nettverkstilknytning for enhetene.</p>`;
    if (this.computers.length === 0) {
      networksInfo += `<p><em>Ingen enheter er registrert.</em></p>`;
    }
    let computersInfo = `<p>Registrer alle datamaskiner og andre enheter tilknyttet ${networkTerm}.</p>`;
    if (this.networks.length === 0) {
      computersInfo += `<p><em>Ingen nettverk er registrert.</em></p>`;
    }
    let externalConnectionsInfo = `<p>Registrer personale med hjemmekontorløsning og samarbeidspartnere som 
      kan koble seg til virksomhetens lokale nettverk via Internett.</p>`;
    if (this.networks.length === 0) {
      externalConnectionsInfo += `<p><em>Ingen lokale nettverk er registrert.</em></p>`;
    }
    return html`
      <d-menu
        small
        .items=${this.menuItems}
        .value=${this.tab}
        @value-changed=${(e) => (this.tab = e.detail.value)}
      ></d-menu>
      ${this.tab === 'networks'
        ? html`
            <d-infosec-view-header title="Nettverk" .info=${networksInfo}></d-infosec-view-header>
            <d-edit-computers-network
              .computers=${this.computers}
              .networks=${this.networks}
              @selected-items-changed=${(e) => this._networksChanged(e.detail)}
            ></d-edit-computers-network>
            <d-action @click=${async () => await this.addNetwork()}>Legg til nettverk</d-action>
            <d-action @click=${async () => await this.addComputer()}>Legg til enhet</d-action>
          `
        : nothing}
      ${this.tab === 'computers'
        ? html`
            <d-infosec-view-header
              title="Lokale enheter i ${networkTerm}"
              .info=${computersInfo}
            ></d-infosec-view-header>
            <d-edit-networks-computers
              .computers=${this.computers}
              .networks=${this.networks}
            ></d-edit-networks-computers>
            <d-action @click=${async () => await this.addNetwork()}>Legg til nettverk</d-action>
            <d-action @click=${async () => await this.addComputer()}>Legg til enhet</d-action>
          `
        : nothing}
      ${this.tab === 'externalConnections'
        ? html`
            <d-infosec-view-header
              title="Eksterne tilkoblinger til lokalt nettverk"
              .info=${externalConnectionsInfo}
            ></d-infosec-view-header>
            <d-edit-networks-external-connections
              .networks=${this.networks}
              .computers=${this.computers}
              .employees=${this.employees}
              .partners=${this.partners}
              .externalConnections=${this.externalConnections}
            ></d-edit-networks-external-connections>
            <d-action @click=${async () => await this.addNetwork()}>Legg til nettverk</d-action>
          `
        : nothing}
    `;
  }

  protected firstUpdated() {
    this.dataItems = sortSelectedCategories(this.dataItems);
  }

  private async addNetwork() {
    const result: NewNetworkResult = await NewNetworkDialog.open({ name: '', type: 'CABLE', connectionType: 'NONE' });
    if (result.action === 'done') {
      const newItem: InfosecNetwork = {
        uuid: uuid(),
        name: result.name,
        type: result.type,
        connectionType: result.connectionType,
      };
      this.dispatchEvent(
        new CustomEvent<{ item: InfosecNetwork }>('new-network', {
          composed: true,
          bubbles: true,
          detail: {
            item: newItem,
          },
        }),
      );
    }
  }

  private async addComputer() {
    const result: NewComputerResult = await NewComputerDialog.open({
      name: '',
      requireNameForServers: this.computers.filter((c) => c.computerType === 'Server').length !== 0,
    });
    if (result.action === 'done') {
      const newItem = {
        uuid: uuid(),
        name: result.name,
        type: result.type,
      };
      this.dispatchEvent(
        new CustomEvent<{ uuid: string; name: string; type: string }>('new-computer', {
          composed: true,
          bubbles: true,
          detail: newItem,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-infosec-computers-networks': DInfosecComputersNetworks;
  }
}
