import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { styleMap } from 'lit/directives/style-map.js';
import { leavePeriodStyles } from 'src/library/leave-period-styles.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../fields/d-expansion.js';
import './d-list-section.js';
import './d-list-section-event-asset-item.js';
import { shortenPath } from 'src/utilities/path';
import { classMap } from 'lit/directives/class-map.js';

/**
 *
 * STATUS OK
 */
@customElement('d-list-section-item')
export class DListSectionItem extends LitElement {
  static readonly styles = [
    leavePeriodStyles,
    css`
      :host {
        display: block;
        border-color: var(--borderColor);
        container-type: inline-size;
      }

      :host * {
        line-height: var(--lineHeightDefault);
      }

      d-list-section {
        margin-left: var(--listPaddingLeft);
      }

      .item.parent-item,
      .item.bold-label {
        font-weight: 500;
      }

      .item.parent-item {
        position: -webkit-sticky;
        position: sticky;
        /* top: 38px; TODO */
        background-color: white;
        font-size: 16px;
        z-index: 1;
      }

      :host([bordered]) .item.parent-item {
        top: 42px;
      }

      :host([theme-page]) .item.parent-item {
        background-color: var(--backgroundGray);
      }

      :host([bordered]) .item {
        border-top: 1px solid var(--borderColor);
      }

      :host([bordered][theme-page]) .item {
        border-top: 1px solid var(--borderColorOnGray);
      }

      :host([bordered][start-tasks]) .item {
        border-top: 1px solid var(--startTaskBorderColor);
      }

      .wrapper {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: baseline;
        position: relative;
      }

      .split {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
      }

      .draft {
        flex: none;
        display: inline-block;
        position: relative;
        top: 5px;
        left: -1px;
        width: 18px;
        height: 22px;
        margin-top: -6px;
        margin-right: 5px;
        background: url(/images/draft.svg) -5px -2px no-repeat;
        background-size: 24px 24px;
      }

      .name {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      .primary {
        margin-right: 6px;
      }

      .secondary {
        font-size: 14px;
        font-weight: 200;
      }

      .secondary-label-items {
        display: block;
        padding-left: 20px;
      }

      .secondary-label-items span {
        display: block;
        font-size: 14px;
        font-weight: 200;
      }

      .notes {
        font-size: 14px;
        font-weight: 200;
      }

      .right {
        flex-grow: 1;
        margin-left: 10px;
        text-align: right;
      }

      a {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        padding: 5px 0 5px 0;
        color: var(--linkColorGray);
        line-height: var(--lineHeightDefault);
        text-decoration: none;
        cursor: pointer;
      }

      :host([bordered]) a {
        padding-top: 10px;
        padding-bottom: 9px;
      }

      a span {
        pointer-events: none;
      }

      d-action {
        margin: -6px 0 -6px 6px;
        pointer-events: all;
      }

      d-label {
        pointer-events: all;
      }

      @media (hover: hover) {
        a:hover,
        item.alert a:hover .name {
          color: black;
        }

        .item.unaccessible a,
        .item.unaccessible a:hover {
          color: hsl(0, 0%, 20%);
          cursor: default;
          pointer-events: none;
        }
      }

      .template-deleted .primary:before,
      .template-updated .primary:before,
      .document-updated .primary:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
      }

      .template-deleted .primary:before {
        background-color: var(--alertColor);
      }

      .template-updated .primary:before {
        background-color: var(--themeColor);
      }

      .document-updated .primary:before {
        background-color: hsl(100, 80%, 40%);
      }

      .item.timekeeping-correction a {
        font-weight: 500;
      }

      a span {
        pointer-events: none;
      }

      .list-content a {
        display: block;
        padding: 6px 0;
        color: var(--linkColorGray);
        text-decoration: none;
      }

      @media (hover: hover) {
        .list-content a:hover {
          color: black;
        }
      }

      .no-item {
        color: hsl(0, 0%, 20%);
        cursor: default;
        font-style: italic;
      }

      .create-event {
        color: hsl(96, 100%, 29%);
      }

      .add-header {
        padding-left: var(--listPaddingLeft);
      }

      .add-header d-action {
        margin-left: -8px;
      }

      .explanation {
        font-size: 13px;
        font-weight: 300;
      }

      .comment {
        width: 100%;
        font-family: var(--mainSerif), serif;
        line-height: 150%;
        font-weight: 200;
        font-style: italic;
      }

      .secondary-info {
        font-weight: 300;
      }

      .secondary-info.notes {
        font-size: 14px;
      }

      .secondary-info.done-text {
        font-size: 14px;
      }

      .assignee {
        font-size: 14px;
        font-weight: 200;
      }

      .item {
        margin-left: var(--listPaddingLeft);
      }

      /* HANGING ICONS WITH BACKGROUND IMAGES */

      .item.lock a,
      .item.check-gray a,
      .item.check-blue a,
      .item.x-orange a,
      .item.info a,
      .item.alert a,
      .item.suggestion a,
      .item.meetings a {
        margin-left: calc(var(--listPaddingLeft) * -1);
        padding-left: var(--listPaddingLeft);
        background-repeat: no-repeat;
        background-position: 1px 3px;
        background-size: 24px auto;
      }

      :host([bordered]) .item.lock a,
      :host([bordered]) .item.check-gray a,
      :host([bordered]) .item.check-blue a,
      :host([bordered]) .item.x-orange a,
      :host([bordered]) .item.alert a,
      :host([bordered]) .item.suggestion a {
        background-position-y: 8px;
      }

      .item.lock a {
        background-image: url(/images/locked.svg);
      }

      .item.check-gray a {
        background-image: url(/images/check-mini-gray.svg);
      }

      .item.check-blue a {
        background-image: url(/images/check-mini-blue.svg);
      }

      .item.x-orange a {
        background-image: url(/images/x-fat-orange.svg);
      }

      .item.info a {
        background-image: url(/images/info-fill.svg);
      }

      .item.alert a {
        background-image: url(/images/alert.svg);
      }

      .item.suggestion a {
        background-image: url(/images/suggestion-gray.svg);
        background-position: -3px -2px;
        background-size: 32px auto;
      }

      :host([bordered]) .item.suggestion a {
        background-position-y: 3px;
      }

      .item.meetings a {
        background: url(/images/meetings-gray.svg) -1px 1px no-repeat;
        background-size: 28px;
      }

      :host([bordered]) .item.meetings a {
        background-position-y: 6px;
      }

      /* HANGING ICONS WITH :BEFORE ELEMENTS */

      .item.leave-period a:before,
      .item.risk a:before {
        content: '';
        position: absolute;
        box-sizing: border-box;
      }

      .item.leave-period a:before {
        content: '';
        display: block;
        position: absolute;
        flex: none;
        left: -30px;
        top: 6px;
        width: 18px;
        height: 18px;
      }

      :host([bordered]) .item.leave-period a:before {
        top: 11px;
      }

      .item.risk a:before {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        left: -32px;
        top: 4px;
        border-radius: 12px;
        background: hsla(0, 0%, 0%, 0.1);
        color: white;
        font-family: var(--small), sans-serif;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 120%;
        font-weight: 500;
      }

      :host([bordered]) .item.risk a:before {
        top: 9px;
      }

      .item.risk.risk1 a:before {
        content: 'L';
        background: var(--risk-color-1);
      }

      .item.risk.risk2 a:before {
        content: 'M';
        background: var(--risk-color-2);
      }

      .item.risk.risk3 a:before {
        content: 'H';
        background: var(--risk-color-3);
      }

      .item.risk.risk4 a:before {
        content: 'S';
        background: var(--risk-color-4);
      }

      /* *************** */

      .item.suggestion a {
        opacity: 0.7;
      }

      .item.alert a .name {
        color: var(--alertColor);
      }

      .item.rotated .primary:after {
        content: '';
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-top: -7px;
        margin-bottom: -7px;
        margin-right: -4px;
        background-image: url(/images/rotate.svg);
        background-repeat: no-repeat;
        background-position: 1px 3px;
        background-size: 24px auto;
      }

      d-action.right-action {
        margin-right: -8px;
      }

      .delete {
        display: block;
        position: relative;
        margin-right: -20px;
        width: 20px;
        align-self: stretch;
        background: url(/images/x-thin-orange.svg) 3px 50% no-repeat;
        background-size: 20px auto;
        cursor: pointer;
        opacity: 0;
      }

      @media (hover: hover) {
        .item:hover .delete {
          opacity: 1;
        }
        .item:hover .delete:hover {
          background-image: url(/images/x-thin-black.svg);
        }
      }
      /* NO LEFT PADDING (BY ATTRIBUTE OR CONTAINER QUERY) */

      :host([no-left-padding]) .item {
        margin-left: 0;
      }

      :host([no-left-padding]) .item.lock a,
      :host([no-left-padding]) .item.check-gray a,
      :host([no-left-padding]) .item.check-blue a,
      :host([no-left-padding]) .item.x-orange a,
      :host([no-left-padding]) .item.info a,
      :host([no-left-padding]) .item.alert a,
      :host([no-left-padding]) .item.suggestion a,
      :host([no-left-padding]) .item.meetings a {
        margin-left: 0;
      }

      :host([no-left-padding]) .item.leave-period a,
      :host([no-left-padding]) .item.risk a {
        margin-left: var(--listPaddingLeft);
      }

      :host([no-left-padding]) d-list-section {
        margin-left: 0;
      }

      @container (max-width: 600px) {
        .item {
          margin-left: 0;
        }
        .item.lock a,
        .item.check-gray a,
        .item.check-blue a,
        .item.x-orange a,
        .item.info a,
        .item.alert a,
        .item.suggestion a,
        .item.meetings a {
          margin-left: 0;
        }
        .item.leave-period a,
        .item.risk a {
          margin-left: var(--listPaddingLeft);
        }
        d-list-section {
          margin-left: 0;
        }
      }

      /* END NO LEFT PADDING */
    `,
  ];

  @property({ type: Object })
  input?: ListSectionItemInput;
  @property({ type: Boolean, reflect: true, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, reflect: true, attribute: 'bordered' })
  bordered = false;
  @property({ type: Boolean })
  hideSublist = false;
  @property({ type: Boolean })
  showNotes = false;
  @property({ type: Number })
  itemStickyTop = 0;
  @property({ type: Boolean, attribute: 'no-left-padding', reflect: true })
  noLeftPadding = false;
  @property({ type: Boolean })
  deleteButton = false;

  _computeTarget(target) {
    if (target) {
      return target;
    }
    return '';
  }

  itemClassMap(i: ListSectionItemInput) {
    const riskLevels = ['risk0', 'risk1', 'risk2', 'risk3', 'risk4'];
    const leavePeriods = [
      'sickLeave',
      'vacation',
      'leave',
      'timeOff',
      'seminar',
      'jobTravel',
      'homeOffice',
      'sickSelf',
      'sickChildren',
      'OTHER',
    ];
    return {
      lock: i.mainIcon === 'lock',
      alert: i.mainIcon === 'alert',
      meetings: i.mainIcon === 'meetings',
      tasks: i.mainIcon === 'tasks',
      'check-gray': i.mainIcon === 'checkGray' || i.eventDone === true,
      'check-blue': i.mainIcon === 'checkBlue',
      'x-orange': i.mainIcon === 'xOrange',
      info: i.mainIcon === 'info',
      suggestion: i.mainIcon === 'suggestion',
      risk: i.mainIcon !== undefined && riskLevels.includes(i.mainIcon),
      risk1: i.mainIcon === 'risk1',
      risk2: i.mainIcon === 'risk2',
      risk3: i.mainIcon === 'risk3',
      risk4: i.mainIcon === 'risk4',
      'leave-period': i.mainIcon !== undefined && leavePeriods.includes(i.mainIcon),
      sickLeave: i.mainIcon === 'sickLeave',
      vacation: i.mainIcon === 'vacation',
      leave: i.mainIcon === 'leave',
      timeOff: i.mainIcon === 'timeOff',
      seminar: i.mainIcon === 'seminar',
      jobTravel: i.mainIcon === 'jobTravel',
      homeOffice: i.mainIcon === 'homeOffice',
      sickSelf: i.mainIcon === 'sickSelf',
      sickChildren: i.mainIcon === 'sickChildren',
      OTHER: i.mainIcon === 'OTHER',
      'parent-item': i.items?.length ?? false,
      'bold-label': i.labelItems !== undefined,
      unaccessible: !i.accessible,
      'template-deleted': i.updateStatus === 'templateDeleted',
      'template-updated': i.updateStatus === 'templateUpdated',
      'document-updated': i.updateStatus === 'documentUpdated',
      rotated: i.functionRotated ?? false,
      'timekeeping-correction': i.timekeepingCorrection ?? false,
    };
  }

  _toggleSublist() {
    console.log('_toggleSublist', this.hideSublist);
    this.hideSublist = !this.hideSublist;
  }

  _clickItem(e, input: ListSectionItemInput | undefined) {
    if (input !== undefined) {
      if (!input.accessible) {
        e.preventDefault();
      } else if (input.clickHandler) {
        e.preventDefault();
        input.clickHandler();
      } else if (input.clickData) {
        e.preventDefault();
        this.dispatchEvent(
          new CustomEvent('item-clicked', {
            bubbles: true,
            composed: true,
            detail: input,
          }),
        );
      }
    }
  }

  firstUpdated() {
    if (this.input && this.input.sublistHidden) {
      this.hideSublist = true;
    }
  }

  renderSublist() {
    if (this.input === undefined || this.input.items === undefined) {
      return nothing;
    }
    if (this.input.items.length === 0 && this.input.noItemText) {
      const noItem = {
        label: this.input.noItemText,
        accessible: false,
      };
      return html`
        <div class="list-content">
          <d-list-section-item
            class="no-item"
            .input=${noItem}
            ?theme-page=${this.themePage}
            ?bordered=${this.bordered}
            .noLeftPadding=${this.noLeftPadding}
          ></d-list-section-item>
        </div>
      `;
    }
    return html`
      <d-expansion ?opened=${!this.hideSublist}>
        <d-list-section
          sublist
          no-header
          ?bordered=${this.bordered}
          ?theme-page=${this.themePage}
          .items=${this.input.items}
          .limitItems=${this.input.limitItems}
          .noLeftPadding=${this.noLeftPadding}
        ></d-list-section>
      </d-expansion>
    `;
  }

  renderSublistHeader() {
    if (!this.input) {
      return nothing;
    }
    return html`
      <d-label .label=${this.input.label} .field=${this.input.field}></d-label>${this.input.sublistToggle
        ? html` <d-action mini ?theme-page=${this.themePage} @click=${() => this._toggleSublist()}
            >${this.hideSublist ? 'Vis' : 'Skjul'}
          </d-action>`
        : nothing}
    `;
  }

  renderItemName() {
    if (!this.input) {
      return nothing;
    }
    return html`
      <span class="primary">${this.input.label}</span>
      <span class="secondary">${this.input.secondaryLabel}</span>
    `;
  }

  render() {
    const i = this.input;
    if (i === undefined) {
      return nothing;
    }
    let parentItemStyles = {};
    if (i.items) {
      parentItemStyles = { top: this.itemStickyTop + 'px' };
    }
    const draftIcon = i.hasDraft ? html`<span class="draft"></span>` : nothing;
    const conditionalRightLabel =
      i.secondaryClickHandler === undefined
        ? html` <span class="right ${i.rightLabelClass}">${i.rightLabel}</span>`
        : nothing;
    const labelItems = i.labelItems?.length
      ? html`
          <span class="secondary-label-items">
            ${i.labelItems.map((labelItem) => html` <span>${labelItem}</span> `)}
          </span>
        `
      : nothing;
    const itemName = i.items && !i.accessible ? html`${this.renderSublistHeader()}` : html`${this.renderItemName()}`;
    const assetOrItem = i.assetCheck
      ? this.renderAssetItem(i)
      : this.renderItem(i, draftIcon, itemName, labelItems, conditionalRightLabel);
    return html`
      <div class="content">
        <d-expansion ?opened=${!i.hidden}>
          <div class="item ${classMap(this.itemClassMap(i))}" style=${styleMap(parentItemStyles)}>${assetOrItem}</div>
          ${this.renderSublist()}
        </d-expansion>
      </div>
    `;
  }

  private renderAssetItem(i: ListSectionItemInput) {
    return html` <d-list-section-event-asset-item
      ?writeAccess=${i.writeAccess}
      .input=${i}
    ></d-list-section-event-asset-item>`;
  }

  private renderItem(
    i: ListSectionItemInput,
    draftIcon: TemplateResult<1> | typeof nothing,
    itemName: TemplateResult<1>,
    labelItems: TemplateResult<1> | typeof nothing,
    conditionalRightLabel: TemplateResult<1> | typeof nothing,
  ) {
    return html`<span class="wrapper">
      <a
        href="${ifDefined(i.accessible ? shortenPath(i.href) : undefined)}"
        target="${this._computeTarget(i.target)}"
        action-data="${ifDefined(i.clickData)}"
        @click=${(e) => this._clickItem(e, this.input)}
      >
        <span class="split">
          <span class="left">
            <span class="name"> ${draftIcon} ${itemName} </span>
            ${labelItems}
            <d-expansion ?opened=${this.showNotes}>
              <span class="notes">${i.notes}</span>
            </d-expansion>
          </span>
          ${conditionalRightLabel}
        </span>
        <slot></slot>
      </a>
      ${this.renderRightAction(i)}
      ${this.deleteButton ? html` <span class="delete" @click=${() => this.removeItem()}></span>` : nothing}
    </span>`;
  }

  private removeItem() {
    this.dispatchEvent(
      new CustomEvent('remove-item', {
        bubbles: true,
        composed: true,
        detail: this.input,
      }),
    );
  }

  private renderRightAction(i: ListSectionItemInput) {
    const handler = i.secondaryClickHandler;
    if (handler !== undefined) {
      return html`<d-action class="right-action" @click=${() => handler()}>${i.rightLabel}</d-action>`;
    } else {
      return nothing;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-item': DListSectionItem;
  }
}
