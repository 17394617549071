import { html, TemplateResult } from 'lit';
import { customElement } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/editors/elements/d-checkbox.js';
import '../../library/editors/components/d-edit-event-timing.js';
import '../../library/editors/elements/d-select-tag.js';
import '../../library/editors/elements/d-edit-text.js';
import '../../library/editors/elements/d-edit-textarea.js';
import '../../library/elements/d-section.js';
import '../../library/editors/elements/d-radio-cell.js';
import '../../library/editors/elements/d-select-dropdown.js';
import '../../library/editors/components/d-edit-html.js';
import '../../library/editors/components/d-select-time.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';

export interface ConstitutionalDocumentViewEditItem {
  partners: string[];
  employees: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface ConstitutionalDocumentView
  extends AbstractEntityViewWithRevisions<ConstitutionalDocumentViewEditItem>,
    UpdateSectionItem {
  availablePartners: SelectTagOption[];
  availableEmployees: SelectTagOption[];
  isEmployee: boolean;
  type: 'constitutionalDocuments';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  classificationText: string;
  content: string;
  validFromDate: string;
  validToDate: string;
  contractPartyNames: string;
  partners: string[];
  employees: string[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-constitutional-document-view')
export class DConstitutionalDocumentView extends EntityContent<
  ConstitutionalDocumentView,
  ConstitutionalDocumentViewEditItem
> {
  static readonly styles = EntityContent.styles;

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: 'validFromDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'contractPartyNames',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'content',
        type: 'view-html',
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
      },
    ];
  }

  renderViewMode() {
    return html`
      ${super.renderViewMode()}
      ${this.renderFieldSection('constitutionalDocuments', this.viewFields(), this.entityView)}
    `;
  }

  async initializeEditItem() {
    this.editItem = {
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: this.entityView.content,
      employees: this.entityView.employees,
      partners: this.entityView.partners,
      validFromDate: this.entityView.validFromDate,
      validToDate: this.entityView.validToDate,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        autofocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        class: 'fullWidth',
      },
      {
        field: 'validFromDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
      {
        field: 'employees',
        type: 'edit-tags',
        options: this.entityView.availableEmployees,
        class: 'fullWidth',
      },
      {
        field: 'partners',
        type: 'edit-tags',
        options: this.entityView.availablePartners,
        class: 'fullWidth',
      },
    ];
  }
  protected renderEditClassification(item: ConstitutionalDocumentViewEditItem): TemplateResult<1> {
    if (this.entityView.isEmployee) {
      return html`
        <d-section>
          <d-edit-classification
            .accessControl=${item.accessControl}
            .accessControlOptions=${this.entityView.accessControlOptions}
            .classification=${item.classification}
            @value-changed=${(e: CustomEvent<{ accessControl: string[]; classification: 'NONE' | 'CONFIDENTIAL' }>) => {
              this.editItem = {
                ...item,
                accessControl: e.detail.accessControl,
                classification: e.detail.classification,
              };
            }}
          ></d-edit-classification>
        </d-section>
      `;
    }
    return html``;
  }

  renderEditItem(item: ConstitutionalDocumentViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)}
      ${this.renderFieldSection('constitutionalDocuments', this.editFields(), item)}
      <d-edit-html
        field="constitutionalDocuments_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      >
      </d-edit-html>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-constitutional-document-view': DConstitutionalDocumentView;
  }
}
