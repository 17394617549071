import type { RiskAssessmentViewModel } from 'src/store/api';
import type { State } from 'src/store/types';

import {
  activityCodesAvailable,
  BASE_PATH,
  getOrganizationId,
  getPageGroups,
  key,
  pageIds,
  PageViewModelExtended,
  riskAssessmentsReducedByName,
} from 'src/store';
import { LocalDate } from 'src/utilities/local-date';
import { entityNameForLists } from 'src/utilities/text';
import {
  RiskAssessmentGroup,
  RiskAssessmentsPageViewModel,
} from 'src/pages/risk-assessments-page/d-risk-assessments-page-content';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';
import { riskLevelFromRisk } from 'src/models/risk';
import { orderBy, sortBy } from 'lodash';
import { ListItemIcon, ListSectionItemInput } from 'src/library/lists/utilities';
import { AbstractPageView } from 'src/pages/abstract-page-view';

function toListItem(item: RiskAssessmentViewModel, hrefPrefix: string): ListSectionItemInput {
  let href = hrefPrefix + 'riskAssessments/' + item.uuid;
  if (!item.isConfirmedEntity || !item.name || item.unchangedTemplate) {
    href += '?edit';
  }
  let mainIcon: ListItemIcon | undefined = undefined;
  if (item.mainTemplateId !== undefined && item.unchangedTemplate) {
    mainIcon = 'suggestion';
  } else if (item.consequence && item.probability) {
    mainIcon = ('risk' + riskLevelFromRisk(item.consequence * item.probability)) as ListItemIcon;
  } else {
    mainIcon = 'risk0';
  }
  return {
    accessible: true,
    updateStatus: calculateUpdateStatus(item, LocalDate.now()),
    hasDraft: item.hasDraft,
    label: entityNameForLists(item.name, item.draftName, item.isConfirmedEntity),
    secondaryLabel: '',
    href,
    mainIcon,
  };
}

function getRiskAssessments(state: State, hrefPrefix: string): RiskAssessmentGroup[] {
  const groups: RiskAssessmentGroup[] = [];
  const themePageGroup = getPageGroups(state).find((group) => {
    return group.code === 'themePages';
  });
  if (themePageGroup) {
    const themePages: PageViewModelExtended[] = themePageGroup.pages;
    const categories: { pageId: number; name: string; substance: boolean }[] = [];
    themePages.forEach((page) => {
      categories.push({ pageId: page.pageId, name: page.name, substance: false });
      if (page.pageId === pageIds.hse) {
        categories.push({ pageId: 0, name: 'HMS: Stoffer', substance: true });
      }
    });
    const riskAssessmentsSorted = orderBy(
      riskAssessmentsReducedByName(state),
      [
        (item) => item.unchangedTemplate,
        (item) => (item.probability ?? 0) * (item.consequence ?? 0),
        (item) => item.name,
      ],
      ['asc', 'desc', 'asc'],
    );
    categories.forEach((category) => {
      const riskAssessmentsInCategory = riskAssessmentsSorted.filter((r) => {
        if (category.substance) {
          return r.relatedSubstance !== undefined && r.relatedSubstance !== null && r.relatedSubstance !== '';
        }
        if (category.pageId === pageIds.hse) {
          return r.pages?.includes(pageIds.hse) && !r.relatedSubstance;
        }
        return r.pages?.includes(category.pageId);
      });
      groups.push({
        themeName: category.name,
        themePageId: category.pageId,
        items: riskAssessmentsInCategory.map((r) => {
          return toListItem(r, hrefPrefix);
        }),
      });
    });
    const themePageIds = themePages.map((p) => p.pageId);
    const ungrouped = riskAssessmentsSorted.filter((r) => {
      return (!r.pages || !r.pages.some((r) => themePageIds.includes(r))) && !r.relatedSubstance;
    });
    if (ungrouped.length) {
      groups.push({ themeName: 'Annet', themePageId: 0, items: ungrouped.map((r) => toListItem(r, hrefPrefix)) });
    }
  }
  return sortBy(groups, (g) => g.items.length === 0);
}

export function riskAssessmentsPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): RiskAssessmentsPageViewModel {
  const o = state.organization;
  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }
  return {
    ...viewModel,
    type: 'risk-assessments-page',
    icon: 'riskAssessments',
    href: hrefPrefix,
    substancesPageHref: '/account/' + getOrganizationId(state) + '/' + pageIds.substances,
    availableActivityCodes: activityCodesAvailable(),
    pdfHref: BASE_PATH + '/organizations/' + o.organizationId + '/overview.pdf?key=' + key(state),
    riskAssessments: getRiskAssessments(state, hrefPrefix),
  };
}
