/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';

/**
 *
 * @export
 * @interface RiskAssessmentViewModel
 */
export interface RiskAssessmentViewModel {
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  createdDateTime: string;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  probability?: number;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  consequence?: number;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  riskDescription?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  measures?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  uuid: string;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  hasDraft: boolean;
  /**
   * true hvis endret fra standard verdier. Også true for ting som ikke kommer fra mal.
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  unchangedTemplate: boolean;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  draftName?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  category: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  assessmentDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  violatesInformationSecurityRequirements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  violatesIntegrityRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  violatesConfidentialityRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  violatesNonRepudiationRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  violatesAvailabilityRequirement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RiskAssessmentViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  responsibleBy?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  relatedSubstance?: string;
  /**
   *
   * @type {string}
   * @memberof RiskAssessmentViewModel
   */
  determinedBy?: string;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  pageId?: number;
  /**
   *
   * @type {number}
   * @memberof RiskAssessmentViewModel
   */
  createdFromTemplate?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof RiskAssessmentViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof RiskAssessmentViewModel
   */
  attachments?: Array<AttachmentViewModel>;
}

/**
 * Check if a given object implements the RiskAssessmentViewModel interface.
 */
export function instanceOfRiskAssessmentViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'createdDateTime' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'unchangedTemplate' in value;
  isInstance = isInstance && 'category' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;

  return isInstance;
}

export function RiskAssessmentViewModelFromJSON(json: any): RiskAssessmentViewModel {
  return RiskAssessmentViewModelFromJSONTyped(json, false);
}

export function RiskAssessmentViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskAssessmentViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdDateTime: json['createdDateTime'],
    probability: !exists(json, 'probability') ? undefined : json['probability'],
    consequence: !exists(json, 'consequence') ? undefined : json['consequence'],
    description: !exists(json, 'description') ? undefined : json['description'],
    riskDescription: !exists(json, 'riskDescription') ? undefined : json['riskDescription'],
    name: !exists(json, 'name') ? undefined : json['name'],
    measures: !exists(json, 'measures') ? undefined : json['measures'],
    uuid: json['uuid'],
    hasDraft: json['hasDraft'],
    unchangedTemplate: json['unchangedTemplate'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    category: json['category'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    assessmentDate: !exists(json, 'assessmentDate') ? undefined : json['assessmentDate'],
    violatesInformationSecurityRequirements: !exists(json, 'violatesInformationSecurityRequirements')
      ? undefined
      : json['violatesInformationSecurityRequirements'],
    violatesIntegrityRequirement: !exists(json, 'violatesIntegrityRequirement')
      ? undefined
      : json['violatesIntegrityRequirement'],
    violatesConfidentialityRequirement: !exists(json, 'violatesConfidentialityRequirement')
      ? undefined
      : json['violatesConfidentialityRequirement'],
    violatesNonRepudiationRequirement: !exists(json, 'violatesNonRepudiationRequirement')
      ? undefined
      : json['violatesNonRepudiationRequirement'],
    violatesAvailabilityRequirement: !exists(json, 'violatesAvailabilityRequirement')
      ? undefined
      : json['violatesAvailabilityRequirement'],
    deleted: !exists(json, 'deleted') ? undefined : json['deleted'],
    isConfirmedEntity: json['isConfirmedEntity'],
    responsibleBy: !exists(json, 'responsibleBy') ? undefined : json['responsibleBy'],
    relatedSubstance: !exists(json, 'relatedSubstance') ? undefined : json['relatedSubstance'],
    determinedBy: !exists(json, 'determinedBy') ? undefined : json['determinedBy'],
    pageId: !exists(json, 'pageId') ? undefined : json['pageId'],
    createdFromTemplate: !exists(json, 'createdFromTemplate') ? undefined : json['createdFromTemplate'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    attachments: !exists(json, 'attachments')
      ? undefined
      : (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function RiskAssessmentViewModelToJSON(value?: RiskAssessmentViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdDateTime: value.createdDateTime,
    probability: value.probability,
    consequence: value.consequence,
    description: value.description,
    riskDescription: value.riskDescription,
    name: value.name,
    measures: value.measures,
    uuid: value.uuid,
    hasDraft: value.hasDraft,
    unchangedTemplate: value.unchangedTemplate,
    draftName: value.draftName,
    helpContent: value.helpContent,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    category: value.category,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    assessmentDate: value.assessmentDate === undefined ? undefined : value.assessmentDate,
    violatesInformationSecurityRequirements: value.violatesInformationSecurityRequirements,
    violatesIntegrityRequirement: value.violatesIntegrityRequirement,
    violatesConfidentialityRequirement: value.violatesConfidentialityRequirement,
    violatesNonRepudiationRequirement: value.violatesNonRepudiationRequirement,
    violatesAvailabilityRequirement: value.violatesAvailabilityRequirement,
    deleted: value.deleted,
    isConfirmedEntity: value.isConfirmedEntity,
    responsibleBy: value.responsibleBy,
    relatedSubstance: value.relatedSubstance,
    determinedBy: value.determinedBy,
    pageId: value.pageId,
    createdFromTemplate: value.createdFromTemplate,
    pages: value.pages,
    attachments:
      value.attachments === undefined ? undefined : (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
