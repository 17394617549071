import { html, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import '../../library/editors/index.js';
import '../../library/editors/components/d-edit-classification.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/components/d-signatures-section';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, isEmptyHtmlContent } from 'src/utilities/text';
import {
  MeetingMessageDialog,
  MeetingMessageDialogInput,
  MeetingMessageDialogResult,
  MessageForSend,
} from 'src/content/meeting-occurrences/meeting-message-dialog';
import { MessageRecipient } from 'src/content/event-occurrences/d-event-occurrence-view';
import { SignableEntity } from 'src/library/components/d-signatures-section';
import { SectionField } from 'src/library/components/d-field-section';
import { ActionInput } from 'src/library/elements/d-action';

export interface MeetingDataForReport {
  uuid: string;
  name: string;
  messageRecipients: MessageRecipient[];
}

export interface ReportView
  extends AbstractEntityViewWithRevisions<ReportViewEditItem>,
    UpdateSectionItem,
    SignableEntity {
  hasRelatedMeetingEvent: boolean;
  pages: string[];
  employee: string;
  availablePersons: SelectTagOption[];
  writtenByName: string;
  reportDate: string;
  type: 'reports';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  classificationText: string;
  content: string;
  availablePages: SelectTagOption[];
  isMeetingReport: boolean;
  meetingData?: MeetingDataForReport;
  meetingOccurrenceUuid?: string;
  meetingOccurrenceDisplay?: string;
  meetingOccurrenceParticipants?: string;
  meetingOccurrenceAgenda?: string;
  currentEmployeeUuid: string;
  secondaryLabel?: string;
}

export interface ReportViewEditItem {
  reportDate: string;
  pages: string[];
  employee: string;
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
}

/**
 *
 * FIX Meeting header with employee and time?
 */
@customElement('d-report-view')
export class DReportView extends EntityContent<ReportView, ReportViewEditItem> {
  @property({ type: Boolean })
  isEmployee = false;
  @property({ type: Object })
  organization: any = {};
  @property({ type: Array })
  contacts = [];
  @property({ type: String })
  currentEmployeeUuid = '';
  @property({ type: String })
  employeeInterviewUuid = '';
  @property({ type: Object })
  relatedMeetingEvent = {};
  @property({ type: String })
  meetingEventHref = '';
  @property({ type: String })
  meetingPresent = '';
  @property({ type: String })
  meetingAgenda = '';

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: 'reportDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'employee',
        type: 'view-text',
        options: this.entityView.availablePersons,
        class: 'minWidth300',
      },
      {
        field: '',
        type: 'custom',
        render: () => this.renderSigningSection(),
        condition: this.entityView.featureStates.signing && !this.entityView.deleted,
      },
      {
        field: 'content',
        type: 'view-html',
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('reports', this.viewFields(), this.entityView)} `;
  }

  protected get currentItemSecondaryLabel() {
    return this.entityView.secondaryLabel || '';
  }

  protected get currentItemSublabel() {
    if (this.entityView.isMeetingReport) {
      return this.entityView.meetingOccurrenceDisplay ?? '';
    }
    return '';
  }

  private get participantsAndAgenda() {
    let result = '';
    if (this.entityView.meetingOccurrenceParticipants !== undefined) {
      result = this.entityView.meetingOccurrenceParticipants;
    }
    if (this.entityView.meetingOccurrenceAgenda !== undefined) {
      result += this.entityView.meetingOccurrenceAgenda;
    }
    return result;
  }

  async initializeEditItem() {
    this.editItem = {
      employee: this.entityView.employee ?? this.entityView.currentEmployeeUuid,
      pages: this.entityView.pages,
      reportDate: this.entityView.reportDate ?? this.entityView.today.toString(),
      accessControl: this.entityView.accessControl,
      classification: this.entityView.classification,
      content: this.entityView.content,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(item: ReportViewEditItem): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        class: 'fullWidth',
        placeholder: 'Dette feltet må fylles ut',
        markIfEmpty: true,
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        condition: !this.entityView.isMeetingReport,
      },
      {
        field: 'reportDate',
        type: 'edit-date',
        class: 'minWidth200',
      },
      {
        field: 'employee',
        type: 'edit-dropdown',
        options: this.entityView.availablePersons,
      },
      {
        field: 'content',
        type: 'edit-html',
        labelAction: this.reportAction(item),
        labelActionPlain: true,
        docsForLinking: this.entityView.docsForLinking,
      },
    ];
  }

  renderEditItem(item: ReportViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)} ${this.renderFieldSection('reports', this.editFields(item), item)}
    `;
  }

  protected onShareContent() {
    if (this.entityView.isMeetingReport) {
      return this.shareMeetingReport();
    } else {
      super.onShareContent();
    }
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }

  private async shareMeetingReport() {
    const meetingData: MeetingDataForReport | undefined = this.entityView.meetingData;
    if (meetingData) {
      const input: MeetingMessageDialogInput = {
        type: 'report',
        name: meetingData.name,
        preventIncludeContent: this.entityView.classification && this.entityView.classification !== 'NONE',
        isEmployeeInterview: meetingData.name.toLowerCase().includes('medarbeidersamtale'),
        recipients: meetingData.messageRecipients,
      };
      const result: MeetingMessageDialogResult = await MeetingMessageDialog.open(input);
      if (result.action === 'send') {
        const recipientsWithAccess = meetingData.messageRecipients.filter((p) => {
          return p.accessLevel !== 'NONE';
        });
        if (recipientsWithAccess.length > 0) {
          const messageData = {
            occurrenceUuid: meetingData.uuid,
            message: result.message,
            includeContent: result.includeContent,
          };
          this.dispatchEvent(
            new CustomEvent<MessageForSend>('send-meeting-report', {
              bubbles: true,
              composed: true,
              detail: messageData,
            }),
          );
        }
      }
    }
  }

  private insertContent() {
    if (this.editItem && this.participantsAndAgenda) {
      this.editItem = {
        ...this.editItem,
        content: this.participantsAndAgenda,
      };
    }
  }

  private renderSigningSection() {
    return html`<d-signatures-section .entityView=${this.entityView} .signingDisabled=${false}></d-signatures-section>`;
  }

  private reportAction(item: ReportViewEditItem): ActionInput {
    if (
      !isEmptyHtmlContent(this.entityView.meetingOccurrenceAgenda) &&
      (isEmptyHtmlContent(item.content) || item.content === this.entityView.meetingOccurrenceParticipants)
    ) {
      return { name: 'Sett inn agenda', action: 'insertContent' };
    }
    return { name: '', action: '' };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-report-view': DReportView;
  }
}
