import type { OrganizationState, State } from '../types.js';
import { groupBy, toList } from './utilities.js';
import type { RiskAssessmentViewModel } from '../api';
import { createSelector } from '@reduxjs/toolkit';
import { getOrganization } from './organization.js';
import { orderBy } from 'lodash';
import { numberWord } from 'src/utilities/text';

export function riskAssessmentsNotDeleted(state: State): RiskAssessmentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.riskAssessmentsById).filter((e) => !e.deleted);
}

export function riskAssessmentsDeleted(state: State): RiskAssessmentViewModel[] {
  if (state.organization === undefined) return [];
  return toList(state.organization.riskAssessmentsById).filter((e) => e.deleted);
}

function riskAssessmentNameWithSubstance(organization: OrganizationState, riskAssessment: RiskAssessmentViewModel) {
  if (!riskAssessment.relatedSubstance) {
    return riskAssessment.name ?? '';
  }
  return (organization.substancesById[riskAssessment.relatedSubstance].name ?? '') + ': ' + riskAssessment.name;
}

export const riskAssessmentsReducedByName = createSelector(
  getOrganization,
  riskAssessmentsNotDeleted,
  function (organization, riskAssessments): RiskAssessmentViewModel[] {
    if (organization === undefined) return [];
    const groupedRiskAssessments = groupBy(riskAssessments, (a) => riskAssessmentNameWithSubstance(organization, a));
    return groupedRiskAssessments.map((r) => {
      if (r.items.length === 1) {
        return { ...r.items[0], name: r.group };
      }
      let mainTemplateId = 0;
      r.items.forEach((item) => {
        if (item.mainTemplateId) {
          mainTemplateId = item.mainTemplateId;
        }
      });
      const newest = orderBy(r.items, [(i) => i.assessmentDate, (i) => i.createdDateTime], ['desc', 'desc'])[0];
      return {
        ...newest,
        mainTemplateId: mainTemplateId,
        name:
          riskAssessmentNameWithSubstance(organization, newest) + ' (' + numberWord(r.items.length) + ' vurderinger)',
      };
    });
  },
);

export function toRiskAssessmentViewModel(item: RiskAssessmentViewModel): RiskAssessmentViewModel {
  return Object.assign({}, item);
}
