import { html, TemplateResult } from 'lit';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/elements/d-section.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/editors/components/d-edit-classification.js';
import { customElement } from 'lit/decorators.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';

export interface DocumentViewEditItem {
  pages: string[];
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  content: string;
  validFromDate: string;
  validToDate: string;
}

export interface DocumentView extends AbstractEntityViewWithRevisions<DocumentViewEditItem>, UpdateSectionItem {
  type: 'documents';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  classificationText: string;
  content: string;
  validFromDate: string;
  validToDate: string;
  availablePages: SelectTagOption[];
  pages: string[];
  hasDraft: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-document-view')
export class DDocumentView extends EntityContent<DocumentView, DocumentViewEditItem> {
  static readonly styles = EntityContent.styles;

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: 'validFromDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'view-date',
        format: 'date-short',
        class: 'minWidth300',
      },
      {
        field: 'content',
        hideLabel: true,
        type: 'view-html',
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('documents', this.viewFields(), this.entityView)} `;
  }

  async initializeEditItem() {
    if (this.entityView.hasDraft) {
      this.editItem = await this.entityView.fetchDraft();
    } else {
      this.editItem = {
        accessControl: this.entityView.accessControl,
        classification: this.entityView.classification,
        content: this.entityView.content,
        validFromDate: this.entityView.validFromDate,
        validToDate: this.entityView.validToDate,
        name: this.entityView.name,
        pages: this.entityView.pages,
      };
    }
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        autofocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        class: 'fullWidth',
      },
      {
        field: 'validFromDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
      {
        field: 'validToDate',
        type: 'edit-date',
        class: 'minWidth300',
      },
    ];
  }

  renderEditItem(item: DocumentViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)} ${this.renderFieldSection('documents', this.editFields(), item)}
      <d-edit-html
        field="documents_content"
        .value=${item.content}
        .docsForLinking=${this.entityView.docsForLinking}
        .contentStickyTop=${this.contentStickyTop}
        @value-changed=${(e: CustomEvent<{ value: string }>) => {
          this.editItem = { ...item, content: e.detail.value };
        }}
      ></d-edit-html>
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-document-view': DDocumentView;
  }
}
