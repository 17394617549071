/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachmentViewModel } from './AttachmentViewModel';
import {
  AttachmentViewModelFromJSON,
  AttachmentViewModelFromJSONTyped,
  AttachmentViewModelToJSON,
} from './AttachmentViewModel';
import type { FunctionRotationViewModel } from './FunctionRotationViewModel';
import {
  FunctionRotationViewModelFromJSON,
  FunctionRotationViewModelFromJSONTyped,
  FunctionRotationViewModelToJSON,
} from './FunctionRotationViewModel';
import type { TaskViewModel } from './TaskViewModel';
import { TaskViewModelFromJSON, TaskViewModelFromJSONTyped, TaskViewModelToJSON } from './TaskViewModel';

/**
 *
 * @export
 * @interface FunctionViewModel
 */
export interface FunctionViewModel {
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  createdDateTime: string;
  /**
   * true hvis endret fra standard verdier. Også true for ting som ikke kommer fra mal.
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  unchangedTemplate: boolean;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  responsibility?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  type: FunctionViewModelTypeEnum;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  rotation: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  template?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  helpContent?: string;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  templateUpdated?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  templateDeclined?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  templateDeleted?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  templateDeletedMessage?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  contentLastModified?: string;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  contentLastModifiedBy?: string;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  contentLastModifiedWasCreate?: boolean;
  /**
   *
   * @type {number}
   * @memberof FunctionViewModel
   */
  mainTemplateId?: number;
  /**
   * The pageId of the template. Null if item is not attached to a template.
   * @type {number}
   * @memberof FunctionViewModel
   */
  templateId?: number;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  hasTemplateUpdate?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  hasTemplateDelete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  responsibilityLocked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  deleted: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof FunctionViewModel
   */
  employees: Array<string>;
  /**
   *
   * @type {Array<FunctionRotationViewModel>}
   * @memberof FunctionViewModel
   */
  rotations: Array<FunctionRotationViewModel>;
  /**
   *
   * @type {boolean}
   * @memberof FunctionViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof FunctionViewModel
   */
  draftName?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof FunctionViewModel
   */
  pages: Array<number>;
  /**
   *
   * @type {Array<TaskViewModel>}
   * @memberof FunctionViewModel
   */
  tasks: Array<TaskViewModel>;
  /**
   *
   * @type {Array<AttachmentViewModel>}
   * @memberof FunctionViewModel
   */
  attachments: Array<AttachmentViewModel>;
}

/**
 * @export
 */
export const FunctionViewModelTypeEnum = {
  Single: 'SINGLE',
  Rotated: 'ROTATED',
  Simultaneous: 'SIMULTANEOUS',
  Common: 'COMMON',
} as const;
export type FunctionViewModelTypeEnum = (typeof FunctionViewModelTypeEnum)[keyof typeof FunctionViewModelTypeEnum];

/**
 * Check if a given object implements the FunctionViewModel interface.
 */
export function instanceOfFunctionViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'createdDateTime' in value;
  isInstance = isInstance && 'unchangedTemplate' in value;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'type' in value;
  isInstance = isInstance && 'rotation' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'deleted' in value;
  isInstance = isInstance && 'employees' in value;
  isInstance = isInstance && 'rotations' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'pages' in value;
  isInstance = isInstance && 'tasks' in value;
  isInstance = isInstance && 'attachments' in value;

  return isInstance;
}

export function FunctionViewModelFromJSON(json: any): FunctionViewModel {
  return FunctionViewModelFromJSONTyped(json, false);
}

export function FunctionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdDateTime: json['createdDateTime'],
    unchangedTemplate: json['unchangedTemplate'],
    uuid: json['uuid'],
    responsibility: !exists(json, 'responsibility') ? undefined : json['responsibility'],
    type: json['type'],
    description: !exists(json, 'description') ? undefined : json['description'],
    rotation: json['rotation'],
    name: !exists(json, 'name') ? undefined : json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    category: !exists(json, 'category') ? undefined : json['category'],
    template: !exists(json, 'template') ? undefined : json['template'],
    helpContent: !exists(json, 'helpContent') ? undefined : json['helpContent'],
    isConfirmedEntity: json['isConfirmedEntity'],
    templateUpdated: !exists(json, 'templateUpdated') ? undefined : json['templateUpdated'],
    templateDeclined: !exists(json, 'templateDeclined') ? undefined : json['templateDeclined'],
    templateDeleted: !exists(json, 'templateDeleted') ? undefined : json['templateDeleted'],
    templateDeletedMessage: !exists(json, 'templateDeletedMessage') ? undefined : json['templateDeletedMessage'],
    contentLastModified: !exists(json, 'contentLastModified') ? undefined : json['contentLastModified'],
    contentLastModifiedBy: !exists(json, 'contentLastModifiedBy') ? undefined : json['contentLastModifiedBy'],
    contentLastModifiedWasCreate: !exists(json, 'contentLastModifiedWasCreate')
      ? undefined
      : json['contentLastModifiedWasCreate'],
    mainTemplateId: !exists(json, 'mainTemplateId') ? undefined : json['mainTemplateId'],
    templateId: !exists(json, 'templateId') ? undefined : json['templateId'],
    hasTemplateUpdate: !exists(json, 'hasTemplateUpdate') ? undefined : json['hasTemplateUpdate'],
    hasTemplateDelete: !exists(json, 'hasTemplateDelete') ? undefined : json['hasTemplateDelete'],
    responsibilityLocked: !exists(json, 'responsibilityLocked') ? undefined : json['responsibilityLocked'],
    deleted: json['deleted'],
    employees: json['employees'],
    rotations: (json['rotations'] as Array<any>).map(FunctionRotationViewModelFromJSON),
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    pages: json['pages'],
    tasks: (json['tasks'] as Array<any>).map(TaskViewModelFromJSON),
    attachments: (json['attachments'] as Array<any>).map(AttachmentViewModelFromJSON),
  };
}

export function FunctionViewModelToJSON(value?: FunctionViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdDateTime: value.createdDateTime,
    unchangedTemplate: value.unchangedTemplate,
    uuid: value.uuid,
    responsibility: value.responsibility,
    type: value.type,
    description: value.description,
    rotation: value.rotation,
    name: value.name,
    status: value.status,
    category: value.category,
    template: value.template,
    helpContent: value.helpContent,
    isConfirmedEntity: value.isConfirmedEntity,
    templateUpdated: value.templateUpdated,
    templateDeclined: value.templateDeclined,
    templateDeleted: value.templateDeleted,
    templateDeletedMessage: value.templateDeletedMessage,
    contentLastModified: value.contentLastModified,
    contentLastModifiedBy: value.contentLastModifiedBy,
    contentLastModifiedWasCreate: value.contentLastModifiedWasCreate,
    mainTemplateId: value.mainTemplateId,
    templateId: value.templateId,
    hasTemplateUpdate: value.hasTemplateUpdate,
    hasTemplateDelete: value.hasTemplateDelete,
    responsibilityLocked: value.responsibilityLocked,
    deleted: value.deleted,
    employees: value.employees,
    rotations: (value.rotations as Array<any>).map(FunctionRotationViewModelToJSON),
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    pages: value.pages,
    tasks: (value.tasks as Array<any>).map(TaskViewModelToJSON),
    attachments: (value.attachments as Array<any>).map(AttachmentViewModelToJSON),
  };
}
