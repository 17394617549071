import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import type { StartTask } from 'src/layout/parts/d-starttask-field.js';
import { ResponsiveElement } from '../../library/elements/responsive-container.js';
import type { ProfileUser } from './d-organization-profile.js';

import './d-organization-search.js';
import type { SearchResult } from './d-organization-search.js';
import './d-organization-settings.js';
import type { SettingsItem } from './d-organization-settings.js';
import './d-starttask-field.js';
import type { FeatureStates } from 'src/store/selectors/features';
import type { UiSettings } from 'src/store/selectors/ui';
import { EventOccurrenceViewModel } from 'src/store/api';

export type HeaderView = HeaderSearchView | HeaderSettingsView;

export interface HeaderSettingsView {
  type: 'settings';
  user: ProfileUser;
  isEmployee: boolean;
  alerts: string;
  receiveReminders: string;
  items: SettingsItem[];
}

export interface HeaderSearchView {
  type: 'search';
  opened: true;
  query: string;
  results: SearchResult[];
}

/**
 * Meny som vises i toppen for en gitt organisasjon. Tilpasses skjermbredden.
 *
 *
 *
 */
@customElement('d-organization-header')
export class DOrganizationHeader extends ResponsiveElement {
  static readonly styles = css`
    :host {
      display: block;
      container-name: host;
      container-type: inline-size;
      padding-bottom: 12px;
    }

    .specialAlertHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #edff6f;
      padding: 16px;
    }

    .specialAlertHeader strong {
      font-weight: 500;
    }

    .specialAlertHeader .closer {
      margin-left: 10px;
    }

    @media only screen and (max-width: 600px) {
      :host {
        padding-bottom: 0;
      }
    }

    .maxWidth600 :host {
      padding-bottom: 0;
    }

    #top-menu {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
      line-height: 120%;
    }

    #top-menu > div {
      color: white;
      box-sizing: border-box;
      font-size: 14px;
      opacity: 0.7;
      padding: 14px 6px 0 34px;
      white-space: nowrap;
      overflow: hidden;
      height: 100%;
      flex: none;
      cursor: pointer;
      margin-left: 1px;
      max-width: 170px;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s;
    }

    #top-menu:not(.logout-expanded) > div:hover,
    #top-menu > div[selected] {
      opacity: 1;
      transition: all 0s;
    }

    #top-menu .search {
      background: url(/images/topmenu/search.svg) 8px 12px no-repeat;
    }

    #top-menu .settings {
      background: url(/images/topmenu/settings.svg) 8px 12px no-repeat;
    }

    #top-menu .support {
      background: url(/images/topmenu/support.svg) 0 12px no-repeat;
      padding-left: 22px;
    }

    #top-menu .tutorial {
      background: url(/images/topmenu/tutorial.svg) 4px 12px no-repeat;
      padding-left: 30px;
    }

    #top-menu .user {
      background: url(/images/topmenu/user.svg) 8px 12px no-repeat;
      max-width: none;
      padding-top: 0;
      padding-right: 20px;
      flex-shrink: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: baseline;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s,
        background 1s;
    }

    #top-menu:not(.logout-expanded) .user {
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s;
    }

    #top-menu > div > div:last-child {
      max-width: 0;
      opacity: 0;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s,
        visibility 0s 0.5s;
    }

    :host(.width845) #top-menu > div > div:last-child {
      max-width: 200px;
      opacity: 1;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s,
        visibility 0s;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    #top-menu .user .user-options {
      display: flex;
      box-sizing: border-box;
      height: var(--applicationHeaderHeight);
    }

    #top-menu.logout-expanded > div:not(.user) {
      padding-left: 0;
      padding-right: 0;
      max-width: 0;
      opacity: 0;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s;
    }

    #top-menu.logout-expanded .user {
      background-image: url(/images/topmenu/logout.svg);
      opacity: 1;
      cursor: default;
      transition:
        max-width 0.5s,
        padding 0.5s,
        opacity 0.5s,
        background 1s;
    }

    #top-menu .user .user-options {
      max-width: 0;
      overflow: hidden;
      opacity: 0;
      cursor: pointer;
      transition: all 0.5s;
    }

    #top-menu.logout-expanded .user .user-options {
      max-width: 150px;
      opacity: 1;
      cursor: pointer;
      transition: all 0.5s;
    }

    #top-menu .user .user-options > div {
      box-sizing: border-box;
      opacity: 0.7;
      padding-top: 14px;
      padding-right: 10px;
      height: var(--applicationHeaderHeight);
    }

    #top-menu.logout-expanded .user .user-options > div:hover {
      opacity: 1;
      transition: all 0s;
    }

    .offlineHeader {
      padding: 10px;
      background-color: var(--alertColor);
      color: white;
      text-align: center;
    }

    .applicationHeader {
      display: flex;
      justify-content: space-between;
      position: relative;
      color: white;
      height: var(--applicationHeaderHeight);
      box-sizing: border-box;
    }

    .logo {
      flex: none;
      width: 97px;
      height: 25px;
      padding: 10px 20px;
      margin-bottom: -5px;
      background: url(/images/trinnvis-logo.svg) 17px 10px no-repeat;
      background-size: 97px 25px;
      text-indent: -10000px;
      font-weight: 500;
      font-size: 17px;
      line-height: 160%;
      color: var(--themeColorLighterTwo);
    }

    @media (hover: hover) {
      .logo:hover {
        color: white;
      }
    }

    :host([menu-covered]) .logo {
      background: none;
      text-indent: 0;
      cursor: pointer;
    }

    d-starttask-field {
      margin: 4px 0 0 0;
      max-width: 760px;
      overflow: hidden;
    }

    @container host (width > 600px) {
      d-starttask-field {
        margin: 4px 20px 0 20px;
        border-radius: 12px;
      }
    }

    .metaContentWrapper {
      margin: 0 20px 20px 20px;
      position: relative;
      box-sizing: border-box;
      background-color: hsla(196, 100%, 81%, 0.45);
      border-radius: 12px;
      transition: all 0.5s;
      max-width: var(--metaContentMaxWidth);
    }
  `;
  @property({ type: Object })
  organization: any = {};
  @property({ type: Number })
  organizationId = 0;
  /**
   * Display message showing that application is currently offline
   */
  @property({ type: Boolean })
  offlineHeader = false;
  @property({ type: Boolean })
  specialAlertHeader = true;
  @property({ type: Object })
  user!: { username: string };
  @property({ type: Boolean })
  showLogout = false;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  displayedUserName = '';
  @property({ type: String })
  selectedPage = '';
  @property({ type: Boolean })
  collapsibleMenuOpen = false;
  @property({ type: Boolean })
  startTaskExpanded = false;
  @property({ type: Boolean })
  noStartTaskExpandAnimation = false;
  @property({ type: Boolean })
  helpViewerOpen = false;
  @property({ type: Boolean })
  tutorialActive = false;
  @property({ type: Boolean })
  tutorialViewerOpen = false;
  @property({ type: Object })
  headerSearchView?: HeaderSearchView = undefined;
  @property({ type: Object })
  headerSettingsView?: HeaderSettingsView = undefined;
  @property({ type: Boolean, attribute: 'menu-covered', reflect: true })
  menuCovered = false;
  /**
   * Is this single user version.
   */
  @property({ type: Boolean })
  singleUserVersion = false;
  /**
   * The list of start tasks
   */
  @property({ type: Number })
  userTotal = 0;
  @property({ type: Number })
  userExecuted = 0;
  @property({ type: Object })
  startTask?: StartTask;
  @property({ type: Object })
  startTaskEvent: EventOccurrenceViewModel | undefined = undefined;
  @property({ type: String })
  currentEmployeeUuid = '';
  @property({ type: String })
  selectedSettings = 'profile';
  @property({ type: Boolean })
  searchOpen = false;
  @property({ type: Boolean })
  settingsOpen = false;
  @property({ type: Object })
  featureStates!: FeatureStates;
  @property({ type: Object })
  uiSettings: UiSettings = {
    isSimplifiedUi: false,
  };
  private showLogoutTimeout;

  private get settingsMenuItems() {
    if (this.headerSettingsView !== undefined) {
      return this.headerSettingsView.items;
    }
    return [];
  }

  _topMenuClasses(showLogout) {
    if (showLogout) {
      return 'logout-expanded';
    }
    return '';
  }

  onResize() {
    const h = this.clientHeight;
    this.dispatchEvent(
      new CustomEvent<{ height: number }>('header-height-changed', {
        bubbles: true,
        composed: true,
        detail: { height: h },
      }),
    );
  }

  _uncoverPageMenu() {
    if (this.menuCovered) {
      this.dispatchEvent(new CustomEvent('uncover-menu', { bubbles: true, composed: true }));
    }
  }

  _orgSelection() {
    this.dispatchEvent(new CustomEvent('org-selection', { bubbles: true, composed: true }));
  }

  _logout() {
    if (this.showLogout) {
      this.dispatchEvent(new CustomEvent('request-logout', { bubbles: true, composed: true }));
    }
  }

  _closeSpecialAlertHeader() {
    this.specialAlertHeader = false;
  }

  render() {
    return html`
      ${this.offlineHeader
        ? html` <div id="temporaryHeaders">
            <div class="offlineHeader">Kobler til server …</div>
          </div>`
        : nothing}
      <div class="applicationHeader">
        <span class="logo" @click=${this._uncoverPageMenu}>Hovedmeny</span>
        <div id="top-menu" class="${this._topMenuClasses(this.showLogout)}">
          <div class="search" ?selected=${this.searchOpen} @click=${this.toggleSearch}>
            <div>Søk</div>
          </div>
          <div class="settings" ?selected=${this.settingsOpen} @click=${this.toggleSettings}>
            <div>Innstillinger</div>
          </div>
          <div class="support" ?selected=${this.helpViewerOpen} @click=${this.fireToggleHelp}>
            <div>Brukerstøtte</div>
          </div>
          ${this.tutorialActive || this.tutorialViewerOpen
            ? html`
                <div class="tutorial" ?selected=${this.tutorialViewerOpen} @click=${this.fireToggleTutorial}>
                  <div>Kurs og veiledere</div>
                </div>
              `
            : nothing}
          <div class="user" @click=${this.toggleShowLogout}>
            <div class="user-options">
              <div @click=${this._logout} class="logout">Logg ut</div>
              <div class="cancel">Avbryt</div>
            </div>
            <div>${this.displayedUserName}</div>
          </div>
        </div>
      </div>
      ${this.headerSearchView !== undefined
        ? html` <d-organization-search
            id="orgSearch"
            @start-task-selected=${this.toggleSearch}
            .query=${this.headerSearchView.query}
            .results=${this.headerSearchView.results}
            @close-search=${() => this.toggleSearch()}
            @search-query-changed=${() => this.onResize()}
            @search-updated=${() => this.onResize()}
            ?open=${this.searchOpen}
          >
          </d-organization-search>`
        : nothing}
      <d-organization-settings
        organization="${this.organization}"
        .organizationId=${this.organizationId}
        selected-page="${this.selectedPage}"
        .user=${this.user}
        .settingsItem=${this.computeSettingsItem()}
        .settingsMenuItems=${this.settingsMenuItems}
        write-access="${this.writeAccess}"
        .featureStates=${this.featureStates}
        @open-user-settings=${this.openUserSettings}
        @close-settings=${this.toggleSettings}
        ?open=${this.settingsOpen}
        .uiSettings=${this.uiSettings}
      >
      </d-organization-settings>
      ${!this.searchOpen && !this.settingsOpen && this.startTask !== undefined
        ? html` <d-starttask-field
            ?open=${this.startTaskExpanded}
            .singleUserVersion=${this.singleUserVersion}
            ?noExpandAnimation=${this.noStartTaskExpandAnimation}
            .userTotal=${this.userTotal}
            .userExecuted=${this.userExecuted}
            ?showNotification=${false}
            .startTask=${this.startTask}
            .startTaskEvent=${this.startTaskEvent}
            .currentEmployeeUuid=${this.currentEmployeeUuid}
          ></d-starttask-field>`
        : nothing}
    `;
  }

  toggleSearch() {
    this.searchOpen = !this.searchOpen;
    if (this.searchOpen) {
      this.settingsOpen = false;
    }
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('toggle-search', {
        bubbles: true,
        composed: true,
        detail: { value: this.searchOpen },
      }),
    );
  }

  protected willUpdate() {
    this.checkForShowUserSettings();
  }

  private setShowLogoutTimeout() {
    this.showLogoutTimeout = setTimeout(() => {
      this.showLogout = false;
    }, 4000);
  }

  private toggleShowLogout(e) {
    if (!e.target.classList.contains('logout')) {
      this.showLogout = !this.showLogout;
      if (this.showLogout) {
        this.setShowLogoutTimeout();
      } else {
        clearTimeout(this.showLogoutTimeout);
      }
    }
  }

  private toggleSettings() {
    this.settingsOpen = !this.settingsOpen;
    if (this.settingsOpen) {
      this.searchOpen = false;
    }
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('toggle-settings', {
        bubbles: true,
        composed: true,
        detail: { value: this.settingsOpen },
      }),
    );
  }

  private fireToggleHelp() {
    if (this.helpViewerOpen) {
      this.dispatchEvent(new CustomEvent<{ value: boolean }>('close-viewer', { bubbles: true, composed: true }));
      setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent<{ value: boolean }>('toggle-help', {
            bubbles: true,
            composed: true,
            detail: { value: false },
          }),
        );
      }, 500);
    } else {
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-help', {
          bubbles: true,
          composed: true,
          detail: { value: true },
        }),
      );
    }
  }

  private fireToggleTutorial() {
    if (this.tutorialViewerOpen) {
      this.dispatchEvent(new CustomEvent<{ value: boolean }>('close-viewer', { bubbles: true, composed: true }));
      setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent<{ value: boolean }>('toggle-tutorial', {
            bubbles: true,
            composed: true,
            detail: { value: false },
          }),
        );
      }, 500);
    } else {
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-tutorial', {
          bubbles: true,
          composed: true,
          detail: { value: true },
        }),
      );
    }
  }

  private openUserSettings(e: CustomEvent<{ settingsType: string }>) {
    this.selectedSettings = e.detail.settingsType;
  }

  private computeSettingsItem(): SettingsItem {
    if (this.headerSettingsView === undefined) {
      throw new Error('headerSettingsView is not defined');
    }
    const settingsItem = this.headerSettingsView.items.find((item) => item.type === this.selectedSettings);
    if (settingsItem === undefined) {
      this.selectedSettings = 'profile';
      return (
        this.headerSettingsView.items.find((item) => item.type === this.selectedSettings) ??
        this.headerSettingsView.items[0]
      );
    }
    return settingsItem;
  }

  private checkForShowUserSettings() {
    const query = location.search.slice(1);
    const params = new URLSearchParams(query);
    if (params.has('showUserSettings')) {
      this.selectedSettings = 'profile';
      if (!this.settingsOpen) {
        this.toggleSettings();
      }
      history.replaceState({}, '', location.protocol + '//' + location.host + location.pathname);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-organization-header': DOrganizationHeader;
  }
}
