import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, query, queryAll } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import '../elements/d-wrap';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../fields/d-expansion';
import { htmlContentStyles } from 'src/library/html-content-styles.js';
import { debounce } from 'lodash';
import { handleNavigation } from 'src/redux-behavior.js';
import { isEmptyHtmlContent } from 'src/utilities/text';

/**
 *
 *
 */
@customElement('d-view-html')
export class DViewHtml extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      d-label {
        margin-bottom: 8px;
      }

      .actions {
        margin-right: -2px;
      }

      d-action {
        margin: -6px 0;
      }

      :host * {
        -webkit-user-select: text;
        -ms-user-select: text;
        -moz-user-select: text;
        user-select: text;
      }

      #content {
        margin-top: 12px;
      }

      .templateDeleted .html {
        font-family: var(--mainSans);
        font-size: 15px;
        line-height: 150%;
      }

      body:not(.touch) d-event-view .html:not(.editable) ul.checklist > li:hover {
        cursor: pointer;
      }

      body:not(.touch) d-event-view .html:not(.editable) ul.checklist > li:hover:before {
        border-color: var(--borderColorDark);
      }
    `,
    htmlContentStyles,
  ];
  @property({ type: Boolean, attribute: 'prevent-checklist', reflect: true })
  opened = false;
  @property({ type: Boolean })
  expandable = false;
  @property({ type: Boolean })
  preventChecklist = false;
  @property({ type: Boolean, attribute: 'absolute-internal-links' })
  absoluteInternalLinks = false;
  @property({ type: Array })
  selectedCheckpoints: number[] = [];
  @property({ type: String })
  label = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  labelClasses = 'big';
  @property({ type: String })
  value = '';
  @property({ type: Array })
  pages = [];
  @property({ type: Boolean, reflect: true })
  big = false;
  @query('#content')
  private content!: HTMLElement;
  @queryAll("#content li[data-type='taskItem']")
  private _checkpointsEl?: HTMLElement[];
  private debouncedFireChecklistChanged = debounce(() => this.fireChecklistChanged(), 500);

  _updateChecks() {
    if (this._checkpointsEl) {
      Array.from(this._checkpointsEl).forEach((c, i) => {
        const oneBasedIndex = i + 1;
        const isChecked = this.selectedCheckpoints.includes(oneBasedIndex);
        if (isChecked) {
          c.setAttribute('checked', 'checked');
        } else {
          c.removeAttribute('checked');
        }
      });
    }
  }

  render() {
    const expandingActionLabel = this.opened ? 'Skjul' : 'Vis';
    const expandingAction = this.expandable
      ? html` <d-action @click=${() => (this.opened = !this.opened)}>${expandingActionLabel}</d-action> `
      : nothing;
    const optionalLabel =
      this.label !== '' || this.field !== ''
        ? html`
            <d-wrap split-reverse>
              <div class="actions">${expandingAction}</div>
              <d-label
                classes="${this.labelClasses}"
                .label=${this.label}
                .field=${this.field}
                .sublabel=${this.sublabel}
                ?big=${this.big}
              ></d-label>
            </d-wrap>
          `
        : nothing;
    const optionalHtmlContent = isEmptyHtmlContent(this.value)
      ? nothing
      : html` <div id="content" class="html" @click=${(e) => this.clickHtml(e)}>${unsafeHTML(this.value)}</div> `;
    return html`
      ${optionalLabel}
      <d-expansion .opened=${this.opened || !this.expandable}> ${optionalHtmlContent} </d-expansion>
    `;
  }

  async clickHtml(event: PointerEvent) {
    const target = event.target as HTMLElement;
    const a = target.closest('a');
    if (a) {
      if (a.hasAttribute('data-internal-link-id')) {
        event.stopPropagation();
        event.preventDefault();
        const href = a.getAttribute('href');
        if (href) {
          let location = href;
          if (!this.absoluteInternalLinks) {
            const hrefArray = href.split('/');
            const path = hrefArray[hrefArray.length - 2] + '/' + hrefArray[hrefArray.length - 1];
            location = window.location + '/' + path;
          }
          window.history.pushState({}, '', location);
          await handleNavigation(window.location);
        }
      }
    } else if (!this.preventChecklist && target !== null) {
      const li = target.closest('li');
      if (li !== null && 'type' in li.dataset && li.dataset.type === 'taskItem') {
        event.stopPropagation();
        event.preventDefault();
        const c = li.dataset.checked === 'true';
        li.dataset.checked = c ? 'false' : 'true';
        const data = this.content.innerHTML;
        this.value = data.replace(/<!--.*?-->/g, '');
        this.debouncedFireChecklistChanged();
      }
    }
  }

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('value')) {
      this._updateChecks();
      this.opened = false;
    }
  }

  private fireChecklistChanged() {
    this.dispatchEvent(
      new CustomEvent<{ content: string }>('checklist-changed', {
        composed: true,
        bubbles: true,
        detail: {
          content: this.value,
        },
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-html': DViewHtml;
  }
}
