import { createSelector } from '@reduxjs/toolkit';
import {
  assetsNotDeleted,
  constitutionalDocumentsNotDeleted,
  contactsNotDeleted,
  contractsNotDeletedWithParties,
  currentUserUuid,
  documentsNotDeleted,
  employeesNotDeleted,
  eventOccurrencesNotDeleted,
  functionsNotDeleted,
  getOrganization,
  getPages,
  guidelinesNotDeleted,
  issuesNotDeleted,
  meetingOccurrencesNotDeleted,
  pageIds,
  partnersNotDeleted,
  reportsNotDeleted,
  riskAssessmentsReducedByName,
  substancesNotDeleted,
  tasksNotDeleted,
} from 'src/store';
import { sortBy } from 'lodash';
import { riskLevelFromRisk } from 'src/models/risk';
import { dateTimeDescription } from 'src/store/utilities';

export interface DocForLinking {
  templateId: number | undefined;
  uuid: string;
  docType: string;
  classes?: string;
  classified: boolean;
  noAccess: boolean;
  href: string;
  name: string;
  dateTimeForOrdering: string;
}

function getDocForLinking(
  item: any,
  type: string,
  organizationId: number,
  pageId: number,
  userId: string,
  parentHref: string,
): DocForLinking {
  if (item.pages?.length) {
    pageId = item.pages[0];
    if (pageId === 279) {
      pageId = pageIds.riskAssessments;
    }
  }
  let classes = '';
  if (type === 'riskAssessments') {
    const calculatedRisk = (item.consequence ?? 0) * (item.probability ?? 0);
    if (calculatedRisk) {
      classes = 'risk' + riskLevelFromRisk(calculatedRisk);
    }
  }
  let noAccess = false;
  let classified = false;
  let href = '/account/' + organizationId + '/' + pageId + '/' + parentHref + type + '/' + item.uuid;
  if (item.classification && item.classification !== 'NONE') {
    classified = true;
    if (!item.accessControl?.includes(userId + '')) {
      noAccess = true;
      href = 'restricted';
    }
  }
  return {
    templateId: item.mainTemplateId ?? undefined,
    uuid: item.uuid,
    docType: type,
    classes,
    classified,
    noAccess,
    href,
    name: item.name,
    dateTimeForOrdering: '',
  };
}

function getEventForLinking(
  item: any,
  type: string,
  organizationId: number,
  pageId: number,
  userId: string,
): DocForLinking {
  const doc = getDocForLinking(item, type, organizationId, pageId, userId, '');
  doc.name = item.name + ' ' + dateTimeDescription(item.date ?? '', item.time ?? '', item.durationMinutes);
  doc.dateTimeForOrdering = item.date + ' ' + (item.time && item.time !== 'NONE' ? item.time : '00:00');
  return doc;
}

export const docsForLinking = createSelector(
  getOrganization,
  currentUserUuid,
  getPages,
  assetsNotDeleted,
  constitutionalDocumentsNotDeleted,
  contractsNotDeletedWithParties,
  documentsNotDeleted,
  employeesNotDeleted,
  functionsNotDeleted,
  tasksNotDeleted,
  guidelinesNotDeleted,
  issuesNotDeleted,
  partnersNotDeleted,
  contactsNotDeleted,
  reportsNotDeleted,
  riskAssessmentsReducedByName,
  substancesNotDeleted,
  eventOccurrencesNotDeleted,
  meetingOccurrencesNotDeleted,
  (
    organization,
    userId,
    pages,
    assets,
    constitutionalDocuments,
    contracts,
    documents,
    employees,
    functions,
    tasks,
    guidelines,
    issues,
    partners,
    contacts,
    reports,
    riskAssessments,
    substances,
    eventOccurrences,
    meetingOccurrences,
  ) => {
    const startTime = Date.now();
    try {
      const result: DocForLinking[] = [];
      if (organization) {
        const organizationId = organization.organizationId;
        pages.forEach((page) => {
          result.push({
            templateId: page.pageId,
            uuid: page.pageId + '',
            docType: 'pages',
            classified: false,
            noAccess: false,
            href: '/account/' + organization.organizationId + '/' + page.pageId,
            name: page.name,
            dateTimeForOrdering: '',
          });
        });
        assets.forEach((item) => {
          const doc = getDocForLinking(item, 'assets', organizationId, pageIds.assets, userId, '');
          doc.name = item.name + ' ' + item.number;
          result.push(doc);
        });
        constitutionalDocuments.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'constitutionalDocuments', organizationId, pageIds.company, userId, ''));
          }
        });
        contracts.forEach((item) => {
          if (item.name) {
            const doc = getDocForLinking(item, 'contracts', organizationId, pageIds.management, userId, '');
            doc.name = item.nameWithParties;
            result.push(doc);
          }
        });
        documents.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'documents', organizationId, pageIds.management, userId, ''));
          }
        });
        employees.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'employees', organizationId, pageIds.employees, userId, ''));
          }
        });
        functions.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'functions', organizationId, pageIds.management, userId, ''));
          }
        });
        tasks.forEach((item) => {
          if (item.name && !item.locked) {
            const parent = organization.functionsById[item.functionUuid];
            const parentHref = 'functions/' + parent.uuid + '/';
            const pageId = parent.pages[0];
            result.push(getDocForLinking(item, 'tasks', organizationId, pageId, userId, parentHref));
          }
        });
        guidelines.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'guidelines', organizationId, pageIds.management, userId, ''));
          }
        });
        issues.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'issues', organizationId, pageIds.issues, userId, ''));
          }
        });
        partners.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'partners', organizationId, pageIds.partners, userId, ''));
          }
        });
        contacts.forEach((item) => {
          if (item.firstName || item.lastName) {
            const parent = organization.partnersById[item.partnerUuid];
            const parentHref = 'partners/' + parent.uuid + '/';
            const doc = getDocForLinking(item, 'contacts', organizationId, pageIds.partners, userId, parentHref);
            doc.name = item.firstName + ' ' + item.lastName + ' (' + parent.name + ')';
            result.push(doc);
          }
        });
        reports.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'reports', organizationId, pageIds.management, userId, ''));
          }
        });
        riskAssessments.forEach((item) => {
          if (item.name) {
            if (item.name.includes('(')) {
              console.log(
                "getDocForLinking(item, 'riskAssessments', organizationId, pageIds.riskAssessments, userId, '')",
                getDocForLinking(item, 'riskAssessments', organizationId, pageIds.riskAssessments, userId, ''),
              );
            }
            result.push(getDocForLinking(item, 'riskAssessments', organizationId, pageIds.riskAssessments, userId, ''));
          }
        });
        substances.forEach((item) => {
          if (item.name) {
            result.push(getDocForLinking(item, 'substances', organizationId, pageIds.substances, userId, ''));
          }
        });
        eventOccurrences.forEach((item) => {
          if (item.name) {
            result.push(getEventForLinking(item, 'eventOccurrences', organizationId, pageIds.events, userId));
          }
        });
        meetingOccurrences.forEach((item) => {
          if (item.name) {
            result.push(getEventForLinking(item, 'meetingOccurrences', organizationId, pageIds.events, userId));
          }
        });
      }
      return sortBy(result, [(e) => e.dateTimeForOrdering, (e) => e.name]);
    } finally {
      const timeElapsed = Date.now() - startTime;
      console.log('Time elapsed docsForLinking', timeElapsed);
    }
  },
);
