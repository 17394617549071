import type { OrganizationState, State, Tutorial } from 'src/store/types.js';
import { getOrganization } from 'src/store';
import type { IconEnum } from 'src/pages/d-page-header';
import { TutorialsPageViewModel } from 'src/pages/tutorials-page/d-tutorials-page-content';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import tutorialsList from 'src/store/data/tutorials.json';
import { AbstractPageView } from 'src/pages/abstract-page-view';

function secondaryLabelForTutorial(tutorial: Tutorial) {
  const totalCountAssigned = tutorial.participants.filter((p) => p.assigned).length;
  const totalCountAssignedAndCompleted = tutorial.participants.filter((p) => p.assigned && p.completed).length;
  return totalCountAssigned === 0
    ? ''
    : '' + totalCountAssignedAndCompleted + ' av ' + totalCountAssigned + ' har gjennomført';
}

function tutorialsAsListItems(organization: OrganizationState | undefined, hrefPrefix: string): ListSectionItemInput[] {
  if (organization) {
    return tutorialsList.map((t) => {
      const tutorial: Tutorial = organization['tutorial_' + t.id];
      return {
        label: t.title,
        secondaryLabel: t.guide ? '' : secondaryLabelForTutorial(tutorial),
        accessible: true,
        updateStatus: 'none',
        href: hrefPrefix + 'tutorials/' + t.id,
      };
    });
  }
  return [];
}

export function tutorialsPageView(
  doctype: IconEnum,
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): TutorialsPageViewModel {
  return {
    ...viewModel,
    type: 'tutorials-page',
    icon: doctype,
    href: hrefPrefix,
    availableActivityCodes: [],
    pdfHref: '',
    list: tutorialsAsListItems(getOrganization(state), hrefPrefix),
  };
}
