import { css, html, LitElement, nothing } from 'lit';
import './d-tooltip.js';
import { customElement, property } from 'lit/decorators.js';
import { commonFieldsByCode } from 'src/store/fields.js';

/**
 *
 * A label. Used as prompt for fields and editors.
 *
 *
 * STATUS OK
 */
@customElement('d-label')
export class DLabel extends LitElement {
  static readonly styles = css`
    :host {
      display: inline-block;
      position: relative;
      color: hsla(1, 0%, 0%, 0.8);
      line-height: var(--lineHeightDefault);
    }
    :host([checklist]) > span {
      display: flex;
    }
    .label {
      display: inline-block;
      font-weight: 600;
      white-space: nowrap;
    }
    :host([list-header]) .label {
      font-weight: 600;
      color: var(--listHeaderColor);
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    :host([allow-wrap]) .label {
      white-space: normal;
      padding-bottom: 0;
    }
    :host([light]) .label {
      font-weight: 400;
    }
    :host([semibold]) .label {
      font-weight: 500;
    }
    :host([small]) .label {
      font-size: 14px;
    }
    :host([big]) .label {
      font-size: 17px;
    }
    :host([big][list-header]) .label {
      font-size: 20px;
      letter-spacing: normal;
      text-transform: none;
    }
    :host([verybig]) .label {
      font-size: 22px;
    }
    .sublabel {
      font-weight: 400;
      font-size: 15px;
    }

    :host([small-sublabel]) .sublabel {
      font-size: 85%;
    }

    .sublabel,
    :host([list-header]) .sublabel {
      font-weight: 400;
      font-size: 15px;
      text-transform: none;
      letter-spacing: normal;
    }
    .bullet {
      display: list-item;
      margin: 3px 0 3px var(--listPaddingLeft);
    }
    .bullet .label {
      display: inline;
      font-weight: normal;
    }
    .template-updated .label:before {
      display: inline-block;
      content: '';
      margin-right: 8px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: var(--themeColorDarkerOne);
    }
    .alert,
    .alert .label {
      color: hsl(29, 100%, 45%);
    }
    :host([option]) .label {
      font-weight: normal;
    }
    :host([checklist]) .label {
      flex-grow: 1;
    }
    .option.disabled .label {
      cursor: default;
    }
    .checkpoint .label {
      display: block;
      font-weight: normal;
      padding: 4px 0 4px 26px;
      background: url('/images/x-fat-orange.svg') 0 2px no-repeat;
      background-size: 24px 24px;
    }
    .checkpoint .label:before {
      content: 'Ikke oppfylt: ';
    }
    .checkpoint.checked .label {
      background: url('/images/check-mini-blue.svg') 0 2px no-repeat;
      background-size: 24px 24px;
    }
    .checkpoint.checked .label:before {
      content: '';
    }
    .check-blue .label {
      background: url('/images/check-mini-blue.svg') 0 -1px no-repeat;
      background-size: 24px 24px;
      padding-left: 30px;
    }
    :host([verybig]) d-tooltip {
      position: relative;
      top: -4px;
    }
    d-action {
      margin: -6px -4px;
    }
    d-action[mini] {
      margin: -4px -4px;
    }
  `;
  @property({ type: Boolean, attribute: 'list-header', reflect: true })
  listHeader = false;
  @property({ type: String })
  field?: string;
  @property({ type: String })
  label?: string;
  @property({ type: String })
  subfield?: string;
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  classes = '';
  @property({ type: Boolean, attribute: 'allow-wrap', reflect: true })
  allowWrap = false;
  @property({ type: Boolean, reflect: true })
  light = false;
  @property({ type: Boolean, reflect: true })
  semibold = false;
  @property({ type: Boolean, reflect: true })
  small = false;
  @property({ type: Boolean, reflect: true })
  big = false;
  @property({ type: Boolean, reflect: true })
  verybig = false;
  @property({ type: Boolean, attribute: 'hide-tooltip', reflect: true })
  hideTooltip = false;
  @property({ type: String })
  tooltip = '';
  @property({ type: Boolean, attribute: 'theme-page' })
  themePage = false;
  @property({ type: Boolean, attribute: 'outskirts' })
  outskirts = false;
  @property({ type: String })
  labelAction = '';
  @property({ type: Boolean, attribute: 'label-action-mini' })
  labelActionMini = false;
  @property({ type: Boolean, attribute: 'label-action-plain' })
  labelActionPlain = false;
  @property({ type: Boolean, reflect: true })
  checklist = false;
  private fieldsByCode = commonFieldsByCode();

  get computedTooltip() {
    if (!this.hideTooltip) {
      if (!this.tooltip && this.field && this.fieldsByCode && this.fieldsByCode[this.field]) {
        return this.fieldsByCode[this.field].tooltip;
      } else {
        return this.tooltip ?? '';
      }
    }
    return '';
  }

  render() {
    return html`
      <span class="${this.classes}">
        ${this.noLabel(this.label, this.field)
          ? nothing
          : html`<span class="label" @click=${this.onLabelClick}>
              ${this.computeLabel(this.label, this.fieldsByCode, this.field)}
              <span class="sublabel"> ${this.computeLabel(this.sublabel, this.fieldsByCode, this.subfield)} </span>
            </span>`}
        ${this.hasTooltip(this.computedTooltip)
          ? html`<d-tooltip
              ?outskirts=${this.outskirts}
              ?theme-page=${this.themePage}
              .content=${this.computedTooltip}
            ></d-tooltip>`
          : nothing}
        ${this.labelAction
          ? html`
              <d-action
                ?plain="${this.labelActionPlain}"
                ?mini="${this.labelActionMini}"
                ?outskirts=${this.outskirts}
                @click=${() => this._labelAction()}
                >${this.labelAction}</d-action
              >
            `
          : nothing}
      </span>
    `;
  }

  _labelAction() {
    this.dispatchEvent(new CustomEvent('label-action', { bubbles: true, composed: true }));
  }

  private noLabel(label: string | undefined, field: string | undefined): boolean {
    return !label && !field;
  }

  private computeLabel(label, fields, field) {
    if (field && fields && fields[field]) {
      return fields[field].label;
    }
    if (label) {
      return label;
    }
    return '';
  }

  private hasTooltip(v: string) {
    return v !== '';
  }

  private onLabelClick() {
    this.dispatchEvent(new Event('label-click', { composed: true, bubbles: true }));
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-label': DLabel;
  }
}
