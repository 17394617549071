/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UpdateTopicCommand
 */
export interface UpdateTopicCommand {
  /**
   * The event occurrence uuid.
   * @type {string}
   * @memberof UpdateTopicCommand
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTopicCommand
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTopicCommand
   */
  description?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateTopicCommand
   */
  pages: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateTopicCommand
   */
  items?: Array<string>;
}

/**
 * Check if a given object implements the UpdateTopicCommand interface.
 */
export function instanceOfUpdateTopicCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'pages' in value;

  return isInstance;
}

export function UpdateTopicCommandFromJSON(json: any): UpdateTopicCommand {
  return UpdateTopicCommandFromJSONTyped(json, false);
}

export function UpdateTopicCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTopicCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    pages: json['pages'],
    items: !exists(json, 'items') ? undefined : json['items'],
  };
}

export function UpdateTopicCommandToJSON(value?: UpdateTopicCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    description: value.description,
    pages: value.pages,
    items: value.items,
  };
}
