/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CreateTopicCommand
 */
export interface CreateTopicCommand {
  /**
   * The uuid of the new event occurrence.
   * @type {string}
   * @memberof CreateTopicCommand
   */
  uuid: string;
  /**
   *
   * @type {Array<number>}
   * @memberof CreateTopicCommand
   */
  pages: Array<number>;
}

/**
 * Check if a given object implements the CreateTopicCommand interface.
 */
export function instanceOfCreateTopicCommand(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'pages' in value;

  return isInstance;
}

export function CreateTopicCommandFromJSON(json: any): CreateTopicCommand {
  return CreateTopicCommandFromJSONTyped(json, false);
}

export function CreateTopicCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTopicCommand {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    pages: json['pages'],
  };
}

export function CreateTopicCommandToJSON(value?: CreateTopicCommand | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    pages: value.pages,
  };
}
