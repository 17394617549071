import { orderBy, sortBy } from 'lodash';
import type {
  EmployeeStatsPeriod,
  EmployeeWithWorkHours,
  QuarterData,
  SicknessAbsenceStatsData,
  SicknessAbsenceStatsDataYear,
  SicknessAbsenceStatsNoGender,
  SimplePeriodData,
  StatisticsAggregate,
} from 'src/pages/staffing-page/d-sickness-absence-statistics.js';
import type {
  EndOfDayTime,
  LeavePeriod,
  LeavePeriodType,
  PeriodNotes,
  PlusTimePeriod,
  StaffingCalendarDataDay,
  StaffingCalendarDataEmployee,
  StaffingCalendarDataGroup,
} from 'src/pages/staffing-page/d-staffing-calendar-data.js';
import type { StaffingCalendar } from 'src/pages/staffing-page/d-staffing-calendar-table.js';
import type { EmployeeTimekeeping } from 'src/pages/staffing-page/d-timekeeping-review.js';
import type { StaffingPageViewModel } from 'src/pages/staffing-page/staffing-page-view-model.js';
import {
  currentEmployeeAsViewModel,
  EmployeeForStaffingCalendar,
  employeesShortNames,
  getEmployeesWithForAccessControl,
  getOrganization,
  HolidaysType,
  PeriodRowGroup,
  PeriodWithMinutes,
  staffingCalendarUserDisplaySelection,
  YearDay,
} from 'src/store';
import type { WorkSchedule, WorkScheduleDay } from 'src/store/api';
import { EmployeeViewModelGenderEnum, WorkScheduleRestPeriodEnum } from 'src/store/api';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import {
  createPeriodGroupsWithMinutes,
  currentUserHasStaffingCalendarAccess,
  defaultWorkSchedule,
  employeeGroups,
  employeesForStaffingCalendar,
  employeesWithTimekeepingPeriods,
  forDayIndex,
  getScheduleDayData,
  getYearDays,
  holidaysForStaffingCalendar,
  leavePeriodsForYear,
  plusTimePeriodsForYear,
  sharedVacations,
  staffingCalendarAccessList,
  staffingCalendarYear,
  timeInMinutes,
} from 'src/store/selectors';
import { StartOfDayTime } from 'src/pages/staffing-page/d-staffing-calendar-data.js';
import { AbstractPageView } from 'src/pages/abstract-page-view';

interface SuperSimplePeriod {
  start: number;
  end: number;
  id?: number;
}

/**
 * Takes a list of non-overlapping periods and subtracts the periods from the other list
 */
function subtractPeriods(mainPeriods: SuperSimplePeriod[], periodsToSubtract: SuperSimplePeriod[]) {
  function subtractPeriod(currentMainPeriods: SuperSimplePeriod[], sp: SuperSimplePeriod) {
    const newMainPeriods: SuperSimplePeriod[] = [];
    currentMainPeriods.forEach(function (mp) {
      if (sp.start < mp.end && sp.end > mp.start) {
        if (sp.start > mp.start) {
          newMainPeriods.push({
            start: mp.start,
            end: sp.start,
          });
        }
        if (sp.end < mp.end) {
          newMainPeriods.push({ start: sp.end, end: mp.end });
        }
      } else {
        newMainPeriods.push({ start: mp.start, end: mp.end });
      }
    });
    return newMainPeriods;
  }

  periodsToSubtract.forEach(function (sp) {
    mainPeriods = subtractPeriod(mainPeriods, sp);
  });
  return mainPeriods;
}

/**
 * Takes a list of periods, merges overlapping periods, returns a list of merged periods
 */
function mergePeriods(periods: SuperSimplePeriod[]) {
  const result: SuperSimplePeriod[] = [];
  const added: number[] = [];

  function addToPeriod(periodA: SuperSimplePeriod, allPeriods: SuperSimplePeriod[]) {
    const remainingPeriods = allPeriods.filter(function (period) {
      return period.id !== undefined && added.indexOf(period.id) === -1;
    });
    remainingPeriods.forEach(function (periodB) {
      if (periodA.id !== periodB.id && periodA.start <= periodB.end && periodA.end >= periodB.start) {
        if (periodB.id !== undefined) {
          added.push(periodB.id);
        }
        if (periodA.start > periodB.start) {
          periodA.start = periodB.start;
        }
        if (periodA.end < periodB.end) {
          periodA.end = periodB.end;
        }
      }
    });
    if (periodA.id !== undefined && added.indexOf(periodA.id) === -1) {
      added.push(periodA.id);
      return { start: periodA.start, end: periodA.end };
    }
  }

  periods
    .map(function (period, index) {
      period.id = index;
      return period;
    })
    .forEach(function (period, index) {
      period.id = index;
      const expandedPeriod = addToPeriod(period, periods);
      if (expandedPeriod) {
        result.push(expandedPeriod);
      }
    });
  return result;
}

function computeCalendarData(state: State): StaffingCalendar {
  const year = staffingCalendarYear(state);
  const holidays = holidaysForStaffingCalendar();
  const employees = employeeGroups(state);

  return {
    data: staffingCalendarData(year, holidays, employees),
  };
}

function staffingCalendarData(
  year: string,
  holidays: { [p: string]: string },
  groups: EmployeeForStaffingCalendar[][],
) {
  const yearStartDate = LocalDate.fromString(year + '-01-01');
  const yearEndDate = LocalDate.fromString(year + '-12-31');
  const result: StaffingCalendarDataGroup[] = [];
  groups.forEach(function (group) {
    const dataEmployeeGroup: StaffingCalendarDataGroup = { employees: [] };
    group.forEach(function (employee) {
      const periods = leavePeriodsForYear(employee.leavePeriods, yearStartDate, yearEndDate);
      const periodRowGroups = createPeriodGroupsWithMinutes(periods, yearStartDate);
      let plusTimePeriods: PeriodWithMinutes[] = [];
      if (employee.plusTimePeriods) {
        plusTimePeriods = plusTimePeriodsForYear(employee.plusTimePeriods, yearStartDate);
      }
      const yearDays = getYearDays(year, holidays);
      const days = dataDays(yearDays, employee, periodRowGroups, plusTimePeriods);
      const dataEmployee: StaffingCalendarDataEmployee = {
        uuid: employee.uuid,
        days: days,
      };
      dataEmployeeGroup.employees.push(dataEmployee);
    });
    result.push(dataEmployeeGroup);
  });
  return result;
}

function flattenPeriodRowGroups(rowGroups: PeriodRowGroup[]): PeriodWithMinutes[] {
  const periods: PeriodWithMinutes[] = [];
  rowGroups.forEach((group) => {
    group.rows.forEach((row) => {
      row.forEach((period) => {
        periods.push(period);
      });
    });
  });
  return periods;
}

function extractNotes(period: PeriodWithMinutes): PeriodNotes {
  let notes = period.notes ?? '';
  if (notes.length > 100) {
    notes = notes.substring(0, 100) + '…';
  }

  return {
    type: period.type as LeavePeriodType | 'plusTime',
    confirmed: period.confirmed,
    notes: notes,
  };
}

/**
 * Takes a list of days, an employee and data for leave periods, plus time and holidays
 * Returns a list of days for that employee, each with all data needed in graphic staffing calendar
 */
function dataDays(
  days: YearDay[],
  employee: EmployeeForStaffingCalendar,
  periodRowGroups: PeriodRowGroup[],
  plusTimePeriods: PeriodWithMinutes[],
) {
  const result: StaffingCalendarDataDay[] = [];
  days.forEach(function (day) {
    const dayDate = LocalDate.fromString(day.date);
    const workSchedule = getScheduleDayData(dayDate, employee);

    const workPeriods: { startMinutes: number; endMinutes: number }[] = [];

    if (workSchedule.workHours > 0 && workSchedule.start && workSchedule.end) {
      workPeriods.push({
        startMinutes: timeInMinutes(workSchedule.start),
        endMinutes: timeInMinutes(workSchedule.end),
      });
    }

    const periodGroupsForDay = periodRowGroups.filter(function (group) {
      return group.startMinutes < day.endMinutes && group.endMinutes > day.startMinutes;
    });
    const dataLeavePeriods: LeavePeriod[] = [];
    if (periodGroupsForDay.length) {
      periodGroupsForDay.forEach((periodGroupForDay) => {
        const groupRows = periodGroupForDay.rows;
        groupRows.forEach(function (row, index) {
          row.forEach(function (period) {
            if (period.startMinutes < day.endMinutes && period.endMinutes > day.startMinutes) {
              dataLeavePeriods.push(leavePeriod(period, day, index, groupRows.length));
            }
          });
        });
      });
    }
    const dataPlusTimePeriods: PlusTimePeriod[] = [];
    if (plusTimePeriods.length) {
      plusTimePeriods.forEach(function (period) {
        if (period.startMinutes < day.endMinutes && period.endMinutes > day.startMinutes) {
          const dataPlusTimePeriod = plusTimePeriod(period, day);
          dataPlusTimePeriods.push(dataPlusTimePeriod);
        }
      });
    }
    const leavePeriodNotes: PeriodNotes[] = flattenPeriodRowGroups(periodGroupsForDay)
      .filter((n) => n.notes !== '')
      .filter((period) => period.startMinutes >= day.startMinutes && period.startMinutes < day.endMinutes)
      .map(extractNotes);
    // OBS kun plusstid notater på første dag
    const plusTimePeriodNotes: PeriodNotes[] = plusTimePeriods
      .filter((n) => n.notes !== '')
      .filter((period) => period.startMinutes >= day.startMinutes && period.startMinutes < day.endMinutes)
      .map(extractNotes);

    const periodsWithNotes = plusTimePeriodNotes.concat(leavePeriodNotes);
    result.push({
      date: day.date,
      unitStart: day.unitStart,
      workPeriods: workPeriods,
      plusTimePeriods: dataPlusTimePeriods,
      leavePeriods: dataLeavePeriods,
      periodsWithNotes: periodsWithNotes.filter((n) => n.notes !== ''),
    });
  });
  return result;
}

function relativeMinutes(start: number, end: number, day: YearDay): { start: number; end: number } {
  let relStartMinutes = 0;
  let relEndMinutes = 1440;
  if (start > day.startMinutes) {
    relStartMinutes = start - day.startMinutes;
  }
  if (end < day.endMinutes) {
    relEndMinutes = end - day.startMinutes;
  }
  return {
    start: relStartMinutes,
    end: relEndMinutes,
  };
}

function leavePeriod(period: PeriodWithMinutes, day: YearDay, stackIndex: number, stackCount: number): LeavePeriod {
  const result: LeavePeriod = {
    startMinutes: relativeMinutes(period.startMinutes, period.endMinutes, day).start,
    endMinutes: relativeMinutes(period.startMinutes, period.endMinutes, day).end,
    stackCount: stackCount,
    stackIndex: stackIndex,
    type: period.type as LeavePeriodType,
    confirmed: period.confirmed,
  };

  if (period.subPeriods && period.subPeriods.length) {
    result.exceptions = period.subPeriods
      .filter(function (subPeriod) {
        return subPeriod.start === day.date;
      })
      .map((subPeriod) => {
        return {
          type: subPeriod.type as LeavePeriodType,
          startMinutes: relativeMinutes(subPeriod.startMinutes, subPeriod.endMinutes, day).start,
          endMinutes: relativeMinutes(subPeriod.startMinutes, subPeriod.endMinutes, day).end,
        };
      });
  }
  return result;
}

function plusTimePeriod(period: PeriodWithMinutes, day: YearDay): PlusTimePeriod {
  let relStartMinutes = 0;
  let relEndMinutes = 1440;
  if (period.startMinutes > day.startMinutes) {
    relStartMinutes = period.startMinutes - day.startMinutes;
  }
  if (period.endMinutes < day.endMinutes) {
    relEndMinutes = period.endMinutes - day.startMinutes;
  }
  return {
    type: 'plusTime',
    confirmed: period.confirmed,
    startMinutes: relStartMinutes,
    endMinutes: relEndMinutes,
  };
}

function getSchedulesForYear(schedules, year): WorkSchedule[] {
  const result: WorkSchedule[] = [];
  const yearStart = LocalDate.fromString(year + '-01-01');
  let prevSchedule: WorkSchedule | undefined = undefined;
  schedules.forEach((schedule) => {
    const scheduleStart = LocalDate.fromString(schedule.start);
    const scheduleStartYear = scheduleStart.year();
    if (scheduleStart.isAfter(yearStart) && prevSchedule) {
      result.push(prevSchedule);
    }
    if (scheduleStartYear + '' === year || (schedules.length === 1 && scheduleStart.isBefore(yearStart))) {
      result.push(schedule);
      prevSchedule = undefined;
    } else if (scheduleStart.isBefore(yearStart)) {
      prevSchedule = schedule;
    }
  });
  return result;
}

function getMaxWorkdayRangeInSchedule(schedule): { start: number; end: number } {
  let start = 1440;
  let end = 0;
  const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  if (schedule.workHoursDefinedPerDay) {
    schedule.workWeeks.forEach((week) => {
      weekdays.forEach((weekday) => {
        if (week[weekday].workDay) {
          if (timeInMinutes(week[weekday].start) < start) {
            start = timeInMinutes(week[weekday].start);
          }
          if (timeInMinutes(week[weekday].end) > end) {
            end = timeInMinutes(week[weekday].end);
          }
        }
      });
    });
  } else {
    if (schedule.workDayStartTime && timeInMinutes(schedule.workDayStartTime) < start) {
      start = timeInMinutes(schedule.workDayStartTime);
    }
    if (schedule.workDayEndTime && timeInMinutes(schedule.workDayEndTime) > end) {
      end = timeInMinutes(schedule.workDayEndTime);
    }
  }
  return { start, end };
}

function roundDownBihourly(minutes) {
  return Math.floor(minutes / 120) * 2;
}

function roundUpBihourly(minutes) {
  return Math.ceil(minutes / 120) * 2;
}

function getCalendarDayRange(employees, userDisplaySelection, year): { start: StartOfDayTime; end: EndOfDayTime } {
  let start: number = 480;
  let end: number = 960;
  const schedules: WorkSchedule[] = [];
  employees.forEach((employee) => {
    const employeeSchedules = sortBy(employee.workSchedules, [(item) => item.start]);
    const employeeSchedulesForYear = getSchedulesForYear(employeeSchedules, year);
    employeeSchedulesForYear.forEach((schedule) => {
      schedules.push(schedule);
    });
    employee.workScheduleExceptions.forEach((exception) => {
      if (exception.date.slice(0, 4) === year && timeInMinutes(exception.start) < timeInMinutes(exception.end)) {
        if (timeInMinutes(exception.start) < start) {
          start = timeInMinutes(exception.start);
        }
        if (timeInMinutes(exception.end) > end) {
          end = timeInMinutes(exception.end);
        }
      }
    });
  });
  schedules.forEach((schedule) => {
    const maxWorkday = getMaxWorkdayRangeInSchedule(schedule);
    if (maxWorkday.start < start) {
      start = maxWorkday.start;
    }
    if (maxWorkday.end > end) {
      end = maxWorkday.end;
    }
  });
  const userSelectedStart = Number(userDisplaySelection.start) * 60;
  const userSelectedEnd = Number(userDisplaySelection.end) * 60;
  if (userSelectedStart < start) {
    start = userSelectedStart;
  }
  if (userSelectedEnd > end) {
    end = userSelectedEnd;
  }
  return {
    start: ('00' + roundDownBihourly(start)).slice(-2) as StartOfDayTime,
    end: ('00' + roundUpBihourly(end)).slice(-2) as EndOfDayTime,
  };
}

export function staffingPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): StaffingPageViewModel {
  const calendarData = computeCalendarData(state);
  const shortNames = employeesShortNames(state);
  const organization = getOrganization(state);
  if (organization === undefined) {
    throw new Error('Illegal state (E153), organization not found');
  }

  const currentEmployee = currentEmployeeAsViewModel(state);
  const currentUserIsOwner = (currentEmployee?.email?.toUpperCase() ?? '') === organization.ownerEmail.toUpperCase();

  const accessList = staffingCalendarAccessList(state);

  return {
    ...viewModel,
    icon: 'staffing',
    type: 'staffing-page',
    href: hrefPrefix,
    year: state.staffingCalendarYear,
    calendarData: calendarData,
    groupGraphData: groupGraphData(calendarData),
    currentUserHasExtendedStaffingAccess: currentUserHasStaffingCalendarAccess(state),
    currentUserCanEditStaffingAccess: currentUserHasStaffingCalendarAccess(state) || currentUserIsOwner,
    leavePeriodEditRestriction: organization.leavePeriodEditRestriction,
    accessList: accessList,
    accessEmployees: getEmployeesWithForAccessControl(state).map((e) => {
      const d = currentUserIsOwner && currentEmployee?.uuid === e.id ? false : e.disabled;
      return {
        value: e.id,
        text: e.value,
        disabled: d,
      };
    }),
    employeeGroups: employeeGroups(state).map((g) => {
      return {
        items: g.map((e) => {
          return {
            uuid: e.uuid,
            displayName: shortNames.find((n) => n.uuid === e.uuid)?.name || e.name,
          };
        }),
      };
    }),
    employeesForStatistics: employeesForStaffingCalendar(state),
    employeesForStaffingCalendar: employeesForStaffingCalendar(state),
    employeesWithPeriods: employeesWithTimekeepingPeriods(state).map(
      (e): EmployeeTimekeeping => ({
        uuid: e.uuid,
        name: e.name,
        saldo: calculateBalance(e.periods),
        periods: e.periods.map((l) => ({
          label: l.timeDisplay,
          accessible: true,
          rightLabel: l.hoursDisplay,
          updateStatus: 'none',
          clickData: JSON.stringify(l),
        })),
        forStaffing: e.forStaffing,
      }),
    ),
    sicknessAbsenceStats: calculateSicknessAbsenceStats(state),
    employeesForShare: employeesShortNames(state)
      .filter((e) => e.status !== 'TERMINATED' && e.name !== '')
      .map((e) => ({
        value: e.uuid,
        text: e.name,
      })),
    vacationSummaryShares: sharedVacations(state),
    vacationSummaryEmployees: organization.vacationSummaryEmployees,
    vacationSummaryNotes: organization.vacationSummaryNotes,
    today: LocalDate.fromString(state.today),
    calendarDayRange: getCalendarDayRange(
      employeesForStaffingCalendar(state),
      staffingCalendarUserDisplaySelection(state),
      state.staffingCalendarYear,
    ),
    zoom: staffingCalendarUserDisplaySelection(state).zoom,
  };
}

export class StatisticsQuarterCalculator {
  year: string;
  quarter: number;

  constructor(year: string, quarter: number) {
    this.year = year;
    this.quarter = quarter;
  }

  stats(employees: EmployeeForStaffingCalendar[]): SicknessAbsenceStatsData {
    const holidays = holidaysForStaffingCalendar();
    return this._getStats(this.year, this.year, 7.5, employees, holidays);
  }

  _getStats(
    startYear: string,
    endYear: string,
    fullDayHours: number,
    employees: EmployeeForStaffingCalendar[],
    holidays: HolidaysType,
  ) {
    // a list of all sickness leave periods where adjacent periods are merged
    const sickPeriods = this._getMergedPeriods(employees, ['sickLeave', 'sickSelf'], holidays, fullDayHours);
    const sickChildPeriods = this._getMergedPeriods(employees, ['sickChildren'], holidays, fullDayHours);
    const periods = sickPeriods.concat(sickChildPeriods);
    // const genders = ['female', 'male'];
    // const groups = ['short', 'medium', 'long', 'extraLong'];

    const stats: SicknessAbsenceStatsData = {
      genderDefined: true,
      years: [],
    };

    const yearsCount = Number(endYear) - Number(startYear) + 1;

    for (let y = 0; y < yearsCount; y++) {
      const yearStartDate = LocalDate.fromString(startYear + '-01-01').plusYears(y);
      const yearStartString = yearStartDate.toString();
      const yearName = yearStartString.slice(0, 4);

      const yearData = this.periodData(
        yearStartString,
        yearName + '-12-31',
        employees,
        periods,
        fullDayHours,
        holidays,
      );

      const year: SicknessAbsenceStatsDataYear = {
        yearName: yearName,
        workDays: this.calculateWorkHours(yearData.employees, fullDayHours),
        absenceDays: this.calculateAbsenceDays(yearData.periods),
        female: this.getGenderGroup(
          yearData.employees,
          yearData.periods,
          fullDayHours,
          EmployeeViewModelGenderEnum.Female,
        ),
        male: this.getGenderGroup(yearData.employees, yearData.periods, fullDayHours, EmployeeViewModelGenderEnum.Male),
        quarters: [],
      };
      for (let q = 0; q < 4; q++) {
        const startDate = yearStartDate.plusMonths(q * 3);
        const start = startDate.toString();
        const end = startDate.plusMonths(3).minusDays(1).toString();

        // a list of employees with total work hours for range period and
        // a list of sickness leave periods that starts in range period
        const quarterData = this.periodData(start, end, employees, periods, fullDayHours, holidays);

        // an object with
        // work hours total for range period and each gender
        // sickness leave periods count and totals for range period, each gender and each period length
        // all sickness leave periods grouped by length
        const structuredQuarterData = this._getStructuredQuarterData(quarterData, fullDayHours);

        const quarter: QuarterData = {
          ...structuredQuarterData,
          quarterName: q + 1 + '. kvartal ',
        };

        /*
        // Calculate year totals for each gender and each length group
        genders.forEach(function (gender) {
          groups.forEach(function (group) {
            year.data[gender].periodsCount[group] += quarter.data[gender].periods[group].length;
            year.data[gender].periodsCountTotal += quarter.data[gender].periodsCount[group];
            year.data[gender].periodDays[group] += quarter.data[gender].periodDays[group];
          });
          year.data[gender].workDays += quarter.data[gender].workDays;
          year.data[gender].absenceDays += quarter.data[gender].absenceDays;
        });
        // calculate year totals
        year.data.workDays += quarter.data.workDays;
        year.data.absenceDays += quarter.data.absenceDays;
         */
        // push quarter into year
        year.quarters.push(quarter);
      }
      // round year totals

      /*
      // Calculate full totals for each gender and each length group
      genders.forEach(function (gender) {
        groups.forEach(function (group) {
          stats[gender].periodsCount[group] += year.data[gender].periodsCount[group];
          stats[gender].periodDays[group] += year.data[gender].periodDays[group];
        });
        stats[gender].periodsCountTotal += year.data[gender].periodsCountTotal;
        stats[gender].workDays += year.data[gender].workDays;
        stats[gender].absenceDays += year.data[gender].absenceDays;
      });
      // calculate full totals
      stats.workDays += year.data.workDays;
      stats.absenceDays += year.data.absenceDays;
      */
      // push year into years list
      stats.years.push(year);
    }
    // round full totals

    return stats;
  }
  // merges all confirmed, adjacent periods with types specified
  // and adds data for leave types, absence days, name and gender
  _getMergedPeriods(
    employees: EmployeeForStaffingCalendar[],
    types: string[],
    holidays: HolidaysType,
    fullDayHours: number,
  ): EmployeeStatsPeriod[] {
    let mergedPeriods: EmployeeStatsPeriod[] = [];
    employees.forEach((employee) => {
      const list: EmployeeStatsPeriod[] = [];
      const periods = employee.leavePeriods.filter((item) => types.indexOf(item.type) > -1 && item.confirmed);
      const sortedPeriods = sortBy(periods, (e) => e.start);
      sortedPeriods.forEach((period) => {
        let grade = 100;
        if ('grade' in period) {
          grade = period.grade;
        }
        const absenceDays =
          (this._workDaysInPeriod(period.start, period.end, employee, holidays, fullDayHours) * grade) / 100;
        if (list.length) {
          const prevPeriod = list[list.length - 1];
          if (LocalDate.fromString(prevPeriod.end).plusDays(1).isSame(LocalDate.fromString(period.start))) {
            prevPeriod.end = period.end;
            prevPeriod.absenceDays += absenceDays;
          } else {
            list.push({
              employeeUuid: employee.uuid,
              employeeName: employee.displayName,
              employeeGender: employee.gender ?? '',
              types: types,
              start: period.start,
              end: period.end,
              absenceDays: absenceDays,
            });
          }
        } else {
          list.push({
            employeeUuid: employee.uuid,
            employeeName: employee.displayName,
            employeeGender: employee.gender ?? '',
            types: types,
            start: period.start,
            end: period.end,
            absenceDays: absenceDays,
          });
        }
      });
      mergedPeriods = mergedPeriods.concat(list);
    });
    return mergedPeriods;
  }

  periodData(
    start: string,
    end: string,
    employees: EmployeeForStaffingCalendar[],
    periods: EmployeeStatsPeriod[],
    fullDayHours: number,
    holidays: HolidaysType,
  ): SimplePeriodData {
    const employeesWithWorkHours: EmployeeWithWorkHours[] = [];
    const startDate = LocalDate.fromString(start);
    const endDate = LocalDate.fromString(end);

    const periodsInRange = orderBy(
      periods.filter(
        (period) =>
          LocalDate.fromString(period.start).isSameOrAfter(startDate) &&
          LocalDate.fromString(period.start).isSameOrBefore(endDate),
      ),
      [(e) => e.end],
      ['desc'],
    );

    let lastSickDay = end;
    if (periodsInRange.length) {
      lastSickDay = periodsInRange[0].end;
    }
    const daysInQuarter = startDate.until(endDate) + 1;
    const daysInRange = startDate.until(LocalDate.fromString(lastSickDay)) + 1;
    let daysToCheck = daysInQuarter;
    if (daysInQuarter < daysInRange) {
      daysToCheck = daysInRange;
    }
    employees.forEach((employee) => {
      const currentEmployee = {
        uuid: employee.uuid,
        name: employee.displayName,
        gender: employee.gender ?? '',
        workHours: 0,
      };
      for (let d = 0; d < daysToCheck; d++) {
        const dayDate = startDate.plusDays(d);
        const hours = this._workMinutesForDay(dayDate, employee, holidays) / 60;

        if (d < daysInQuarter) {
          currentEmployee.workHours += hours;
        }
      }
      employeesWithWorkHours.push(currentEmployee);
    });
    return {
      employees: employeesWithWorkHours,
      periods: periodsInRange,
    };
  }

  calculateWorkHours(
    employees: EmployeeWithWorkHours[],
    fullDayHours: number,
    gender: EmployeeViewModelGenderEnum = EmployeeViewModelGenderEnum.Undefined,
  ) {
    return employees
      .filter((e) => gender === EmployeeViewModelGenderEnum.Undefined || e.gender === gender)
      .map((e) => e.workHours / fullDayHours) // work days
      .reduce((accumulator, workDays) => {
        return accumulator + workDays;
      }, 0);
  }

  calculateAbsenceDays(
    periods: EmployeeStatsPeriod[],
    gender: EmployeeViewModelGenderEnum = EmployeeViewModelGenderEnum.Undefined,
  ) {
    return periods
      .filter((e) => gender === EmployeeViewModelGenderEnum.Undefined || e.employeeGender === gender)
      .map((e) => e.absenceDays) // work days
      .reduce((accumulator, absenceDays) => {
        return accumulator + absenceDays;
      }, 0);
  }
  calculatePeriodsCount(
    periods: EmployeeStatsPeriod[],
    gender: EmployeeViewModelGenderEnum = EmployeeViewModelGenderEnum.Undefined,
  ) {
    return periods.filter((e) => gender === EmployeeViewModelGenderEnum.Undefined || e.employeeGender === gender)
      .length;
  }

  _getStructuredQuarterData(quarterData: SimplePeriodData, fullDayHours: number) {
    const employees = quarterData.employees;
    const periods = quarterData.periods;

    return {
      workDays: this.calculateWorkHours(employees, fullDayHours),
      absenceDays: this.calculateAbsenceDays(periods),
      female: this.getGenderGroup(employees, periods, fullDayHours, EmployeeViewModelGenderEnum.Female),
      male: this.getGenderGroup(employees, periods, fullDayHours, EmployeeViewModelGenderEnum.Male),
    };
  }

  _durationInMinutes(start: string, end: string): number {
    const startHours = Number(start.split(':')[0]);
    const startMinutes = Number(start.split(':')[1]);
    const endHours = Number(end.split(':')[0]);
    const endMinutes = Number(end.split(':')[1]);
    return endHours * 60 + endMinutes - (startHours * 60 + startMinutes);
  }

  _isHoliday(day: string, holidays: HolidaysType): boolean {
    return holidays[day] !== undefined;
  }

  _workMinutesForDay(dayDate: LocalDate, employee: EmployeeForStaffingCalendar, holidays: HolidaysType): number {
    const day = dayDate.toString();
    let workMinutes = 0;
    let breakMinutes = 0; //FIND CURRENT SCHEDULE
    const schedules = orderBy(employee.workSchedules, [(e) => e.start], ['desc']);
    let currentSchedule = defaultWorkSchedule;
    for (const item of schedules) {
      if (!item.start || LocalDate.fromString(item.start).isSameOrBefore(dayDate)) {
        currentSchedule = item;
        break;
      }
    }
    if (currentSchedule === undefined) {
      console.error(employee);
    }
    if (currentSchedule.restPeriod === WorkScheduleRestPeriodEnum.WithoutPay) {
      breakMinutes = 30;
    } //CHECK FOR SCHEDULE EXCEPTION
    for (const element of employee.workScheduleExceptions) {
      //If there is an exception for this day
      if (dayDate.isSame(LocalDate.fromString(element.date))) {
        //If the exception is work
        if (element.work) {
          if (element.start === undefined) {
            throw new Error('Illegal state (E587)');
          }
          if (element.end === undefined) {
            throw new Error('Illegal state (E588)');
          }
          //Get minutes for exception
          workMinutes = this._durationInMinutes(element.start, element.end); //No break if exeption is a short day
          if (workMinutes < 240) {
            breakMinutes = 0;
          }
          return workMinutes - breakMinutes;
        } else {
          return 0;
        }
      }
    } //CHECK FOR HOLIDAY
    if (this._isHoliday(day, holidays) && currentSchedule.holidaysAreTimeOff) {
      workMinutes = 0; //CHECK FOR VACATIONS
    } else if (
      employee.leavePeriods.filter(function (period) {
        return (
          LocalDate.fromString(period.start).isSameOrBefore(dayDate) &&
          LocalDate.fromString(period.end).isSameOrAfter(dayDate) &&
          period.type === 'vacation' &&
          period.confirmed
        );
      }).length
    ) {
      workMinutes = 0;
      //FIND CURRENT DAY IN SCHEDULE
    } else {
      let scheduleDay: WorkScheduleDay | undefined;
      if (currentSchedule.workWeeks) {
        const weekDayOrdinal = dayDate.weekDayOrdinal();
        const weeksInSchedule = currentSchedule.workWeeks.length;
        if (weeksInSchedule === 1) {
          scheduleDay = forDayIndex(currentSchedule.workWeeks[0], weekDayOrdinal);
        } else {
          const startDate = LocalDate.fromString(currentSchedule.start);
          const startDateWeekStart = startDate.minusDays(startDate.weekDayOrdinal());
          const thisDayWeekStart = dayDate.minusDays(dayDate.weekDayOrdinal());
          let thisWeekCount = 1;
          const daysDiff = startDateWeekStart.until(thisDayWeekStart);
          if (daysDiff > 6) {
            thisWeekCount = daysDiff / 7 + 1;
          }
          let theRightWeek;
          if (thisWeekCount <= weeksInSchedule) {
            theRightWeek = thisWeekCount;
          } else {
            theRightWeek = thisWeekCount % weeksInSchedule;
          }
          if (theRightWeek === 0) {
            theRightWeek = weeksInSchedule;
          }
          const weekIndex = theRightWeek - 1;
          scheduleDay = forDayIndex(currentSchedule.workWeeks[weekIndex], weekDayOrdinal);
        }
        if (!scheduleDay.workDay) {
          workMinutes = 0;
        } else if (
          !currentSchedule.workHoursDefinedPerDay &&
          currentSchedule.workDayStartTime &&
          currentSchedule.workDayEndTime
        ) {
          workMinutes = this._durationInMinutes(currentSchedule.workDayStartTime, currentSchedule.workDayEndTime);
        } else {
          if (scheduleDay.start === undefined) {
            throw new Error('Illegal state (E589)');
          }
          if (scheduleDay.end === undefined) {
            throw new Error('Illegal state (E586)');
          }
          workMinutes = this._durationInMinutes(scheduleDay.start, scheduleDay.end);
        }
      }
    }
    if (workMinutes < 30) {
      breakMinutes = 0;
    }
    return workMinutes - breakMinutes;
  }

  _workDaysInPeriod(
    start: string,
    end: string,
    employee: EmployeeForStaffingCalendar,
    holidays: HolidaysType,
    fullDayHours: number,
  ) {
    const startDate = LocalDate.fromString(start);
    const endDate = LocalDate.fromString(end).plusDays(1);
    const dates = LocalDate.dates(startDate, endDate);
    let minutes = 0;
    dates.forEach((day) => {
      const workMinutesForDay = this._workMinutesForDay(day, employee, holidays);
      minutes += workMinutesForDay;
    });
    return minutes / (60 * fullDayHours);
  }

  private getGenderGroup(
    employees: EmployeeWithWorkHours[],
    periods: EmployeeStatsPeriod[],
    fullDayHours: number,
    gender: EmployeeViewModelGenderEnum = EmployeeViewModelGenderEnum.Undefined,
  ): StatisticsAggregate {
    return {
      workDays: this.calculateWorkHours(employees, fullDayHours, gender),
      absenceDays: this.calculateAbsenceDays(periods, gender),
      periodsCountTotal: this.calculatePeriodsCount(periods, gender),
      periods: periods.filter((e) => e.employeeGender === gender),
    };
  }
}

function calculateYears(state: State): SicknessAbsenceStatsDataYear[] {
  const startYear = 2018;
  const endYear = Number(state.today.substring(0, 4));
  const options: SicknessAbsenceStatsDataYear[] = [];
  const employees = employeesForStaffingCalendar(state);
  for (let i = startYear; i <= endYear; i++) {
    const c = new StatisticsQuarterCalculator(i.toString(), 1);

    options.push(c.stats(employees).years[0]);
  }
  return options;
}

function calculateActualStats(state: State): SicknessAbsenceStatsData {
  return { genderDefined: true, years: calculateYears(state) };
}

function calculateSicknessAbsenceStats(state: State) {
  const employees = employeesForStaffingCalendar(state);
  const genderDefinedForAll = employees.filter((e) => e.gender === EmployeeViewModelGenderEnum.Undefined).length === 0;
  const noGender: SicknessAbsenceStatsNoGender = { genderDefined: false };
  return genderDefinedForAll ? calculateActualStats(state) : noGender;
}

function groupGraphData(staffingCalendar: StaffingCalendar) {
  const days: number[][] = [];
  let employeesCount = 0;
  staffingCalendar.data.forEach(function (group, groupIndex) {
    group.employees.forEach(function (employee) {
      employeesCount += 1;
      employee.days.forEach(function (day, dayIndex) {
        if (days[dayIndex] === undefined) {
          days.push([0, 0, 0]);
        }
        days[dayIndex][groupIndex] += calculateWorkDays(day);
      });
    });
  });
  return days.map((groups) => {
    return groups.map((group) => {
      return 'height: ' + (group / employeesCount) * 100 + '%';
    });
  });
}

function calculateWorkDays(day: StaffingCalendarDataDay): number {
  // Calculate total work minutes:
  // workdays minus leave periods plus plus time periods (for use in group graph)
  // Periods are calculated regardless of status (confirmed true/false)
  let totalWorkMinutes = 0;
  let totalWorkDays;

  let work = day.workPeriods.map((p) => {
    return { start: p.startMinutes, end: p.endMinutes };
  });

  let leave = day.leavePeriods.map((period) => {
    return { start: period.startMinutes, end: period.endMinutes };
  });
  const workPeriodsInLeave: SuperSimplePeriod[] = [];
  day.leavePeriods.forEach((period) => {
    if (period.exceptions && period.exceptions.length) {
      const workPeriodInLeave = {
        start: period.exceptions[0].startMinutes,
        end: period.exceptions[0].endMinutes,
      };
      workPeriodsInLeave.push(workPeriodInLeave);
    }
  });
  if (workPeriodsInLeave.length) {
    leave = subtractPeriods(leave, workPeriodsInLeave);
  }

  const plus = day.plusTimePeriods.map((period) => {
    return { start: period.startMinutes, end: period.endMinutes };
  });

  if (leave) {
    work = subtractPeriods(work, leave);
  }
  if (plus) {
    work = mergePeriods(work.concat(plus));
  }
  work.forEach(function (item) {
    totalWorkMinutes += item.end - item.start;
  });

  totalWorkDays = totalWorkMinutes / 450; // = 7.5 hours
  if (totalWorkDays > 1) {
    totalWorkDays = 1;
  }

  return totalWorkDays;
}

export function calculateBalance(periods: { confirmed: boolean; type: string; minutes: number }[]) {
  let balance = 0;
  periods.forEach(function (period) {
    if (period.confirmed) {
      if (period.type === 'plusTime' || period.type === 'correctionPlus') {
        balance += period.minutes;
      }
      if (period.type === 'timeOff' || period.type === 'correction') {
        balance -= period.minutes;
      }
    }
  });
  return balance / 60;
}
