import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../elements/d-label.js';
import { LabeledElement } from '../../abstracts/labeled-element.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';

export interface SelectDropdownOption {
  value: string;
  text: string;
  disabled?: boolean; // TODO Is this used anywhere?
}

/**
 *
 *
 * @fires value-changed - Dispatched immediately after changes by the user
 *
 * STATUS OK
 */
@customElement('d-select-dropdown')
export class DSelectDropdown extends LabeledElement {
  static readonly styles = [
    ...LabeledElement.styles,
    css`
      :host([action-style]) {
        margin: -6px -8px;
      }
      .select-wrapper {
        display: block;
        background-image: url(/images/selectbox-image.svg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 32px 32px;
        overflow: hidden;
      }

      :host([action-style]) .select-wrapper {
        border: none;
        background: none;
        box-shadow: none;
      }

      :host([no-arrow]) .select-wrapper,
      :host([link-style]) .select-wrapper {
        background-image: none;
      }

      :host([small]) .select-wrapper {
        background: none;
        box-shadow: none;
      }

      :host([toolbar]) .select-wrapper {
        box-shadow: none;
        background-color: hsl(0, 0%, 92%);
        border-radius: 4px;
      }

      :host([invalid]) .select-wrapper {
        border: 1px solid var(--alertColor);
        margin-left: -1px;
        margin-right: -1px;
      }

      :host([theme-page]) .select-wrapper {
        background-color: white;
      }

      :host([toolbar]) select {
        font-family: var(--mainSans), sans-serif;
      }

      :host([disabled-as-view-text]) .select-wrapper[disabled] {
        background: none;
        box-shadow: none;
        opacity: 1;
      }

      .select-wrapper[disabled] select,
      .select-wrapper[disabled] select.placeholder {
        opacity: 1;
      }

      :host([disabled-as-view-text]) .select-wrapper[disabled] select {
        padding-left: 0;
      }

      select {
        display: flex;
        width: calc(100% + 18px);
        align-items: center;
        box-sizing: border-box;
        border: none;
        outline: none;
        padding: var(--select-dropdown-text-padding, 6px 30px 0 10px);
        background: transparent;
        font-family: var(--mainSerif), serif;
        font-size: 15px;
        color: black;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
      }

      :host([action-style]) select {
        width: 100%;
        padding: 6px 8px;
        font-family: var(--mainSans);
        color: var(--themeColor);
      }

      @media (hover: hover) {
        :host([action-style]) select:not([disabled]):hover {
          color: black;
        }
      }

      select[disabled] {
        cursor: default;
      }

      select[disabled] > option {
        color: black;
      }

      :host([no-arrow]) select,
      :host([small]) select {
        padding-right: 6px;
      }

      :host([small]) select {
        padding: 7px 0 7px 0;
        font-family: var(--narrow), sans-serif;
        font-size: 14px;
        font-weight: 300;
      }
    `,
  ];
  @property({ type: String })
  placeholder = '';
  @property({ type: Boolean })
  unselectablePlaceholder = false;
  @property({ type: String })
  value = '';
  @property({ type: Boolean })
  disabled = false;
  @property({ type: Boolean, attribute: 'disabled-as-view-text', reflect: true })
  disabledAsViewText = false;
  @property({ type: Boolean, reflect: true })
  invalid = false;
  @property({ type: Array })
  options: SelectDropdownOption[] = [];
  @property({ type: Boolean, reflect: true })
  controller = false;
  @property({ type: Boolean, reflect: true })
  toolbar = false;
  @property({ type: Boolean, reflect: true })
  noArrow = false;
  @property({ type: Boolean, reflect: true })
  small = false;
  @property({ type: Boolean, attribute: 'mark-if-empty', reflect: true })
  markIfEmpty = false;

  _placeholderClass() {
    if (this.placeholder && this.placeholder !== '' && (!this.value || this.value === '')) {
      return 'placeholder';
    }
    return '';
  }

  _setValue(event) {
    this.value = event.target.value;
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        bubbles: true,
        composed: true,
        detail: { value: this.value },
      }),
    );
    // Needed to make resize observers react
    this.blur();
  }

  private get inputStyle() {
    if (this.markIfEmpty && isEmptyOrInvalidString(this.value)) {
      return 'border: 1px solid var(--themeColor)';
    }
    return '';
  }

  protected renderContent() {
    return html`
      <div class="select-wrapper" ?disabled=${this.disabled} style="${this.inputStyle}">
        <select class="${this._placeholderClass()}" ?disabled=${this.disabled} @change=${(e) => this._setValue(e)}>
          ${this.placeholder
            ? html`<option ?disabled=${this.unselectablePlaceholder} .selected=${this.value === ''} value="">
                ${this.placeholder}
              </option>`
            : nothing}
          ${this.options.map(
            (o) => html`<option value="${o.value}" .selected=${this.value === o.value}>${o.text}</option>`,
          )}
        </select>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-select-dropdown': DSelectDropdown;
  }
}
