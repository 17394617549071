import Ably from 'ably/callbacks';
import { customElement } from 'lit/decorators.js';
import {
  CollectionEditDoneDetail,
  ContentEditDoneDetail,
  RelateAssetToEventOccurrencesDetail,
} from 'src/content/d-content.js';
import {
  AppState,
  AppStateAccount,
  AppStateLink,
  AppStateLoading,
  AppStateNotFound,
  AppStateNoUser,
  AppStateSecured,
  AppStateSignin,
  AppStateSignup,
  DAppCore,
  MapElementChangedDetail,
} from 'src/d-app-core.js';
import type { Subscription } from 'src/layout/parts/d-organization-subscription.js';
import type { TutorialParticipant } from 'src/library/lists/d-list-section-tutorial-participants.js';
import type {
  DeleteTimeCorrectionEvent,
  UpdateTimeCorrectionEvent,
} from 'src/library/lists/d-timekeeping-list-section.js';
import { mapSearchResultHits } from 'src/models/search.js';
import type { EditPeriodResult } from 'src/pages/edit-periods-dialog.js';
import type { OrganizationEditItem } from 'src/pages/organization-page/d-organization-edit.js';
import type { ShareVacationResult } from 'src/pages/staffing-page/share-staffing-dialog.js';
import { handleNavigation, logOut, reduxConnectMixin } from 'src/redux-behavior.js';
import * as dabihStore from 'src/store';
import {
  checkEmail,
  createAsset,
  createClient,
  createContract,
  createEmployee,
  createPartner,
  createRiskAssessment,
  createUser,
  debouncedSearch,
  declineTaskTemplateUpdate,
  declineTemplateUpdate,
  deleteAttachment,
  deleteItem,
  fetchPageGroups,
  getPages,
  getStore,
  loadedPageGroups,
  loadOrganization,
  loginUserAction,
  renameAttachment,
  saveItem,
  sendPassword,
  sendShareVacation,
  stopShareVacation,
  taskDone,
  updateCurrentTutorialId,
  updateHelpViewerOpen,
  updateItem,
  updateOrganization,
  updateSelectedStartTask,
  updateSideContentOpen,
  updateStaffingCalendarYear,
  updateTutorialViewerOpen,
  updateUserAction,
  updateUserPropertiesForOrganization,
  updateWeekStart,
  uploadFile,
} from 'src/store';
import {
  AccountStateViewModel,
  AccountUpdateMessage,
  AccountUpdateMessageReceiveRemindersEnum,
  AccountUpdateMessageSummariesEnum,
  AddToTopicRequest,
  ConnectEventOccurrencesRequest,
  CreateMeetingOccurrenceReportRequest,
  type CreateOrUpdateComputerRequest,
  CreateOrUpdateEmployeeRequest,
  CreateOrUpdateFunctionRequest,
  type CreateOrUpdateNetworkRequest,
  CreateOrUpdatePartnerContactRequest,
  CreateOrUpdatePartnerRequest,
  CreateTopicRequest,
  DeleteEventOccurrenceRequest,
  DeleteMeetingOccurrenceRequest,
  DeleteTopicRequest,
  type EmployeeUpdateMessageAccessLevelEnum,
  EmployeeViewModelGenderEnum,
  FunctionUpdateMessage,
  IssueUpdateMessage,
  LeavePeriod,
  MarkEventOccurrenceDoneRequest,
  MarkEventOccurrenceNotDoneRequest,
  MarkMeetingOccurrenceDoneRequest,
  MarkMeetingOccurrenceNotDoneRequest,
  type NetworkUpdateMessageNetworkTypeEnum,
  OrganizationReference,
  OrganizationSettingsUpdateMessage,
  OrganizationSettingsUpdateMessageInvoiceSendMethodEnum,
  OrganizationSettingsUpdateMessageSpecialTermsEnum,
  OrganizationUpdateMessage,
  PlusTimePeriod,
  RepeatEventOccurrenceRequest,
  RepeatMeetingOccurrenceRequest,
  RequestSignaturesSignatory,
  SaveDraftRequest,
  SendMeetingOccurrenceNoticeRequest,
  SendMeetingOccurrenceReportRequest,
  SendMessageCommandMessageTypeEnum,
  TimekeepingCorrection,
  UpdateEntityTopicsRequest,
  UpdateEventOccurrenceRelatedAssetRequest,
  UpdateEventOccurrenceRequest,
  UpdateMeetingOccurrenceCommandClassificationEnum,
  UpdateMeetingOccurrenceRequest,
  UpdateSpecialTermsCommandSpecialTermsEnum,
  UpdateStaffingAccessRequest,
  UpdateSubstancePdfRequest,
  UpdateTopicRequest,
  UpdateTutorialRequest,
  UpdateTutorialStateForEmployeeRequest,
  UploadSubstanceFilesRequest,
  WorkSchedule,
  WorkScheduleException,
} from 'src/store/api';
import { displayAlert } from 'src/utilities/display-alert.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { uuid } from 'src/utilities/text.js';
import { applicationViewModel } from './models/application-view-model.js';
import type { State, User } from './store/types.js';
import type { TutorialState } from 'src/outskirts/d-tutorial-viewer.js';
import { updateMessage } from 'src/d-app--update-message.js';
import type { MessageForSend } from 'src/content/meeting-occurrences/meeting-message-dialog.js';
import type { EmployeeWithStaffGroup } from 'src/pages/staffing-page/staffing-groups-dialog.js';
import { dataItemChanged } from 'src/d-app--data-item-changed.js';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import type { NetworkExternalConnectionChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-network-external-connections.js';
import type { AccessChange } from 'src/pages/computers-page/infosec-procedure/d-infosec-access.js';
import type { BackupChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-backup.js';
import {
  accessChangeHandler,
  backupChangeHandler,
  computerNetworkChangeHandler,
  dataItemsRemovedHandler,
  externalConnectChangeHandler,
  mapElementChangedHandler,
  mapElementDeletedHandler,
} from 'src/handlers/computers-handlers.js';
import type { EditException } from 'src/pages/staffing-page/staffing-employee-day-dialog.js';
import type { Types } from 'ably/promises';
import type { FileViewerDocument } from 'src/layout/parts/file-viewer-dialog.js';
import { findAvailableTutorials } from 'src/models/tutorials.js';
import {
  breadCrumbSelectedName,
  currentEmployeeAsViewModel,
  currentEmployeeShortName,
  currentEntityTypeNameDetermined,
  employeeNamesById,
  getItemDataFromTemplateId,
  getOrganization,
  ignoredRobotAlerts,
  isPageId,
  itemsByTypeAndId,
  partnerNamesById,
  writeAccess,
} from 'src/store/selectors';
import type { UpdateUserAccessEvent } from 'src/layout/parts/d-organization-edit-access.js';
import { produce } from 'immer';
import _, { isEqual } from 'lodash';
import { handleCreateEntity } from 'src/d-app-create-entity-handler';
import { newItemLabels } from 'src/utilities/new-item-labels';
import { CreateEmployeeInput } from 'src/content/meeting-occurrences/d-meeting-occurrence-view';
import { CreateContactAndPartnerInput } from 'src/layout/parts/d-new-contact-and-partner-dialog';
import { getFeatureStates } from 'src/store/selectors/features';
import type { ZoomUnit } from 'src/pages/staffing-page/d-staffing-calendar-data';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NewOrganization, UserForSignup } from 'src/outskirts/signup/d-signup-form';
import { CollectionFilter } from 'src/store/selectors/collections';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import { EventDoneDetail } from 'src/content/event-occurrences/d-event-occurrence-view';
import { ContentItem } from 'src/layout/parts/request-signatures-dialog';
import { UploadedFile } from 'src/pages/substances-page/d-substances-page-content';
import { saveTokens } from 'src/store/oauth-browser';
import type { InfosecNetwork } from 'src/pages/computers-page/infosec-procedure/defaults';

@customElement('d-app')
class DApp extends reduxConnectMixin(DAppCore) {
  private employeeNamesById: { [uuid: string]: string } = {};
  private partnerNamesById: { [uuid: string]: string } = {};
  private currentAblyTokenRequest?: string;

  private api = createClient();
  private lastDraftSaved?: SaveDraftRequest;

  private debouncedDoStateChanged = _.debounce((state: State) => this.doStateChanged(state), 400, {
    trailing: true,
    leading: true,
  });

  stateChanged(state: State) {
    this.debouncedDoStateChanged(state);
  }

  doStateChanged(state: State) {
    console.log('state changed');
    this.updateAppState(state).then(() => console.log('app state updated'));
  }

  subscribeToAbly(user: User, organizationId: number) {
    if (user.ablyTokenRequest && this.currentAblyTokenRequest !== user.ablyTokenRequest) {
      this.currentAblyTokenRequest = user.ablyTokenRequest;
      const lastToken: Ably.Types.TokenRequest | undefined = JSON.parse(user.ablyTokenRequest);
      const ablyClient = this.createAblyClient(lastToken, user);
      const org = user.organizations.find((o) => o.id === organizationId);
      this.startListeningToChannels(
        ablyClient,
        organizationId,
        org !== undefined && org.userType === 'CONTACT' ? '-contact' : '',
      );
    }
  }

  async onWeekStartChanged(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(updateWeekStart(e.detail.value));
  }

  async onStaffingCalendarUserDisplaySelectionChanged(
    e: CustomEvent<{
      start: string;
      end: string;
      zoom: ZoomUnit;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          this.api,
          { staffingCalendarUserDisplaySelection: e.detail },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  /**
   * For utvikling
   *
   */
  async onUpdateUiSettings(e: CustomEvent<{ isSimplifiedUi: boolean }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      console.log('onUpdateUiSettings');
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          this.api,
          { uiSettings: e.detail },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onUpdateCollectionsFilter(e: CustomEvent<CollectionFilter[]>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      console.log('onUpdateCollectionsFilter');
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          this.api,
          { collectionsFilter: e.detail },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onSaveHideDoneOption(e) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          this.api,
          { todoListHideAfterDaysOption: e.detail.value },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onUploadFile(e: CustomEvent<{ file: File; callback: () => void; type: string; uuid: string }>) {
    console.log('upload file', e.detail);
    await getStore().dispatch(uploadFile(this.api, e.detail.type, e.detail.uuid, e.detail.file));
    e.detail.callback();
  }

  async onUpdateSubstanceFile(
    e: CustomEvent<{ files: File[]; substanceId: string; callback: (data: UploadedFile[]) => void }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      console.log('Files ' + e.detail.files[0].name);
      let hasFiles = false;
      const files: Blob[] = [];
      const requestParameters: UpdateSubstancePdfRequest = {
        organizationId: this.appState.viewModel.currentOrganizationId.toString(),
        substanceId: e.detail.substanceId,
      };

      for (const file of e.detail.files) {
        const suffix = file.name.slice(-3).toLowerCase();
        if (suffix === 'pdf' || file.type === '') {
          console.log('file', file);
          files.push(file);
          hasFiles = true;
        }
      }
      if (hasFiles) {
        const data = await this.api.substances.updateSubstancePdf({ ...requestParameters, filename: files });

        console.log('substances', data);
        e.detail.callback(data ?? []);
      }
    }
  }

  async onUploadSubstanceFiles(e: CustomEvent<{ files: File[]; callback: (data: UploadedFile[]) => void }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      let hasFiles = false;
      const files: Blob[] = [];
      const requestParameters: UploadSubstanceFilesRequest = {
        organizationId: this.appState.viewModel.currentOrganizationId.toString(),
      };

      for (const file of e.detail.files) {
        const suffix = file.name.slice(-3).toLowerCase();
        if (suffix === 'pdf' || file.type === '') {
          console.log('file', file);
          files.push(file);
          hasFiles = true;
        }
      }
      if (hasFiles) {
        const data = await this.api.substances.uploadSubstanceFiles({ ...requestParameters, filename: files });

        console.log('substances', data);
        e.detail.callback(data ?? []);
      }
    }
  }

  async onResetSubstances() {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      alert('Sletter data. Dette kan ta inntil 5 minutter. (IKKE IMPLEMENTERT)');
      // await this.api.substances.reset({});
    }
  }

  async onSaveTimekeepingPeriod(e: CustomEvent<EditPeriodResult>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: PlusTimePeriod = {
        confirmed: e.detail.confirmed,
        end: e.detail.end.split(' ')[0],
        notes: e.detail.notes,
        start: e.detail.start.split(' ')[0],
        uuid: e.detail.periodId ?? uuid(),
        startTime: e.detail.start.split(' ')[1] ?? '',
        endTime: e.detail.end.split(' ')[1] ?? '',
      };

      await this.api.employees.createOrUpdateEmployeePlusTimePeriod({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: m.uuid,
        plusTimePeriodUpdateMessage: {
          plusTimePeriod: m,
        },
      });
    }
  }

  connectedCallback() {
    super.connectedCallback();
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      if (event.url.startsWith('no.trinnvis.dabih:')) {
        console.log(event.url);
      } else if (event.url.startsWith('no.trinnvis.dabih.issues:')) {
        console.log(event.url);
      } else {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('app.trinnvis.no').pop();
        if (slug) {
          window.location.href = slug;
        }
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }

  async onDeleteTimekeepingPeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.employees.deleteEmployeePlusTimePeriod({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: e.detail.periodId,
      });
    }
  }

  async onSaveLeavePeriod(e: CustomEvent<EditPeriodResult>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: LeavePeriod = {
        days: e.detail.type === 'sickLeave' && e.detail.grade < 100 ? e.detail.days : [],
        grade: e.detail.type === 'sickLeave' ? e.detail.grade : 100,
        type: e.detail.type,
        confirmed: e.detail.confirmed,
        end: e.detail.end.split(' ')[0],
        notes: e.detail.notes,
        start: e.detail.start.split(' ')[0],
        uuid: e.detail.periodId ?? uuid(),
        startTime: e.detail.start.split(' ')[1],
        endTime: e.detail.end.split(' ')[1],
      };

      await this.api.employees.createOrUpdateEmployeeLeavePeriod({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: m.uuid,
        leavePeriodUpdateMessage: {
          leavePeriod: m,
        },
      });
    }
  }

  async onDeleteLeavePeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.employees.deleteEmployeeLeavePeriod({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: e.detail.periodId,
      });
    }
  }

  async onSaveStaffingAccess(e: CustomEvent<{ accessList: string[]; leavePeriodEditRestriction: boolean }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: UpdateStaffingAccessRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateStaffingAccessCommand: {
          staffingCalendarAccessList: e.detail.accessList,
          leavePeriodEditRestriction: e.detail.leavePeriodEditRestriction,
        },
      };

      await this.api.organization.updateStaffingAccess(p);
    }
  }

  async onRepeatMeetingOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
      applyToCollections: boolean;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: RepeatMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        repeatMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          dateTimesToAdd: e.detail.dateTimesToAdd,
          occurrencesToRemove: e.detail.occurrencesToRemove,
          repeatTopics: e.detail.applyToCollections,
        },
      };

      await this.api.meetingOccurrences.repeatMeetingOccurrence(p);
    }
  }

  async onRepeatEventOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
      applyToCollections: boolean;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: RepeatEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        repeatEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          dateTimesToAdd: e.detail.dateTimesToAdd,
          occurrencesToRemove: e.detail.occurrencesToRemove,
          repeatTopics: e.detail.applyToCollections,
        },
      };

      await this.api.eventOccurrences.repeatEventOccurrence(p);
    }
  }

  async onUpdateEmployeeWorkSchedules(
    e: CustomEvent<{
      employeeUuid: string;
      workSchedules: WorkSchedule[];
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.employees.updateEmployeeWorkSchedules({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        workSchedulesUpdateMessage: {
          workSchedules: e.detail.workSchedules,
        },
      });
    }
  }

  onStaffingYearChanged(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(updateStaffingCalendarYear(e.detail.value));
  }

  async onUpdateEmployeeStaffGroups(e: CustomEvent<{ value: EmployeeWithStaffGroup[] }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      for (const employee of e.detail.value) {
        await this.api.employees.updateEmployeeStaffGroup({
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          entityId: employee.uuid,
          updateStaffGroupCommand: {
            staffGroup: employee.staffGroup,
          },
        });
      }
    }
  }

  onUpdateDeclined(e: CustomEvent<{ entityType: string; entityId: string }>) {
    if (e.detail.entityType === 'tasks') {
      getStore().dispatch(declineTaskTemplateUpdate(this.api, e.detail.entityId));
    } else {
      getStore().dispatch(declineTemplateUpdate(this.api, e.detail.entityType, e.detail.entityId));
    }
  }

  async onContentSaveEvent(e: CustomEvent<ContentEditDoneDetail>) {
    if (
      this.appState &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded &&
      e.detail.type === 'eventOccurrences'
    ) {
      const p: UpdateEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          alerts: e.detail.alert,
          alertMessage: e.detail.message,
          alertIncludesDetails: e.detail.includeDetailsInAlert,
          name: e.detail.editItem.type === 'standard' ? e.detail.editItem.name : '',
          date: e.detail.editItem.date,
          time: e.detail.editItem.time === 'NONE' ? '' : e.detail.editItem.time,
          durationMinutes: e.detail.editItem.durationMinutes,
          employees: e.detail.editItem.assignedToEmployees,
          contacts: e.detail.editItem.assignedToContacts,
          functionUuid: e.detail.editItem.assignedToFunction,
          assets: e.detail.editItem.assets,
          persistent: e.detail.editItem.persistent,
          reminder: e.detail.editItem.reminder,
          notes: e.detail.editItem.notes,
          description: e.detail.editItem.procedures,
          taskUuid: e.detail.editItem.taskUuid,
        },
      };

      await this.api.eventOccurrences.updateEventOccurrence(p);
      console.log('saving after');
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    }
  }

  async onRelateAssetToEventOccurrences(e: CustomEvent<RelateAssetToEventOccurrencesDetail>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onRelateAssetToEventOccurrences', e.detail);
      const p: ConnectEventOccurrencesRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        assetId: e.detail.asset,
        assetConnectEventOccurrencesCommand: {
          eventOccurrenceIds: e.detail.eventOccurrences,
        },
      };

      await this.api.assets.connectEventOccurrences(p);
    }
  }

  async onContentSaveMeetingOccurrence(e: CustomEvent<ContentEditDoneDetail>) {
    if (
      this.appState &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded &&
      e.detail.type === 'meetingOccurrences'
    ) {
      const p: UpdateMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          name: e.detail.editItem.name,
          date: e.detail.editItem.date,
          time: e.detail.editItem.time,
          durationMinutes: e.detail.editItem.durationMinutes,
          participants: e.detail.editItem.assignedToEmployees,
          externalParticipants: e.detail.editItem.assignedToContacts,
          reminder: e.detail.editItem.reminder,
          agenda: e.detail.editItem.meetingAgenda,
          classification: e.detail.editItem.classification as UpdateMeetingOccurrenceCommandClassificationEnum,
          accessControl: e.detail.editItem.accessControl,
          responsible: e.detail.editItem.meetingResponsibleFunctionUuid,
          responsibleEmployee: e.detail.editItem.meetingResponsibleEmployeeUuid,
        },
      };

      await this.api.meetingOccurrences.updateMeetingOccurrence(p);
      console.log('saving after');
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    }
  }

  async onContentDeleteEvent(
    e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string; alert: boolean }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      window.history.pushState({}, '', e.detail.url);
      await handleNavigation(window.location);
      const p: DeleteEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        deleteEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          alerts: e.detail.alert,
        },
      };

      await this.api.eventOccurrences.deleteEventOccurrence(p);
    }
  }

  async onContentDeleteMeetingOccurrence(
    e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string; alerts: boolean }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      window.history.pushState({}, '', e.detail.url);
      await handleNavigation(window.location);
      const p: DeleteMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        deleteMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
        },
      };

      await this.api.meetingOccurrences.deleteMeetingOccurrence(p);
    }
  }

  /**
   * Must navigate away from the item to be deleted before actually deleting.
   */
  async onEntityDelete(e: CustomEvent<{ url: string; type: string; uuid: string }>) {
    console.log('onEntityDelete', e);
    window.history.pushState({}, '', e.detail.url);
    await handleNavigation(window.location);
    if (e.detail.type === 'collections') {
      await this.deleteCollection(e.detail.uuid);
    } else {
      getStore().dispatch(deleteItem(this.api, e.detail.type, e.detail.uuid));
    }
  }

  async onRequestLogin(
    e: CustomEvent<{ user: { username: string; password: string }; loginFailed: () => void; target?: string }>,
  ) {
    console.log(e.detail);
    try {
      const r = await this.api.accounts.passwordTokenLogin({
        passwordTokenLoginMessage: {
          username: e.detail.user.username,
          password: e.detail.user.password,
        },
      });

      console.log(r);
      await saveTokens(r.accessToken, r.refreshToken);
    } catch (error) {
      console.error(error);
      e.detail.loginFailed();
      return;
    }

    const user = e.detail.user;

    const result = await getStore().dispatch(loginUserAction(this.api, user.username));
    console.log(result);
    if (result && e.detail.target !== undefined) {
      setTimeout(() => {
        window.location.href = e.detail.target ?? '/';
      }, 200);
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else if (result) {
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else {
      console.log('Login not success');
      e.detail.loginFailed();
    }
  }

  async onRequestPassword(e: CustomEvent<{ email: string }>) {
    await sendPassword(e.detail.email);
  }

  async onSavePrepareState(
    e: CustomEvent<{
      organizationId: string;
      state: AccountStateViewModel;
      saveToServer: boolean;
      afterSave: (() => void) | undefined;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account') {
      if (e.detail.saveToServer) {
        await this.api.organization.updatePendingOrganizationState({
          organizationId: e.detail.organizationId,
          accountStateUpdateMessage: e.detail.state,
        });
        console.log('State saved');
      }
      const store = getStore();
      const state = store.getState();
      if (state.user) {
        const user = produce(state.user, (draft) => {
          const organization = draft.organizations.find((o) => '' + o.id === e.detail.organizationId);
          if (organization) organization.state = e.detail.state;
        });
        await store.dispatch(updateUserAction(user));
        if (e.detail.afterSave) {
          e.detail.afterSave();
        }
      }
    }
  }

  async _requestLink(e) {
    const user = e.detail;

    const result = await getStore().dispatch(loginUserAction(this.api, user.username));
    console.log(result);
    if (result) {
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else {
      console.log('Login not success');
      // e.detail.loginFailed();
    }
  }

  async onRequestLogout(e: CustomEvent) {
    logOut(e.detail?.redirect ?? false);
  }

  async onDeleteTimeCorrection(e: DeleteTimeCorrectionEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.employees.deleteEmployeeTimekeepingCorrection({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: e.detail.uuid,
      });
    }
  }

  async onUpdateTimeCorrection(e: UpdateTimeCorrectionEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: TimekeepingCorrection = {
        correctionType: e.detail.correctionType,
        date: e.detail.date,
        hours: e.detail.hours,
        notes: e.detail.notes,
        uuid: e.detail.uuid,
      };
      await this.api.employees.createOrUpdateEmployeeTimekeepingCorrection({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.employeeUuid,
        periodId: m.uuid,
        timekeepingCorrectionUpdateMessage: {
          timekeepingCorrection: m,
        },
      });
    }
  }

  async onTutorialChanged(
    e: CustomEvent<{
      uuid: string;
      participants: TutorialParticipant[];
      isCommonDate: boolean;
      commonDate: string;
      comment: string;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: UpdateTutorialRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateTutorialCommand: {
          id: Number.parseInt(e.detail.uuid),
          participants: e.detail.participants.map((e) => {
            return {
              ...e,
              statusComment: e.statusComment ?? '',
            };
          }),
          dueDate: e.detail.isCommonDate ? e.detail.commonDate : undefined,
          comment: e.detail.comment,
        },
      };

      await this.api.organization.updateTutorial(p);
    }
  }

  async onToggleHelp(e: CustomEvent<{ value: boolean }>) {
    getStore().dispatch(updateHelpViewerOpen(e.detail.value));
    getStore().dispatch(updateTutorialViewerOpen(false));
    getStore().dispatch(updateSideContentOpen(false));
  }

  async onToggleSideContent(e: CustomEvent<{ value: boolean }>) {
    console.log('XXXXXXXXXXXXXX onToggleSideContent', e.detail.value);
    getStore().dispatch(updateSideContentOpen(e.detail.value));
    getStore().dispatch(updateHelpViewerOpen(false));
    getStore().dispatch(updateTutorialViewerOpen(false));
  }

  async onToggleTutorial(e: CustomEvent<{ value: boolean }>) {
    const state = getStore().getState();
    if (state.currentTutorialId === undefined) {
      const organization = getOrganization(state);
      if (organization === undefined) {
        throw new Error('Illegal state (E487), expected organization');
      }
      const activeTutorials = findAvailableTutorials(state, organization, currentEmployeeAsViewModel(state));
      const firstTutorial = activeTutorials[0];
      getStore().dispatch(updateCurrentTutorialId(firstTutorial.value));
    }
    getStore().dispatch(updateTutorialViewerOpen(e.detail.value));
    getStore().dispatch(updateHelpViewerOpen(false));
  }

  onSaveOrganization(e: CustomEvent<OrganizationEditItem>) {
    const state = getStore().getState();
    const organization = getOrganization(state);
    if (organization !== undefined) {
      const updateMessage: OrganizationUpdateMessage = {
        address: organization.address ?? '',
        notes: e.detail.notes,
        organizationNumber: e.detail.organizationNumber,
        activityCodes: e.detail.activityCodes.slice(),
        businessEntityType: e.detail.businessEntityType,
        mailAddress: e.detail.mailAddress,
        officeAddress: e.detail.officeAddress,
        sector: e.detail.sector,
        type: organization.type ?? '',
        mailPostcode: organization.mailPostcode ?? '',
        url: e.detail.url,
        officePostcode: organization.officePostcode ?? '',
        phone: e.detail.phone,
        name: e.detail.name,
        herNumber: e.detail.herNumber,
        fax: e.detail.fax,
        email: e.detail.email,
      };
      getStore().dispatch(updateOrganization(this.api, updateMessage));
    }
  }

  async onUpdateAllCollections(
    e: CustomEvent<{ entityUuid: string; existingTopics: string[]; newCollectionName?: string }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: UpdateEntityTopicsRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateEntityTopicsCommand: {
          uuid: e.detail.entityUuid,
          existingTopics: e.detail.existingTopics ?? [],
          newTopics: e.detail.newCollectionName ? [{ uuid: uuid(), name: e.detail.newCollectionName }] : [],
        },
      };

      await this.api.topics.updateEntityTopics(p);
    }
  }

  async updateCollection(detail: CollectionEditDoneDetail) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: UpdateTopicRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateTopicCommand: {
          uuid: detail.uuid,
          name: detail.editItem.name,
          description: detail.editItem.description,
          items: detail.editItem.items.map((i) => i.uuid),
          pages: detail.editItem.pages.map((p) => Number(p)),
        },
      };
      await this.api.topics.updateTopic(p);
    }
  }

  async onAddToCollection(e: CustomEvent<{ collectionUuid: string; entityUuid: string; entityType: string }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: AddToTopicRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        addToTopicCommand: {
          topicUuid: e.detail.collectionUuid,
          entityUuid: e.detail.entityUuid,
        },
      };
      await this.api.topics.addToTopic(p);
    }
  }

  async deleteCollection(uuid: string) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: DeleteTopicRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        deleteTopicCommand: {
          uuid: uuid,
        },
      };
      await this.api.topics.deleteTopic(p);
    }
  }

  async onContentEditDone(e: CustomEvent<ContentEditDoneDetail>) {
    if (e.detail.type === 'collections') {
      await this.updateCollection(e.detail);
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    } else {
      const entityUuid = e.detail.uuid === '' ? uuid() : e.detail.uuid;
      const message = updateMessage(getStore().getState(), e.detail);
      let parentId: string | undefined = undefined;
      if (e.detail.type === 'contacts') {
        parentId = e.detail.editItem.partnerId;
      } else if (e.detail.type === 'tasks') {
        parentId = e.detail.editItem.functionUuid;
      }

      const entityUuidWithOutInstanceDate = entityUuid.split(':')[0];
      console.log('saving saveitem');
      await getStore().dispatch(saveItem(this.api, e.detail.type, entityUuidWithOutInstanceDate, message, parentId));
      console.log('saving saveitem done');

      console.log('saving after');
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    }
  }

  async onSearchQueryChanged(e: CustomEvent<{ query: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      this.searchQuery = e.detail.query;
      const o = getStore().getState().organization;
      if (o !== undefined) {
        const hits = await debouncedSearch(
          this.api,
          this.searchQuery,
          String(this.appState.viewModel.currentOrganizationId),
        );
        this.searchResults = mapSearchResultHits(
          this.appState.viewModel.currentOrganizationId,
          hits,
          o.singleUser,
          o,
          this.employeeNamesById,
          this.partnerNamesById,
        );
      }
    }
  }

  onUpdateHelp(e: CustomEvent<{ page: string }>) {
    getStore().dispatch(dabihStore.updateCurrentHelpPage(e.detail.page));
  }

  onUpdateTutorial(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(dabihStore.updateCurrentTutorialId(e.detail.value));
  }

  onUpdateSideContent(e: CustomEvent<{ value: number }>) {
    getStore().dispatch(dabihStore.updateCurrentSideContentTemplateId(e.detail.value));
  }

  async onUpdateEventAsset(
    e: CustomEvent<{
      checkedBy: string;
      checkedTime: string;
      status: string;
      comments: string;
      assetUuid: string;
      eventOccurrenceUuid: string;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (!(e.detail.status === 'NONE' || e.detail.status === 'OK' || e.detail.status === 'NOT_OK')) {
        throw new Error('Illegal state (E343) ' + e.detail.status);
      }

      const p: UpdateEventOccurrenceRelatedAssetRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        eventOccurrenceRelatedAssetUpdateMessage: {
          uuid: e.detail.eventOccurrenceUuid,
          assetStatus: e.detail.status,
          comment: e.detail.comments,
          assetUuid: e.detail.assetUuid,
        },
      };

      await this.api.eventOccurrences.updateEventOccurrenceRelatedAsset(p);
    }
  }

  onOpenPage(e: CustomEvent<{ pageId: number; queryString: string }>) {
    const state = getStore().getState();
    const items = itemsByTypeAndId(state);
    const pages = getPages(state);
    const organization = getOrganization(state);

    let target = '';
    // module meetings has a custom page for both employees and partners
    if (
      (e.detail.pageId === 996526 || e.detail.pageId === 312616) &&
      !getFeatureStates(state).core &&
      getFeatureStates(state).meetings
    ) {
      target = '476666';
    } else if (e.detail.pageId === 14785) {
      target = '65/tutorials/1';
    } else if (e.detail.pageId === 14571) {
      target = '65/tutorials/2';
    } else if (e.detail.pageId === 0) {
      target = '695944?showUserSettings';
    } else if (isPageId(e.detail.pageId, pages)) {
      target = '' + e.detail.pageId;
    }
    console.log('open page target', target);
    if (items && organization) {
      console.log('open page looking for item-data');

      const itemData = getItemDataFromTemplateId('' + e.detail.pageId, items, organization.singleUser);
      let startTask = '';
      if (itemData) {
        console.log('open page found item-data', itemData);
        if (itemData.startTask) {
          startTask = itemData.target;
          target = '695944';
        } else {
          target = itemData.target;
        }
      }
      const url =
        '/account/' +
        organization.organizationId +
        '/' +
        target +
        (e.detail.queryString ? '?' + e.detail.queryString : '');
      console.log('navigate to', url);
      setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent<{ href: string }>('navigate', { bubbles: true, composed: true, detail: { href: url } }),
        );
      }, 200);
      if (startTask) {
        this.dispatchAction(dabihStore.updateSelectedStartTask(startTask));
      }
    }
    if (this.windowWidth < 801) {
      this.dispatchAction(dabihStore.updateHelpViewerOpen(false));
    }
  }

  onShare(e: CustomEvent<{ message: string; recipients: string[] }>) {
    const state = getStore().getState();
    const entityTypeNameDetermined = currentEntityTypeNameDetermined(state) ?? 'siden';
    const entityName = breadCrumbSelectedName(state);
    const currentEmployeeName = currentEmployeeShortName(state);
    const subject = currentEmployeeName + ' har sendt deg en lenke til ' + entityTypeNameDetermined + ' ' + entityName;

    const p = state.path;
    const s = p.split('/');

    const urlForMessage = s.length > 3 ? '/' + s.slice(3).join('/') : '';

    const c = {
      subject: subject,
      message: e.detail.message,
      url: urlForMessage,
      employees: e.detail.recipients,
      messageType: SendMessageCommandMessageTypeEnum.Email,
    };
    getStore().dispatch(dabihStore.sendMessage(this.api, c));
  }

  async onToggleEventDone(e: CustomEvent<EventDoneDetail>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.done) {
        const p: MarkEventOccurrenceDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markEventOccurrenceDoneMessage: {
            uuid: e.detail.uuid,
            doneBy: e.detail.doneBy,
            doneDate: e.detail.doneDate,
          },
        };
        await this.api.eventOccurrences.markEventOccurrenceDone(p);
      } else {
        const p: MarkEventOccurrenceNotDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markEventOccurrenceNotDoneMessage: {
            uuid: e.detail.uuid,
          },
        };
        await this.api.eventOccurrences.markEventOccurrenceNotDone(p);
      }
    }
  }

  async onToggleMeetingDone(e: CustomEvent<EventDoneDetail>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.done) {
        const p: MarkMeetingOccurrenceDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markMeetingOccurrenceDoneMessage: {
            uuid: e.detail.uuid,
            doneBy: e.detail.doneBy,
            doneDate: e.detail.doneDate,
          },
        };
        await this.api.meetingOccurrences.markMeetingOccurrenceDone(p);
      } else {
        const p: MarkMeetingOccurrenceNotDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markMeetingOccurrenceNotDoneMessage: {
            uuid: e.detail.uuid,
          },
        };
        await this.api.meetingOccurrences.markMeetingOccurrenceNotDone(p);
      }
    }
  }

  onShareVacation(e: CustomEvent<ShareVacationResult>) {
    getStore().dispatch(sendShareVacation(this.api, e.detail));
  }

  async onShowShareVacation(e: CustomEvent<{ email: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const result = await this.api.organization.fetchSharedVacationsLink({
        organizationId: this.appState.viewModel.currentOrganizationId,
        email: e.detail.email,
      });
      console.log(result);
      window.open(result.location, 'super');
    }
  }

  onUnShareVacation(e: CustomEvent<{ recipient: string }>) {
    getStore().dispatch(stopShareVacation(this.api, e.detail));
  }

  onStartTaskSelected(e: CustomEvent<{ uuid: string }>) {
    getStore().dispatch(updateSelectedStartTask(e.detail.uuid));
  }

  onStartTaskClosed() {
    getStore().dispatch(updateSelectedStartTask(''));
  }

  onIgnoreRobot() {
    const state = getStore().getState();
    const e = LocalDate.fromString(state.today).plusDays(1);
    getStore().dispatch(
      dabihStore.updateUserProperties(this.api, {
        ignoreRobotUntil: e.toString(),
      }),
    );
  }

  onIgnoreThisRobotAlert(e: CustomEvent<{ id: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const alerts = ignoredRobotAlerts(state);
      const updatedIgnoredRobotAlerts = [...alerts, e.detail.id];
      getStore().dispatch(
        dabihStore.updateUserPropertiesForOrganization(
          this.api,
          { ignoredRobotAlerts: updatedIgnoredRobotAlerts },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onAssignFunction(e: CustomEvent<{ functionUuid: string; employeeUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const item = o.functionsById[e.detail.functionUuid];
        if (item !== undefined) {
          const employees =
            item.type === 'SINGLE' ? [e.detail.employeeUuid] : [...item.employees, e.detail.employeeUuid];
          const updateMessage: FunctionUpdateMessage = {
            name: item.name,
            template: item.template,
            category: item.category,
            rotations: item.rotations.map((r) => ({
              employee: r.employee,
              day1: '' + r.day1,
              day2: '' + r.day2,
              day3: '' + r.day3,
              day4: '' + r.day4,
              day5: '' + r.day5,
              day6: '' + r.day6,
              day7: '' + r.day7,
              startDate: r.startDate,
            })),
            status: item.status,
            rotation: item.rotation,
            description: item.description,
            type: item.type,
            employees: employees,
            responsibility: item.responsibility,
            pages: [...item.pages],
            isConfirmedEntity: true,
          };
          const requestParameters: CreateOrUpdateFunctionRequest = {
            organizationId: '' + o.organizationId,
            functionId: e.detail.functionUuid,
            functionUpdateMessage: updateMessage,
          };

          await this.api.functions.createOrUpdateFunction(requestParameters);
        }
      }
    }
  }

  async onUpdateUser(e: CustomEvent<{ email: string; password?: string; alerts: string; receiveReminders: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const message: AccountUpdateMessage = {
          email: e.detail.email,
          password: e.detail.password,
          organizationIdForSummaries: o.organizationId,
          summaries: e.detail.alerts as AccountUpdateMessageSummariesEnum,
          receiveReminders: e.detail.receiveReminders as AccountUpdateMessageReceiveRemindersEnum,
        };
        await this.api.accounts.updatePassword({
          accountEmail: this.appState.user.username,
          accountUpdateMessage: message,
        });
        if (e.detail.email !== this.appState.user.username) {
          displayAlert('Epost er endret. Vennligst logg inn på nytt.');
          this.onRequestLogout(new CustomEvent('request-logout', { bubbles: true, composed: true }));
        } else if (e.detail.password) {
          const user = {
            username: this.appState.user.username,
            password: e.detail.password,
          };
          await this.onRequestLogin(
            new CustomEvent('request-login', {
              bubbles: true,
              composed: true,
              detail: { user, loginFailed: () => console.error('Login failed from update user') },
            }),
          );
        }
      }
    }
  }

  async onAddFeatures(e: CustomEvent<{ features: string[] }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        for (const feature of e.detail.features) {
          await this.api.organization.addFeature({
            organizationId: '' + o.organizationId,
            addFeatureCommand: {
              feature,
            },
          });
        }
      }
    }
  }

  async onToggleFeature(e: CustomEvent<{ feature: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const f = o.features ?? [];
        if (f.includes(e.detail.feature)) {
          await this.api.organization.removeFeature({
            organizationId: '' + o.organizationId,
            removeFeatureCommand: {
              feature: e.detail.feature,
            },
          });
        } else {
          await this.api.organization.addFeature({
            organizationId: '' + o.organizationId,
            addFeatureCommand: {
              feature: e.detail.feature,
            },
          });
        }
      }
    }
  }

  async onUpdateSpecialTerms(e: CustomEvent<{ specialTerms: UpdateSpecialTermsCommandSpecialTermsEnum }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.organization.updateSpecialTerms({
          organizationId: '' + o.organizationId,
          updateSpecialTermsCommand: {
            specialTerms: e.detail.specialTerms,
          },
        });
      }
    }
  }

  async onUpdateSubscription(e: CustomEvent<Subscription>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const message: OrganizationSettingsUpdateMessage = {
          invoiceAddress: e.detail.invoiceAddress,
          reference: e.detail.invoiceReference,
          invoiceReceiver: e.detail.invoiceReceiver,
          invoiceReference: e.detail.invoiceReference,
          ownerEmail: e.detail.ownerEmail,
          invoicePostcode: e.detail.invoicePostcode,
          invoiceLocality: e.detail.invoiceLocality,
          specialTerms: e.detail.specialTerms as OrganizationSettingsUpdateMessageSpecialTermsEnum,
          invoiceSendMethod: e.detail.invoiceSendMethod as OrganizationSettingsUpdateMessageInvoiceSendMethodEnum,
          singleUser: e.detail.singleUser,
          invoiceOrganizationNumber: e.detail.invoiceOrganizationNumber,
        };
        await this.api.organization.updateOrganizationSettings({
          organizationId: o.organizationId.toString(),
          organizationSettingsUpdateMessage: message,
        });
      }
    }
  }

  onCreateIssue(e: CustomEvent<{ id: string; message: IssueUpdateMessage }>) {
    getStore().dispatch(saveItem(this.api, 'issues', e.detail.id, e.detail.message));
  }

  async onRestore(e: CustomEvent<{ entityType: string; entityId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.organization.restoreDeletedEntity({
          organizationId: o.organizationId.toString(),
          restoreCommand: {
            entityType: e.detail.entityType,
            entityId: e.detail.entityId,
          },
        });
      }
    }
  }

  async onChecklistChanged(e: CustomEvent<{ content: string; uuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.eventOccurrences.updateEventOccurrenceChecklist({
          organizationId: '' + o.organizationId,
          eventOccurrenceId: e.detail.uuid,
          updateChecklistMessage: {
            instance: '',
            content: e.detail.content,
          },
        });
      }
    }
  }

  async onRestoreRevision(
    e: CustomEvent<{ name: string; content: string; status: string; type: string; uuid: string }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.organization.restoreRevision({
          organizationId: '' + o.organizationId,
          restoreRevisionCommand: {
            entityId: e.detail.uuid,
            entityType: e.detail.type,
            name: e.detail.name,
            content: e.detail.content,
            status: e.detail.status,
          },
        });
      }
    }
  }

  async onAddTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const task = o.tasksById[e.detail.taskUuid];
        if (task !== undefined) {
          await this.api.functions.postFunctionTaskConnectAsset({
            organizationId: '' + o.organizationId,
            functionId: task.functionUuid,
            taskId: task.uuid,
            connectAssetCommand: {
              assetUuid: e.detail.assetUuid,
            },
          });
        }
      }
    }
  }

  async onRemoveTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const task = o.tasksById[e.detail.taskUuid];
        if (task !== undefined) {
          await this.api.functions.postFunctionTaskDisconnectAsset({
            organizationId: '' + o.organizationId,
            functionId: task.functionUuid,
            taskId: task.uuid,
            disconnectAssetCommand: {
              assetUuid: e.detail.assetUuid,
            },
          });
        }
      }
    }
  }

  async onSaveDraft(
    e: CustomEvent<{ entityType: string; entityUuid: string; draft: Record<string, unknown>; done: () => void }>,
  ) {
    await this.doSaveDraft(e);
    e.detail.done();
  }

  async onClearDraft(e: CustomEvent<{ entityType: string; entityUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters = {
          organizationId: '' + o.organizationId,
          clearDraftCommand: {
            entityType: e.detail.entityType,
            entityUuid: e.detail.entityUuid,
          },
        };
        await this.api.organization.clearDraft(requestParameters);
      }
    }
  }

  async onCreateOtherEntity(organizationId: number, detail: CreateEntityInput) {
    try {
      const p = newItemLabels.find((x) => x.type === detail.entityType);
      if (p) {
        this.slideNotification({ primaryText: 'Oppretter ' + p.name.toLowerCase() });
      }
      await handleCreateEntity(organizationId, detail, this.api, getStore().dispatch);
      this.edit = true;
      window.history.pushState({}, '', detail.targetUrl);
      await handleNavigation(window.location);
      if (p) {
        this.showSlideNotification = false;
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  async onCreateEntity(e: CustomEvent<CreateEntityInput>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        if (e.detail.entityType === 'collections') {
          const requestParameters: CreateTopicRequest = {
            organizationId: '' + o.organizationId,
            createTopicCommand: {
              uuid: e.detail.entityUuid,
              pages: [e.detail.pageId],
            },
          };
          await this.api.topics.createTopic(requestParameters);
          window.history.pushState({}, '', e.detail.targetUrl);
          await handleNavigation(window.location);
        } else {
          await this.onCreateOtherEntity(o.organizationId, e.detail);
        }
      }
    }
  }

  async onCreateEmployee(e: CustomEvent<CreateEmployeeInput>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters: CreateOrUpdateEmployeeRequest = {
          organizationId: '' + o.organizationId,
          employeeId: e.detail.data.uuid,
          employeeUpdateMessage: {
            isConfirmedEntity: true,
            address: '',
            profession: '',
            notes: '',
            email: e.detail.data.email,
            lastName: e.detail.data.lastName,
            firstName: e.detail.data.firstName,
            herNumber: '',
            hprNumber: '',
            expertise: '',
            accessLevel: e.detail.data.accessLevel as EmployeeUpdateMessageAccessLevelEnum,
            phone: e.detail.data.phone,
            nextOfKin: '',
            secondaryPhone: '',
            status: 'ACTIVE',
            gender: 'UNDEFINED',
          },
        };
        await this.api.employees.createOrUpdateEmployee(requestParameters);
        e.detail.complete();
      }
    }
  }

  async createPartnerForContact(uuid: string, name: string) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters: CreateOrUpdatePartnerRequest = {
          organizationId: '' + o.organizationId,
          partnerId: uuid,
          partnerUpdateMessage: {
            isConfirmedEntity: true,
            address: '',
            notes: '',
            name: name,
          },
        };
        await this.api.partners.createOrUpdatePartner(requestParameters);
      }
    }
  }

  async onCreateContactAndPartner(e: CustomEvent<CreateContactAndPartnerInput>) {
    if (e.detail.data.newPartnerName) {
      await this.createPartnerForContact(e.detail.data.partnerUuid, e.detail.data.newPartnerName);
    }
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters: CreateOrUpdatePartnerContactRequest = {
          organizationId: '' + o.organizationId,
          partnerId: e.detail.data.partnerUuid,
          contactId: e.detail.data.contactUuid,
          contactPersonUpdateMessage: {
            notes: '',
            email: e.detail.data.email,
            lastName: e.detail.data.lastName,
            firstName: e.detail.data.firstName,
            personalIdentifier: '',
            telephone: '',
            mobilePhone: e.detail.data.phone,
            accessLevel: 'NONE',
            accessExpires: '',
            isConfirmedEntity: true,
          },
        };
        await this.api.partners.createOrUpdatePartnerContact(requestParameters);
        e.detail.complete();
      }
    }
  }

  async onDataItemChanged(e: CustomEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await dataItemChanged(
          this.api,
          o,
          e,
          state,
          (s: string) => this.slideNotification({ primaryText: s }),
          o.organizationId.toString(),
        );
      }
    }
  }

  async onComputerNetworkChange(e: CustomEvent<ComputerNetworkChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await computerNetworkChangeHandler(this.api, e, this.appState.viewModel.currentOrganizationId.toString());
    }
  }

  async onDataItemsRemoved(e: CustomEvent<{ uuid: string; dataType: string; category: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await dataItemsRemovedHandler(this.api, e);
    }
  }

  async onLinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    if (this.appState && this.appState.page === 'account') {
      const message = {
        redirect: window.location.origin,
        provider: e.detail.provider,
      };
      const result = await this.api.accounts.startLinkAuthentication({
        linkAuthUpdateMessage: message,
      });
      window.location.href = result.url ?? '';
    }
  }

  async onUnlinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    if (this.appState && this.appState.page === 'account') {
      const message = {
        authDescription:
          e.detail.provider === 'oidc'
            ? this.appState.user.authDescription ?? ''
            : this.appState.user.criiptoAuthDescription ?? '',
        provider: e.detail.provider,
      };
      await this.api.accounts.unlinkAuthentication({
        unlinkAuthMessage: message,
      });
      await this.onRequestLogout(new CustomEvent('request-logout', { bubbles: true, composed: true }));
    }
  }

  async onTutorialStateChanged(e: CustomEvent<TutorialState>) {
    if (
      this.appState !== undefined &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded === true &&
      this.appState.viewModel.currentEmployeeUuid !== undefined
    ) {
      const p2: UpdateTutorialStateForEmployeeRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: this.appState.viewModel.currentEmployeeUuid,
        updateTutorialStateCommand: {
          id: Number.parseInt(e.detail.tutorialId),
          tutorialState: {
            currentPart: e.detail.currentPart,
            partStates: e.detail.partStates,
          },
        },
      };
      await this.api.employees.updateTutorialStateForEmployee(p2);
    }
  }

  async onDisconnectSecureLogin() {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.organization.updateSecureLogin({
        updateSecureLoginRequest: {
          requiresSecureLogin: false,
        },
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
      });
      window.location.href = '/';
    }
  }

  async onConnectSecureLogin() {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.organization.updateSecureLogin({
        updateSecureLoginRequest: {
          requiresSecureLogin: true,
        },
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
      });
      window.location.href = '/';
    }
  }

  async onMapElementChanged(e: CustomEvent<MapElementChangedDetail>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await mapElementChangedHandler(
        this.api,
        e,
        (s: string) => this.slideNotification({ primaryText: s }),
        this.appState.viewModel.currentOrganizationId.toString(),
      );
    }
  }

  async onMapElementDeleted(e: CustomEvent<{ uuid: string; unitType: string }>) {
    await mapElementDeletedHandler(this.api, e);
  }

  async onNewPartner(e: CustomEvent<{ uuid: string; name: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewPartner', e.detail);
      await createPartner(this.api, e.detail.uuid, e.detail.name, '' + this.appState.viewModel.currentOrganizationId);
      this.slideNotification({ primaryText: (e.detail.name ?? '') + ' ble lagt til som samarbeidspartner' });
    }
  }

  async onNewNetwork(e: CustomEvent<{ item: InfosecNetwork }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewNetwork', e.detail);
      const createNetworkRequest: CreateOrUpdateNetworkRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        networkId: e.detail.item.uuid,
        networkUpdateMessage: {
          name: e.detail.item.name,
          connectionType: e.detail.item.connectionType,
          networkType: e.detail.item.type as NetworkUpdateMessageNetworkTypeEnum,
          isConfirmedEntity: true,
        },
      };
      await this.api.computers.createOrUpdateNetwork(createNetworkRequest);
    }
  }

  async onNewComputer(e: CustomEvent<{ uuid: string; name: string; type: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const createComputerRequest: CreateOrUpdateComputerRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        computerId: e.detail.uuid,
        computerUpdateMessage: {
          location: '',
          name: e.detail.name,
          type: e.detail.type,
          networkUuid: '',
          unitType: 'computer',
          serviceProvider: '',
          connectionType: '',
          serviceProviderContract: '',
          systemUpdateOperatorType: 'UNDEFINED',
          systemUpdateOperator: undefined,
          antiVirusOperatorType: 'UNDEFINED',
          antiVirusOperator: undefined,
          antiVirusRequirements: [],
          locked: false,
          restrictedPhysicalAccess: false,
          displayPositionSecure: false,
          elevated: false,
          mobileMedicalDataRequirements: [],
          notes: undefined,
          isConfirmedEntity: true,
        },
      };
      await this.api.computers.createOrUpdateComputer(createComputerRequest);
    }
  }

  async onNewRiskAssessment(e: CustomEvent<{ uuid: string; name: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewRiskAssessment', e.detail);
      await createRiskAssessment(
        this.api,
        e.detail.uuid,
        e.detail.name,
        '' + this.appState.viewModel.currentOrganizationId,
      );
    }
  }

  async onNewEmployee(e: CustomEvent<{ uuid: string; name: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewEmployee', e.detail);
      await createEmployee(this.api, e.detail.uuid, e.detail.name, '' + this.appState.viewModel.currentOrganizationId);
    }
  }

  async onNewContract(e: CustomEvent<{ uuid: string; name: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewContract', e.detail);
      await createContract(this.api, e.detail.uuid, e.detail.name, '' + this.appState.viewModel.currentOrganizationId);
    }
  }

  async onNewAsset(e: CustomEvent<{ uuid: string; name: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onNewAsset', e.detail);
      await createAsset(this.api, e.detail.uuid, e.detail.name, '', '' + this.appState.viewModel.currentOrganizationId);
    }
  }

  async onSendFeedback(e: CustomEvent<{ url: string; message: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.accounts.sendFeedback({
        feedbackMessage: {
          email: this.appState.user.username,
          message: e.detail.message,
          url: e.detail.url,
        },
      });
    }
  }

  async onCreateMeetingReport(e: CustomEvent<{ uuid: string; href: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const reportUuid = uuid();

      const p2: CreateMeetingOccurrenceReportRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        createMeetingOccurrenceReportCommand: {
          uuid: e.detail.uuid,
          reportUuid: reportUuid,
        },
      };
      await this.api.meetingOccurrences.createMeetingOccurrenceReport(p2);

      const s = this.currentPathArray.join('/');
      const targetUrl = s + '/reports/' + reportUuid + '?edit';
      window.history.pushState({}, '', targetUrl);
      await handleNavigation(window.location);
    }
  }

  async onSendMeetingNotice(e: CustomEvent<MessageForSend>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p2: SendMeetingOccurrenceNoticeRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        sendMeetingOccurrenceNoticeCommand: {
          uuid: e.detail.occurrenceUuid,
          message: e.detail.message,
          includeAgenda: e.detail.includeContent,
        },
      };
      await this.api.meetingOccurrences.sendMeetingOccurrenceNotice(p2);
    }
  }

  async onSendMeetingReport(e: CustomEvent<MessageForSend>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p2: SendMeetingOccurrenceReportRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        sendMeetingOccurrenceReportCommand: {
          uuid: e.detail.occurrenceUuid,
          message: e.detail.message,
          includeReport: e.detail.includeContent,
        },
      };
      await this.api.meetingOccurrences.sendMeetingOccurrenceReport(p2);
    }
  }

  async onUpdateUserAccess(e: UpdateUserAccessEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.userType === 'EMPLOYEE') {
        await this.api.employees.updateEmployeeAccess({
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          entityId: e.detail.userUuid,
          updateAccessCommand: { accessLevel: e.detail.accessLevel, email: e.detail.email },
        });
      } else {
        const o = getStore().getState().organization;
        if (o === undefined) {
          throw new Error('Illegal state (E435)');
        }
        const c = o.contactsById[e.detail.userUuid];
        if (c === undefined) {
          throw new Error('Illegal state (E435)');
        }
        await this.api.partners.postUpdateAccessForContact({
          partnerId: c.partnerUuid,
          entityId: e.detail.userUuid,
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          updateAccessCommand: {
            accessLevel: e.detail.accessLevel,
            email: e.detail.email,
            accessExpires: e.detail.accessExpires,
          },
        });
      }
    }
  }

  async onEmployeeGender(e: CustomEvent<{ uuid: string; gender: EmployeeViewModelGenderEnum }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await this.api.employees.updateEmployeeGender({
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: e.detail.uuid,
        updateGenderCommand: {
          gender: e.detail.gender,
        },
      });
    }
  }

  async onTaskDone(e: CustomEvent<{ taskUuid: string }>) {
    await getStore().dispatch(taskDone(this.api, e.detail.taskUuid));
    getStore().dispatch(updateSelectedStartTask(''));
  }

  async onExternalConnectionChange(e: CustomEvent<NetworkExternalConnectionChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await externalConnectChangeHandler(this.api, e, '' + this.appState.viewModel.currentOrganizationId);
    }
  }

  /**
   * For dev purposes
   */
  getRelativeUrl(url: string): string {
    const urlObj = new URL(url);
    return window.location.origin + urlObj.pathname + urlObj.search;
  }

  async onCheckEmail(e: CustomEvent<{ email: string; callback: (exists: boolean) => void }>) {
    const r = await checkEmail(e.detail.email);
    console.log('onCheckEmail', r);
    e.detail.callback(r.message === 'exists');
  }

  async onCreateUser(e: CustomEvent<{ email: string; module: string; serviceType: string }>) {
    console.log('creating user account for ' + e.detail.email + ' with ' + e.detail.module);

    const r = await createUser({
      createUserCommand: {
        email: e.detail.email,
        module: e.detail.module,
        serviceType: e.detail.serviceType,
      },
    });
    const target = this.getRelativeUrl(r.target);
    console.log('received response ' + target);
    setTimeout(() => {
      window.location.href = target;
    }, 400);
  }

  async onCreateOrganization(e: CustomEvent<NewOrganization>) {
    console.log('creating account for ' + e.detail.number + ' with ' + e.detail.plan + '/' + e.detail.module);
    const accounts = this.api.accounts;
    const r = await accounts.createOrganization({
      createOrganizationInput: {
        number: e.detail.number,
        name: e.detail.name,
        ownerEmail: e.detail.ownerEmail,
        addons: e.detail.addons,
        ownerFirstName: e.detail.ownerFirstName,
        ownerLastName: e.detail.ownerLastName,
        address: e.detail.address,
        postcode: e.detail.postcode,
        locality: e.detail.locality,
        module: e.detail.module.toUpperCase(),
        plan: e.detail.plan,
        employeesCount: e.detail.employeesCount,
        specialTerms: e.detail.specialTerms,
        sector: e.detail.sector,
      },
    });
    const a = r.target.split('/');
    const payload = a[5];
    const str = payload.replace(/-/g, '+').replace(/_/g, '/');
    const payloadObject = JSON.parse(atob(str));
    const id = payloadObject.targetUrl.split('/')[2];
    const value = e.detail.plan === 'X' ? 1600 : 7500;
    const target = this.getRelativeUrl(r.target);
    const searchParams = new URLSearchParams();

    searchParams.append('t', target);
    searchParams.append('p', e.detail.plan + '_' + e.detail.module.toUpperCase() + '_' + e.detail.specialTerms);
    searchParams.append('id', id);
    searchParams.append('v', value.toString());

    const baseUrl = new URL('https://trinnvis.no/thankyou.html');

    // Attach search parameters to the base URL
    baseUrl.search = searchParams.toString();

    window.location.href = baseUrl.toString();
  }

  async onAccessChange(e: CustomEvent<AccessChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await accessChangeHandler(this.api, e, '' + this.appState.viewModel.currentOrganizationId);
    }
  }

  async onSaveWorkday(
    e: CustomEvent<{
      value: EditException;
      employeeUuid: string;
      sameAsScheduled: boolean;
      day: string;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.sameAsScheduled) {
        await this.api.employees.deleteEmployeeWorkScheduleException({
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          entityId: e.detail.employeeUuid,
          date: e.detail.day,
        });
      } else {
        const exception: WorkScheduleException = {
          date: e.detail.day,
          work: e.detail.value.type === 'work',
          start: e.detail.value.start,
          end: e.detail.value.end,
        };
        await this.api.employees.createOrUpdateEmployeeWorkScheduleException({
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          entityId: e.detail.employeeUuid,
          date: exception.date,
          workScheduleExceptionUpdateMessage: {
            workScheduleException: exception,
          },
        });
      }
    }
  }

  async onBackupChange(e: CustomEvent<BackupChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await backupChangeHandler(this.api, e);
    }
  }

  onRenameAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      getStore().dispatch(
        renameAttachment(
          this.api,
          e.detail.document.entityType,
          e.detail.document.uuid,
          e.detail.document.attachmentId,
          e.detail.document.name,
        ),
      );
    }
  }

  onDeleteAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      getStore().dispatch(
        deleteAttachment(
          this.api,
          e.detail.document.entityType,
          e.detail.document.uuid,
          e.detail.document.attachmentId,
        ),
      );
    }
  }

  async onCancelSigningOrder(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signingOrderId: string;
    }>,
  ) {
    const o = getStore().getState().organization;
    if (o !== undefined) {
      await this.api.organization.cancelSignatures({
        organizationId: '' + o.organizationId,
        cancelSignaturesCommand: {
          entityType: e.detail.entityType,
          entityUuid: e.detail.entityUuid,
          signatureOrderId: e.detail.signingOrderId,
        },
      });
    }
  }

  async onCreateSignaturePerson(
    e: CustomEvent<{
      uuid: string;
      firstName: string;
      lastName: string;
      email?: string;
      partnerUuid: string;
      personalIdentifier?: string;
    }>,
  ) {
    const o = getStore().getState().organization;
    if (o !== undefined) {
      await this.api.organization.createSignaturePerson({
        organizationId: '' + o.organizationId,
        createSignaturePersonCommand: {
          uuid: e.detail.uuid,
          firstName: e.detail.firstName,
          lastName: e.detail.lastName,
          email: e.detail.email,
          partnerUuid: e.detail.partnerUuid,
          personalIdentifier: e.detail.personalIdentifier,
        },
      });
    }
  }

  async onUpdateSignaturePerson(
    e: CustomEvent<{
      uuid: string;
      email?: string;
      personalIdentifier?: string;
    }>,
  ) {
    const o = getStore().getState().organization;
    if (o !== undefined) {
      await this.api.organization.updateSignaturePerson({
        organizationId: '' + o.organizationId,
        updateSignaturePersonCommand: {
          uuid: e.detail.uuid,
          email: e.detail.email,
          personalIdentifier: e.detail.personalIdentifier,
        },
      });
    }
  }

  async onExtendSigningOrder(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signingOrderId: string;
      dueDate: string;
    }>,
  ) {
    console.log('EXTEND', e.detail);
    const o = getStore().getState().organization;
    if (o !== undefined) {
      await this.api.organization.extendSignatures({
        organizationId: '' + o.organizationId,
        extendSignaturesCommand: {
          entityType: e.detail.entityType,
          entityUuid: e.detail.entityUuid,
          signatureOrderId: e.detail.signingOrderId,
          dueDate: e.detail.dueDate,
        },
      });
    }
  }

  async onRequestSignatures(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      signeesForOrganization: string[];
      signeesForPartners: string[];
      signeesAsEmployees: string[];
      content: ContentItem[];
      message: string;
      dueDate: LocalDate;
    }>,
  ) {
    const o = getStore().getState().organization;
    if (o !== undefined) {
      const signatories: RequestSignaturesSignatory[] = [
        ...e.detail.signeesForOrganization.map((x): RequestSignaturesSignatory => {
          return {
            uuid: x,
            role: 'ORGANIZATION',
          };
        }),
        ...e.detail.signeesForPartners.map((x): RequestSignaturesSignatory => {
          return {
            uuid: x,
            role: 'PARTNER',
          };
        }),
        ...e.detail.signeesAsEmployees.map((x): RequestSignaturesSignatory => {
          return {
            uuid: x,
            role: 'EMPLOYEE',
          };
        }),
      ];

      const documents = e.detail.content.map((x) => {
        if (x.type === 'textContent') {
          return e.detail.entityUuid;
        } else {
          return x.value;
        }
      });

      await this.api.organization.requestSignatures({
        organizationId: '' + o.organizationId,
        requestSignaturesCommand: {
          entityType: e.detail.entityType,
          entityUuid: e.detail.entityUuid,
          message: e.detail.message,
          signatories: signatories,
          dueDate: e.detail.dueDate.toString(),
          documents: documents,
        },
      });
    }
  }

  private async doSaveDraft(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      draft: Record<string, unknown>;
      done: () => void;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters = {
          organizationId: '' + o.organizationId,
          saveDraftCommand: {
            entityType: e.detail.entityType === 'contacts' ? 'contactPersons' : e.detail.entityType,
            entityUuid: e.detail.entityUuid,
            draft: e.detail.draft,
          },
        };
        if (!isEqual(this.lastDraftSaved, requestParameters)) {
          this.lastDraftSaved = requestParameters;
          await this.api.organization.saveDraft(requestParameters);
        }
      }
    }
  }

  private async updateAppState(state: State) {
    this.currentPathArray = location.pathname.split('/');
    const page = this.currentPathArray[1] ?? '';
    const usernameFromLocalStorage = localStorage.getItem('dabih-username');
    console.log('User: ' + usernameFromLocalStorage + ' at page ' + page, location.href, location.pathname);
    if (usernameFromLocalStorage !== null) {
      console.log('has user ' + usernameFromLocalStorage);
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else if (page === 'link') {
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else if (page === 'signup') {
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else if (page === 'callback') {
      this.appState = {
        page: 'loading',
      };
    } else {
      if (page !== 'signin') {
        this.dispatchEvent(
          new CustomEvent('navigate', {
            composed: true,
            bubbles: true,
            detail: { href: '/signin' },
          }),
        );
      }
      this.appState = {
        page: 'signin',
      };
    }
  }

  private async appStateForPage(page: string, state: State): Promise<AppState> {
    switch (page) {
      case 'link':
        return this.appStateForLink();
      case 'account':
        return this.appStateForAccount(state);
      case 'p':
        return this.appStateForPageRedirect();
      case 'a':
        return this.appStateForEntityRedirect();
      case '':
        return this.appStateForIndex(state);
      case 'index.html':
        return this.appStateForIndexHtml();
      case 'signin':
        return this.appStateForSignin();
      case 'signup':
        return this.appStateForSignup(state);
      default:
        return this.appStatePageNotFound(page);
    }
  }

  private startListeningToChannels(ablyClient: Types.RealtimePromise, organizationId: number, suffix: string) {
    const hex = '214c9a797bd5f4ae5a82ec14e28445863829fe8636fab137e1c9c4b779f30257';
    const regExpMatchArray = hex.match(/[\da-f]{2}/gi);
    if (regExpMatchArray === null) {
      throw new Error('Illegal state (E27), cipher params wrong format');
    }
    const numbers = regExpMatchArray.map((h) => parseInt(h, 16));

    const typedArrayKey = new Uint8Array(numbers);

    const cipherParams = {
      key: typedArrayKey,
    };

    const channelOpts = { cipher: cipherParams };

    const channel = ablyClient.channels.get('organization' + suffix + '-' + organizationId, channelOpts);
    channel.on('attached', (stateChange) => {
      console.log('channel ' + channel.name + ' is now attached');
      console.log('Message continuity on this channel ' + (stateChange.resumed ? 'was' : 'was not') + ' preserved');
      if (!stateChange.resumed) {
        // location.reload();
      }
    });

    channel.subscribe((message) => {
      const m = JSON.parse(message.data);
      console.log('message', m.path, m.eventType, m.item, m.hidden);

      if (!m.path.endsWith('/archive')) {
        getStore().dispatch(updateItem(this.api, m));
      }
      if (m.path === '/organizations/' + organizationId) {
        fetchPageGroups(this.api, organizationId).then((pages) => getStore().dispatch(loadedPageGroups(pages)));
      }
    });

    const myListener = (stateChange) => {
      console.log('channel state ', stateChange);
      console.log('channel state is ' + stateChange.current);
      console.log('previous state was ' + stateChange.previous);
      if (stateChange.reason) {
        console.log('the reason for the state change was: ' + stateChange.reason.toString());
      }
    };
    channel.on(myListener);
  }

  private createAblyClient(lastToken: Types.TokenRequest | undefined, user: User) {
    const ablyClient = new Ably.Realtime.Promise({
      authCallback: (_params, callback) => {
        console.log('authCallback');
        if (lastToken) {
          callback('', lastToken);
          lastToken = undefined;
        } else {
          this.api.accounts
            .listOrganizations({
              accountEmail: user.username,
            })
            .then((r) => {
              console.warn('Auth callback', r);
              if (r.ablyTokenRequest !== undefined) {
                callback('', JSON.parse(r.ablyTokenRequest));
              }
            });
        }
      },
    });

    ablyClient.connection.on('connected', (stateChange) => {
      console.log('# successful connection', stateChange);
      this.online = true;
    });

    ablyClient.connection.on('failed', (stateChange) => {
      console.log('# failed connection', stateChange);
      this.online = false;
    });
    ablyClient.connection.on('disconnected', (stateChange) => {
      console.log('# disconnected connection', stateChange, stateChange.reason);
      this.online = false;
    });
    ablyClient.connection.on('suspended', (stateChange) => {
      console.log('# suspended connection', stateChange);
      this.online = false;
    });
    return ablyClient;
  }

  private checkForPageRedirect() {
    const t = sessionStorage.getItem('targetPageId');
    if (t !== null) {
      const n = t;
      sessionStorage.removeItem('targetPageId');
      this.onOpenPage(
        new CustomEvent('open-page', {
          composed: true,
          bubbles: true,
          detail: { pageId: Number(n), queryString: '' },
        }),
      );
    } else if (sessionStorage.getItem('targetUrl') !== null) {
      const targetUrl = sessionStorage.getItem('targetUrl');
      sessionStorage.removeItem('targetUrl');
      this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: targetUrl } }));
    }
  }

  private appStateForSignin(): AppStateSignin | AppStateLoading {
    console.log('appStateForSignin');
    if (localStorage.getItem('dabih-username')) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          composed: true,
          bubbles: true,
          detail: { href: '/account' },
        }),
      );
      return { page: 'loading' };
    }
    return { page: 'signin' };
  }

  private async appStateForSignup(state: State): Promise<AppStateSignup> {
    const user: User | undefined = state.user;
    let userForSignup: UserForSignup | undefined = undefined;
    if (user) {
      const featuresMap = {
        MEETINGS: 'meetings',
        MINI: 'complete',
        BASIC: 'complete',
        EXTRA: 'complete',
      };
      userForSignup = {
        email: user.username,
        firstName: user['firstName'] === undefined ? '' : user['firstName'],
        lastName: user['lastName'] === undefined ? '' : user['lastName'],
        organizations: user.organizations.map((o) => {
          return {
            id: o.id,
            name: o.name,
            modules: o.features.map((e) => featuresMap[e] ?? ''),
          };
        }),
      };
    }

    return { page: 'signup', currentUser: userForSignup };
  }

  private appStateForLink(): AppStateLink {
    console.log('appStateForIndex');
    return { page: 'link', locationSearch: window.location.search };
  }

  private async appStateForAccount(
    state: State,
  ): Promise<AppStateNoUser | AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    console.log('appStateForAccount');
    const user = state.user;
    if (user === undefined) {
      return {
        page: 'noUser',
      };
    } else {
      return await this.appStateForUser(user, state);
    }
  }

  private async appStateForUser(
    user: User,
    state: State,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    const segments = this.currentPathArray;
    console.log(segments);
    if (segments.length > 2 && segments[2] !== '') {
      const id = Number(segments[2]);
      const userOrganization = user.organizations.find((o) => o.id === id);
      return await this.appStateForUserWithOrganization(userOrganization, user, state, segments, id);
    } else {
      console.log('no organization');
      return {
        page: 'account',
        user: user,
        helpViewerOpen: state.helpViewerOpen,
        helpViewerPage: state.currentHelpPage,
        writeAccess: false,
      };
    }
  }

  private async appStateForUserWithOrganization(
    userOrganization: OrganizationReference | undefined,
    user: User,
    state: State,
    segments: string[],
    id: number,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    if (userOrganization && userOrganization.requiresSecureLogin && user.provider === '') {
      return { page: 'secured', user: user, selectedUserOrganization: userOrganization };
    } else if (state.loading) {
      return { page: 'loading' };
    } else if (segments[3] === '2300') {
      setTimeout(async () => {
        window.history.pushState({}, '', '/account/' + segments[2] + '/695944');
        await handleNavigation(window.location);
      }, 200);
      return { page: 'loading' };
    } else {
      return await this.appStateForOrganizationAccess(state, segments, userOrganization, user, id);
    }
  }

  private async appStateForOrganizationAccess(
    state: State,
    segments: string[],
    userOrganization: OrganizationReference | undefined,
    user: User,
    id: number,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound> {
    if (state.error) {
      return {
        page: 'notFound',
        requestedPage: segments[2],
      };
    } else {
      if (userOrganization !== undefined && userOrganization.pending) {
        return {
          page: 'account',
          user: user,
          helpViewerOpen: state.helpViewerOpen,
          helpViewerPage: state.currentHelpPage,
          writeAccess: writeAccess(state),
        };
      }

      const o = getStore().getState().organization;
      if (o === undefined || o.organizationId !== id) {
        console.log('Loading organization ' + id);
        // Sjekk at riktig organisasjon er lastet inn
        getStore().dispatch(loadOrganization(id, user, this.api));
        this.subscribeToAbly(user, id);
      }
      this.employeeNamesById = employeeNamesById(state);
      this.partnerNamesById = partnerNamesById(state);

      if (!state.loaded) {
        return {
          page: 'loading',
        };
      }

      this.checkForPageRedirect();

      return {
        page: 'account',
        user: user,
        helpViewerOpen: state.helpViewerOpen,
        helpViewerPage: state.currentHelpPage,
        viewModel: await applicationViewModel(this.api, state),
        writeAccess: writeAccess(state),
      };
    }
  }

  private appStatePageNotFound(page: string): AppStateNotFound {
    console.log('appStatePageNotFound');
    return { page: 'notFound', requestedPage: page };
  }

  private appStateForPageRedirect(): AppStateLoading {
    console.log('appStateForPageRedirect');
    const segments = this.currentPathArray;

    sessionStorage.setItem('targetPageId', segments[2]);

    const o = localStorage.getItem('lastOrganizationId');
    const target1 = o === null ? '' : '/' + o;

    this.dispatchEvent(
      new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/account' + target1 } }),
    );

    return { page: 'loading' };
  }

  private appStateForIndexHtml(): AppStateLoading {
    console.log('appStateForIndexHtml');

    this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/' } }));

    return { page: 'loading' };
  }

  private appStateForEntityRedirect(): AppStateLoading {
    console.log('appStateForPageRedirect');
    const segments = this.currentPathArray;
    const o = segments[2];
    const target1 = o === null ? '' : '/' + o;

    this.dispatchEvent(
      new CustomEvent('navigate', {
        composed: true,
        bubbles: true,
        detail: { href: '/account' + target1 + '/20?tab=map&c=' + segments[3] },
      }),
    );

    return { page: 'loading' };
  }

  private appStateForIndex(_state: State): AppStateLoading {
    console.log('appStateForIndex');
    if (localStorage.getItem('dabih-username')) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          composed: true,
          bubbles: true,
          detail: { href: '/account' },
        }),
      );
    } else {
      this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/signin' } }));
    }
    return { page: 'loading' };
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-app': DApp;
  }
}
