import { css, html, LitElement } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../fields/d-expansion.js';
import '../fields/d-closer.js';
import '../fields/d-view-info.js';
import { SigningOrderListItem } from 'src/library/lists/d-list-section-signing-orders';
import { LocalDate } from 'src/utilities/local-date';
import {
  FileViewerDialog,
  FileViewerDocument,
  FileViewerInput,
  FileViewerResult,
} from 'src/layout/parts/file-viewer-dialog';
import { assertIsDefined } from 'src/lib';
import {
  SignableDocumentDialog,
  SignableDocumentDialogInput,
  SignableDocumentDialogResult,
} from 'src/layout/parts/signable-document-dialog';
import {
  PartnerForSigning,
  PersonForSigning,
  RequestSignaturesDialog,
  RequestSignaturesInput,
  RequestSignaturesResult,
} from 'src/layout/parts/request-signatures-dialog';
import { isEmptyHtmlContent } from 'src/utilities/text';
import { sortBy } from 'lodash';
import '../../library/lists/d-list-section-signing-orders';
import type { AttachmentItem } from 'src/library/lists/d-list-section-attachment';
import { SigningOrder } from 'src/store/api';

export interface SigningOrderWithAsDocument extends SigningOrder {
  asDocumentItem: () => Promise<FileViewerDocument>;
}

export interface SignableEntity {
  hasSigningModule: boolean;
  signingOrders: SigningOrderWithAsDocument[];
  availableEmployees: PersonForSigning[];
  availableContacts: PersonForSigning[];
  availablePartners: PartnerForSigning[];
  currentUserHasWriteAccess: boolean;
  uuid: string;
  organizationName: string;
  activeEmployeesCount: number;
  currentUserIsOwner: boolean;
  name: string;
  content: string;
  attachments: AttachmentItem[];
  partners: string[];
  employees: string[];
  contacts: string[];
  type: 'contracts' | 'reports';
}

/**
 *
 * The help section closes whenever the content changes, ie in connection with navigating to a different page.
 *
 * STATUS OK
 */
@customElement('d-signatures-section')
export class DSignaturesSection extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
    }
  `;

  @property({ type: Object })
  entityView!: SignableEntity;
  @property({ type: Boolean })
  signingDisabled = false;

  private get signingOrdersListItems(): SigningOrderListItem[] {
    return this.entityView.signingOrders.map((s) => {
      const signedBy = sortBy(
        (s.signatories || [])
          .filter((e) => e.signatoryStatus === 'SIGNED')
          .map((e) => {
            const employee = this.entityView.availableEmployees.find((v) => v.uuid === e.uuid);
            if (employee) {
              return employee.firstName + ' ' + employee.lastName;
            }
            const contact = this.entityView.availablePartners.flatMap((x) => x.contacts).find((v) => v.uuid === e.uuid);
            if (contact) {
              const p = this.entityView.availablePartners.find((x) => x.uuid === contact.partnerUuid);
              console.log(contact, this.entityView.availablePartners);
              return contact.firstName + ' ' + contact.lastName + ' (' + p?.name + ')';
            }
            const contact2 = this.entityView.availableContacts.find((v) => v.uuid === e.uuid);
            if (contact2) {
              return contact2.firstName + ' ' + contact2.lastName;
            }
            return '-';
          }),
      );
      const waitingFor = sortBy(
        (s.signatories || [])
          .filter((e) => e.signatoryStatus !== 'SIGNED')
          .map((e) => {
            const employee = this.entityView.availableEmployees.find((v) => v.uuid === e.uuid);
            if (employee) {
              return employee.firstName + ' ' + employee.lastName;
            }
            const contact = this.entityView.availablePartners.flatMap((x) => x.contacts).find((v) => v.uuid === e.uuid);
            if (contact) {
              const p = this.entityView.availablePartners.find((x) => x.uuid === contact.partnerUuid);
              console.log(contact, this.entityView.availablePartners);
              return contact.firstName + ' ' + contact.lastName + ' (' + p?.name + ')';
            }
            const contact2 = this.entityView.availableContacts.find((v) => v.uuid === e.uuid);
            if (contact2) {
              return contact2.firstName + ' ' + contact2.lastName;
            }
            return '-';
          }),
      );
      const l: string[] = [];
      if (signedBy.length > 0) {
        l.push('signert av ' + signedBy.join(', '));
      }
      if (waitingFor.length > 0) {
        l.push('venter på ' + waitingFor.join(', '));
      }
      let status: string = '';
      if (s.signingOrderStatus === 'OPEN') {
        status = 'Opprettet ' + LocalDate.fromString(s.createdAt).toStringForDisplay();
      }
      if (s.signingOrderStatus === 'CLOSED') {
        status = 'Signert ' + LocalDate.fromString(s.closedAt).toStringForDisplay();
      }
      if (s.signingOrderStatus === 'CANCELLED') {
        status = 'Kansellert ' + LocalDate.fromString(s.closedAt).toStringForDisplay();
      }
      if (s.signingOrderStatus === 'EXPIRED') {
        status = 'Utløpt ' + LocalDate.fromString(s.closedAt).toStringForDisplay();
      }
      return {
        label: status,
        secondaryLabel: l.join(', '),
        status: s.signingOrderStatus,
        asDocumentItem: s.asDocumentItem,
        uuid: s.uuid,
      };
    });
  }

  async onSigningItemClick(item: SigningOrderListItem) {
    if (item.status === 'XXCLOSED') {
      console.log('onSigningItemClick item', item);
      const input: FileViewerInput = {
        document: await item.asDocumentItem(),
        writeAccess: this.entityView.currentUserHasWriteAccess,
      };
      const result: FileViewerResult = await FileViewerDialog.open(input);
      console.error(result);
    } else if (item.status == 'OPEN' || item.status === 'CLOSED') {
      const signingOrder = this.entityView.signingOrders.find((x) => x.uuid === item.uuid);
      assertIsDefined(signingOrder);

      let previewHref = '';
      if (signingOrder.signingOrderStatus === 'CLOSED') {
        const documentItem = await item.asDocumentItem();
        previewHref = documentItem.url;
      }
      const input: SignableDocumentDialogInput = {
        status: item.status,
        requestDate: signingOrder.createdAt,
        dueDate: signingOrder.dueDate,
        previewHref: previewHref,
        asDocumentItem: async () => await item.asDocumentItem(),
        signees: signingOrder.signatories.map((s) => {
          const e = this.entityView.availableEmployees.find((x) => x.uuid === s.uuid);
          const p = this.entityView.availablePartners.flatMap((x) => x.contacts).find((x) => x.uuid === s.uuid);
          const p2 = this.entityView.availableContacts.find((x) => x.uuid === s.uuid);

          const personForSigning = e ?? p ?? p2;

          assertIsDefined(personForSigning);

          const partnerName = this.entityView.availablePartners.find(
            (u) => u.uuid === personForSigning.partnerUuid,
          )?.name;

          return {
            status: s.signatoryStatus,
            label: personForSigning.firstName + ' ' + personForSigning.lastName,
            secondaryLabel: partnerName !== undefined ? 'for ' + partnerName : '',
            rightLabel: s.signatoryStatus === 'SIGNED' ? 'signert' : 'venter',
          };
        }),
      };
      const result: SignableDocumentDialogResult = await SignableDocumentDialog.open(input);
      if (result.action === 'cancel-signing') {
        this.dispatchEvent(
          new CustomEvent<{
            entityType: string;
            entityUuid: string;
            signingOrderId: string;
          }>('cancel-signing-order', {
            bubbles: true,
            composed: true,
            detail: {
              entityType: this.entityView.type,
              entityUuid: this.entityView.uuid,
              signingOrderId: signingOrder.signatureOrderId,
            },
          }),
        );
      } else if (result.action === 'extend-signing') {
        this.dispatchEvent(
          new CustomEvent<{
            entityType: string;
            entityUuid: string;
            signingOrderId: string;
            dueDate: string;
          }>('extend-signing-order', {
            bubbles: true,
            composed: true,
            detail: {
              entityType: this.entityView.type,
              entityUuid: this.entityView.uuid,
              signingOrderId: signingOrder.signatureOrderId,
              dueDate: result.dueDate,
            },
          }),
        );
      }
    }
  }

  async requestSignatures() {
    const input: RequestSignaturesInput = {
      organizationName: this.entityView.organizationName,
      hasSigningModule: this.entityView.hasSigningModule,
      currentUserIsOwner: this.entityView.currentUserIsOwner,
      activeEmployeesCount: this.entityView.activeEmployeesCount,
      entityTypeName: this.entityView.type === 'contracts' ? 'Avtale' : 'Referat',
      entityName: this.entityView.name,
      hasTextContent: !isEmptyHtmlContent(this.entityView.content),
      attachments: this.entityView.attachments.map((a) => ({ text: a.name, value: a.uuid })),
      availableSigneesForOrganization: this.entityView.availableEmployees,
      contractPartyPartners: this.entityView.availablePartners
        .filter((p) => this.entityView.partners.includes(p.uuid))
        .map((p) => {
          return p;
        }),
      contractPartyEmployees: this.entityView.availableEmployees.filter((e) =>
        this.entityView.employees.includes(e.uuid),
      ),
      showOrganization: this.entityView.type === 'contracts',
      contractPartyContacts: this.entityView.availableContacts.filter((e) => this.entityView.contacts.includes(e.uuid)),
    };
    const result: RequestSignaturesResult = await RequestSignaturesDialog.open(input);
    if (result.action === 'send') {
      this.dispatchEvent(
        new CustomEvent('request-signatures', {
          bubbles: true,
          composed: true,
          detail: {
            entityType: this.entityView.type,
            entityUuid: this.entityView.uuid,
            content: result.content,
            dueDate: result.dueDate,
            signeesForOrganization: result.signeesForOrganization,
            signeesForPartners: result.signeesForPartners.map((x) => x.uuid),
            signeesAsEmployees: result.signeesAsEmployees,
          },
        }),
      );
    }
  }

  render() {
    return html`<d-list-section-signing-orders
      .items=${this.signingOrdersListItems}
      .signingDisabled=${this.signingDisabled}
      @request-signatures=${() => this.requestSignatures()}
      @signing-item-click=${async (e) => await this.onSigningItemClick(e.detail.value)}
    ></d-list-section-signing-orders>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signatures-section': DSignaturesSection;
  }
}
