import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../components/d-popup.js';
import '../fields/d-view-info.js';

/**
 *
 * Shows a '?' that displays the tooltip when clicked. If the content of the tooltip is a link to a help page, then
 * the help viewer is opened on that page.
 *
 * STATUS OK
 */
@customElement('d-tooltip')
export class DTooltip extends LitElement {
  static readonly styles = css`
    :host {
      position: relative;
      display: inline-block;
      text-align: left;
    }

    .trigger {
      display: inline-block;
      position: relative;
      top: -3px;
      width: 16px;
      height: 16px;
      margin-left: -4px;
      color: var(--themeColor);
      font-size: 15px;
      line-height: inherit;
      font-weight: 800;
      text-align: center;
      cursor: pointer;
      mix-blend-mode: multiply;
    }

    .trigger:hover,
    .triggern:active {
      color: black;
    }
  `;
  @property({ type: String })
  content = '';
  @property({ type: Boolean })
  open = false;

  helpClick(event) {
    event.preventDefault();
    const matches = RegExp(/(href="https:\/\/trinnvis\.no\/hjelp\/)(.*?)(\/")/).exec(this.content);
    if (matches) {
      const helpPage = matches[2];
      this.dispatchEvent(
        new CustomEvent<{ page: string }>('update-help', { bubbles: true, composed: true, detail: { page: helpPage } }),
      );
    }
  }

  docClick(event) {
    event.preventDefault();
    const matches = RegExp(/(data-internal-link-id=")(.*?)(")/).exec(this.content);
    if (matches) {
      const templateId = Number(matches[2]);
      this.dispatchEvent(
        new CustomEvent<{ value: number }>('update-side-content', {
          bubbles: true,
          composed: true,
          detail: { value: templateId },
        }),
      );
      this.dispatchEvent(
        new CustomEvent<{ value: boolean }>('toggle-side-content', {
          bubbles: true,
          composed: true,
          detail: { value: true },
        }),
      );
    }
  }

  _close(e) {
    e.preventDefault();
    if (e.target.id === 'closer' || e.target.id === 'cover') {
      this.open = false;
    }
  }

  renderTrigger() {
    if (this.isHelpLink) {
      return html`<a class="trigger" @click=${(e) => this.helpClick(e)}>?</a>`;
    } else if (this.isDocLink) {
      return html`<a class="trigger" @click=${(e) => this.docClick(e)}>?</a>`;
    }
    return html`<span class="trigger" @click="${this.onOpen}">?</span>`;
  }

  render() {
    return html`
      ${this.renderTrigger()}
      <d-popup
        .show=${this.open}
        @close=${(e) => {
          e.stopPropagation();
          this.open = false;
        }}
      >
        <d-view-info .content=${this.content} @update-help=${() => (this.open = false)}></d-view-info>
      </d-popup>
    `;
  }

  private get isHelpLink(): boolean {
    return this.content?.startsWith('<p><a href="https://trinnvis.no/hjelp') === true;
  }

  private get isDocLink(): boolean {
    return this.content?.startsWith('<p><a data-internal-link') === true;
  }

  private onOpen(event) {
    event.preventDefault();
    this.open = true;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-tooltip': DTooltip;
  }
}
