/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopicItemViewModel } from './TopicItemViewModel';
import {
  TopicItemViewModelFromJSON,
  TopicItemViewModelFromJSONTyped,
  TopicItemViewModelToJSON,
} from './TopicItemViewModel';
import type { TopicTemplate } from './TopicTemplate';
import { TopicTemplateFromJSON, TopicTemplateFromJSONTyped, TopicTemplateToJSON } from './TopicTemplate';

/**
 *
 * @export
 * @interface TopicViewModel
 */
export interface TopicViewModel {
  /**
   *
   * @type {string}
   * @memberof TopicViewModel
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof TopicViewModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TopicViewModel
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof TopicViewModel
   */
  hasDraft: boolean;
  /**
   *
   * @type {string}
   * @memberof TopicViewModel
   */
  draftName?: string;
  /**
   *
   * @type {boolean}
   * @memberof TopicViewModel
   */
  isConfirmedEntity: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TopicViewModel
   */
  deleted: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof TopicViewModel
   */
  pages?: Array<number>;
  /**
   *
   * @type {Array<TopicItemViewModel>}
   * @memberof TopicViewModel
   */
  items: Array<TopicItemViewModel>;
  /**
   *
   * @type {TopicTemplate}
   * @memberof TopicViewModel
   */
  template?: TopicTemplate;
}

/**
 * Check if a given object implements the TopicViewModel interface.
 */
export function instanceOfTopicViewModel(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'hasDraft' in value;
  isInstance = isInstance && 'isConfirmedEntity' in value;
  isInstance = isInstance && 'deleted' in value;
  isInstance = isInstance && 'items' in value;

  return isInstance;
}

export function TopicViewModelFromJSON(json: any): TopicViewModel {
  return TopicViewModelFromJSONTyped(json, false);
}

export function TopicViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicViewModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    hasDraft: json['hasDraft'],
    draftName: !exists(json, 'draftName') ? undefined : json['draftName'],
    isConfirmedEntity: json['isConfirmedEntity'],
    deleted: json['deleted'],
    pages: !exists(json, 'pages') ? undefined : json['pages'],
    items: (json['items'] as Array<any>).map(TopicItemViewModelFromJSON),
    template: !exists(json, 'template') ? undefined : TopicTemplateFromJSON(json['template']),
  };
}

export function TopicViewModelToJSON(value?: TopicViewModel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
    description: value.description,
    hasDraft: value.hasDraft,
    draftName: value.draftName,
    isConfirmedEntity: value.isConfirmedEntity,
    deleted: value.deleted,
    pages: value.pages,
    items: (value.items as Array<any>).map(TopicItemViewModelToJSON),
    template: TopicTemplateToJSON(value.template),
  };
}
