import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { ResponsiveElement } from 'src/library/elements/responsive-container.js';

import helpPages from '../../store/data/help-pages.json';
import '../d-help-viewer';
import '../d-side-content';
import '../d-tutorial-viewer';
import '../../library/elements/d-action';
import type { TutorialContent } from 'src/outskirts/d-tutorial-viewer';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import { classMap } from 'lit/directives/class-map.js';
import type { PropertyValues } from 'lit/development';
import { MobileAppViewModel } from 'src/outskirts/account/mobile-app/d-mobile-app';
import './mobile-app/d-mobile-app-navigation';
import type { SideContent } from 'src/outskirts/d-side-content';

/**
 *
 * STATUS OK
 */
@customElement('d-account-layout')
export class DAccountLayout extends ResponsiveElement {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  static readonly styles = css`
    :host {
      --help-viewer-width: 400px;
      --side-content-width: 400px;
      --tutorial-viewer-width: 800px;
      width: 100vw;
      box-sizing: border-box;
      scrollbar-gutter: stable;
    }

    :host * {
      box-sizing: border-box;
    }

    main {
      position: fixed;
      top: var(--safe-area-inset-top);
      width: 100%;
      min-width: 375px;
      height: auto;
      transition: width 0.5s;
      animation: to-relative 0s;
      animation-delay: 0.6s;
      animation-fill-mode: forwards;
      scrollbar-gutter: stable;
      scroll-padding-top: 300px;
    }

    main.help,
    main.side-content,
    main.tutorial,
    main.android,
    main.scroll-context-is-main {
      height: 100vh;
      overflow: auto;
      animation: to-fixed 0s;
      animation-delay: 0s;
      animation-fill-mode: forwards;
      scrollbar-gutter: stable;
    }

    aside {
      position: fixed;
      flex: none;
      width: 0;
      min-height: 100vh;
      max-height: 100vh;
      left: 100vw;
      top: var(--safe-area-inset-top);
      background: white;
      overflow: hidden;
      box-shadow: 0 0 10px hsla(0, 0%, 0%, 0.3);
      z-index: 100;
      transition: all 0.5s;
      scrollbar-gutter: stable;
    }

    aside.help,
    aside.side-content,
    aside.tutorial {
      animation: to-relative 0s;
      animation-delay: 0.6s;
      animation-fill-mode: forwards;
      width: 100%;
      max-width: 100%;
      max-height: none;
      left: 0;
      overflow: visible;
    }

    d-help-viewer,
    d-side-content,
    d-tutorial-viewer {
      width: 100vw;
    }

    @media only screen and (min-width: 750px) {
      main.help {
        width: calc(100vw - var(--help-viewer-width));
      }
      aside.help {
        max-width: none;
        width: var(--help-viewer-width);
        left: calc(100vw - var(--help-viewer-width));
      }
      d-help-viewer {
        width: var(--help-viewer-width);
      }
      main.tutorial {
        width: calc(100vw - var(--tutorial-viewer-width));
      }
      aside.tutorial {
        width: var(--tutorial-viewer-width);
        left: calc(100vw - var(--tutorial-viewer-width));
      }
      d-tutorial-viewer {
        width: var(--tutorial-viewer-width);
      }
      main.side-content {
        width: calc(100vw - var(--side-content-width));
      }
      aside.side-content {
        width: var(--side-content-width);
        left: calc(100vw - var(--side-content-width));
      }
      d-side-content {
        width: var(--side-content-width);
      }
    }

    @keyframes to-fixed {
      to {
        position: fixed;
      }
    }

    @keyframes to-relative {
      to {
        position: relative;
      }
    }

    .navigate {
      position: fixed;
      bottom: 20px;
      width: 40px;
      height: 40px;
      box-shadow: 0px 0px 10px hsla(0, 0%, 0%, 0.3);
      border-radius: 20px;
      background: white;
      z-index: 60;
    }

    .navigate:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-top: 3px solid var(--themeColor);
      border-left: 3px solid var(--themeColor);
      position: absolute;
      top: 14px;
    }

    .navigate.back {
      left: 20px;
    }

    .navigate.back:after {
      transform: rotate(-45deg);
      left: 15px;
    }

    .navigate.forward {
      left: 70px;
    }

    .navigate.forward:after {
      transform: rotate(135deg);
      left: 12px;
    }

    .ui-toggle {
      box-sizing: border-box;
      width: 144px;
      height: 40px;
      position: fixed;
      bottom: 20px;
      left: calc(50vw - 72px);
      border-radius: 20px;
      padding: 10px 0 0 0;
      background: var(--themeColor);
      font-size: 16px;
      text-align: center;
      color: white;
      z-index: 60;
    }
  `;

  @property({ type: Number })
  organizationId = 0;
  @property({ type: Boolean })
  isAppPlatform = false;
  @property({ type: Boolean })
  isSimplifiedUi = false;
  @property({ type: Object })
  mobileAppViewModel: MobileAppViewModel | undefined = undefined;
  @property({ type: Boolean })
  scrollContextIsMain = false;
  @property({ type: Boolean, reflect: true })
  help = false;
  @property({ type: Boolean, reflect: true })
  contentViewer = false;
  @property({ type: Boolean, reflect: true })
  tutorial = false;
  @property({ type: String })
  currentHelpPage = '';
  @property({ type: Object })
  currentTutorialContent?: TutorialContent;
  @property({ type: Boolean })
  sideContent = false;
  @property({ type: Object })
  currentSideContent?: SideContent | undefined;
  @property({ type: Array })
  availableTutorials: SelectDropdownOption[] = [];
  @property({ type: Number })
  setMainScrollTop = 0;
  @property({ type: Boolean })
  scrollingMain = false;
  @property({ type: Boolean })
  edit = false;
  @query('main')
  mainEl!: HTMLElement;

  handleScroll(e) {
    this.dispatchEvent(
      new CustomEvent('main-scroll-position-changed', {
        bubbles: true,
        composed: true,
        detail: { top: e.target.scrollTop, left: e.target.scrollLeft },
      }),
    );
  }

  closeViewer() {
    this.dispatchEvent(
      new CustomEvent<{ value: boolean }>('toggle-help', { bubbles: true, composed: true, detail: { value: false } }),
    );
  }

  public scrollHorizontal(e, behaviour) {
    if (this.scrollContextIsMain) {
      this.mainEl.scrollTo({
        top: this.mainEl.scrollTop,
        left: e.detail.value,
        behavior: behaviour,
      });
    } else {
      window.scrollTo({
        top: window.scrollY,
        left: e.detail.value,
        behavior: behaviour,
      });
    }
  }

  renderMobile() {
    if (this.mobileAppViewModel) {
      return html` <d-mobile-app .viewModel=${this.mobileAppViewModel}></d-mobile-app>`;
    }
    return html``;
  }

  renderDesktop() {
    const asideClassMap = {
      help: this.help,
      tutorial: this.tutorial,
      'side-content': this.sideContent,
      'scroll-context-is-main': this.scrollContextIsMain,
    };
    return html`<main id="main" class="${classMap(asideClassMap)}">
        <slot></slot>
      </main>
      <aside class="${classMap(asideClassMap)}">
        ${this.help
          ? html`
              <d-help-viewer
                .helpPages=${helpPages}
                .currentHelpPage=${this.currentHelpPage}
                @close-viewer=${() => this.closeViewer()}
              ></d-help-viewer>
            `
          : nothing}
        ${this.sideContent && this.currentSideContent
          ? html`
              <d-side-content
                .item=${this.currentSideContent}
                .organizationId=${this.organizationId}
                @close-viewer=${() => this.closeViewer()}
              ></d-side-content>
            `
          : nothing}
        ${this.tutorial && this.currentTutorialContent
          ? html`
              <d-tutorial-viewer
                .content=${this.currentTutorialContent}
                .availableTutorials=${this.availableTutorials}
                @close-viewer=${() => this.closeViewer()}
              ></d-tutorial-viewer>
            `
          : nothing}
      </aside>
      ${this.isAppPlatform && !this.edit
        ? html`<d-mobile-app-navigation
            .currentPath=${this.mobileAppViewModel?.currentPath ?? ''}
          ></d-mobile-app-navigation>`
        : nothing}`;
  }

  render() {
    return html`
      ${this.isAppPlatform && this.isSimplifiedUi ? html`${this.renderMobile()}` : html`${this.renderDesktop()}`}
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    const main = this.shadowRoot?.getElementById('main');
    if (main) {
      main.addEventListener('scroll', this.handleScroll);
    }
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);
    if (changedProperties.has('setMainScrollTop')) {
      const main = this.shadowRoot?.getElementById('main');
      if (main) {
        main.scrollTo({
          top: this.setMainScrollTop,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-account-layout': DAccountLayout;
  }
}
