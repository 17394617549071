/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface NewTopic
 */
export interface NewTopic {
  /**
   *
   * @type {string}
   * @memberof NewTopic
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof NewTopic
   */
  name: string;
}

/**
 * Check if a given object implements the NewTopic interface.
 */
export function instanceOfNewTopic(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'uuid' in value;
  isInstance = isInstance && 'name' in value;

  return isInstance;
}

export function NewTopicFromJSON(json: any): NewTopic {
  return NewTopicFromJSONTyped(json, false);
}

export function NewTopicFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewTopic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
  };
}

export function NewTopicToJSON(value?: NewTopic | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    uuid: value.uuid,
    name: value.name,
  };
}
