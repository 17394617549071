import { css, html, nothing, PropertyValues, TemplateResult } from 'lit';
import './d-page-header.js';
import '../library/editors/elements/d-select-dropdown.js';
import '../library/components/d-help-section.js';
import '../library/elements/d-action.js';
import 'src/library/components/d-field-section';
import 'src/library/lists/d-list-section';
import { property } from 'lit/decorators.js';
import type { PageViewModel } from '../store/api';
import type { AbstractPageView } from './abstract-page-view.js';
import type { IconEnum } from 'src/pages/d-page-header.js';
import { levelContentStyles } from 'src/library/level-content-styles.js';
import { ResponsiveLevel } from 'src/library/elements/responsive-level';
import type { FeatureStates } from 'src/store/selectors/features';
import { ListDefinition } from 'src/content/entity-content';
import { SectionField } from 'src/library/components/d-field-section';
import { getPdfTemplateDefinitionForPage } from 'src/store/selectors/pdf-template-functions';
import { TableDefinition } from 'src/library/abstracts/item-table';
import { ListSectionItemInput } from 'src/library/lists/utilities';

/**
 *
 *
 */
export class PageContent<T extends AbstractPageView> extends ResponsiveLevel {
  static readonly styles = [
    levelContentStyles,
    css`
      d-promo-section {
        max-width: 100%;
        padding-right: calc(100% - 724px);
      }
    `,
  ];
  @property({ type: Object })
  pageView!: T;
  @property({ type: String })
  icon: IconEnum = '';
  @property({ type: Boolean })
  singleUserVersion = false;
  @property({ type: Number })
  scrollTop = 0;
  @property({ type: Number })
  scrollLeft = 0;
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: String })
  editHref = '';
  @property({ type: Boolean, attribute: 'write-access', reflect: true })
  writeAccess = false;
  @property({ type: String })
  selectValue = '';
  @property({ type: Array })
  selectItems = [];
  @property({ type: Array })
  activityCodes = [];
  @property({ type: String })
  pdfLink = '';
  @property({ type: String })
  customPageAction = '';
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: Boolean })
  headerTop = false;
  @property({ type: Boolean })
  showClose = false;
  /**
   * The covered property. If the page is shown below other content views then the page is covered.
   */
  @property({ type: Boolean, reflect: true })
  covered = false;
  @property({ type: Boolean, reflect: true })
  top = false;
  @property({ type: Object })
  scrollData = {};
  @property({ type: Boolean, reflect: true, attribute: 'wide-page' })
  widePage = false;
  @property({ type: Number })
  contentStickyTop = 0;
  @property({ type: Number })
  setScrolltop = 0;
  @property({ type: Boolean, attribute: 'prevent-header-shrink' })
  preventHeaderShrink = false;
  @property({ type: Number })
  pageWidth = 1000;
  @property({ type: Object })
  featureStates!: FeatureStates;

  protected get currentItemLabel() {
    return this.pageView.name;
  }

  protected get currentItemSublabel() {
    return '';
  }

  onShareContent() {
    this.dispatchEvent(new CustomEvent('share-content', { bubbles: true, composed: true, detail: {} }));
  }

  _setContentStickyTop(height: number) {
    if (this.covered) {
      this.contentStickyTop = 0;
    } else {
      /**
       * Space for status bar when application runs as native app
       */
      const safeAreaInsetTop = Number(
        getComputedStyle(document.documentElement).getPropertyValue('--safe-area-inset-top').replace('px', ''),
      );
      this.contentStickyTop = height + safeAreaInsetTop;
    }
  }

  handleScroll(e) {
    this.dispatchEvent(
      new CustomEvent('scrolltop-changed', {
        bubbles: true,
        composed: true,
        detail: e.target.scrollTop,
      }),
    );
  }

  renderHeader(id: string) {
    return html`
      <d-page-header
        ?no-margin=${this.pageView['type'] === 'staffing-page'}
        ?top="${id === 'small-header'}"
        id="${id}"
        .icon=${this.pageView.icon}
        .label=${this.currentItemLabel}
        .sublabel=${this.currentItemSublabel}
        .href=${this.noTrailingSlash(this.pageView.href)}
        ?covered=${this.covered}
        @height-changed=${(e) => this._setContentStickyTop(e.detail)}
      >
        ${this.renderBeforeHeaderLabel()} ${this.renderCustomActions()} ${this.renderClose()}
      </d-page-header>
    `;
  }

  render() {
    return html`
      ${this.renderPdfData()} ${this.renderHeader('small-header')} ${this.renderHeader('large-header')}
      ${this.renderHeader('header')}
      <div class="content" id="content">${this.renderHelp()} ${this.renderContent()}</div>
    `;
  }

  noTrailingSlash(href: string) {
    return href.endsWith('/') ? href.substring(0, href.length - 1) : href;
  }

  protected renderBeforeHeaderLabel() {
    return html``;
  }

  protected renderHelp() {
    return html` <d-help-section content="${this.pageView.helpContent}"></d-help-section> `;
  }

  protected renderContent(): TemplateResult | typeof nothing {
    return nothing;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    const elm = this.shadowRoot?.getElementById('header');
    if (elm) {
      this.intersectionController.observe(elm);
    }
    const content = this.shadowRoot?.getElementById('content');
    if (content) {
      content.addEventListener('scroll', this.handleScroll);
    }
  }

  protected updated(_changedProperties: PropertyValues) {
    if (_changedProperties.has('setScrolltop')) {
      const content = this.shadowRoot?.getElementById('content');
      if (content) {
        content.scrollTo({
          top: this.setScrolltop,
          left: 0,
          behavior: 'instant',
        });
      }
    }
  }

  /**
   * Renders page actions. Default actions include only "Share".
   * @protected
   */
  protected renderCustomActions(): TemplateResult | typeof nothing {
    return this.singleUserVersion
      ? nothing
      : html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`;
  }

  protected renderClose(): TemplateResult | typeof nothing {
    return this.showClose
      ? html`<d-action .href=${'/account/' + this.pageView.organizationId}>Lukk</d-action>`
      : nothing;
  }

  protected viewFields(): SectionField[] {
    return [];
  }

  protected tables(view: any = undefined): TableDefinition[] {
    if (view.type === 'nothing') {
      console.log(view);
    }
    return [];
  }

  renderPdfData() {
    const showPdfData = localStorage.getItem('showPdfData');
    if (showPdfData === 'TRUE') {
      return html`<textarea>
        ${getPdfTemplateDefinitionForPage(
          this.viewFields(),
          this.tables(this.pageView),
          this.lists(),
          this.pageView,
        )}</textarea
      >`;
    }
    return nothing;
  }

  protected onFieldAction(e) {
    this[e.detail.value]();
  }

  renderFieldSection(entityType: string, fields: SectionField[], item: any) {
    return html`
      <d-field-section
        .entityType=${entityType}
        .fields=${fields}
        .item=${item}
        .contentStickyTop=${this.contentStickyTop}
        @field-action=${(e) => this.onFieldAction(e)}
      ></d-field-section>
    `;
  }

  private onListSectionAction(detail: string) {
    this[detail]();
  }

  protected lists(): ListDefinition[] {
    return [];
  }

  protected renderListSections(): TemplateResult<1> {
    return html`
      ${this.lists()
        .filter((list) => !Object.hasOwn(list, 'condition') || list.condition)
        .map((list) => {
          if (list.type === 'custom' && list.render) {
            return list.render();
          }
          let items: ListSectionItemInput[] = [];
          if (list.items) {
            items = this.pageView[list.items];
          }
          return html`
            <d-list-section
              theme-page
              .actions=${list.actions ?? []}
              .field=${list.field ?? ''}
              .icon=${list.icon}
              .items=${items}
              .contentStickyTop=${this.contentStickyTop}
              @action=${(e: CustomEvent) => this.onListSectionAction(e.detail)}
            ></d-list-section>
          `;
        })}
    `;
  }
}
