/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TopicSuggestion
 */
export interface TopicSuggestion {
  /**
   *
   * @type {number}
   * @memberof TopicSuggestion
   */
  templateId: number;
  /**
   *
   * @type {string}
   * @memberof TopicSuggestion
   */
  type: string;
}

/**
 * Check if a given object implements the TopicSuggestion interface.
 */
export function instanceOfTopicSuggestion(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'templateId' in value;
  isInstance = isInstance && 'type' in value;

  return isInstance;
}

export function TopicSuggestionFromJSON(json: any): TopicSuggestion {
  return TopicSuggestionFromJSONTyped(json, false);
}

export function TopicSuggestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicSuggestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    templateId: json['templateId'],
    type: json['type'],
  };
}

export function TopicSuggestionToJSON(value?: TopicSuggestion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    templateId: value.templateId,
    type: value.type,
  };
}
