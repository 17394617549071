import type { TaskView, TaskViewEditItem } from 'src/content/tasks/d-task-view.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import type { TaskEvent } from 'src/library/lists/utilities.js';
import {
  fetchDraft,
  fetchRevisions,
  pagesAsSelectTagOptions,
  toAttachmentItems,
} from 'src/models/factory-utilities.js';
import { BASE_PATH, EmployeeViewModelStatusEnum, FunctionViewModel } from 'src/store/api';
import {
  currentUserUuid,
  employeesAndContactsOptions,
  employeesNotDeleted,
  employeesShortNamesAndI,
  eventOccurrencesNotDeleted,
  functionsNotDeleted,
  getContactsAsUsers,
  getOrganization,
  key,
  singleUserVersion,
  StartTaskInstance,
  startTasks,
  taskEventsInstances,
  toTaskViewModel,
  writeAccess,
} from 'src/store/selectors';
import type { OrganizationState, State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { sortedByName } from 'src/store/utilities.js';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import { ApiClient } from 'src/store';

const smartTaskEventTexts = {
  employeeStart: 'når en ny person registreres som personale',
  employeeLeave: 'når noen registreres som sluttet eller med langvarig fravær',
};

function _startTaskText(locked: boolean, taskUuid: string, startTasks: StartTaskInstance[]) {
  if (locked) {
    return (
      'Etableringsoppgave for ' +
      startTasks.filter(function (item) {
        return item.uuid === taskUuid;
      })[0].timeDisplay
    );
  }
  return '';
}

function functionsForAssigned(functions: FunctionViewModel[]): SelectDropdownOption[] {
  functions = sortedByName(functions);
  const common = functions.find((x) => x.type === 'COMMON');
  assertIsDefined(common);

  const otherFunctions = functions.filter((x) => x.type !== 'COMMON' && x.isConfirmedEntity);

  const list = [common, ...otherFunctions];

  return list.map((x) => ({ value: x.uuid, text: x.type === 'COMMON' ? 'Alle' : x.name ?? '' }));
}

function functionsForResponsible(functions: FunctionViewModel[]): SelectDropdownOption[] {
  functions = sortedByName(functions);
  return functions
    .filter((item) => item.type === 'SINGLE' && item.isConfirmedEntity)
    .map((x) => ({ value: x.uuid, text: x.name ?? '' }));
}

function mapPagesPerFunction(functions: FunctionViewModel[]): Map<string, Set<number>> {
  const result = new Map<string, Set<number>>();
  functions
    .filter((x) => x.type !== 'COMMON')
    .forEach((f) => {
      result.set(f.uuid, new Set<number>(f.pages));
    });
  return result;
}

export async function buildTaskView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<TaskView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);

  const availableFunctions = functionsForAssigned(functionsNotDeleted(state));
  const availableFunctionsForResponsible = functionsForResponsible(functionsNotDeleted(state));
  const item = organization.tasksById[uuid];
  assertIsDefined(item);

  const entity = toTaskViewModel(item, organization);
  const href = currentParent + '/tasks/' + uuid;
  const taskEvents = getTaskEvents(state, organization, uuid, href, entity.name || '');
  let smartTaskEventText = '';
  if (entity.smartTaskEvent) {
    smartTaskEventText = smartTaskEventTexts[entity.smartTaskEvent];
  }
  return {
    ...commonData,
    docsForLinking: [],
    contentLastModified: entity.contentLastModified ?? '',
    contentLastModifiedBy: entity.contentLastModifiedBy ?? '',
    contentLastModifiedWasCreate: entity.contentLastModifiedWasCreate ?? false,
    hasTemplateDelete: entity.hasTemplateDelete ?? false,
    hasTemplateUpdate: entity.hasTemplateUpdate ?? false,
    templateDeclined: entity.templateDeclined ?? '',
    templateDeleted: entity.templateDeleted ?? '',
    templateDeletedMessage: entity.templateDeletedMessage ?? '',
    templateUpdated: entity.templateUpdated ?? '',
    currentUserHasAccess: true,
    currentUserHasWriteAccess: writeAccess(state),
    uuid: uuid,
    isConfirmedEntity: entity.isConfirmedEntity,
    helpContent: entity.helpContent ?? '',
    href: href,
    pdfHref:
      BASE_PATH +
      '/organizations/' +
      organization.organizationId +
      '/functions/' +
      entity.functionUuid +
      '/tasks/' +
      uuid +
      '.pdf?key=' +
      key(state),

    parentHref: currentParent,
    deleted: entity.deleted === true,
    type: 'tasks',
    name: entity.name ?? '',
    revisions: await fetchRevisions(
      api,
      'tasks',
      uuid,
      organization.organizationId,
      entity.templateDeleted !== undefined && entity.templateDeleted !== '',
      entity.functionUuid,
    ),
    attachments: toAttachmentItems(api, 'tasks', entity.uuid, entity.attachments, entity.functionUuid),
    procedures: entity.procedures ?? '',
    parentEntityId: entity.functionUuid ?? '',
    assignedFunctionName: availableFunctions.find((f) => f.value === entity.functionUuid)?.text ?? '',
    responsibleFunctionName:
      availableFunctionsForResponsible.find((f) => f.value === entity.responsibleFunctionUuid)?.text || 'Ingen',
    situation: entity.situation ?? '',
    taskEvents,
    startTaskText: _startTaskText(entity.locked === true, entity.uuid, startTasks(state)),
    availablePages: pagesAsSelectTagOptions(state),
    hasEvents: taskEvents.length > 0,
    pages: (entity.pages ?? []).map((x) => '' + x),
    responsibleFunctionUuid: entity.responsibleFunctionUuid ?? '',
    today: LocalDate.fromString(state.today),
    availableFunctions: availableFunctions,
    availableFunctionsForResponsible: availableFunctionsForResponsible,
    disabledPagesPerFunction: mapPagesPerFunction(functionsNotDeleted(state)),
    smartTaskEventText,
    deletable: true,
    availableEmployees: employeesNotDeleted(state)
      .filter((e) => e.status !== EmployeeViewModelStatusEnum.Terminated)
      .map((p) => ({ value: p.uuid, text: p.name })),
    availableContacts: getContactsAsUsers(state).map((p) => ({ value: p.uuid, text: p.name })),
    hasDraft: entity.hasDraft,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'tasks', uuid, organization.organizationId);
      return command.draft as TaskViewEditItem;
    },
  };
}

export function getTaskEvents(
  state: State,
  organization: OrganizationState,
  uuid: string,
  href: string,
  taskName: string,
): TaskEvent[] {
  const taskEventOccurrences = eventOccurrencesNotDeleted(state).filter((o) => {
    return o.taskUuid === uuid;
  });
  if (taskEventOccurrences.length === 0) {
    return [];
  } else {
    return taskEventsInstances(
      taskEventOccurrences,
      organization,
      employeesShortNamesAndI(state),
      functionsNotDeleted(state),
      getContactsAsUsers(state),
      organization.organizationId,
      currentUserUuid(state),
      singleUserVersion(state),
      LocalDate.now(),
      employeesAndContactsOptions(state),
    ).map((e) => {
      return {
        overdue: e.overdue ?? false,
        doneDate: e.doneDate ?? '',
        time: e.time,
        sortTime: e.dateTimeForOrdering ?? '',
        uuid: e.eventUuid,
        href: href + '/eventOccurrences/' + e.eventUuid,
        timeOfDayDisplay: e.timeOfDayDisplay,
        eventName: e.eventName !== taskName ? e.eventName : '',
        eventCurrentUser: e.myTask,
        secondaryLabel: e.overdue ? '' : e.doneText ?? '',
        rightLabel: e.assignee ?? '',
        rightLabelClass: 'assignee',
        hasDraft: e.hasDraft,
      };
    });
  }
}
