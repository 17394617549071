import { createSelector } from '@reduxjs/toolkit';
import { currentUserUuid } from 'src/store/selectors/user';
import { employeeInterviewUuid, employeeInterviewTemplateId } from 'src/store/selectors/organization-meetings';
import {
  employeesNotDeleted,
  employeesShortNamesAndI,
  employeesShortNamesAndMe,
} from 'src/store/selectors/organization-employees';
import { getOrganization, employeesOptions } from 'src/store/selectors/organization';
import { eventDoneText } from 'src/store/selectors/event-selector-functions';
import { functionsNotDeleted } from 'src/store/selectors/organization-functions';
import { PartnerContactAsUser, getContactsAsUsers } from 'src/store/selectors/organization-partners';
import { meetingsNotDeleted, meetingOccurrencesNotDeleted } from 'src/store/selectors/organization-meetings';
import { reportsNotDeleted } from 'src/store/selectors/organization-documents';
import { ShortNameEntry } from 'src/store/selectors/organization-employees';
import { singleUserVersion } from 'src/store/selectors/organization';
import { toList } from 'src/store/selectors/utilities';

import { userPropertiesForCurrentOrganization } from './user';
import { dateTimeDisplayShort, eventAssigneesText, meetingParticipantsText } from 'src/store/utilities';
import { sortBy } from 'lodash';
import { ListItemIcon, ListSectionItemInput, RiskLevels } from 'src/library/lists/utilities';
import { EmployeeViewModelWithName, State } from 'src/store/types';
import { entityNameForLists } from 'src/utilities/text';
import { LocalDate } from 'src/utilities/local-date';
import { riskLevelFromRisk } from 'src/models/risk';
import {
  EmployeeViewModel,
  EventOccurrenceViewModel,
  FunctionViewModel,
  MeetingOccurrenceViewModel,
  ReportViewModel,
  TopicViewModel,
} from 'src/store/api';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

/**
 * CollectionItem er lagringsformatet for elementer i en samling
 */
export interface CollectionItem {
  type: string;
  uuid: string;
  interviewEmployee?: string;
  createEvent?: boolean;
}

/**
 * CollectionSuggestion er formatet for forslag til elementer i en mal-samling (samling som kommer fra innholdsmalen)
 */
export interface CollectionSuggestion {
  templateId: number;
  type: string;
  /**
   * Hvis forslaget er en rutine som skal brukes til å lage en oppgave (hvis en slik oppgave ikke allerede finnes)
   */
  createEvent?: boolean;
}

/**
 * CollectionEntity er en representasjon av et TrinnVis-element,
 * og inneholder alle data som trengs for å behandle og vise det i sammenheng med samlinger
 */
export interface CollectionEntity extends CollectionItem {
  templateId: number;
  href: string;
  name: string;
  notes: string;
  assigneeUuids: string[];
  assignees: string;
  doneDate: string;
  doneText: string;
  dateTimeDisplay: string;
  dateTimeForOrdering: string;
  interviewEmployeeTerminated?: boolean;
  deleted: boolean;
  locked: boolean;
  accessible: boolean;
  classes: string;
  entities?: CollectionEntity[];
}

/**
 * CollectionFilter er en formatet for filtrert visning av elementene i en samling
 */
export interface CollectionFilter {
  uuid: string;
  startDate: string;
  endDate: string;
  people: string[];
}

export interface CollectionSuggestionClickData {
  type: string;
  createEvent: boolean;
  uuid: string;
  interviewEmployee: string;
}

export const storedCollections = createSelector([getOrganization], function (organization): TopicViewModel[] {
  return organization?.topics ?? [];
});

export const hsePlanTemplateId = 285878;

export const getHsePlan = createSelector(storedCollections, function (collections): TopicViewModel | undefined {
  return collections.find((c) => c.template?.id === hsePlanTemplateId);
});

/**
 * Alle elementtyper som kan være med i en samling, unntatt oppgaver og møter (disse trenger spesiell behandling)
 */
const entityTypes = [
  'reports',
  'tasks',
  'guidelines',
  'documents',
  'constitutionalDocuments',
  'contracts',
  'functions',
  'assets',
  'substances',
  'riskAssessments',
  'issues',
  'employees',
  'partners',
  'contacts',
];

export function getFilterParams(
  startDate: string | undefined,
  endDate: string | undefined,
  assignees: string[] | undefined,
): string {
  let paramsString = '';
  const params: string[] = [];
  if (startDate) {
    params.push('filterStartDate=' + startDate);
  }
  if (endDate) {
    params.push('filterEndDate=' + endDate);
  }
  if (assignees?.length) {
    params.push('filterPeople=' + encodeURIComponent(JSON.stringify(assignees)));
  }
  if (params.length) {
    paramsString = '?' + params.join('&');
  } else {
    paramsString = '?noFilter=true';
  }
  return paramsString;
}

function getDateTimeForOrdering(item: any): string {
  if (item.date) {
    return item.date + ' ' + (item.time && item.time !== 'NONE' ? item.time : '00:00');
  }
  return '';
}

function getClasses(item: any, type: string, createEvent): string {
  const classes: string[] = [];
  if (type === 'riskAssessments') {
    let riskAssessmentRisk: RiskLevels = undefined;
    if (item.consequence && item.probability) {
      riskAssessmentRisk = riskLevelFromRisk(item.consequence * item.probability);
      classes.push('risk');
      classes.push('risk' + riskAssessmentRisk);
    }
  }
  if (createEvent && !classes.includes('create-event')) {
    classes.push('create-event');
  }
  return classes.join(' ');
}

function eventAssigneeUuids(event: EventOccurrenceViewModel, functions: FunctionViewModel[]): string[] {
  let result: string[] = [];
  if (event.functionUuid) {
    const func = functions.find((f) => {
      return f.uuid === event.functionUuid;
    });
    if (func) {
      result = func.employees;
    }
  }
  if (event.employees?.length) {
    result = result.concat(event.employees);
  }
  if (event.contacts?.length) {
    result = result.concat(event.contacts);
  }
  if (event.doneByEmployeeUuid) {
    result = result.concat(event.doneByEmployeeUuid);
  }
  if (event.doneByContactPersonUuid) {
    result = result.concat(event.doneByContactPersonUuid);
  }
  return result;
}

function meetingParticipantUuids(event: MeetingOccurrenceViewModel, functions: FunctionViewModel[]): string[] {
  let result: string[] = [];
  if (event.responsibleUuid) {
    const func = functions.find((f) => {
      return f.uuid === event.responsibleUuid;
    });
    if (func) {
      result = func.employees;
    }
  }
  if (event.responsibleEmployeeUuid) {
    result.push(event.responsibleEmployeeUuid);
  }
  if (event.participants?.length) {
    result = result.concat(event.participants);
  }
  if (event.externalParticipants?.length) {
    result = result.concat(event.externalParticipants);
  }
  return result;
}

function findInterviewEmployee(
  meetingOccurrence: MeetingOccurrenceViewModel,
  functions: FunctionViewModel[],
  employees: EmployeeViewModel[],
): EmployeeViewModel | undefined {
  let meetingResponsibleEmployeeUuid = meetingOccurrence.responsibleEmployeeUuid;
  if (meetingOccurrence.responsibleUuid) {
    const responsibleFunction = functions.find((f) => f.uuid === meetingOccurrence.responsibleUuid);
    if (responsibleFunction) {
      meetingResponsibleEmployeeUuid = responsibleFunction.employees[0];
    }
  }
  const interviewEmployeeUuid = meetingOccurrence.participants.find((p) => {
    return p !== meetingResponsibleEmployeeUuid;
  });
  return employees.find((e) => e.uuid === interviewEmployeeUuid);
}

function filteredEmployeesAndContactsOptions(
  contactsAsUsers: PartnerContactAsUser[],
  shortNamesAndMe: ShortNameEntry[],
  selectedEmployees: string[] | undefined = undefined,
): SelectDropdownOption[] {
  const contactsOptions = sortBy(
    contactsAsUsers.map((item) => ({
      value: item.uuid,
      text: item.name + ' (' + item.partnerName + ')',
    })),
    ['text'],
  );
  return employeesOptions(shortNamesAndMe, selectedEmployees).concat(contactsOptions);
}

/**
 * Konverterer et TrinnVis-element til CollectionEntity med alle data som trengs for behandling og visning
 */
function toCollectionEntity(
  item: any,
  type: string,
  createEvent: boolean = false,
  functions: FunctionViewModel[],
  userUuid: string,
  shortNamesAndI: ShortNameEntry[],
  shortNamesAndMe: ShortNameEntry[],
  contactsAsUsers: PartnerContactAsUser[],
  singleUser: boolean,
  employees: EmployeeViewModelWithName[],
  interviewUuid: string,
  reports: ReportViewModel[],
): CollectionEntity {
  let assigneeUuids: string[] = [];
  let assignees = '';
  let hrefType = type;
  let name = entityNameForLists(item.name, item.draftName, item.isConfirmedEntity);
  let doneText = '';
  if (type === 'events') {
    assigneeUuids = eventAssigneeUuids(item, functions);
    assignees = eventAssigneesText(item, functions, shortNamesAndI, contactsAsUsers);
    hrefType = 'eventOccurrences';
    if (item.doneDate) {
      doneText =
        'Utført ' +
        eventDoneText(
          item,
          filteredEmployeesAndContactsOptions(contactsAsUsers, shortNamesAndMe, assigneeUuids),
          singleUser,
        );
    }
  }
  if (type === 'meetings') {
    assigneeUuids = meetingParticipantUuids(item, functions);
    assignees = meetingParticipantsText(item, functions, shortNamesAndI, contactsAsUsers, true);
    hrefType = 'meetingOccurrences';
    if (item.meetingUuid === interviewUuid) {
      const employee = findInterviewEmployee(item, functions, employees);
      if (employee) {
        name += ' med ' + [employee.firstName, employee.lastName].join(' ');
      }
    }
    const hasReport: boolean = reports.find((report) => report.meetingOccurrenceUuid === item.uuid) !== undefined;
    if (item.doneDate || hasReport) {
      let doneDate = item.date;
      if (item.doneDate) {
        doneDate = item.doneDate;
      }
      doneText = 'Gjennomført ' + LocalDate.fromString(doneDate).toStringForDisplay();
    }
  }
  let dateTimeDisplay = '';
  if (type === 'events' || (type === 'meetings' && item.date)) {
    dateTimeDisplay = dateTimeDisplayShort(item.date, item.time);
  }
  const locked = item.classification && item.classification !== 'NONE';
  return {
    type: type,
    uuid: item.uuid,
    templateId: item.mainTemplateId,
    interviewEmployee: item.interviewEmployee,
    href: '/' + hrefType + '/' + item.uuid,
    name,
    notes: type === 'events' ? item.notes : '',
    assigneeUuids,
    assignees,
    doneDate: item.doneDate,
    doneText,
    dateTimeForOrdering: getDateTimeForOrdering(item),
    dateTimeDisplay,
    deleted: item.deleted,
    locked,
    accessible: !locked || item.accessControl.includes(userUuid),
    classes: getClasses(item, type, createEvent),
    createEvent,
  };
}

/**
 * Finner eksisterende occurrences for medarbeidersamtaler for enkeltperonale
 */
function singleEmployeeInterviewEntities(
  meetingOccurrences: MeetingOccurrenceViewModel[],
  interviewMeetingUuid: string,
  employeeUuid: string,
  functions: FunctionViewModel[],
  userUuid: string,
  shortNamesAndI: ShortNameEntry[],
  shortNamesAndMe: ShortNameEntry[],
  contactsAsUsers: PartnerContactAsUser[],
  singleUser: boolean,
  employees: EmployeeViewModelWithName[],
  interviewUuid: string,
  reports: ReportViewModel[],
): CollectionEntity[] | undefined {
  const occurrences = meetingOccurrences.filter((o) => {
    return o.meetingUuid === interviewMeetingUuid && o.participants.includes(employeeUuid) && o.isConfirmedEntity;
  });
  if (occurrences.length) {
    return occurrences.map((o) => {
      return toCollectionEntity(
        o,
        'meetings',
        false,
        functions,
        userUuid,
        shortNamesAndI,
        shortNamesAndMe,
        contactsAsUsers,
        singleUser,
        employees,
        interviewUuid,
        reports,
      );
    });
  }
}

/**
 * Finner eksisterende occurrences for medarbeidersamtaler for alt personale
 */
function employeeInterviewEntities(
  employees: EmployeeViewModelWithName[],
  interviewMeetingUuid: string,
  meetingOccurrences: MeetingOccurrenceViewModel[],
  functions: FunctionViewModel[],
  userUuid: string,
  shortNamesAndI: ShortNameEntry[],
  shortNamesAndMe: ShortNameEntry[],
  contactsAsUSers: PartnerContactAsUser[],
  singleUser: boolean,
  reports: ReportViewModel[],
): CollectionEntity[] {
  const result: CollectionEntity[] = [];
  employees.forEach((employee) => {
    const occurrenceEntities = singleEmployeeInterviewEntities(
      meetingOccurrences,
      interviewMeetingUuid,
      employee.uuid,
      functions,
      userUuid,
      shortNamesAndI,
      shortNamesAndMe,
      contactsAsUSers,
      singleUser,
      employees,
      interviewMeetingUuid,
      reports,
    );
    if (occurrenceEntities) {
      occurrenceEntities.forEach((entity) => {
        result.push(entity);
      });
    }
  });
  return result;
}

function entityToSuggestionListItem(
  item: CollectionEntity,
  createEvent: boolean,
  icons: boolean,
): ListSectionItemInput {
  let label = item.name;
  const clickData: CollectionSuggestionClickData = {
    type: item.type,
    createEvent: createEvent,
    uuid: item.uuid,
    interviewEmployee: '',
  };
  if ((item.type === 'events' || item.type === 'meetings') && item.dateTimeDisplay) {
    label = item.dateTimeDisplay + ' ' + item.name;
  }
  if (item.templateId === employeeInterviewTemplateId) {
    clickData.interviewEmployee = item.interviewEmployee ?? '';
  }
  let mainIcon: ListItemIcon | undefined = undefined;
  if (icons) {
    mainIcon = item.type as ListItemIcon;
  } else if (item.locked) {
    mainIcon = 'lock';
  } else if (item.doneText !== '') {
    mainIcon = 'checkBlue';
  }
  return {
    label,
    secondaryLabel: item.doneText,
    notes: item.notes,
    clickData: JSON.stringify(clickData),
    accessible: !item.interviewEmployeeTerminated,
    rightLabel: item.assignees,
    rightLabelClass: 'assignee',
    mainIcon,
  };
}

function getEmployeeInterview(
  state: State,
  interviewMeetingUuid: string,
  employee: EmployeeViewModelWithName,
  collectedItemUuids: string[],
): CollectionEntity {
  let name = 'Medarbeidersamtale med ' + employee.name;
  if (employee.status === 'TERMINATED') {
    name += ' (sluttet)';
  }
  let entities: CollectionEntity[] | undefined = undefined;
  const occurrenceEntities = singleEmployeeInterviewEntities(
    meetingOccurrencesNotDeleted(state),
    interviewMeetingUuid,
    employee.uuid,
    functionsNotDeleted(state),
    currentUserUuid(state),
    employeesShortNamesAndI(state),
    employeesShortNamesAndMe(state),
    getContactsAsUsers(state),
    singleUserVersion(state),
    employeesNotDeleted(state),
    employeeInterviewUuid(state),
    reportsNotDeleted(state),
  );
  if (occurrenceEntities) {
    const uncollected = occurrenceEntities.filter((entity) => {
      return !collectedItemUuids.includes(entity.uuid);
    });
    if (uncollected.length) {
      entities = uncollected;
    }
  }
  return {
    type: 'meetings',
    uuid: interviewMeetingUuid,
    templateId: employeeInterviewTemplateId,
    interviewEmployee: employee.uuid,
    interviewEmployeeTerminated: employee.status === 'TERMINATED',
    href: '',
    name,
    notes: '',
    assigneeUuids: [employee.uuid],
    assignees: '',
    doneDate: '',
    doneText: '',
    dateTimeForOrdering: '',
    dateTimeDisplay: '',
    deleted: false,
    locked: false,
    accessible: true,
    classes: '',
    createEvent: true,
    entities,
  };
}
/**
 * Finner entities i collectibles som tilsvarer forslagene.
 *
 * Dersom et forslag er en rutine som skal lage en oppgave (merket med createEvent),
 * returneres også alle oppgaver med samme navn som rutinen.
 *
 * Dersom forslaget refererer til en møtemal, returneres også alle møter med samme navn som møtemalen.
 *
 * Dersom forslaget refererer til malen for medarbeidersamtaler returneres, for hver person,
 * alle medarbeidersamtaler med den personen, samt møtemal for personen.
 */
function getSuggestionEntities(
  item: CollectionSuggestion,
  collectedItemUuids: string[],
  entity: CollectionEntity,
  collectibles: CollectionEntity[],
  state: State,
): CollectionEntity[] {
  const result: CollectionEntity[] = [];
  entity = { ...entity };
  entity.type = item.type;
  if (entity.type === 'tasks') {
    entity.createEvent = true;
    const events = collectibles.filter((c) => {
      return c.type === 'events' && c.name === entity.name && !collectedItemUuids.includes(c.uuid);
    });
    if (events.length) {
      const entityWithEvents: CollectionEntity = { ...entity, entities: events };
      result.push(entityWithEvents);
    } else {
      result.push(entity);
    }
  }
  if (entity.type === 'meetings') {
    if (item.templateId !== employeeInterviewTemplateId) {
      const meetingTemplate = meetingsNotDeleted(state).find((m) => {
        return m.mainTemplateId === item.templateId;
      });
      if (meetingTemplate) {
        const meetings = collectibles.filter((c) => {
          return c.type === 'meetings' && c.name === meetingTemplate.name && !collectedItemUuids.includes(c.uuid);
        });
        if (meetings.length) {
          const entityWithEvents: CollectionEntity = { ...entity, entities: meetings };
          result.push(entityWithEvents);
        } else {
          result.push(entity);
        }
      }
    }
    if (item.templateId === employeeInterviewTemplateId) {
      const interviewMeetingUuid = employeeInterviewUuid(state);
      employeesNotDeleted(state).forEach((employee) => {
        result.push(getEmployeeInterview(state, interviewMeetingUuid, employee, collectedItemUuids));
      });
    }
  }
  return result;
}

/**
 * Konverterer CollectionSuggestions (lagringsformatet for forslag)
 * til CollectionEntities (bruksformat med nødvendige data)
 * med alle oppgaver for rutiner og alle møter for møtemaler
 */
function suggestionsToEntities(
  suggestions: CollectionSuggestion[],
  collectedItemUuids: string[],
  collectibles: CollectionEntity[],
  state: State,
): CollectionEntity[] {
  const result: CollectionEntity[] = [];
  if (suggestions) {
    suggestions.forEach((item) => {
      let entity = collectibles.find((c) => {
        return c.templateId && c.templateId === item.templateId;
      });
      /**
       * Møtemaler finnes ikke i collectibles?
       */
      if (item.type === 'meetings') {
        const meeting = meetingsNotDeleted(state).find((m) => m.mainTemplateId === item.templateId);
        if (meeting) {
          entity = toCollectionEntity(
            meeting,
            'meetings',
            false,
            functionsNotDeleted(state),
            currentUserUuid(state),
            employeesShortNamesAndI(state),
            employeesShortNamesAndMe(state),
            getContactsAsUsers(state),
            singleUserVersion(state),
            employeesNotDeleted(state),
            employeeInterviewUuid(state),
            reportsNotDeleted(state),
          );
          entity.createEvent = true;
        }
      }
      if (entity) {
        getSuggestionEntities(item, collectedItemUuids, entity, collectibles, state).forEach((entity) => {
          result.push(entity);
        });
      }
    });
    return result;
  }
  return [];
}

function isInterviewWithTerminatedEmployeeWithoutOccurrencesInPeriod(
  entity: CollectionEntity,
  state: State,
  start: string,
  end: string,
): boolean {
  if (entity.type === 'meetings' && entity.interviewEmployee) {
    const employee = employeesNotDeleted(state).find((e) => e.uuid === entity.interviewEmployee);
    if (!employee || employee.status === 'TERMINATED') {
      if (entity.entities?.length) {
        const eventInPeriod = entity.entities.find((event) => {
          return inPeriodOrTodo(start, end, event.dateTimeForOrdering, event.doneDate);
        });
        if (eventInPeriod) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

export function suggestionsToListItems(
  suggestions: CollectionSuggestion[],
  collectibles: CollectionEntity[],
  collected: CollectionItem[],
  state: State,
  start: string,
  end: string,
): ListSectionItemInput[] {
  const result: ListSectionItemInput[] = [];
  const collectedUuids = collected.map((c) => c.uuid);
  const entities = suggestionsToEntities(suggestions, collectedUuids, collectibles, state);
  entities
    .filter((entity) => {
      return !isInterviewWithTerminatedEmployeeWithoutOccurrencesInPeriod(entity, state, start, end);
    })
    .forEach((entity) => {
      const listItem = entityToSuggestionListItem(entity, entity.createEvent ?? false, true);
      if (entity.entities?.length) {
        listItem.items = entity.entities
          .filter((entity) => {
            return inPeriodOrTodo(start, end, entity.dateTimeForOrdering, entity.doneDate);
          })
          .map((e) => entityToSuggestionListItem(e, false, false));
      }
      result.push(listItem);
    });
  return result;
}

function inPeriodOrTodo(start: string, end: string, dateTime: string, done: string): boolean {
  if (!start && !end) {
    return true;
  }
  if (!dateTime) {
    return true;
  }
  let result = false;
  let startDate: LocalDate;
  if (start) {
    startDate = LocalDate.fromString(start);
  } else {
    startDate = LocalDate.fromString('1000-01-01');
  }
  let endDate: LocalDate;
  if (end) {
    endDate = LocalDate.fromString(end);
  } else {
    endDate = LocalDate.fromString('3000-01-01');
  }
  const plannedDate = LocalDate.fromString(dateTime.split(' ')[0]);
  if (plannedDate.isSameOrAfter(startDate) && plannedDate.isSameOrBefore(endDate)) {
    result = true;
  }
  if (done) {
    const doneDate = LocalDate.fromString(done);
    if (doneDate.isSameOrAfter(startDate) && doneDate.isSameOrBefore(endDate)) {
      result = true;
    }
  }
  return result;
}

export function sortEntities(items: CollectionEntity[]): CollectionEntity[] {
  const sortedByName = sortBy(items, (i) => i.name);
  const itemsWithoutDateTime: CollectionEntity[] = sortedByName.filter((item) => {
    return item.dateTimeForOrdering === '';
  });
  const itemsWithDateTime: CollectionEntity[] = sortedByName.filter((item) => {
    return item.dateTimeForOrdering !== '';
  });
  const sortedByDateTime = sortBy(itemsWithDateTime, (i) => i.dateTimeForOrdering);
  return [...itemsWithoutDateTime, ...sortedByDateTime];
}

export function filterEntities(
  entities: CollectionEntity[],
  start: string = '',
  end: string = '',
  filterAssignees: string[] = [],
): CollectionEntity[] {
  const includesAny = (arrayA: string[], arrayB: string[]) => arrayB.some((b) => arrayA.includes(b));
  return entities.filter((e) => {
    return (
      (e.type === 'events' || e.type === 'meetings' || e.type === 'meeting-occurrencess') &&
      inPeriodOrTodo(start, end, e.dateTimeForOrdering, e.doneDate) &&
      (includesAny(e.assigneeUuids, filterAssignees) || !filterAssignees.length)
    );
  });
}

/**
 * Tar en liste med CollectionItems og returnerer en liste med de tilsvarende CollectionEntities.
 * For CollectionItems som representerer rutiner, og som skal brukes til å lage oppgaver,
 * returneres en oppgave hvis den finnes, og rutinen hvis oppgaver ikke finnes.
 */
export function getItemsAsCollectionEntities(
  items: CollectionItem[],
  collectibles: CollectionEntity[],
): CollectionEntity[] {
  const result: CollectionEntity[] = [];
  items.forEach((item) => {
    let entity: CollectionEntity | undefined;
    if (item.createEvent && (item.type === 'tasks' || item.type === 'events')) {
      entity = collectibles.find((c) => {
        return c.type === 'tasks' && c.uuid === item.uuid;
      });
    } else {
      entity = collectibles.find((c) => {
        return c.uuid === item.uuid && c.interviewEmployee === item.interviewEmployee;
      });
    }
    if (entity) {
      let type = item.type;
      if (type === 'MeetingOccurrence') {
        type = 'meetings';
      }
      if (type === 'EventOccurrence') {
        type = 'events';
      }
      const dupicate = result.find((r) => {
        return r.uuid === entity?.uuid && r.interviewEmployee === entity?.interviewEmployee;
      });
      if (!dupicate) {
        result.push({ ...entity, createEvent: item.createEvent, type });
      }
    }
  });
  return result.filter((item) => {
    if (item.createEvent) {
      return (
        result.find((i) => {
          return i.name === item.name && !i.createEvent;
        }) === undefined
      );
    }
    return true;
  });
}

export function getRelatedCollections(collections: TopicViewModel[], uuid: string): TopicViewModel[] {
  return collections.filter((collection) => {
    return collection.items.find((item) => item.uuid === uuid) !== undefined;
  });
}

export const collectionsFilter = createSelector(
  [userPropertiesForCurrentOrganization],
  function (userProperties): CollectionFilter[] {
    return userProperties.collectionsFilter || [];
  },
);

export function collectionsById() {
  return {};
}

/**
 * Lager CollectionEntities av alle elementer
 */
export const getCollectionEntities = createSelector(
  getOrganization,
  employeesNotDeleted,
  employeeInterviewUuid,
  meetingOccurrencesNotDeleted,
  functionsNotDeleted,
  currentUserUuid,
  employeesShortNamesAndI,
  employeesShortNamesAndMe,
  getContactsAsUsers,
  singleUserVersion,
  reportsNotDeleted,
  function (
    organization,
    employees,
    interviewMeetingUuid,
    meetingOccurrences,
    functions,
    userUuid,
    shortNamesAndI,
    shortNamesAndMe,
    contactsAsUsers,
    singleUser,
    reports,
  ): CollectionEntity[] {
    if (organization) {
      const meetingOccurrencesNotInterviews = meetingOccurrences.filter(
        (item) => item.meetingUuid !== interviewMeetingUuid,
      );
      const result: CollectionEntity[] = [];
      organization.eventOccurrences
        .filter((item) => {
          return item.isConfirmedEntity;
        })
        .forEach((item: any) => {
          result.push(
            toCollectionEntity(
              item,
              'events',
              false,
              functions,
              userUuid,
              shortNamesAndI,
              shortNamesAndMe,
              contactsAsUsers,
              singleUser,
              employees,
              interviewMeetingUuid,
              reports,
            ),
          );
        });
      meetingOccurrencesNotInterviews
        .filter((item) => {
          return item.isConfirmedEntity;
        })
        .forEach((item: any) => {
          result.push(
            toCollectionEntity(
              item,
              'meetings',
              false,
              functions,
              userUuid,
              shortNamesAndI,
              shortNamesAndMe,
              contactsAsUsers,
              singleUser,
              employees,
              interviewMeetingUuid,
              reports,
            ),
          );
        });
      employeeInterviewEntities(
        employees,
        interviewMeetingUuid,
        meetingOccurrences,
        functions,
        userUuid,
        shortNamesAndI,
        shortNamesAndMe,
        contactsAsUsers,
        singleUser,
        reports,
      ).forEach((entity) => {
        result.push(entity);
      });
      entityTypes.forEach((type) => {
        toList(organization[type + 'ById'])
          .filter((item: any) => {
            return item.isConfirmedEntity && !item.deleted;
          })
          .forEach((item: any) => {
            result.push(
              toCollectionEntity(
                item,
                type,
                false,
                functions,
                userUuid,
                shortNamesAndI,
                shortNamesAndMe,
                contactsAsUsers,
                singleUser,
                employees,
                interviewMeetingUuid,
                reports,
              ),
            );
          });
      });
      return sortEntities(result);
    }
    return [];
  },
);
