import './app-root.js';

export { numberWord } from 'src/utilities/text.js';
export { joinWithAnd } from 'src/utilities/text.js';
export { uuid } from 'src/utilities/text.js';
export { ordinal } from 'src/utilities/text.js';
import { registerSW } from 'virtual:pwa-register';

registerSW({ immediate: true });

window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  window.location.reload(); // for example, refresh the page
});
