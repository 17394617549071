import { css, html, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import type { DPopup } from 'src/library/components/d-popup';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog';

import 'src/library/elements/d-section';
import 'src/library/elements/d-wrap';
import 'src/library/fields/d-view-text';
import 'src/library/elements/d-action';
import 'src/library/editors/elements/d-select-date';
import 'src/library/components/pdf-viewer/pdf-viewer';
import 'src/library/elements/d-label';

import { LocalDate } from 'src/utilities/local-date';
import { classMap } from 'lit/directives/class-map.js';
import { signatureStatusStyles } from 'src/library/signature-status-styles';
import { FileViewerDocument } from 'src/layout/parts/file-viewer-dialog';

export interface SignableDocumentDialogInput {
  status: string;
  requestDate: string;
  dueDate: string;
  previewHref: string;
  signees: SigneesForSignableDocument[];
  asDocumentItem: () => Promise<FileViewerDocument>;
}

export interface CancelSigningSignableDocumentDialogResult {
  action: 'cancel-signing';
}

export interface ExtendSigningSignableDocumentDialogResult {
  action: 'extend-signing';
  dueDate: string;
}

export type SignableDocumentDialogResult =
  | CancelSigningSignableDocumentDialogResult
  | ExtendSigningSignableDocumentDialogResult
  | DialogCancelResult;

export interface SigneesForSignableDocument {
  status: string;
  label: string;
  secondaryLabel: string;
  rightLabel: string;
}

/**
 *
 * USAGE:
 *
 * STATUS OK
 */
@customElement('signable-document-dialog')
export class SignableDocumentDialog extends BaseDialog<SignableDocumentDialogInput, SignableDocumentDialogResult> {
  static styles = [
    ...BaseDialog.styles,
    signatureStatusStyles,
    css`
      .list {
        margin: 4px 0 6px 0;
      }

      d-wrap {
        align-items: baseline;
      }

      .actions {
        margin-right: -2px;
      }

      d-select-date {
        margin-top: -2px;
        margin-bottom: -2px;
      }

      .item {
        display: flex;
        align-items: baseline;
        color: var(--linkColorGray);
        line-height: var(--lineHeightDefault);
      }

      .icon {
        position: relative;
        top: 5px;
      }

      .right-label {
        font-weight: 200;
      }

      pdf-viewer {
        margin: 0 1px;
        border: 1px solid var(--borderColor);
        overflow: hidden;
      }
    `,
  ];
  @property({ type: String })
  status = '';
  @property({ type: String })
  requestDate = '';
  @property({ type: String })
  requestDeadline = '';
  @property({ type: String })
  originalRequestDeadline = '';
  @property({ type: Array })
  signees: SigneesForSignableDocument[] = [];
  @property({ type: String })
  requestMessage = '';
  @property({ type: String })
  previewHref = '';
  @query('d-popup')
  popup!: DPopup;
  @property({ type: Number })
  width = 600;
  @property({ type: String })
  title = '';
  @property({ type: Boolean })
  editDeadline = false;
  @property({ type: Boolean })
  showMessage = false;
  @property({ type: Boolean })
  noAnimation = false;
  asDocumentItem: (() => Promise<FileViewerDocument>) | undefined = undefined;

  renderBody() {
    return html`
      ${this.status === 'CLOSED'
        ? nothing
        : html`
            <d-section>
              <div>
                <d-wrap split>
                  <d-view-text
                    inline-label
                    label="Forespørsel sendt"
                    .value=${LocalDate.fromString(this.requestDate).toStringForDisplay()}
                  ></d-view-text>
                  <div class="actions">
                    ${this.status === 'OPEN'
                      ? html`
                          <d-action
                            mini
                            delete
                            @click=${async () => {
                              await this.onDispatchAction(false, 'cancel-signing');
                            }}
                            >Kanseller forespørsel</d-action
                          >
                        `
                      : nothing}
                  </div>
                </d-wrap>
              </div>
            </d-section>
            <d-section>
              <div>
                <d-wrap split>
                  ${this.editDeadline
                    ? html`
                        <d-select-date
                          inline-label
                          label="Frist for signering"
                          .disableBefore=${this.originalRequestDeadline}
                          .value=${LocalDate.now().plusDays(14).toString()}
                          @value-changed=${(e) => {
                            this.requestDeadline = e.detail.value;
                          }}
                        ></d-select-date>
                        <div class="actions">
                          <d-action
                            mini
                            @click=${() => {
                              this.requestDeadline = this.originalRequestDeadline;
                              return (this.editDeadline = false);
                            }}
                            >Avbryt</d-action
                          >
                          <d-action
                            mini
                            @click=${async () => {
                              this.editDeadline = false;
                              await this.onDispatchAction(false, 'extend-signing');
                            }}
                            >Ferdig</d-action
                          >
                        </div>
                      `
                    : html`
                        <d-view-text
                          inline-label
                          label="Frist for signering"
                          .value=${LocalDate.fromString(this.requestDeadline).toStringForDisplay()}
                        ></d-view-text>
                        <div class="actions">
                          ${this.status === 'OPEN'
                            ? html` <d-action mini @click=${() => (this.editDeadline = true)}>Utvid frist</d-action> `
                            : nothing}
                        </div>
                      `}
                </d-wrap>
              </div>
            </d-section>
            <d-section>
              <div>
                ${!this.showMessage
                  ? html`
                      <d-wrap split>
                        <d-label label="Melding"></d-label>
                        <div class="actions">
                          <d-action mini @click=${() => (this.showMessage = true)}>Vis meldingen</d-action>
                        </div>
                      </d-wrap>
                    `
                  : html` <d-view-text label="Melding" .value=${this.requestMessage}></d-view-text> `}
              </div>
            </d-section>
          `}
      <d-section>
        <div>
          <d-label label="${this.status === 'CLOSED' ? 'Signert av' : 'Skal signeres av'}"></d-label>
          <div class="list">
            ${this.signees.map(
              (item) =>
                html` 
                  <d-wrap split baseline>
                    <div class="item">
                      <div
                        class="icon ${classMap({
                          idle: item.status === 'OPEN',
                          signed: item.status === 'SIGNED',
                          error: item.status === 'REJECTED',
                          noAnimation: this.noAnimation,
                        })}"
                      ></div>
                      <d-label semibold .label=${item.label} .sublabel=${item.secondaryLabel}></d-label>
                    </div>
                    <div class="right-label">${item.rightLabel}</div>
                  </d-wrap>
                </div>`,
            )}
          </div>
        </div>
      </d-section>
      ${this.status === 'CLOSED'
        ? html` <d-section>
              <div>
                <d-wrap split>
                  <d-label label="Dokument"></d-label>
                  <div class="actions">
                    <d-action mini @click=${this.onClickDownload}>Last ned</d-action>
                    <d-action mini @click=${this.onClickOpen}>Åpne</d-action>
                  </div>
                </d-wrap>
              </div>
            </d-section>
            <pdf-viewer src="${this.previewHref}" ?no-animation=${this.noAnimation}></pdf-viewer>`
        : nothing}
    `;
  }

  protected fetchResult(detail: string | undefined): SignableDocumentDialogResult {
    if (detail === 'cancel-signing') {
      return {
        action: 'cancel-signing',
      };
    } else if (detail === 'extend-signing') {
      return {
        action: 'extend-signing',
        dueDate: this.requestDeadline,
      };
    }
    return {
      action: 'cancel',
    };
  }

  protected initializeDialog(input: SignableDocumentDialogInput) {
    this.status = input.status;
    this.requestDate = input.requestDate;
    this.requestDeadline = input.dueDate;
    this.originalRequestDeadline = input.dueDate;
    this.signees = input.signees;
    this.previewHref = input.previewHref;
    this.asDocumentItem = input.asDocumentItem;
  }

  private downloadURI(uri = '', name = '', target = '') {
    const link = document.createElement('a');
    link.download = name;
    link.href = uri;
    if (target !== '') {
      link.target = target;
    }
    this.appendChild(link);
    link.click();
    this.removeChild(link);
    link.remove();
  }

  private async onClickDownload() {
    if (this.asDocumentItem !== undefined) {
      const documentItem = await this.asDocumentItem();
      this.downloadURI(documentItem.urlForDownload, 'signert dokument');
    }
  }
  private async onClickOpen() {
    if (this.asDocumentItem !== undefined) {
      const documentItem = await this.asDocumentItem();
      this.downloadURI(documentItem.url, 'signert dokument', '_blank');
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'signable-document-dialog': SignableDocumentDialog;
  }
}
