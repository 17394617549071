import type { State } from '../../store/types.js';
import { AbstractPageView } from 'src/pages/abstract-page-view';
import { CollectionsPageViewModel } from 'src/pages/collections-page/d-collections-page-content';
import { storedCollections } from 'src/store/selectors/collections';
import { ListSectionItemInput } from 'src/library/lists/utilities';
import { entityNameForLists } from 'src/utilities/text';

export function collectionsPageView(
  hrefPrefix: string,
  viewModel: AbstractPageView,
  state: State,
): CollectionsPageViewModel {
  const o = state.organization;
  if (o === undefined) {
    throw new Error('Illegal state (E151), organization not found');
  }
  const collections: ListSectionItemInput[] = storedCollections(state).map((c) => {
    return {
      label: entityNameForLists(c.name, '', true),
      href: hrefPrefix + 'collections/' + c.uuid,
      accessible: true,
    };
  });
  return {
    ...viewModel,
    type: 'collections-page',
    icon: 'collections',
    href: hrefPrefix,
    pdfHref: '', // TODO
    collections,
  };
}
