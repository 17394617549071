import { css, html, nothing } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { BaseDialog, DialogCancelResult } from 'src/library/components/BaseDialog.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { Option } from 'src/library/editors/elements/d-select-radio';

import 'src/library/elements/d-smooth-resize';
import 'src/library/elements/d-section';
import 'src/library/fields/d-view-info';
import 'src/library/editors/elements/d-select-checkbox';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-select-radio';
import { TopicViewModel } from 'src/store/api';

export interface AddToCollectionDialogInput {
  entityUuid: string;
  entityType: string;
  collections: TopicViewModel[];
  recurringEventType: string;
  occurrenceUuids: string[];
}

export type AddToCollectionDialogResult =
  | DialogCancelResult
  | { action: 'done'; collections: TopicViewModel[]; newCollectionName?: string };

@customElement('add-to-collection-dialog')
export class AddToCollectionDialog extends BaseDialog<AddToCollectionDialogInput, AddToCollectionDialogResult> {
  static readonly styles = BaseDialog.styles.concat(css`
    d-select-checkbox {
      margin-top: -6px;
    }
    .new-collection {
      display: flex;
      margin-top: 8px;
    }
    .new-collection > div {
      width: 27px;
      height: 30px;
      background: url(/images/check-mini-gray.svg) -4px 2px no-repeat;
      background-size: 24px;
      opacity: 0.5;
      cursor: pointer;
    }
    .new-collection > div[disabled] {
      cursor: default;
    }
    .new-collection > div[selected] {
      background: url(/images/check-mini-blue.svg) -4px 2px no-repeat;
      background-size: 24px;
      opacity: 1;
    }
    d-edit-text {
      flex: 1;
    }
    d-select-radio {
      box-shadow: none;
      margin-top: -12px;
    }
  `);
  @property({ type: Boolean })
  show = true;
  @property({ type: String })
  entityUuid = '';
  @property({ type: String })
  entityType = '';
  @property({ type: Array })
  collections: TopicViewModel[] = [];
  @property({ type: String })
  recurringEventType = '';
  @property({ type: Array })
  occurrenceUuids: string[] = [];
  @property({ type: Boolean })
  allOcurrences = true;
  @property({ type: Number })
  width = 500;
  @state()
  newCollectionName = '';
  @state()
  newCollectionSelected = false;

  private readonly info = `<p>Samlinger er egendefinerte grupper av TrinnVis-dokumenter. Samlinger kan brukes til prosjekter, handlingsplaner og andre oversikter. Du finner alle samlinger i hovedmenyen.</p>`;

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      {
        name: 'Ferdig',
        action: 'done',
        disabled: this.collections.length === 0 && isEmptyOrInvalidString(this.newCollectionName),
      },
    ];
  }

  protected get calculatedTitle(): string {
    return 'Samlinger';
  }

  private get recurringEventInfo() {
    if (this.recurringEventType === 'eventOccurrences') {
      return `<p>Dette er en gjentatt oppgave. Vil du at alle gjentakelser av oppgaven skal tas med, eller bare denne?</p>`;
    }
    if (this.recurringEventType === 'meetingOccurrences') {
      return `<p>Dette møtet er del av en møteserie. Vil du at hele møteserien skal tas med, eller bare dette møtet?</p>`;
    }
    return '';
  }

  private get recurringEventOptions(): Option[] {
    if (this.recurringEventType === 'eventOccurrences') {
      return [
        { value: 'true', text: 'Alle gjentakelser' },
        { value: '', text: 'Bare denne' },
      ];
    }
    if (this.recurringEventType === 'meetingOccurrences') {
      return [
        { value: 'true', text: 'Hele møteserien' },
        { value: '', text: 'Bare dette møtet' },
      ];
    }
    return [];
  }

  private get allOcurrencesValue() {
    return this.allOcurrences ? 'true' : '';
  }

  private get collectionsOptions(): SelectDropdownOption[] {
    return this.collections.map((c) => {
      return { value: c.uuid, text: c.name };
    });
  }

  private get relatedCollections() {
    const result: string[] = [];
    this.collections.forEach((c) => {
      c.items.forEach((i) => {
        if (i.uuid === this.entityUuid) {
          result.push(c.uuid);
        }
      });
    });
    return result;
  }

  renderBody() {
    return html`
      <d-smooth-resize>
        <d-section>
          <d-view-info .content=${this.info}></d-view-info>
          <div class="fullWidth">
            ${this.collections.length > 0
              ? html` <d-select-checkbox
                  class="fullWidth"
                  checklist
                  .options=${this.collectionsOptions}
                  .value=${this.relatedCollections}
                  @value-changed=${(e) => this.onSelectedCollectionsChanged(e)}
                ></d-select-checkbox>`
              : nothing}
            <div class="new-collection">
              ${this.collections.length > 0
                ? html` <div
                    ?disabled="${isEmptyOrInvalidString(this.newCollectionName)}"
                    ?selected="${this.newCollectionSelected}"
                    @click=${() => {
                      if (this.newCollectionSelected) {
                        this.newCollectionSelected = false;
                        this.newCollectionName = '';
                      }
                    }}
                  ></div>`
                : nothing}
              <d-edit-text
                system-content
                class="fullWidth"
                placeholder="Ny samling"
                .autofocus=${this.collections.length === 0}
                .value=${this.newCollectionName}
                @value-changed=${(e) => this.onNewCollectionNameChanged(e)}
              ></d-edit-text>
            </div>
          </div>
          ${this.recurringEventType
            ? html` <d-view-info .content=${this.recurringEventInfo}></d-view-info
                ><d-select-radio
                  class="fullWidth"
                  .options=${this.recurringEventOptions}
                  .value=${this.allOcurrencesValue}
                  @value-changed=${(e) => this.onAllOccurrenvesChanged(e)}
                ></d-select-radio>`
            : nothing}
        </d-section>
      </d-smooth-resize>
    `;
  }

  protected fetchResult(detail: string | undefined): AddToCollectionDialogResult {
    if (detail === 'done') {
      return {
        action: 'done',
        collections: this.collections,
        newCollectionName: !isEmptyOrInvalidString(this.newCollectionName) ? this.newCollectionName : undefined,
      };
    } else {
      return {
        action: 'cancel',
      };
    }
  }

  protected initializeDialog(input: AddToCollectionDialogInput) {
    this.entityUuid = input.entityUuid;
    this.entityType = input.entityType;
    this.collections = input.collections;
    this.occurrenceUuids = input.occurrenceUuids;
    this.recurringEventType = input.recurringEventType;
  }

  private onAllOccurrenvesChanged(e) {
    if (e.detail.value === 'true') {
      this.allOcurrences = true;
    } else {
      this.allOcurrences = false;
    }
  }

  private updateCollection(selectedCollectionUuids: string[], occurrenceUuids: string[]) {
    this.collections = this.collections.map((collection) => {
      if (selectedCollectionUuids.includes(collection.uuid)) {
        let exists = false;
        collection.items.forEach((item) => {
          if (item.uuid === this.entityUuid) {
            exists = true;
          }
        });
        if (!exists) {
          occurrenceUuids.forEach((uuid) => {
            collection.items.push({ uuid, type: this.entityType });
          });
        }
      } else {
        collection.items = collection.items.filter((item) => {
          return !occurrenceUuids.includes(item.uuid);
        });
      }
      return collection;
    });
  }

  private onSelectedCollectionsChanged(e) {
    e.preventDefault();
    let occurrenceUuids: string[] = [this.entityUuid];
    if (this.allOcurrences && this.occurrenceUuids.length) {
      occurrenceUuids = this.occurrenceUuids;
    }
    this.updateCollection(e.detail.value, occurrenceUuids);
  }

  private onNewCollectionNameChanged(e) {
    e.stopPropagation();
    this.newCollectionName = e.detail.value;
    if (isEmptyOrInvalidString(this.newCollectionName)) {
      this.newCollectionSelected = false;
    } else {
      this.newCollectionSelected = true;
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'add-to-collection-dialog': AddToCollectionDialog;
  }
}
