import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { dateToMonths, monthsToDateFirst, monthsToDateLast, timespanType } from 'src/utilities/timespan';
import { EditTimespanDialog, EditTimespanDialogResult } from 'src/library/editors/components/edit-timespan-dialog';
import { LocalDate } from 'src/utilities/local-date';
import { endYear } from 'src/store';

/**
 *
 */
@customElement('d-edit-timespan')
export class DEditTimespan extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      align-items: center;
    }
    d-label {
      margin-right: 6px;
    }
    .prev,
    .next {
      flex: none;
      position: relative;
      width: 24px;
      height: 24px;
      background: url('/images/calendar-prev.svg') 50% 50% no-repeat;
      background-size: 16px 16px;
      cursor: pointer;
      opacity: 0.3;
    }
    @media (hover: hover) {
      .prev:hover,
      .next:not([disabled]):hover {
        opacity: 0.8;
      }
    }
    .next {
      background-image: url('/images/calendar-next.svg');
    }
    .next[disabled] {
      opacity: 0;
      cursor: default;
    }
    .text {
      display: inline-block;
      border: 1px solid var(--borderColor);
      border-radius: var(--inputElementBorderRadius);
      padding: 4px 8px 0 8px;
      height: 32px;
      box-sizing: border-box;
      font-weight: 200;
      cursor: pointer;
    }
  `;
  @property({ type: String })
  start = '';
  @property({ type: String })
  end = '';
  @property({ type: String })
  text = '';

  private get prevDates(): { start: string; end: string } {
    const type = timespanType(this.start, this.end);
    let start: string;
    let end: string;
    if (type === 'DAYS') {
      const startDate = LocalDate.fromString(this.start);
      const endDate = LocalDate.fromString(this.end);
      const days = startDate.until(endDate) + 1;
      start = startDate.minusDays(days).toString();
      end = startDate.minusDays(1).toString();
    } else {
      const startInMonths = dateToMonths(this.start);
      const endInMonths = dateToMonths(this.end);
      const durationInMonths = endInMonths - startInMonths + 1;
      const newStartInMonths = startInMonths - durationInMonths;
      start = monthsToDateFirst(newStartInMonths);
      end = LocalDate.fromString(this.start).minusDays(1).toString();
    }
    return { start, end };
  }

  private get nextDates(): { start: string; end: string } | undefined {
    const type = timespanType(this.start, this.end);
    let start: string;
    let end: string;
    if (type === 'DAYS') {
      const startDate = LocalDate.fromString(this.start);
      const endDate = LocalDate.fromString(this.end);
      const days = startDate.until(endDate) + 1;
      start = endDate.plusDays(1).toString();
      end = endDate.plusDays(days).toString();
    } else {
      const startInMonths = dateToMonths(this.start);
      const endInMonths = dateToMonths(this.end);
      const durationInMonths = endInMonths - startInMonths + 1;
      const newEndInMonths = endInMonths + durationInMonths;
      const newEnd = monthsToDateLast(newEndInMonths);
      start = LocalDate.fromString(this.end).plusDays(1).toString();
      end = newEnd;
    }
    if (LocalDate.fromString(end).year() > endYear) {
      return undefined;
    }
    return { start, end };
  }

  prev() {
    if (this.prevDates) {
      this.setDates(this.prevDates);
    }
  }

  next() {
    if (this.nextDates) {
      this.setDates(this.nextDates);
    }
  }

  private setDates(dates: { start: string; end: string }) {
    this.dispatchEvent(
      new CustomEvent('value-changed', {
        composed: true,
        bubbles: true,
        detail: dates,
      }),
    );
  }

  private async editTimespanDialog() {
    const result: EditTimespanDialogResult = await EditTimespanDialog.open({
      start: this.start,
      end: this.end,
    });
    if (result.action === 'done') {
      const dates = { start: result.start ?? '', end: result.end ?? '' };
      this.setDates(dates);
    }
  }

  render() {
    return html`
      <d-label label="Periode"></d-label>
      <div class="prev" @click=${() => this.prev()}></div>
      <span class="text" @click=${() => this.editTimespanDialog()}>${this.text}</span>
      <div class="next" ?disabled=${this.nextDates === undefined} @click=${() => this.next()}></div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-collection-filter': DEditTimespan;
  }
}
