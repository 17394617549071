import type { MeetingOccurrenceViewModel } from 'src/store/api';
import type { State } from 'src/store/types.js';
import * as dabihStore from 'src/store';
import {
  assetsNotDeleted,
  BASE_PATH,
  constitutionalDocumentsNotDeleted,
  contractsNotDeleted,
  currentEmployeeUuid,
  currentUserUuid,
  currentWeekRemainingTasksAndMeetingsForEmployee,
  documentsNotDeleted,
  employeeContractsAsListItems,
  employeeShortNamesAndIAsText,
  employeesNotDeleted,
  employeesShortNamesAndMe,
  eventOccurrencesNotDeleted,
  functionsNotDeleted,
  getContactsAsUsers,
  getOrganization,
  guidelinesNotDeleted,
  isIssueProcessed,
  isRestricted,
  issuesNotDeleted,
  key,
  leavePeriodsToday,
  meetingOccurrencesNotDeleted,
  partnersNotDeleted,
  reportsNotDeleted,
  riskAssessmentsNotDeleted,
  singleUserVersion,
  StartTaskInstance,
  startTasks,
  substancesNotDeleted,
  tasksNotDeleted,
  timekeepingPeriodsForCurrentEmployee,
} from 'src/store';
import {
  DocumentUpdatesForStatusPage,
  StartTasksForStatusPage,
  StatusPageViewModel,
} from 'src/pages/status-page/d-status-page-content';
import { ListSectionItemInput } from 'src/library/lists/utilities';
import { calculateUpdateStatus } from 'src/models/resolvers/calculate-update-status';
import { LocalDate, mondayThisWeek } from 'src/utilities/local-date';
import { orderBy, sortBy } from 'lodash';
import { eventAssigneesText, meetingParticipantsText } from 'src/store/utilities';
import { capitalize, entityNameForLists, numberWord } from 'src/utilities/text';
import { calculateBalance } from 'src/models/pages/staffing-page-view';
import { AbstractPageView } from 'src/pages/abstract-page-view';
import { getIssueLabel } from 'src/store/selectors/issues';

interface ListSectionItemInputWithDate extends ListSectionItemInput {
  date: string;
}

interface Count {
  templateDeleted: number;
  templateUpdated: number;
  documentUpdated: number;
}
function documentLists(state: State) {
  const lists = [
    { type: 'assets', list: assetsNotDeleted(state) },
    { type: 'constitutionalDocuments', list: constitutionalDocumentsNotDeleted(state) },
    { type: 'contracts', list: contractsNotDeleted(state) },
    { type: 'documents', list: documentsNotDeleted(state) },
    { type: 'employees', list: employeesNotDeleted(state) },
    { type: 'eventOccurrences', list: eventOccurrencesNotDeleted(state) },
    { type: 'functions', list: functionsNotDeleted(state) },
    { type: 'guidelines', list: guidelinesNotDeleted(state) },
    { type: 'issues', list: issuesNotDeleted(state) },
    { type: 'meetingOccurrences', list: meetingOccurrencesNotDeleted(state) },
    { type: 'partners', list: partnersNotDeleted(state) },
    { type: 'reports', list: reportsNotDeleted(state) },
    { type: 'riskAssessments', list: riskAssessmentsNotDeleted(state) },
    { type: 'substances', list: substancesNotDeleted(state) },
    { type: 'tasks', list: tasksNotDeleted(state) },
  ];
  if (singleUserVersion(state)) {
    return lists.filter((l) => {
      return l.type !== 'functions' && l.type !== 'meetingOccurrences';
    });
  }
  return lists;
}

function getDocumentUpdates(state: State, hrefPrefix: string): DocumentUpdatesForStatusPage {
  const items: ListSectionItemInputWithDate[] = [];
  const count: Count = {
    templateDeleted: 0,
    templateUpdated: 0,
    documentUpdated: 0,
  };
  documentLists(state).forEach((type) => {
    type.list.forEach((item) => {
      const updateStatus = calculateUpdateStatus(item, LocalDate.now());
      if (
        updateStatus === 'templateDeleted' ||
        updateStatus === 'templateUpdated' ||
        updateStatus === 'documentUpdated'
      ) {
        let date = item[updateStatus];
        if (updateStatus === 'documentUpdated') {
          date = item.contentLastModified;
        }
        count[updateStatus] += 1;
        items.push({
          date,
          label: entityNameForLists(item.name, item.draftName, item.isConfirmedEntity),
          accessible: !isRestricted(item, currentUserUuid(state)),
          href: hrefPrefix + type.type + '/' + item.uuid,
          updateStatus,
        });
      }
    });
  });
  const sorted = orderBy(items, ['date'], ['desc']).map((item) => {
    return {
      label: LocalDate.fromString(item.date).toStringForDisplay() + ' ' + item.label,
      accessible: item.accessible,
      href: item.href,
      updateStatus: item.updateStatus,
    };
  });
  return {
    summary: [
      {
        label: (count.documentUpdated || 'Ingen') + ' oppdaterte dokumenter',
        accessible: false,
        updateStatus: 'documentUpdated',
      },
      {
        label: (count.templateUpdated || 'Ingen') + ' forslag til oppdateringer',
        accessible: false,
        updateStatus: 'templateUpdated',
      },
      {
        label: (count.templateDeleted || 'Ingen') + ' forslag til slettinger',
        accessible: false,
        updateStatus: 'templateDeleted',
      },
    ],
    items: sorted,
  };
}

function getOverdueEvents(state: State, hrefPrefix: string): ListSectionItemInput[] {
  const today = LocalDate.now();
  return eventOccurrencesNotDeleted(state)
    .filter((e) => {
      return !e.doneDate && e.persistent && LocalDate.fromString(e.date).isBefore(today);
    })
    .map((e) => {
      return {
        label:
          LocalDate.fromString(e.date).toStringForDisplay() +
          ' ' +
          entityNameForLists(e.name, e.draftName, e.isConfirmedEntity),
        rightLabel: eventAssigneesText(
          e,
          functionsNotDeleted(state),
          employeesShortNamesAndMe(state),
          getContactsAsUsers(state),
        ),
        rightLabelClass: 'assignee',
        accessible: true,
        href: hrefPrefix + 'eventOccurrences/' + e.uuid,
      };
    });
}

function getDrafts(state: State, hrefPrefix: string): ListSectionItemInput[] {
  const items: ListSectionItemInputWithDate[] = [];
  documentLists(state).forEach((type) => {
    type.list.forEach((item) => {
      if (item.hasDraft) {
        const date = item.contentLastModified;
        items.push({
          date,
          label: entityNameForLists(item.name, item.draftName, item.isConfirmedEntity),
          accessible: !isRestricted(item, currentUserUuid(state)),
          href: hrefPrefix + type.type + '/' + item.uuid,
        });
      }
    });
  });
  return orderBy(items, ['date'], ['desc']).map((item) => {
    return {
      label: item.label,
      accessible: item.accessible,
      href: item.href,
    };
  });
}

function getIssues(state: State, hrefPrefix: string): ListSectionItemInput[] {
  const issues = orderBy(
    issuesNotDeleted(state)
      .filter((item) => {
        return !isIssueProcessed(item);
      })
      .map((item) => {
        const accessible = !isRestricted(item, currentUserUuid(state));
        return {
          date: item.reportedDate,
          label: getIssueLabel(item, accessible ?? false, employeesNotDeleted(state)),
          accessible,
          href: hrefPrefix + 'issues/' + item.uuid,
          locked: item.classification !== 'NONE',
        };
      }),
    ['date'],
    ['desc'],
  );
  return issues.map((item) => {
    delete item.date;
    return item;
  });
}

function getStartTasks(startTasks: StartTaskInstance[]): StartTasksForStatusPage {
  const done = startTasks.filter((t) => {
    return t.executed;
  });
  const remaining = startTasks.filter((t) => {
    return !t.executed;
  });
  let statusText = '';
  if (startTasks.length === done.length) {
    statusText = 'Alle etableringsoppgaver er utført';
  } else if (done.length === 0) {
    statusText = 'Ingen etableringsoppgaver er utført';
  } else {
    statusText = done.length + ' av ' + startTasks.length + ' etableringsoppgaver er utført';
  }
  return {
    total: startTasks.length,
    done: done.length,
    statusText,
    items: remaining.map((t) => {
      return {
        label: t.name,
        accessible: true,
        clickData: t.uuid,
      };
    }),
  };
}

function getFutureMeetings(state: State, hrefPrefix: string): ListSectionItemInput[] | undefined {
  if (singleUserVersion(state)) {
    return undefined;
  }
  const today = LocalDate.now();
  const futureMeetings = meetingOccurrencesNotDeleted(state).filter((m: MeetingOccurrenceViewModel): boolean => {
    const meetingDate = LocalDate.fromString(m.date);
    return (
      meetingDate.isSameOrAfter(today) &&
      meetingDate.isBefore(today.plusDays(3)) &&
      !m.name?.includes('Medarbeidersamtale')
    );
  });
  const sortedMeetings = sortBy(futureMeetings, ['date']);
  return sortedMeetings.map((m: MeetingOccurrenceViewModel): ListSectionItemInput => {
    return {
      label: LocalDate.fromString(m.date).toStringForDisplay() + ' ' + m.name,
      accessible: true,
      href: hrefPrefix + 'meetingOccurrences/' + m.uuid,
      rightLabel: meetingParticipantsText(
        m,
        functionsNotDeleted(state),
        employeesShortNamesAndMe(state),
        getContactsAsUsers(state),
        true,
      ),
      rightLabelClass: 'assignee',
    };
  });
}

function getInterviewInfo(state: State): { status: string; text: string }[] | undefined {
  if (singleUserVersion(state)) {
    return undefined;
  }
  const result: { status: string; text: string }[] = [];
  const today = LocalDate.now();
  const employeesInterviewState = employeesNotDeleted(state)
    .filter((e) => {
      return e.name !== '' && e.status === 'ACTIVE';
    })
    .map((e) => {
      const interviews = meetingOccurrencesNotDeleted(state).filter((m) => {
        return m.name?.includes('Medarbeidersamtale') && m.participants.includes(e.uuid);
      });
      if (!interviews.length) {
        return { uuid: e.uuid, state: 'none' };
      }
      const futureOrRecent = interviews.find((i) => {
        const day = LocalDate.fromString(i.date);
        return day.isSameOrAfter(today) || day.isSameOrAfter(today.minusDays(366));
      });
      if (futureOrRecent) {
        return { uuid: e.uuid, state: 'ok' };
      }
      return { uuid: e.uuid, state: 'overdue' };
    });
  const overdue = employeesInterviewState.filter((e) => {
    return e.state === 'overdue';
  });
  const ok = employeesInterviewState.filter((e) => {
    return e.state === 'ok';
  });
  if (ok.length) {
    const uuids = ok.map((n) => {
      return n.uuid;
    });
    result.push({
      status: 'checked',
      text: capitalize(
        employeeShortNamesAndIAsText(uuids, state) + ' har hatt medarbeidersamtale i løpet av det siste året',
      ),
    });
  }
  if (overdue.length) {
    const uuids = overdue.map((n) => {
      return n.uuid;
    });
    result.push({
      status: 'alert',
      text: capitalize(employeeShortNamesAndIAsText(uuids, state) + ' har ikke hatt medarbeidersamtale på over et år'),
    });
  }
  const none = employeesInterviewState.filter((e) => {
    return e.state === 'none';
  });
  if (none.length) {
    const uuids = none.map((n) => {
      return n.uuid;
    });
    result.push({
      status: 'alert',
      text: capitalize(employeeShortNamesAndIAsText(uuids, state) + ' har aldri hatt medarbeidersamtale'),
    });
  }
  return result;
}

const periodText = (): string => {
  const today = LocalDate.now();
  if (mondayThisWeek().isSame(today)) {
    return 'denne uken';
  }
  return 'resten av denne uken';
};

function getCurrentEmployeeInfo(state: State): { type: string; text: string; class: string }[] | undefined {
  if (singleUserVersion(state)) {
    return undefined;
  }
  const result: { type: string; text: string; class: string }[] = [];
  const employeeUuid = currentEmployeeUuid(state) ?? '';
  const eventsCount = currentWeekRemainingTasksAndMeetingsForEmployee(state);
  let eventsCountText = 'Ingen oppgaver ' + periodText();
  if (eventsCount > 0) {
    eventsCountText = 'Én oppgave ' + periodText();
    if (eventsCount > 1) {
      eventsCountText = capitalize(numberWord(eventsCount)) + ' oppgaver ' + periodText();
    }
  }
  result.push({
    type: 'first',
    text: eventsCountText,
    class: '',
  });
  const functions = functionsNotDeleted(state).filter((f) => {
    return f.employees.includes(employeeUuid);
  });
  let functionCountText = 'Ett ansvarsområde';
  if (functions.length > 1) {
    functionCountText = capitalize(numberWord(functions.length)) + ' ansvarsområder';
  }
  result.push({
    type: 'second',
    text: functionCountText,
    class: 'checked',
  });
  const contracts = employeeContractsAsListItems(state, '', employeeUuid);
  if (contracts) {
    let contractsCountText = 'Én avtale';
    if (contracts.length > 1) {
      contractsCountText = capitalize(numberWord(contracts.length)) + ' avtaler';
    }
    result.push({
      type: 'second',
      text: contractsCountText,
      class: 'checked',
    });
  }
  const balance = Math.round(calculateBalance(timekeepingPeriodsForCurrentEmployee(state)) * 10) / 10;
  result.push({
    type: 'second',
    text: balance + ' avspaseringstimer',
    class: balance < 0 ? 'alert' : 'checked',
  });
  return result;
}

function getFunctionsInfo(state: State, hrefPrefix: string): ListSectionItemInput[] | undefined {
  if (singleUserVersion(state)) {
    return undefined;
  }
  const unassignedFunctions = functionsNotDeleted(state).filter((f) => {
    return f.employees.length === 0;
  });
  if (unassignedFunctions.length) {
    return unassignedFunctions.map((f) => {
      return {
        label: f.name + ' er ikke tildelt noen',
        mainIcon: 'alert',
        href: hrefPrefix + 'functions/' + f.uuid,
        accessible: true,
      };
    });
  }
  return [
    {
      label: 'Alle ansvarsområder er bemannet',
      mainIcon: 'checkBlue',
      accessible: true,
    },
  ];
}

export function statusPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): StatusPageViewModel {
  const organization = getOrganization(state);
  if (organization === undefined) {
    throw new Error('Illegal state (E154), organization not found');
  }
  const organizationId = dabihStore.getOrganizationId(state);
  const isInternalOrganization =
    organization.ownerEmail.endsWith('@trinnvis.no') || organization.ownerEmail.endsWith('@mailinator.com');
  return {
    ...viewModel,
    type: 'status-page',
    icon: 'robot',
    href: hrefPrefix,
    key: dabihStore.key(state),
    pdfHref: BASE_PATH + '/organizations/' + organizationId + '/substances/substances-list.pdf?key=' + key(state),
    isInternalOrganization,
    leavePeriodsToday: singleUserVersion(state) ? undefined : leavePeriodsToday(state),
    overdueEvents: getOverdueEvents(state, hrefPrefix),
    drafts: getDrafts(state, hrefPrefix),
    issues: getIssues(state, hrefPrefix),
    documentUpdates: getDocumentUpdates(state, hrefPrefix),
    startTasks: getStartTasks(startTasks(state)),
    futureMeetings: getFutureMeetings(state, hrefPrefix),
    interviewInfo: getInterviewInfo(state),
    currentEmployeeInfo: getCurrentEmployeeInfo(state),
    functionsInfo: getFunctionsInfo(state, hrefPrefix),
    ownerEmail: organization.ownerEmail,
  };
}
