import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';

import { customElement, property } from 'lit/decorators.js';
import '../fields/d-expansion.js';
import '../fields/d-closer.js';
import '../fields/d-view-info.js';

/**
 *
 * The help section closes whenever the content changes, ie in connection with navigating to a different page.
 *
 * STATUS OK
 */
@customElement('d-help-section')
export class DHelpSection extends LitElement {
  private openHelp() {
    this.opened = true;
  }
  private closeHelp() {
    this.opened = false;
  }

  private previousContent = '';

  protected updated(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('content') && this.previousContent && this.opened) {
      this.opened = false;
    }
    this.previousContent = this.content;
  }

  private _wrapperClasses() {
    let classes = 'wrapper';
    if (this.opened) {
      classes += ' open';
    }
    return classes;
  }

  @property({ type: Boolean, reflect: true })
  opened = false;
  @property({ type: String })
  content = '';
  @property({ type: Boolean })
  helpOnly = false;

  static readonly styles = css`
    :host {
      display: block;
      font-family: var(--mainSans);
    }

    .wrapper {
      position: relative;
      max-width: 724px;
      box-sizing: border-box;
      padding: 8px;
      margin: 20px 0;
      background: hsla(1, 0%, 0%, 0.04) url(/images/info.svg) 1px 1px no-repeat;
      background-size: 32px 32px;
      border: 1px solid hsla(1, 0%, 0%, 0.07);
      border-radius: 20px;
      word-wrap: normal;
      transition: all 0.3s;
      cursor: pointer;
    }

    .wrapper.open {
      border-radius: 14px;
      background-image: none;
      cursor: default;
    }

    .content {
      padding: 12px;
      -webkit-user-select: text;
      -ms-user-select: text;
      -moz-user-select: text;
      user-select: text;
    }

    .opener {
      padding-left: 26px;
      color: var(--linkColorGray);
      line-height: 120%;
      opacity: 1;
      transition: opacity 0.3s;
    }

    .wrapper:hover .opener {
      color: black;
    }

    .wrapper.open .opener {
      opacity: 0;
    }

    d-view-info {
      margin-bottom: 0;
    }
  `;

  private get showCloser() {
    return !this.helpOnly && this.opened;
  }

  render() {
    return html`
      ${this.content
        ? html`
            <div class="${this._wrapperClasses()}">
              <d-expansion ?opened=${!this.opened} @click=${this.openHelp}>
                <div class="opener">Hjelp, informasjon og referanser</div>
              </d-expansion>
              <d-expansion id="help" ?opened=${this.opened}>
                <div class="content">
                  ${this.showCloser ? html`<d-closer @click=${this.closeHelp}></d-closer>` : nothing}
                  <d-view-info help .content=${this.content}></d-view-info>
                </div>
              </d-expansion>
            </div>
          `
        : nothing}
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-help-section': DHelpSection;
  }
}
