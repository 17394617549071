import { css, html, LitElement, nothing, TemplateResult } from 'lit';
import '../elements/d-tooltip.js';
import '../elements/d-label.js';
import { property } from 'lit/decorators.js';

/**
 * Et abstrakt element som brukes som felles oppsett av "fields".
 *
 * Forenklet fra tidligere bruk. Mye av sectionInnerElement er flyttet til d-section direkte.
 * Det som gjenstår er at feltet kan ha en "overskrift"
 *
 * Representerer det som tidligere var
 *  <div class="sectionElementInner">
 *         <d-label classes="big" field="generalFields_checklist"></d-label>
 *
 * Det vil si elementer laget for å vises i et "Entity-view"
 *
 * Dette skal ikke brukes direkte men arves fra i enkeltelementer
 *
 * Klassene som arver implementerer renderContent og setter
 * css = [super.css, css' ...mer spesifikk css ...']
 *
 * Poenget med disse elementene er at de har border på venstre og høyre, men ikke dobbelt hvis flere vises ved siden av hverandre.
 *
 */
export class LabeledElement extends LitElement {
  static readonly styles = [
    css`
      :host {
        display: block;
      }

      :host([inline-label]) {
        display: flex;
      }

      d-label {
        display: block;
        padding: 0 6px 6px 0;
      }

      :host([small-label]) d-label {
        padding-bottom: 2px;
      }

      :host([inline-label]) d-label {
        flex: none;
        display: flex;
        padding: 5px 6px 0 0;
      }

      :host([light-label]) d-label {
        font-weight: normal;
      }

      input,
      textarea,
      .select-wrapper,
      .radioIcon,
      .checkIcon {
        background-color: var(--backgroundLightGray);
        box-shadow: var(--inputElementShadow);
      }

      input,
      textarea,
      .grow-wrap,
      .select-wrapper {
        box-sizing: border-box;
        width: 100%;
        border: none;
        border-radius: 0;
        outline: none;
        -webkit-appearance: none;
        font-family: var(--mainSerif), serif;
        font-weight: 200;
        font-size: 15px;
        line-height: 160%;
        color: black;
      }

      input,
      .select-wrapper {
        height: var(--inputElementHeight);
      }

      input {
        display: flex;
        align-items: center;
        padding: 0 14px 0 10px;
      }

      textarea {
        resize: none;
      }

      ::placeholder,
      select.placeholder {
        color: var(--placeholderColor);
      }

      input[disabled],
      textarea[disabled] {
        opacity: 0.5;
      }

      select.placeholder:focus {
        color: black;
      }

      :host([theme-page]) input,
      :host([outskirts]) input,
      :host([controller]) input,
      :host([theme-page]) textarea,
      :host([outskirts]) textarea,
      :host([controller]) textarea,
      :host([theme-page]) .select-wrapper,
      :host([outskirts]) .select-wrapper,
      :host([controller]) .select-wrapper,
      :host([theme-page]) .radioIcon,
      :host([outskirts]) .radioIcon,
      :host([controller]) .radioIcon,
      :host([theme-page]) .checkIcon,
      :host([outskirts]) .checkIcon,
      :host([controller]) .checkIcon,
      :host([theme-page]) d-radio-cell,
      :host([outskirts]) d-radio-cell,
      :host([controller]) d-radio-cell {
        box-shadow: none;
        background-color: white;
      }

      :host([outskirts]) input[type='text'],
      :host([outskirts]) input[type='email'],
      :host([outskirts]) input[type='password'],
      :host([outskirts]) textarea,
      :host([outskirts]) .select-wrapper,
      :host([outskirts]) .checkIcon,
      :host([theme-page]) textarea,
      :host([theme-page]) .select-wrapper,
      :host([theme-page]) input,
      :host([theme-page]) .checkIcon,
      :host([controller]) textarea,
      :host([controller]) .select-wrapper,
      :host([controller]) input,
      :host([controller]) .checkIcon {
        border-radius: var(--inputElementBorderRadius);
      }

      :host([system-content]) input,
      :host([system-content]) textarea,
      :host([system-content]) select {
        font-family: var(--mainSans), sans-serif;
        font-weight: normal;
      }

      :host([outskirts]) input {
        height: var(--inputElementHeight);
        margin: 0;
        border: none;
      }

      :host([theme-page]) input,
      :host([theme-page]) input[type='search'],
      :host([theme-page]) input[type='password'],
      :host([theme-page]) textarea,
      :host([theme-page]) .select-wrapper,
      :host([controller]) input,
      :host([controller]) input[type='search'],
      :host([controller]) input[type='password'],
      :host([controller]) textarea,
      :host([controller]) .select-wrapper {
        height: calc(var(--inputElementHeight) + 2px);
        border: 1px solid var(--borderColor);
      }

      :host([controller]) input,
      :host([controller]) input[type='search'],
      :host([controller]) input[type='password'],
      :host([controller]) textarea,
      :host([controller]) .select-wrapper {
        font-family: var(--mainSans), sans-serif;
        font-weight: 200;
      }

      :host([controller]) select {
        font-family: var(--mainSans), sans-serif;
        font-weight: 200;
      }

      :host([theme-page]) .checkIcon,
      :host([theme-page]) d-radio-cell,
      :host([controller]) .checkIcon,
      :host([controller]) d-radio-cell {
        border: 1px solid var(--borderColor);
      }

      :host([theme-page]) d-radio-cell,
      :host([controller]) d-radio-cell {
        margin-top: -3px;
      }

      :host([outskirts]) input,
      :host([outskirts]) .select-wrapper {
        height: var(--inputElementHeight);
        margin: 0;
        border: none;
      }

      .select-wrapper[disabled] {
        opacity: 0.5;
      }

      input + div {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        background: transparent url(/images/x-thin-black.svg) 50% 50% no-repeat;
        background-size: 20px 20px;
        opacity: 0.34;
        cursor: pointer;
      }

      :host([controller]) input + div {
        top: 1px;
      }

      input + div:hover {
        opacity: 1;
      }

      :host([disabled]) input + div:hover {
        opacity: 0.34;
        cursor: default;
      }
    `,
  ];

  @property({ type: String })
  field = '';
  @property({ type: String })
  label = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: Boolean, attribute: true, reflect: true })
  disabled = false;
  @property({ type: Boolean, attribute: 'theme-page', reflect: true })
  themePage = false;
  @property({ type: Boolean, attribute: 'outskirts' })
  outskirts = false;
  @property({ type: Boolean, attribute: 'system-content' })
  systemContent = false;
  @property({ type: Boolean, attribute: 'semibold-label' })
  semiboldLabel = false;
  @property({ type: Boolean, attribute: 'light-label' })
  lightLabel = false;
  @property({ type: Boolean, attribute: 'small-label' })
  smallLabel = false;
  @property({ type: Boolean, attribute: 'big-label' })
  bigLabel = false;
  @property({ type: Boolean, attribute: 'wrap-label', reflect: true })
  wrapLabel = false;
  @property({ type: Boolean, attribute: 'inline-label' })
  inlineLabel = false;
  @property({ type: Number, attribute: 'label-min-width' })
  labelMinWidth = 0;
  @property({ type: String, attribute: 'label-action' })
  labelAction = '';
  @property({ type: Boolean, attribute: 'label-action-mini' })
  labelActionMini = false;
  @property({ type: Boolean, attribute: 'label-action-plain' })
  labelActionPlain = false;

  /**
   * Optional classes for the label.
   */
  @property({ type: String })
  classes = '';

  private get styles() {
    if (this.labelMinWidth > 0) {
      return 'min-width:' + this.labelMinWidth + 'px';
    }
    return '';
  }

  render() {
    return html` ${this.renderLabel()} ${this.renderContent()} `;
  }

  protected renderContent(): TemplateResult | typeof nothing {
    return nothing;
  }

  private renderLabel() {
    return html` ${this.label || this.field
      ? html`<d-label
          .classes=${this.classes}
          style="${this.styles}"
          .field=${this.field}
          .label=${this.label}
          ?allow-wrap=${this.wrapLabel}
          .sublabel=${this.sublabel}
          ?semibold=${this.semiboldLabel}
          ?light=${this.lightLabel}
          ?small=${this.smallLabel}
          ?big=${this.bigLabel}
          ?outskirts=${this.outskirts}
          ?theme-page=${this.themePage}
          .allowWrap=${this.wrapLabel}
          .labelAction=${this.labelAction}
          ?label-action-plain="${this.labelActionPlain}"
          ?label-action-mini="${this.labelActionMini}"
        ></d-label>`
      : nothing}`;
  }
}
