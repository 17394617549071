import { fetchDraft, pagesAsSelectTagOptions } from 'src/models/factory-utilities.js';
import { BASE_PATH, ContactPersonViewModel, PartnerViewModel } from 'src/store/api';
import {
  allEqual,
  contactsDeleted,
  contactsNotDeleted,
  currentUserUuid,
  employeesShortNamesAndMe,
  getFilterEndDate,
  getFilterPeople,
  getFilterStartDate,
  getOrganization,
  key,
  meetingsNotDeleted,
  partnersDeleted,
  partnersNotDeleted,
  partnersWithContacts,
  tasksNotDeleted,
  toTopicViewModel,
} from 'src/store/selectors';
import type { State } from 'src/store/types.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { assertIsDefined } from 'src/lib';
import { CommonDataEntityView } from 'src/content/entity-content';
import {
  CollectionView,
  CollectionViewEditItem,
  OptionWithPartner,
  PartnerWithContacts,
} from 'src/content/collections/d-collection-view';
import {
  CollectionItem,
  CollectionEntity,
  getCollectionEntities,
  getItemsAsCollectionEntities,
  storedCollections,
  collectionsFilter,
  suggestionsToListItems,
  filterEntities,
  sortEntities,
} from 'src/store/selectors/collections';
import { sortBy } from 'lodash';
import { ApiClient } from 'src/store';
import { joinWithAnd } from 'src/utilities/text';
import { timespanText } from 'src/utilities/timespan';
import { ListItemIcon, ListSectionItemInput } from 'src/library/lists/utilities';

function partnersWithSelectedContacts(state: State, selectedContacts: string[] | undefined): PartnerViewModel[] {
  const partnerUuidsOfSelectedContacts: string[] = contactsNotDeleted(state)
    .concat(contactsDeleted(state))
    .filter((contact) => {
      return selectedContacts?.includes(contact.uuid);
    })
    .map((contact) => {
      return contact.partnerUuid;
    });
  return partnersNotDeleted(state)
    .concat(partnersDeleted(state))
    .filter((partner) => {
      return partnerUuidsOfSelectedContacts.includes(partner.uuid);
    });
}

function deletedSelectedContacts(state: State, selectedContacts: string[] | undefined): ContactPersonViewModel[] {
  return contactsDeleted(state).filter((item) => {
    return selectedContacts?.includes(item.uuid);
  });
}

export function employeesPartnersAndContactsOptions(
  state: State,
  assigneeUuids: string[] | undefined,
): OptionWithPartner[] {
  const employeesOptions: OptionWithPartner[] = employeesShortNamesAndMe(state)
    .filter((item) => item.name !== '')
    .filter((item) => item.status !== 'TERMINATED' || (assigneeUuids ?? []).includes(item.uuid))
    .map((item) => ({ value: item.uuid, text: item.name }));
  const partnersAndContactsOptions: OptionWithPartner[] = [];
  const contacts = contactsNotDeleted(state).concat(deletedSelectedContacts(state, assigneeUuids));
  const partners = sortBy(partnersWithSelectedContacts(state, assigneeUuids), ['name']);
  partners.forEach((partner) => {
    partnersAndContactsOptions.push({ value: partner.uuid, text: partner.name });
    const partnerContacts = sortBy(
      contacts.filter((contact) => {
        return contact.partnerUuid === partner.uuid;
      }),
      ['name'],
    );
    partnerContacts.forEach((contact) => {
      let space = '';
      if (contact.firstName && contact.lastName) {
        space = ' ';
      }
      const name = contact.firstName + space + contact.lastName;
      partnersAndContactsOptions.push({
        value: contact.uuid,
        text: '\xA0\xA0' + name,
        partnerUuid: partner.uuid,
        partnerName: partner.name,
      });
    });
  });
  return sortBy(employeesOptions, ['text']).concat(partnersAndContactsOptions);
}

function getFilterByAssignedOptions(state: State, items: CollectionItem[], collectibles: CollectionEntity[]) {
  const assigneeUuids: string[] = [];
  const entities = getItemsAsCollectionEntities(items, collectibles);
  entities.forEach((e) => {
    e.assigneeUuids.forEach((uuid) => {
      assigneeUuids.push(uuid);
    });
  });
  return employeesPartnersAndContactsOptions(state, assigneeUuids);
}

function getPartnersWithContacts(state: State): PartnerWithContacts[] {
  return partnersWithContacts(state).map((p) => {
    return {
      uuid: p.uuid,
      name: p.name,
      contacts: p.contacts.map((c) => {
        return { uuid: c.uuid, name: c.firstName + ' ' + c.lastName };
      }),
    };
  });
}

function filterYear(start: string | undefined, end: string | undefined): string {
  if (start && end && start.endsWith('01-01') && end.endsWith('12-31') && start.split('-')[0] === end.split('-')[0]) {
    return start.split('-')[0];
  }
  return '';
}

function getFilterAssigneesText(filterPeople, filterByAssignedOptions): string {
  if (filterPeople.length === 0) {
    return 'Alle';
  }
  const selected = filterByAssignedOptions.filter((o) => {
    return filterPeople?.includes(o.value);
  });
  if (
    selected.length > 1 &&
    selected[0].partnerName &&
    allEqual(
      selected.map((o) => {
        return o.partnerUuid;
      }),
    )
  ) {
    return selected[0].partnerName;
  }
  return joinWithAnd(
    selected.map((s) => {
      return s.text.trim();
    }),
  );
}

function getEventItemsList(
  entities: CollectionEntity[],
  start: string = '',
  end: string = '',
  filterAssignees: string[] = [],
  baseHref: string,
): ListSectionItemInput[] {
  const filteredEntities: CollectionEntity[] = filterEntities(entities, start, end, filterAssignees);
  const sortedEntities = sortEntities(filteredEntities);
  return sortedEntities.map((entity) => {
    let label = entity.name;
    if ((entity.type === 'events' || entity.type === 'meetings') && entity.dateTimeDisplay) {
      label = entity.dateTimeDisplay + ' ' + entity.name;
    }
    let mainIcon: ListItemIcon | undefined = undefined;
    if (entity.locked) {
      mainIcon = 'lock';
    } else if (entity.doneText !== '') {
      mainIcon = 'checkBlue';
    }
    return {
      label,
      secondaryLabel: entity.doneText,
      notes: entity.notes,
      accessible: entity.accessible,
      rightLabel: entity.assignees,
      rightLabelClass: 'assignee',
      mainIcon,
      href: baseHref + entity.href,
    };
  });
}

function getItemsList(type: string, entities: CollectionEntity[], baseHref: string): ListSectionItemInput[] {
  return entities
    .filter((e) => {
      return e.type === type;
    })
    .map((e) => {
      let mainIcon: ListItemIcon | undefined = undefined;
      if (e.locked) {
        mainIcon = 'lock';
      }
      return { label: e.name, notes: e.notes, mainIcon, accessible: e.accessible, href: baseHref + e.href };
    });
}

export async function buildCollectionView(
  api: ApiClient,
  uuid: string,
  commonData: CommonDataEntityView,
  state: State,
  currentParent: string,
): Promise<CollectionView> {
  const organization = getOrganization(state);
  assertIsDefined(organization);
  const availablePages = pagesAsSelectTagOptions(state);
  const item = storedCollections(state).find((c) => {
    return c.uuid === uuid;
  });
  assertIsDefined(item);
  const entity = toTopicViewModel(item);
  const collectibles = getCollectionEntities(state);
  const href = currentParent + '/collections/' + uuid;
  let filterStartDate: string = LocalDate.now().year() + '-01-01';
  let filterEndDate: string = LocalDate.now().year() + '-12-31';
  let filterPeople: string[] = [];
  if (getFilterStartDate(state) || getFilterEndDate(state) || getFilterPeople(state).length) {
    filterStartDate = getFilterStartDate(state);
    filterEndDate = getFilterEndDate(state);
    filterPeople = getFilterPeople(state);
  } else {
    const savedFilter = collectionsFilter(state).find((f) => f.uuid === uuid);
    if (savedFilter) {
      filterStartDate = savedFilter.startDate;
      filterEndDate = savedFilter.endDate;
      filterPeople = savedFilter.people;
    }
  }
  const filterByAssignedOptions = [{ value: '', text: 'Alle' }].concat(
    getFilterByAssignedOptions(state, item.items, collectibles),
  );
  const entities = getItemsAsCollectionEntities(item.items, collectibles);
  return {
    ...commonData,
    currentUserUuid: currentUserUuid(state),
    attachments: [],
    collectibles,
    availableMeetings: meetingsNotDeleted(state),
    availableTasks: tasksNotDeleted(state),
    docsForLinking: [],
    currentUserHasAccess: true,
    uuid: uuid,
    isConfirmedEntity: true,
    helpContent: item.template?.helpContent ?? '',
    href,
    parentHref: currentParent,
    pdfHref:
      BASE_PATH + '/organizations/' + organization.organizationId + '/collections/' + uuid + '.pdf?key=' + key(state),
    deleted: false,
    type: 'collections',
    name: entity.name ?? '',
    description: entity.description ?? '',
    availablePages: availablePages,
    pages: (entity.pages ?? []).map((x) => '' + x),
    today: LocalDate.fromString(state.today),
    deletable: true,
    fetchDraft: async () => {
      const command = await fetchDraft(api, 'guidelines', uuid, organization.organizationId);
      return command.draft as CollectionViewEditItem;
    },
    items: item.items,
    events: getEventItemsList(entities, filterStartDate, filterEndDate, filterPeople, href),
    reports: getItemsList('reports', entities, href),
    tasks: getItemsList('tasks', entities, href),
    guidelines: getItemsList('guidelines', entities, href),
    documents: getItemsList('documents', entities, href),
    constitutionalDocuments: getItemsList('constitutionalDocuments', entities, href),
    contracts: getItemsList('contracts', entities, href),
    functions: getItemsList('functions', entities, href),
    assets: getItemsList('assets', entities, href),
    substances: getItemsList('substances', entities, href),
    riskAssessments: getItemsList('riskAssessments', entities, href),
    issues: getItemsList('issues', entities, href),
    employees: getItemsList('employees', entities, href),
    partners: getItemsList('partners', entities, href),
    contacts: getItemsList('contacts', entities, href),
    suggestionsAsListItems: suggestionsToListItems(
      item.template?.suggestions ?? [],
      collectibles,
      item.items,
      state,
      filterStartDate,
      filterEndDate,
    ),
    editRestricted: item.template !== undefined && item.template !== null,
    filterByAssignedOptions,
    partnersWithContacts: getPartnersWithContacts(state),
    allCollectionsFilter: collectionsFilter(state),
    filterStartDate,
    filterEndDate,
    filterPeople,
    filterYear: filterYear(filterStartDate, filterEndDate),
    filterPeriodDisplayText: timespanText(filterStartDate, filterEndDate),
    filterPeopleDisplayText: getFilterAssigneesText(filterPeople, filterByAssignedOptions),
  };
}
