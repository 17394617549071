import { html, nothing, TemplateResult } from 'lit';
import { PageContent } from 'src/pages/page-content';
import { customElement, property } from 'lit/decorators.js';
import type { AssetsPageViewModel } from './assets-page-view-model';
import './d-assets-table';
import { isEmpty } from 'lodash';
import { uuid } from 'src/utilities/text';
import { CreateAssetInput, CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import type { PageLink, PageLinks } from 'src/pages/overview-page/overview-page-view-model';
import 'src/library/promo/d-promo-section';
import { features, promo } from 'src/store/selectors/features';
import { registerItemsPromptStyles } from 'src/library/register-items-prompt-styles';
import { pageIds } from 'src/store';
import { TableDefinition } from 'src/library/abstracts/item-table';
import { commonFieldsByCode } from 'src/store/fields';

/**
 *
 */
@customElement('d-assets-page-content')
export class DAssetsPageContent extends PageContent<AssetsPageViewModel> {
  static readonly styles = [...super.styles, registerItemsPromptStyles];

  @property({ type: String })
  sortBy = 'name';
  @property({ type: String })
  groupBy = 'location';

  private assetsTableColumns(view: AssetsPageViewModel) {
    return [
      {
        name: 'name',
        field: 'name',
        label: 'Navn',
        sortOption: true,
      },
      {
        name: 'number',
        field: 'number',
        label: 'ID',
        sortOption: true,
      },
      {
        name: 'category',
        field: 'category',
        label: 'Kategori',
        sortOption: true,
        groupOption: true,
      },
      {
        name: 'location',
        field: 'location',
        label: 'Plassering',
        groupOption: true,
      },
      {
        name: 'requiresElectricity',
        field: 'requiresElectricity',
        label: 'EMU',
        condition: !view.variantSimplifiedAssets,
      },
      {
        name: 'tasksAndEvents',
        field: 'tasksAndEvents',
        label: 'Oppgaver',
        noValue: 'Ingen oppgaver',
      },
    ];
  }

  protected tables(view: any): TableDefinition[] {
    return [
      {
        field: 'assetsNotDiscarded',
        fieldForLabel: 'organization_assetsNotDiscarded',
        noHeader: true,
        type: 'assets',
        items: 'assetsNotDiscarded',
        tableColumns: this.assetsTableColumns(view),
        sortBy: this.sortBy,
        groupBy: this.groupBy,
      },
      {
        field: 'assetsDiscarded',
        fieldForLabel: 'organization_assetsDiscarded',
        type: 'assets',
        items: 'assetsDiscarded',
        tableColumns: this.assetsTableColumns(view),
        sortBy: this.sortBy,
        groupBy: this.groupBy,
      },
    ];
  }

  renderPageLinks(list: PageLinks) {
    if (list) {
      return html`
        ${list.items.map((pageLink: PageLink) => {
          return html`<a class="page-link" href="${pageLink.href}">${pageLink.name}</a>`;
        })}
      `;
    }
    return html``;
  }

  renderAssetsTable(table: TableDefinition) {
    if (table.items) {
      const items = this.pageView[table.items];
      let header = html``;
      if (table.field && !table.noHeader) {
        const f = commonFieldsByCode()[table.fieldForLabel];
        if (f) {
          header = html`<h2>${f.label}</h2>`;
        }
      }
      if (items.length) {
        return html` ${header}
          <d-assets-table
            .tableColumns=${table.tableColumns}
            .items=${items}
            .sortBy=${this.sortBy}
            .groupBy=${this.groupBy}
            .filter=${true}
            theme-page
            .contentStickyTop=${this.contentStickyTop}
          ></d-assets-table>`;
      }
    }
    return html``;
  }

  renderContent() {
    if (!this.pageView.featureStates.assets && !this.pageView.featureStates.complete) {
      const promoData = promo.find((p) => {
        return p.id === 'assets';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`
      ${this.renderPageLinks(this.pageView.pageLinks)}
      ${isEmpty(this.pageView.assetsNotDiscarded) && isEmpty(this.pageView.assetsDiscarded)
        ? html`<div class="register-items-prompt assets" @click=${() => this.onCreateAsset()}>
            <h2>Ingen utstyrsenheter er registrert.</h2>
            ${this.pageView.writeAccess ? html` <p>Trykk for å legge til.</p>` : nothing}
          </div>`
        : html`
            ${this.tables(this.pageView).map((table) => {
              return html`${this.renderAssetsTable(table)}`;
            })}
          `}
    `;
  }

  protected renderCustomActions(): TemplateResult | typeof nothing {
    return html`
      <d-action href="${this.pageView.pdfHref}" id="print" blank>PDF</d-action>
      ${this.singleUserVersion
        ? nothing
        : html`<d-action class="action" @click=${() => this.onShareContent()}>Del</d-action>`}
    `;
  }

  private onCreateAsset() {
    if (this.pageView.writeAccess) {
      const id = uuid();
      const input: CreateAssetInput = {
        entityType: 'assets',
        entityUuid: id,
        targetUrl: '/account/' + this.pageView.organizationId + pageIds.assets + '/assets/' + id + '?edit',
      };
      this.dispatchEvent(
        new CustomEvent<CreateEntityInput>('create-entity', {
          bubbles: true,
          composed: true,
          detail: input,
        }),
      );
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-assets-page-content': DAssetsPageContent;
  }
}
