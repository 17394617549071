import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { PageContent } from 'src/pages/page-content.js';
import 'src/utilities/display-alert';
import '../../content/d-entity-header.js';
import '../../library/components/d-help-section.js';
import '../../library/elements/d-action.js';
import { PageViewModel } from 'src/store/api';
import type { AbstractPageView } from 'src/pages/abstract-page-view';
import type { ListSectionItemInput } from 'src/library/lists/utilities';
import { ActivityCode } from 'src/pages/organization-page/organization-page-view-model';
import { features, promo } from 'src/store/selectors/features';
import { uuid } from 'src/utilities/text';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';

export interface RiskAssessmentGroup {
  themeName: string;
  themePageId: number;
  items: ListSectionItemInput[];
}

export interface RiskAssessmentsPageViewModel extends AbstractPageView {
  type: 'risk-assessments-page';
  icon: 'riskAssessments';
  availableActivityCodes: ActivityCode[];
  riskAssessments: RiskAssessmentGroup[];
  substancesPageHref: string;
  pdfHref: string;
}

/**
 *
 */
@customElement('d-risk-assessments-page-content')
export class DRiskAssessmentsPageContent extends PageContent<RiskAssessmentsPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      .columns {
        column-count: 3;
        column-width: 400px;
        column-gap: 20px;
      }
      .box {
        position: relative;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        box-shadow: 0 0 10px hsla(1, 0%, 0%, 0.2);
        margin-bottom: 20px;
        background: white;
        border-radius: 12px;
        padding: 0 16px 10px 16px;
        line-height: 130%;
      }
      .header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        position: sticky;
        padding-top: 16px;
        background: white;
        z-index: 1;
      }
      .theme-name {
        font-size: 22px;
        line-height: 120%;
        font-weight: 500;
        margin-bottom: 6px;
      }
      .add {
        flex: none;
        display: flex;
        cursor: pointer;
        margin-top: 4px;
      }
      .add-text {
        margin-top: -1px;
        color: var(--themeColor);
        font-size: 14px;
        padding-right: 8px;
        opacity: 0;
      }
      .plus {
        position: relative;
        width: 16px;
        height: 16px;
      }
      .plus > div {
        position: absolute;
        top: 6.5px;
        width: 16px;
        height: 3px;
        background: var(--themeColor);
      }
      .plus > div:last-child {
        transform: rotate(90deg);
      }
      d-list-section {
        margin: 8px 0;
      }
      @media (hover: hover) {
        .add:hover > .add-text {
          opacity: 1;
        }
        .add:hover d-action {
          display: block;
        }
      }
    `,
  ];
  @property({ type: Array })
  pages: PageViewModel[] = [];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Boolean })
  editMode = false;
  @property({ type: Object })
  token = {};
  @property({ type: Boolean })
  uncoverPageMenu = false;
  @property({ type: String })
  key = '';
  @property({ type: Number })
  organizationId = 0;

  private create(pageId: number) {
    const id = uuid();
    const targetUrl = this.pageView.href + 'riskAssessments/' + id + '?edit';
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: {
          entityType: 'riskAssessments',
          entityUuid: id,
          pageId: pageId,
          targetUrl,
        },
      }),
    );
  }

  renderContent() {
    if (!this.pageView.featureStates.core) {
      const promoData = promo.find((p) => {
        return p.id === 'risk';
      });
      if (promoData) {
        return html` <d-promo-section
          theme-page
          .employeesCount=${this.pageView.employeesCount}
          .specialTerms=${this.pageView.specialTerms}
          .promoData=${promoData}
          .features=${features}
        ></d-promo-section>`;
      }
      return nothing;
    }
    return html`<div class="columns">
      ${this.pageView.riskAssessments.map((r) => {
        return html` <div class="box">
          <div class="header" style="top: ${this.contentStickyTop - 4}px">
            <div>
              ${r.themePageId
                ? html` <div class="add" @click=${() => this.create(r.themePageId)}>
                    <div class="add-text">Legg til</div>
                    <div class="plus">
                      <div></div>
                      <div></div>
                    </div>
                  </div>`
                : html`<d-action plain href="${this.pageView.substancesPageHref}">Gå til Stoffkartotek</d-action>`}
            </div>
            <div class="theme-name">${r.themeName}</div>
          </div>
          ${r.items.length
            ? html` <d-list-section
                no-header
                no-left-padding
                .items=${r.items}
                organization-id="${this.pageView.organizationId}"
                write-access="${this.writeAccess}"
                .contentStickyTop=${this.contentStickyTop}
              ></d-list-section>`
            : nothing}
        </div>`;
      })}
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-risk-assessments-page-content': DRiskAssessmentsPageContent;
  }
}
