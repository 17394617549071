import { generateUUIDv4 } from '@bitjourney/uuid-v4';
import { Occurrence } from 'src/library/lists/d-occurrences-list';

export function ordinal(n: number) {
  const ordinals = [
    'første',
    'andre',
    'tredje',
    'fjerde',
    'femte',
    'sjette',
    'syvende',
    'åttende',
    'niende',
    'tiende',
    'ellevte',
    'tolvte',
  ];
  if (n < 13) {
    return ordinals[n - 1];
  }
  return n + '.';
}

export function uuid(): string {
  return generateUUIDv4();
}

/**
 * Joins the members of the array seperated by comman and the last elements seperated by ' og ' (and).
 * @param list
 */
export function joinWithAnd(list: string[]): string {
  let text = '';
  list.forEach(function (item, index) {
    let divider = '';
    if (index < list.length - 1 && list.length > 2) {
      divider = ', ';
    }
    if (index === list.length - 2) {
      divider = ' og ';
    }
    text += item + divider;
  });
  return text;
}

export function namesListFromUuids(list: { value: string; text: string }[], uuids: string[]) {
  return joinWithAnd(list.filter((l) => uuids.includes(l.value)).map((l) => l.text));
}

export function numberWord(n: number, neuter: boolean = false): string {
  if (n === 0) {
    return 'ingen';
  }
  if (n === 1) {
    if (neuter) {
      return 'ett';
    }
    return 'én';
  }
  if (n === 2) {
    return 'to';
  }
  if (n === 3) {
    return 'tre';
  }
  if (n === 4) {
    return 'fire';
  }
  if (n === 5) {
    return 'fem';
  }
  if (n === 6) {
    return 'seks';
  }
  if (n === 7) {
    return 'syv';
  }
  if (n === 8) {
    return 'åtte';
  }
  if (n === 9) {
    return 'ni';
  }
  if (n === 10) {
    return 'ti';
  }
  if (n === 11) {
    return 'elleve';
  }
  if (n === 12) {
    return 'tolv';
  }
  return n.toString();
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function zeropad(n) {
  return ('00' + n).slice(-2);
}

export function minutesToTime(n) {
  const toFive = Math.floor(n / 5) * 5;
  const hours = toFive / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return zeropad(rhours) + ':' + zeropad(rminutes);
}

export function isEmptyOrInvalidString(string) {
  if (string === undefined || string === null) {
    return true;
  }
  return string.replace('&nbsp;', '').trim() === '';
}

export function isEmptyHtmlContent(s: string | undefined): boolean {
  return s === undefined || s === '' || s === '<p></p>';
}

function prepHtml(string: string): string {
  return string
    .replace(/ {2}|\r\n|\n|\r/gm, '')
    .replaceAll('> <', '><')
    .replaceAll('<li><p>', '<li>')
    .replaceAll('</p></li>', '</li>')
    .replaceAll('<br />', '<br>')
    .replaceAll('<br/>', '<br>')
    .replaceAll('<br> ', '<br>');
}

export function isSameHtmlContent(a: string, b: string): boolean {
  return prepHtml(a) === prepHtml(b);
}

export function displayName(s: string | undefined | null, defaultValue: string = 'uten navn'): string {
  const s1 = s ?? '';
  return s1 === '' ? defaultValue : s1;
}

export function formatDuration(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;

  return `${formattedHours}:${formattedMinutes}`;
}

export function convertToMinutes(timeString: string): number {
  const [hoursString, minutesString] = timeString.split(':');
  const hours = parseInt(hoursString, 10);
  const minutes = parseInt(minutesString, 10);

  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error('Invalid time format. Please provide time in "hh:mm" format.');
  }

  return hours * 60 + minutes;
}

export function toDateTimeDuration(e: Occurrence): string {
  const dateTime = e.dateTime;
  const hasTime = dateTime.split(' ').length === 2;
  return dateTime + (hasTime ? ` ${e.durationMinutes}` : '');
}

export function levenshteinDistance(str1: string, str2: string): number {
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();
  const len1 = str1.length;
  const len2 = str2.length;
  const matrix = Array(len1 + 1);
  for (let i = 0; i <= len1; i++) {
    matrix[i] = Array(len2 + 1);
  }
  for (let i = 0; i <= len1; i++) {
    matrix[i][0] = i;
  }
  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }
  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + 1);
      }
    }
  }
  return matrix[len1][len2];
}

export function entityNameForLists(
  name: string | undefined,
  draftName: string | undefined,
  isConfirmedEntity: boolean,
): string {
  let result: string;
  if (!isConfirmedEntity) {
    result = '(Utkast: ';
    if (name) {
      result += name + ')';
    } else if (draftName) {
      result += draftName + ')';
    } else {
      result += 'Uten navn)';
    }
  } else {
    result = name || 'UTEN NAVN';
  }
  return result;
}

export function shorten(text: string, length: number) {
  if (text.length < length) {
    return text;
  }
  return text.substring(0, length - 2) + '…';
}
