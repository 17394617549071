import { LocalDate, mondayThisWeek } from 'src/utilities/local-date';
import { reducer } from 'src/store/reducers/reducers';
import { configureStore } from '@reduxjs/toolkit';
import { uuid } from 'src/utilities/text';
import type { State } from 'src/store/types';
import { setToday } from 'src/store/actions';
import { getConfiguredCache } from './money-clip';
import ms from 'milliseconds';
import { debounce } from 'lodash';

export * from './framework';

export { BASE_PATH } from './config';

export { RecurrenceRule } from './recurrence-rule/recurrence-rule';
export * from './selectors';
export * from './actions';
export * from './backend';

const IS_BROWSER = !!(typeof window !== 'undefined' || typeof self !== 'undefined');
const fallback = (cb) => setTimeout(cb, 0);
const ric = typeof requestIdleCallback === 'undefined' ? fallback : requestIdleCallback;
const ignoreActions = [
  'UPDATE_PATH',
  'UPDATE_HELP_VIEWER_OPEN',
  'UPDATE_TUTORIAL_VIEWER_OPEN',
  'UPDATE_SIDE_CONTENT_OPEN',
  'SET_TODAY',
  'UPDATE_POPUP_OPEN',
  'UPDATE_CURRENT_EDIT_LEVEL',
  'UPDATE_CURRENT_HELP_PAGE',
  'UPDATE_SELECTED_START_TASK',
  'UPDATE_CALENDAR_WEEK',
  'UPDATE_STAFFING_CALENDAR_YEAR',
  'UPDATE_CURRENT_TUTORIAL_ID',
];

function saveToCache(state: State, cacheFn, logger, action) {
  const organizationId = state.id;
  const username = state.username;
  console.log(organizationId, username);
  if (username !== undefined && username !== null && organizationId !== undefined && organizationId !== null) {
    const keys = {
      user: username,
      organization: username + '-organization-' + organizationId,
      pageGroups: username + '-page-groups-' + organizationId,
      references: username + '-references-' + organizationId,
    };
    const reducersToPersist = Object.keys(keys) as Array<keyof typeof keys>;
    ric(
      () => {
        Promise.all(reducersToPersist.map((key) => cacheFn(keys[key], state[key]))).then(() => {
          if (logger) {
            logger(`cached ${reducersToPersist.join(', ')} due to ${action.type}`);
          }
        });
      },
      { timeout: 500 },
    );
  }
}

const debouncedSaveToCache = debounce(
  (state, cacheFn, logger, action) => saveToCache(state, cacheFn, logger, action),
  400,
  {
    trailing: true,
    leading: true,
  },
);

const cacheMiddleware = ({ cacheFn, logger }) => {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const res = next(action);
      const state = getState();
      if (IS_BROWSER && !ignoreActions.includes(action.type)) {
        debouncedSaveToCache(state, cacheFn, logger, action);
      }
      return res;
    };
};

export const cache = getConfiguredCache({
  version: '1',
  maxAge: ms.days(14),
});

const cachingMiddleware = cacheMiddleware({ cacheFn: cache.set, logger: console.info });

const initialState: State = {
  organization: undefined,
  user: undefined,
  id: null,
  loaded: false,
  loading: false,
  error: false,
  references: [],
  session: {},
  popupOpen: false,
  currentEditLevel: 0,
  helpViewerOpen: false,
  tutorialViewerOpen: false,
  sideContentOpen: false,
  currentHelpPage: '',
  currentSideContentTemplateId: 0,
  weekStart: mondayThisWeek().toString(),
  singleUserView: true,
  staffingCalendarYear: LocalDate.now().year().toString(),
  path: '',
  queryParams: {},
  pageGroups: [],
  key: '',
  selectedStartTask: '',
  today: LocalDate.now().toString(),
};

const store = configureStore({
  reducer: reducer,
  preloadedState: initialState,
  middleware: (gDM) => {
    return gDM().concat(cachingMiddleware);
  },
});

export function getStore() {
  return store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export function createUuid(): string {
  return uuid();
}

setInterval(
  () => {
    const t = store.getState().today;
    const n = LocalDate.now().toString();
    if (n !== t) {
      store.dispatch(setToday(LocalDate.now()));
    }
  },
  5 * 60 * 1000,
);

export { endYear } from './config';
