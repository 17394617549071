import { css, html, nothing, PropertyValues } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import 'src/library/elements/d-section';
import 'src/library/elements/d-label';
import 'src/library/editors/elements/d-select-radio';
import 'src/library/editors/elements/d-select-dropdown';
import 'src/library/editors/elements/d-select-date';
import { BaseDialog } from 'src/library/components/BaseDialog';
import type { ActionInput } from 'src/library/elements/d-action';
import { monthsToDateLast, timespanType } from 'src/utilities/timespan';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { endYear } from 'src/store';
import { LocalDate } from 'src/utilities/local-date';

export interface EditTimespanDialogInput {
  start: string;
  end: string;
}

export interface EditTimespanDialogResult {
  action: 'cancel' | 'done';
  start?: string;
  end?: string;
}

@customElement('edit-timespan-dialog')
export class EditTimespanDialog extends BaseDialog<EditTimespanDialogInput, EditTimespanDialogResult> {
  static readonly styles = BaseDialog.styles.concat(css`
    .settings {
      display: flex;
      align-items: center;
    }
    .settings > * {
      margin-right: 6px;
    }
  `);
  @property({ type: String })
  title = 'Velg periode';
  @property({ type: String })
  type = '';
  @property({ type: String })
  start = '';
  @property({ type: String })
  end = '';
  @property({ type: Number })
  width = 460;

  private get startDate() {
    return LocalDate.fromString(this.start);
  }

  private get endDate() {
    return LocalDate.fromString(this.end);
  }

  protected get calculatedFooterActions(): ActionInput[] {
    return [
      { name: 'Avbryt', action: 'cancel' },
      { name: 'Ferdig', action: 'done' },
    ];
  }

  typeOptions = [
    { value: 'YEARS', text: 'år' },
    { value: 'QUARTERS', text: 'kvartal' },
    { value: 'MONTHS', text: 'måned' },
    { value: 'DAYS', text: 'dag' },
  ];

  private get yearOptions(): SelectDropdownOption[] {
    const result: SelectDropdownOption[] = [];
    for (let year = 2020; year < endYear + 1; year++) {
      result.push({ value: year + '', text: year + '' });
    }
    return result;
  }

  quarterOptions = [
    { value: '1', text: '1. kvartal' },
    { value: '2', text: '2. kvartal' },
    { value: '3', text: '3. kvartal' },
    { value: '4', text: '4. kvartal' },
  ];

  private get monthOptions() {
    const result: SelectDropdownOption[] = [];
    const start = LocalDate.fromString('2024-01-01');
    for (let i = 0; i < 12; i++) {
      result.push({ value: i + 1 + '', text: start.plusMonths(i).nameOfMonth() });
    }
    return result;
  }

  private year(dateString: string) {
    return LocalDate.fromString(dateString).year();
  }

  private startQuarter(start: string): number {
    return (LocalDate.fromString(start).month() - 1) / 3 + 1;
  }

  private endQuarter(end: string): number {
    return LocalDate.fromString(end).month() / 3;
  }

  private startMonth(start: string) {
    return LocalDate.fromString(start).month();
  }

  private endMonth(end) {
    return LocalDate.fromString(end).month();
  }

  private typeChanged(e) {
    e.stopPropagation();
    this.type = e.detail.value;
    if (this.type === 'YEARS') {
      this.start = this.startDate.year() + '-01-01';
      this.end = this.startDate.year() + '-12-31';
    }
    if (this.type === 'QUARTERS') {
      this.start = this.startDate.year() + '-01-01';
      this.end = this.startDate.year() + '-03-31';
    }
    if (this.type === 'MONTHS') {
      this.start = this.startDate.year() + '-01-01';
      this.end = this.startDate.year() + '-01-31';
    }
  }

  private zeropad(n: number) {
    return ('00' + n).slice(-2);
  }

  private newStartForQuarters(y: number, q: number) {
    const m = this.zeropad((q - 1) * 3 + 1);
    return y + '-' + m + '-01';
  }

  private getQuarterEnd(y: number, q: number) {
    const m = this.zeropad((q - 1) * 3 + 3);
    const days = LocalDate.fromString(y + '-' + m + '-01').daysInMonth();
    const d = this.zeropad(days);
    return y + '-' + m + '-' + d;
  }

  private startInQuarters(date: string): number {
    return this.year(date) * 4 + this.startQuarter(date);
  }

  private endInQuarters(date: string): number {
    return this.year(date) * 4 + this.endQuarter(date);
  }

  private startDateFromQuarters(startInQuarters: number): string {
    const years = startInQuarters / 4;
    let year = Math.floor(years);
    let quarter = (years - year) * 4;
    if (quarter === 0) {
      year = year - 1;
      quarter = 4;
    }
    return this.newStartForQuarters(year, quarter);
  }

  private endDateFromQuarters(endInQuarters: number): string {
    const years = endInQuarters / 4;
    let year = Math.floor(years);
    let quarter = (years - year) * 4;
    if (quarter === 0) {
      year = year - 1;
      quarter = 4;
    }
    if (year > endYear) {
      year = endYear;
      quarter = 4;
    }
    return this.getQuarterEnd(year, quarter);
  }

  private newEndForQuarters(start: string, end: string, newStart: string) {
    const startInQuarters = this.startInQuarters(start);
    const endInQuarters = this.endInQuarters(end);
    const timespanInQuarters = endInQuarters - startInQuarters;
    const newStartInQuarters = this.startInQuarters(newStart);
    const newEndInQuarters = newStartInQuarters + timespanInQuarters;
    return this.endDateFromQuarters(newEndInQuarters);
  }

  private newEndForMonths(start: string, end: string, newStart: string) {
    const startDate = LocalDate.fromString(start);
    const endDate = LocalDate.fromString(end);
    const newStartDate = LocalDate.fromString(newStart);
    const startInMonths = startDate.year() * 12 + startDate.month();
    const endInMonths = endDate.year() * 12 + endDate.month();
    const timespanInMonths = endInMonths - startInMonths;
    const newStartInMonths = newStartDate.year() * 12 + newStartDate.month();
    const newEndInMonths = newStartInMonths + timespanInMonths;
    return monthsToDateLast(newEndInMonths);
  }

  onEndChangeInTypeMonth(year: string, month: string) {
    const newEndFirstOfMonth = year + '-' + month + '-01';
    const newEndLastOfMonth = LocalDate.fromString(newEndFirstOfMonth).daysInMonth();
    const newEnd = year + '-' + month + '-' + this.zeropad(newEndLastOfMonth);
    this.end = newEnd;
    const newEndDate = LocalDate.fromString(newEnd);
    const startDate = LocalDate.fromString(this.start);
    if (newEndDate.isBefore(startDate)) {
      const newStart = newEndDate.year() + '-' + this.zeropad(newEndDate.month()) + '-01';
      this.start = newStart;
    }
  }

  private startYearChanged(e) {
    e.stopPropagation();
    if (this.type === 'YEARS') {
      const years = Number(this.end.split('-')[0]) - Number(this.start.split('-')[0]);
      this.start = e.detail.value + '-01-01';
      this.end = this.startDate.year() + years + '-12-31';
    }
    if (this.type === 'QUARTERS') {
      const newStart = this.newStartForQuarters(e.detail.value, this.startQuarter(this.start));
      const newEnd = this.newEndForQuarters(this.start, this.end, newStart);
      this.start = newStart;
      this.end = newEnd;
    }
    if (this.type === 'MONTHS') {
      const newStart = e.detail.value + '-' + this.start.split('-')[1] + '-01';
      const newEnd = this.newEndForMonths(this.start, this.end, newStart);
      this.start = newStart;
      this.end = newEnd;
    }
  }

  private endYearChanged(e) {
    e.stopPropagation();
    if (this.type === 'YEARS') {
      this.end = e.detail.value + '-12-31';
      if (this.startDate.year() > this.endDate.year()) {
        this.start = this.endDate.year() + '-01-01';
      }
    }
    if (this.type === 'QUARTERS') {
      const newEnd = this.getQuarterEnd(e.detail.value, this.endQuarter(this.end));
      const startInQuarters = this.startInQuarters(this.start);
      const endInQuarters = this.endInQuarters(newEnd);
      this.end = newEnd;
      if (endInQuarters < startInQuarters) {
        this.start = this.startDateFromQuarters(endInQuarters);
      }
    }
    if (this.type === 'MONTHS') {
      const year = e.detail.value;
      const month = this.zeropad(this.endDate.month());
      this.onEndChangeInTypeMonth(year, month);
    }
  }

  private startQuarterChanged(e) {
    e.stopPropagation();
    const newStart = this.newStartForQuarters(this.startDate.year(), e.detail.value);
    const newEnd = this.newEndForQuarters(this.start, this.end, newStart);
    this.start = newStart;
    this.end = newEnd;
  }

  private endQuarterChanged(e) {
    e.stopPropagation();
    const newEnd = this.getQuarterEnd(this.startDate.year(), e.detail.value);
    const startInQuarters = this.startInQuarters(this.start);
    const endInQuarters = this.endInQuarters(newEnd);
    this.end = newEnd;
    if (endInQuarters < startInQuarters) {
      this.start = this.startDateFromQuarters(endInQuarters);
    }
  }

  private startMonthChanged(e) {
    e.stopPropagation();
    const newStart = this.year(this.start) + '-' + this.zeropad(e.detail.value) + '-01';
    const newEnd = this.newEndForMonths(this.start, this.end, newStart);
    this.start = newStart;
    this.end = newEnd;
  }

  private endMonthChanged(e) {
    e.stopPropagation();
    const year = this.year(this.start) + '';
    const month = this.zeropad(e.detail.value);
    this.onEndChangeInTypeMonth(year, month);
  }

  private startDayChanged(e) {
    e.stopPropagation();
    const newStartDate = LocalDate.fromString(e.detail.value);
    const days = this.startDate.until(this.endDate);
    let newEndDate = newStartDate.plusDays(days);
    const endDate = LocalDate.fromString(endYear + '-12-31');
    if (newEndDate.isAfter(endDate)) {
      newEndDate = endDate;
    }
    this.start = e.detail.value;
    this.end = newEndDate.toString();
  }

  private endDayChanged(e) {
    e.stopPropagation();
    this.end = e.detail.value;
    if (this.start > this.end) {
      this.start = this.end;
    }
  }

  renderBody() {
    return html`
      <d-section>
        <d-select-radio
          class="fullWidth"
          controller
          no-arrow
          .options=${this.typeOptions}
          .value=${this.type}
          @value-changed=${(e) => this.typeChanged(e)}
        ></d-select-radio>
        <div class="settings">
          ${this.type === 'QUARTERS'
            ? html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.quarterOptions}
                  .value=${this.startQuarter(this.start) + ''}
                  @value-changed=${(e) => this.startQuarterChanged(e)}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.type === 'MONTHS'
            ? html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.monthOptions}
                  .value=${this.startMonth(this.start) + ''}
                  @value-changed=${(e) => this.startMonthChanged(e)}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.type === 'DAYS'
            ? html`<d-select-date
                controller
                .value=${this.start}
                @value-changed=${(e) => this.startDayChanged(e)}
              ></d-select-date>`
            : html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.yearOptions}
                  .value=${this.year(this.start) + ''}
                  @value-changed=${(e) => this.startYearChanged(e)}
                ></d-select-dropdown>
              `}
          <d-label light label="til"></d-label>
          ${this.type === 'QUARTERS'
            ? html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.quarterOptions}
                  .value=${this.endQuarter(this.end) + ''}
                  @value-changed=${(e) => this.endQuarterChanged(e)}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.type === 'MONTHS'
            ? html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.monthOptions}
                  .value=${this.endMonth(this.end) + ''}
                  @value-changed=${(e) => this.endMonthChanged(e)}
                ></d-select-dropdown>
              `
            : nothing}
          ${this.type === 'DAYS'
            ? html`<d-select-date
                controller
                .value=${this.end}
                @value-changed=${(e) => this.endDayChanged(e)}
              ></d-select-date>`
            : html`
                <d-select-dropdown
                  controller
                  no-arrow
                  .options=${this.yearOptions}
                  .value=${this.year(this.end) + ''}
                  @value-changed=${(e) => this.endYearChanged(e)}
                ></d-select-dropdown>
              `}
        </div>
      </d-section>
    `;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    if (this.start && this.end) {
      this.type = timespanType(this.start, this.end);
    }
  }

  protected fetchResult(detail: string | undefined): EditTimespanDialogResult {
    switch (detail) {
      case 'cancel':
        return { action: 'cancel' };
      case 'done':
        return { action: 'done', start: this.start, end: this.end };
    }
    throw new Error('Method not implemented.');
  }

  protected initializeDialog(input: EditTimespanDialogInput) {
    this.start = input.start;
    this.end = input.end;
    this.type = timespanType(input.start, input.end);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'edit-timespan-dialog': EditTimespanDialogResult;
  }
}
