import { css, html } from 'lit';
import { PageContent } from 'src/pages/page-content.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-item.js';
import type { TrashList, TrashPageViewModel } from './trash-page-view-model.js';
import { customElement } from 'lit/decorators.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';

/**
 *
 * STATUS OK
 */
@customElement('d-trash-page-content')
export class DTrashPageContent extends PageContent<TrashPageViewModel> {
  static readonly styles = [
    ...super.styles,
    css`
      d-skil-course-section {
        margin-bottom: 20px;
      }
      d-list-section {
        margin-bottom: 6px;
      }
    `,
  ];

  _computeHref(item, type) {
    return '/account/' + this.pageView.organizationId + '/223117/' + type + '/' + item.uuid;
  }

  renderContent() {
    return html`${this.pageView.lists.map((list) => this.renderList(list))}`;
  }

  private renderList(list: TrashList) {
    return html` <d-list-section
      field="${list.field}"
      icon="${list.icon}"
      theme-page
      .contentStickyTop=${this.contentStickyTop}
      .items=${list.items.map((item): ListSectionItemInput => {
        return {
          accessible: true,
          updateStatus: 'none',
          label: item.name,
          secondaryLabel: '',
          href: this._computeHref(item, list.type),
        };
      })}
    >
    </d-list-section>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-trash-page-content': DTrashPageContent;
  }
}
