import { css } from 'lit';
import { customElement } from 'lit/decorators.js';
import { ItemTable, TableItem } from '../../library/abstracts/item-table.js';

export interface Properties {
  name: string;
  label: string;
  field: string;
  value: string;
  href: string;
  classes: string;
}

export interface TasksAndEvents {
  classes: string;
  name: string;
  href: string;
  properties: Properties[];
}

export interface TableItemsAssets extends TableItem {
  uuid: string;
  name: string;
  number: string;
  category: string;
  location: string;
  requiresElectricity: boolean;
  tasksAndEvents: TasksAndEvents[];
}

/**
 *
 * STATUS OK
 */
@customElement('d-assets-table')
export class DAssetsTable extends ItemTable<TableItemsAssets> {
  static readonly styles = [
    ...ItemTable.styles,
    css`
      :host {
        display: block;
      }

      tr.tasks td,
      tr.events td {
        background-size: 22px auto;
        background-repeat: no-repeat;
        background-position: -2px 6px;
        padding-left: 0;
        cursor: default;
      }

      div.date {
        display: inline-block;
      }

      .event-done a,
      .asset-ok a,
      .asset-not-ok a {
        background-size: 22px auto;
        background-repeat: no-repeat;
        background-position: 0 -2px;
        padding-left: 26px;
      }

      .event-done a {
        background-image: url(/images/check-mini-gray.svg);
      }

      .asset-ok a {
        background-image: url(/images/check-mini-blue.svg);
      }

      .asset-not-ok a {
        background-image: url(/images/x-fat-orange.svg);
      }
    `,
  ];

  protected get entityType() {
    return 'assets';
  }

  includeItem(item: TableItemsAssets): boolean {
    return this.filterFields(item.name, item.category, item.location, item.number);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-assets-table': DAssetsTable;
  }
}
