import { commonFieldsByCode } from 'src/store/fields';
import { SectionField, ViewConditionalBullet } from 'src/library/components/d-field-section';
import { ListDefinition } from 'src/content/entity-content';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { TableDefinition } from 'src/library/abstracts/item-table';

const entityTypeTerms = {
  assets: 'Utstyrsenhet',
  collections: 'Samling',
  constitutionalDocuments: 'Stiftelsesdokument',
  contacts: 'Kontaktperson',
  contracts: 'Avtale',
  documents: 'Måldokument',
  employees: 'Personale',
  eventOccurrences: 'Oppgave',
  functions: 'Ansvarsområde',
  guidelines: 'Retningslinje',
  issues: 'Avvik',
  meetingOccurrences: 'Møte',
  partners: 'Samarbeidspartner',
  reports: 'Referat/rapport',
  riskAssessments: 'Risikovurdering',
  substances: 'Stoff',
  tasks: 'Rutine',
};

const pageTypeTerms = {
  organization: 'Organisasjon',
};

function getLabelFromField(fieldCode: string | undefined, prefix: string): string | undefined {
  if (fieldCode) {
    if (!fieldCode.includes('_')) {
      if (prefix === 'eventOccurrences') {
        prefix = 'events';
      }
      if (prefix === 'meetingOccurrences') {
        prefix = 'meetings';
      }
      fieldCode = prefix + '_' + fieldCode;
    }
    const field = commonFieldsByCode()[fieldCode];
    if (field) {
      return field.label;
    }
  }
  return undefined;
}

function getLabel(item: SectionField, prefix: string) {
  if (item['fieldForLabel'] === 'generalFields_access') {
    return 'Begrenset tilgang';
  }
  let fieldCode = item.field;
  if (item['fieldForLabel']) {
    fieldCode = item['fieldForLabel'];
  }
  if (fieldCode) {
    const label = getLabelFromField(fieldCode, prefix);
    if (label) {
      return label;
    } else if (item['label']) {
      return item['label'];
    }
  }
  return 'MISSING LABEL';
}

function getViewFieldsForPdfTemplate(viewFields: SectionField[], entityType: string) {
  return viewFields
    .filter((field) => {
      return (
        !field['excludeFromPdf'] &&
        field.type !== 'break' &&
        field.type !== 'custom' &&
        (field.field || field['fieldForLabel'] || field.type === 'view-conditional-bullet-list')
      );
    })
    .map((field) => {
      let listItems: ViewConditionalBullet[] | undefined = undefined;
      let label = getLabel(field, entityType);
      if (field.type === 'view-conditional-bullet-list') {
        if (!field.field) {
          label = '';
        }
        listItems = field.listItems.map((item) => {
          return {
            field: item.field,
            label: getLabelFromField(item.field, entityType) ?? '',
            elseField: item.elseField,
            elseLabel: getLabelFromField(item.elseField, entityType) ?? '',
            alert: item.alert,
          };
        });
      }
      const result = {
        field: field?.field,
        label,
        type: field.type,
        class: field.class ?? '',
        size: field['size'] ?? '',
        listItems,
      };
      if (!result.listItems) {
        delete result.listItems;
      }
      return result;
    });
}

function getListsForPdfTemplate(lists: ListDefinition[], entityType: string): { field: string }[] {
  return lists
    .filter((list) => {
      return list.type !== 'custom' && list.items !== '';
    })
    .map((list) => {
      return {
        field: list.field ?? '',
        label: getLabelFromField(list.field, entityType),
        icon: list.icon,
        items: list.items,
      };
    });
}

function getSampleValueForPdfTemplate(value: string, options: SelectDropdownOption[]) {
  if (options) {
    const option = options.find((o) => {
      return o.value === value;
    });
    if (option) {
      return option.text;
    }
    return 'MISSING VALUE';
  }
  return value;
}

function getSampleDataForPdfTemplate(
  viewFields: SectionField[],
  lists: ListDefinition[],
  tables,
  view: any,
  type: string,
) {
  const array: { field: string; value: any }[] = [{ field: 'name', value: view.name }];
  viewFields
    .filter((field) => {
      return field.type !== 'break' && field.type !== 'custom';
    })
    .forEach((field) => {
      if (field.field) {
        array.push({
          field: field.field,
          value: getSampleValueForPdfTemplate(view[field.field], field['options']),
        });
      }
      if (field.type === 'view-conditional-bullet-list') {
        field.listItems.forEach((item) => {
          if (item.field) {
            array.push({ field: item.field, value: view[item.field] });
          }
        });
      }
    });
  lists.forEach((list) => {
    if (list.items) {
      const items = view[list.items];
      if (items) {
        array.push({
          field: list.items,
          value: items.map((item) => {
            if (view.type === 'collections' && list.items === 'events') {
              return {
                checkedClass: item.secondaryLabel !== '' ? 'checked' : '',
                name: item.label,
                assignedTo: item.rightLabel,
                doneText: item.secondaryLabel,
                notes: item.notes,
              };
            }
            let name = item.label;
            if (!item.label) {
              name = item.name;
            }
            return { name };
          }),
        });
      } else {
        console.error(list.items, 'No such list in view');
      }
    }
  });
  tables.forEach((table) => {
    if (table.items) {
      const header = table.tableColumns.map((c) => {
        return { field: c.field, label: c.label ?? getLabelFromField(c.field, type) };
      });
      const items = view[table.items];
      const rows: any[][] = [];
      if (items) {
        const row: any[] = [];
        items.forEach((item) => {
          table.tableColumns.forEach((c) => {
            row.push(item[c.field]);
          });
        });
        rows.push(row);
      }
      array.push({
        field: table.field,
        value: { header, rows },
      });
    }
  });
  const object = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item.field]: item.value,
    };
  }, object);
}

function getTablesForPdfTemplate(tables: TableDefinition[]) {
  return tables.map((table) => {
    let header = '';
    if (!table.noHeader) {
      header = getLabelFromField(table.fieldForLabel, table.type) ?? 'MISSING HEADER';
    }
    return {
      header,
      sortBy: table.sortBy,
      groupBy: table.groupBy,
      groupByLabel: getLabelFromField(table.groupBy, table.type) ?? 'MISSING LABEL',
      items: table.items,
      columns: table.tableColumns.map((c) => {
        return { field: c.field, label: c.label };
      }),
    };
  });
}

export function getPdfTemplateDefinitionForEntity(
  viewFields: SectionField[],
  lists: ListDefinition[],
  entityView: any,
) {
  return JSON.stringify(
    {
      name: entityView.type.slice(0, -1),
      type: entityView.type,
      typeName: entityTypeTerms[entityView.type],
      fields: getViewFieldsForPdfTemplate(viewFields, entityView.type),
      lists: getListsForPdfTemplate(lists, entityView.type),
      sampleItem: getSampleDataForPdfTemplate(viewFields, lists, [], entityView, entityView.type),
    },
    undefined,
    2,
  );
}

export function getPdfTemplateDefinitionForPage(
  viewFields: SectionField[],
  tables: TableDefinition[],
  lists: ListDefinition[],
  pageView: any,
) {
  const name = pageView.type.replace('-page', 'Page');
  const type = pageView.type.replace('-page', '');
  return JSON.stringify(
    {
      name,
      type,
      typeName: pageTypeTerms[type] ?? '',
      fields: getViewFieldsForPdfTemplate(viewFields, type),
      lists: getListsForPdfTemplate(lists, type),
      tables: getTablesForPdfTemplate(tables),
      sampleItem: getSampleDataForPdfTemplate(viewFields, lists, tables, pageView, type),
    },
    undefined,
    2,
  );
}
