import { css, html, LitElement, nothing } from 'lit';
import '../../library/elements/d-label';
import '../../library/elements/d-action';
import '../../library/components/pdf-viewer';
import { customElement, property } from 'lit/decorators.js';
import { AttachmentItem, UploadItem } from 'src/library/lists/d-list-section-attachment';
import { FileViewerDialog, FileViewerInput, FileViewerResult } from 'src/layout/parts/file-viewer-dialog';

/**
 * Viser alle vedlegg
 *
 * USAGE:
 *    d-contract-view
 *
 */
@customElement('d-view-attachments')
export class DViewAttachments extends LitElement {
  static styles = css`
    :host {
      display: block;
      border-top: 1px solid var(--borderColor);
      padding: 12px 0 10px 0;
    }

    .header {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      flex-wrap: wrap;
    }

    .actions {
      margin-top: -6px;
      margin-right: -8px;
    }

    .header > d-label {
      flex: 1;
    }

    .attachments .header {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    pdf-viewer {
      margin: 0 1px;
      border: 1px solid var(--borderColor);
    }
    input {
      position: absolute;
      width: 0;
      height: 0;
      opacity: 0;
    }
  `;
  attachmentsBefore: any;
  u: any;
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  label = '';
  @property({ type: String })
  sublabel = '';
  @property({ type: String })
  field = '';
  @property({ type: String })
  subfield = '';
  @property({ type: Boolean })
  hideTooltip = false;
  @property({ type: String })
  tooltip = '';
  @property({ type: Array })
  items: AttachmentItem[] = [];
  @property({ type: String })
  allowedTypes = '*';
  @property({ type: Array })
  uploadList: UploadItem[] = [];
  @property({ type: String })
  token = '';
  @property({ type: Boolean, attribute: 'no-animation' })
  noAnimation = false;

  async onAttachmentItemClick(item: AttachmentItem) {
    const input: FileViewerInput = { document: await item.asDocumentItem(), writeAccess: this.writeAccess };
    const result: FileViewerResult = await FileViewerDialog.open(input);
    console.error(result);
  }

  handleFilePick(e) {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target as HTMLInputElement;
    const files = target.files;
    if (files !== null) {
      this.u = files.length;
      this.attachmentsBefore = this.items.length;
      for (const file1 of files) {
        this.uploadList = [...this.uploadList, { name: file1.name, progress: 0, file: file1 }];
        this.dispatchEvent(
          new CustomEvent<{ file: File; callback: () => void }>('upload-file', {
            bubbles: true,
            composed: true,
            detail: {
              file: file1,
              callback: () => {
                this.uploadList = this.uploadList.filter((f) => f.file !== file1);
              },
            },
          }),
        );
      }
    }
  }

  upload() {
    if (this.shadowRoot) {
      let input;
      if (this.shadowRoot.getElementById('input')) {
        input = this.shadowRoot.getElementById('input') as HTMLInputElement;
        if (input) {
          input.click();
        }
      }
    }
  }

  render() {
    return html`
      <div class="header">
        <div class="actions">
          <d-action @click=${() => this.upload()}>Last opp</d-action>
        </div>
        <d-label
          field="${this.field}"
          ?hide-tooltip="${this.hideTooltip}"
          label="${this.label}"
          subfield="${this.subfield}"
          sublabel="${this.sublabel}"
          tooltip="${this.tooltip}"
        ></d-label>
      </div>
      ${this.items.length
        ? html`
            <div class="attachments">
              ${this.items.map(
                (item) =>
                  html`<div class="header">
                      <div class="actions">
                        <d-action mini @click=${() => this.onAttachmentItemClick(item)}>Åpne</d-action>
                      </div>
                      <d-label light label="${item.name}"></d-label>
                    </div>
                    <pdf-viewer src="${item.previewHref}" ?no-animation=${this.noAnimation}></pdf-viewer>`,
              )}
            </div>
          `
        : nothing}
      <input
        id="input"
        accept="${this.allowedTypes}"
        multiple=""
        @change=${(e) => this.handleFilePick(e)}
        type="file"
      />
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-view-attachments': DViewAttachments;
  }
}
