import { html, TemplateResult } from 'lit';
import { AbstractEntityView, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/elements/d-label.js';
import './d-edit-partner-access.js';
import { customElement } from 'lit/decorators.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { ActionInput } from 'src/library/elements/d-action';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';
import { FeatureStates } from 'src/store/selectors/features';
import { SectionField } from 'src/library/components/d-field-section';
import { ListSectionItemInput } from 'src/library/lists/utilities';

export interface PartnerView extends AbstractEntityView<PartnerViewEditItem> {
  pages: string[];
  contracts: ListSectionItemInput[];
  contacts: ListSectionItemInput[];
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
  type: 'partners';
  name: string;
  availablePages: SelectTagOption[];
  featureStates: FeatureStates;
}

export interface PartnerViewEditItem {
  pages: string[];
  name: string;
  remoteAccess: boolean;
  notes: string;
  url: string;
  address: string;
  physicalAccess: boolean;
  email: string;
  phone: string;
  industry: string;
}

/**
 *
 * STATUS OK
 */
@customElement('d-partner-view')
export class DPartnerView extends EntityContent<PartnerView, PartnerViewEditItem> {
  protected viewFields(): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'industry',
        type: 'view-text',
        class: 'minWidth300',
        condition: m.core,
      },
      {
        field: 'phone',
        type: 'view-text',
        class: 'minWidth300',
        condition: m.core,
      },
      {
        field: 'address',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
      {
        field: 'email',
        type: 'view-text',
        class: 'minWidth300',
        condition: m.core,
      },
      {
        field: 'url',
        type: 'view-text',
        class: 'minWidth300',
        condition: m.core,
      },
      {
        field: 'access',
        type: 'view-conditional-bullet-list',
        condition: m.core,
        listItems: [
          {
            field: 'physicalAccess',
          },
          {
            field: 'remoteAccess',
          },
        ],
      },
      {
        field: 'notes',
        type: 'view-text',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    let contactActions: ActionInput[] = [];
    let contractActions: ActionInput[] = [];
    if (this.entityView.currentUserHasWriteAccess) {
      contactActions = [
        {
          name: 'Legg til',
          action: 'createContact',
          slot: 'top-right',
        },
      ];
      contractActions = [
        {
          name: 'Legg til',
          action: 'createContract',
          slot: 'top-right',
        },
      ];
    }
    return [
      {
        field: 'organization_contacts',
        icon: 'employees',
        actions: contactActions,
        items: 'contacts',
      },
      {
        field: 'organization_contracts',
        icon: 'contracts',
        actions: contractActions,
        items: 'contracts',
        condition: this.entityView.featureStates.core,
      },
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('partners', this.viewFields(), this.entityView)} `;
  }
  protected renderAttachments() {
    if (this.entityView.featureStates.core) {
      return super.renderAttachments();
    }
    return html``;
  }

  async initializeEditItem() {
    this.editItem = {
      address: this.entityView.address,
      email: this.entityView.email,
      industry: this.entityView.industry,
      notes: this.entityView.notes,
      pages: this.entityView.pages,
      phone: this.entityView.phone,
      physicalAccess: this.entityView.physicalAccess,
      remoteAccess: this.entityView.remoteAccess,
      url: this.entityView.url,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(item: PartnerViewEditItem): SectionField[] {
    const m = this.entityView.featureStates;
    return [
      {
        field: 'name',
        type: 'edit-text',
        class: 'minWidth300',
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        placeholder: 'Dette feltet må fylles ut',
      },
      {
        field: 'phone',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.staffing || m.core,
      },
      {
        field: 'industry',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'address',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.core,
      },
      {
        field: 'email',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        field: 'url',
        type: 'edit-text',
        class: 'minWidth200',
        condition: m.core,
      },
      {
        type: 'custom',
        render: () => this.renderEditAccess(item),
        condition: m.core,
      },
      {
        field: 'notes',
        type: 'edit-textarea',
        class: 'fullWidth',
        condition: m.core,
      },
    ];
  }

  renderEditAccess(item: PartnerViewEditItem): TemplateResult<1> {
    return html`
      <d-edit-partner-access
        field="partners_access"
        class="fullWidth"
        ?physicalAccessValue=${item.physicalAccess}
        ?remoteAccessValue=${item.remoteAccess}
        @access-changed=${(e: CustomEvent<{ physical: boolean; remote: boolean }>) => {
          this.editItem = { ...item, physicalAccess: e.detail.physical, remoteAccess: e.detail.remote };
        }}
      >
      </d-edit-partner-access>
    `;
  }

  renderEditItem(item: PartnerViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('partners', this.editFields(item), item)} `;
  }
  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  protected createContact() {
    const id = uuid();
    const input: CreateEntityInput = {
      entityType: 'contacts',
      entityUuid: id,
      targetUrl: this.entityView.href + '/contacts/' + id + '?edit',
      partnerId: this.entityView.uuid,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }

  protected createContract() {
    const id = uuid();
    const input: CreateEntityInput = {
      entityType: 'contracts',
      entityUuid: id,
      targetUrl: this.entityView.href + '/contracts/' + id + '?edit',
      pageId: 63,
      partnerId: this.entityView.uuid,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-partner-view': DPartnerView;
  }
}
