import type { EventOccurrenceViewModel, FunctionViewModel, TaskViewModel } from '../api';
import { endYear, EventModel, ShortNameEntry, RecurrenceRule, ContactsAsUsers } from 'src/store';
import { sortBy } from 'lodash';
import { LocalDate } from 'src/utilities/local-date';
import type { OrganizationState } from '../types';
import { listNamesAsText } from './employee-selector-functions';
import { Occurrence } from 'src/library/lists/d-occurrences-list';
import { dateTimeDescription, eventAssigneesText } from 'src/store/utilities';
import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';

export interface Event {
  name?: string;
  uuid: string;
  rrule?: string;
  persistent?: boolean;
  employees?: string[];
  assets?: string[];
  functionUuid?: string;
  contacts?: string[];
  taskUuid?: string;
  doneDate?: string;
  doneByEmployeeUuid?: string;
  doneByContactPersonUuid?: string;
  taskProcedure?: string;
  notes?: string;
  meetingUuid?: string;
  meetingResponsible?: string;
  meetingAgendaCustom?: boolean;
  meetingAgenda?: string;
  accessControl?: string[];
  classification?: string;
}

export interface EventInstance {
  uuid: string;
  event: Event;
  time: string;
  sortTime?: string;
  dateAndTimeDisplay?: string;
  eventUuid: string;
  eventName: string;
  eventNotes?: string;
  href: string;
  dayTask: boolean;
  overdue?: boolean;
  persistent?: boolean;
  employees?: string[];
  employeeNames: string;
  functionUuid?: string;
  functionName: string;
  contacts?: string[];
  contactNames?: string;
  assignee?: string;
  myTask: boolean;
  timeOfDayDisplay: string;
  dateTimeForOrdering: string;
  doneDate?: string;
  doneByEmployeeUuid?: string;
  doneByContactPersonUuid?: string;
  doneText?: string;
  locked: boolean;
  accessible: boolean;
  hasDraft: boolean;
}
export interface ModelEvent {
  name: string;
  time: string;
  durationMinutes: number;
  assignedToFunction: string;
  assignedToEmployees: string[];
  assignedToContacts: string[];
  notes: string;
  procedures: string;
  taskUuid: string;
  futureNotDoneOccurrences: Occurrence[];
}

export function isRestricted(event, currentEmployeeUuid) {
  return !(
    !event.classification ||
    event.classification === 'NONE' ||
    event.accessControl?.includes(currentEmployeeUuid)
  );
}

function findClosestInstance(occurrences: EventOccurrenceViewModel[], date: LocalDate): EventOccurrenceViewModel {
  const datedOccurrences = occurrences.filter((o) => o.date !== undefined);
  const sortedDatedOccurrences = sortBy(datedOccurrences, (o) => LocalDate.fromString(o.date));
  const previous = sortedDatedOccurrences.findLast((o) => LocalDate.fromString(o.date).isSameOrBefore(date));
  if (previous !== undefined) return previous;
  const next = sortedDatedOccurrences.find((o) => LocalDate.fromString(o.date).isAfter(date));
  if (next !== undefined) return next;
  const undated = occurrences.find((o) => o.date === undefined);
  if (undated === undefined) {
    throw new Error('No occurrence found in EventModel');
  }
  return undated;
}

export function getModelEvents(
  events: EventModel[],
  eventOccurrences: EventOccurrenceViewModel[],
  tasks: TaskViewModel[],
  functionsNotDeleted: FunctionViewModel[],
  employeesShortNamesAndI: ShortNameEntry[],
  contactsAsUsers: ContactsAsUsers[],
  relatedAssetForNew: string,
): ModelEvent[] {
  const now = LocalDate.now();
  const eventModels: ModelEvent[] = events.map((e) => {
    const o = findClosestInstance(e.eventOccurrences, now);
    return {
      name: o.name ?? '',
      durationMinutes: o.durationMinutes,
      assignedToFunction: o.functionUuid ?? '',
      assignedToEmployees: o.employees ?? [],
      assignedToContacts: o.contacts ?? [],
      notes: o.notes,
      time: o.time || 'NONE',
      procedures: o.description ?? '',
      taskUuid: o.taskUuid ?? '',
      futureNotDoneOccurrences: sortBy(
        e.eventOccurrences
          .filter((o) => {
            return !o.doneDate && (o.date === '' || LocalDate.fromString(o.date).isSameOrAfter(now));
          })
          .map((o) => {
            let assetRelated = false;
            if (
              o.relatedAssets?.find((a) => {
                return a.assetUuid === relatedAssetForNew;
              }) !== undefined
            ) {
              assetRelated = true;
            }
            return {
              occurrenceUuid: o.uuid,
              dateTime: (o.date ?? '') + (o.time ?? ''),
              durationMinutes: o.durationMinutes,
              classified: false,
              restricted: false,
              displayDate: dateTimeDescription(o.date ?? '', o.time ?? ''),
              assignees: eventAssigneesText(e, functionsNotDeleted, employeesShortNamesAndI, contactsAsUsers),
              assetRelated,
            };
          }),
        (x) => x.dateTime,
        (x) => x.occurrenceUuid,
      ),
    };
  });

  const taskModels = tasks
    .filter((t) => {
      return (
        eventOccurrences.find((e) => {
          return e.taskUuid === t.uuid;
        }) === undefined
      );
    })
    .map((t) => {
      return {
        name: t.name ?? '',
        time: 'NONE',
        durationMinutes: 0,
        assignedToFunction: '',
        assignedToEmployees: [],
        assignedToContacts: [],
        notes: '',
        procedures: t.procedures ?? '',
        taskUuid: t.uuid,
        futureNotDoneOccurrences: [],
      };
    });

  const modelEvents = [...eventModels, ...taskModels];

  return sortBy(modelEvents, ['name']);
}

function getDoneUpdatedText(
  doneDate: LocalDate,
  doneUpdatedAt: LocalDate,
  doneByUuid: string,
  doneUpdatedByUuid: string,
  doneByOptions: SelectDropdownOption[],
) {
  let doneUpdatedText = '';
  if (!doneDate.isSame(doneUpdatedAt)) {
    doneUpdatedText = doneUpdatedAt.toStringForDisplay();
  }
  if (doneByUuid !== doneUpdatedByUuid) {
    const doneByOption = doneByOptions.find((o) => {
      return o.value === doneUpdatedByUuid;
    });
    if (doneByOption) {
      doneUpdatedText += ' av ' + doneByOption.text;
    }
  }
  if (doneUpdatedText) {
    doneUpdatedText = ' – merket ' + doneUpdatedText + '';
  }
  return doneUpdatedText;
}
export function eventDoneText(
  item: EventOccurrenceViewModel,
  doneByOptions: SelectDropdownOption[],
  singleUserVersion: boolean,
  includeUpdated: boolean = false,
) {
  if (!item.doneDate) {
    return '';
  }
  const doneDate = LocalDate.fromString(item.doneDate);
  const doneUpdatedAt = LocalDate.fromString(item.doneUpdatedAt);
  let doneBy = '';
  let doneUpdatedText = '';
  if (!singleUserVersion) {
    let doneByUuid = item.doneByEmployeeUuid ?? '';
    if (item.doneByContactPersonUuid) {
      doneByUuid = item.doneByContactPersonUuid;
    }
    let doneUpdatedByUuid = item.doneUpdatedByEmployeeUuid ?? '';
    if (item.doneUpdatedByContactPersonUuid) {
      doneUpdatedByUuid = item.doneUpdatedByContactPersonUuid;
    }
    const doneByOption = doneByOptions.find((o) => {
      return o.value === doneByUuid;
    });
    if (doneByOption) {
      doneBy = ' av ' + doneByOption.text;
    }
    if (includeUpdated) {
      doneUpdatedText = getDoneUpdatedText(doneDate, doneUpdatedAt, doneByUuid, doneUpdatedByUuid, doneByOptions);
    }
  }
  return doneDate.toStringForDisplay() + doneBy + doneUpdatedText;
}

export function taskEventTodoItemsFunction(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState,
  employeesAndContactsOptions: SelectDropdownOption[],
  employeeNamesI: { uuid: string; status: string; name: string }[],
  functions: FunctionViewModel[],
  contacts: ContactsAsUsers[],
  organizationId: number,
  currentUserUuid: string,
  singleUserVersion: boolean,
): EventInstance[] {
  const result: EventInstance[] = [];
  events.forEach(function (event) {
    if (event.date === null || event.date === undefined) {
      let functionName = '';
      let employeeNamesDisplay = '';
      let myTask = true;
      if (!singleUserVersion) {
        const employeesList = event.employees ?? [];
        const employeeNamesList = listNamesAsText(employeeNamesI, employeesList);
        employeeNamesDisplay = employeeNamesList.charAt(0).toUpperCase() + employeeNamesList.slice(1);
        if (event.functionUuid) {
          functionName = organization.functionsById[event.functionUuid].name ?? '';
        }
        myTask = employeesList.includes(currentUserUuid) || event.functionUuid !== '';
      }
      if (event.contacts && event.contacts.length) {
        myTask = event.contacts.includes(currentUserUuid);
      }
      let doneText = '';
      if (event.doneDate) {
        doneText = 'Utført av ' + eventDoneText(event, employeesAndContactsOptions, singleUserVersion);
      }
      let sortTime = '1000-01-01';
      if (event.doneDate) {
        sortTime = event.doneDate;
      }
      const occurrence = event;
      if (occurrence) {
        result.push({
          uuid: occurrence.uuid,
          event,
          time: '',
          sortTime: sortTime,
          dateAndTimeDisplay: 'Snarest',
          eventUuid: event.uuid,
          eventName: event.name !== undefined ? event.name : '',
          eventNotes: '', // TODO event.notes,
          href: '/account/' + organizationId + '/' + 318434 + '/eventOccurrences/' + occurrence.uuid,
          dayTask: false,
          employees: event.employees,
          employeeNames: employeeNamesDisplay,
          functionUuid: event.functionUuid,
          functionName: functionName,
          contacts: event.contacts,
          assignee: eventAssigneesText(event, functions, employeeNamesI, contacts),
          myTask: myTask,
          timeOfDayDisplay: '',
          dateTimeForOrdering: '1000-01-01 00:00',
          doneDate: event.doneDate,
          doneByEmployeeUuid: event.doneByEmployeeUuid,
          doneByContactPersonUuid: event.doneByContactPersonUuid,
          doneText,
          accessible: true,
          locked: false,
          hasDraft: occurrence.hasDraft,
        });
      }
    }
  });
  return result;
}

export function eventOccurrences(event, currentEmployeeUuid) {
  const result: Occurrence[] = [];
  if (event.rrule !== undefined && event.rrule !== '') {
    const r = RecurrenceRule.fromString(event.rrule);
    const start = LocalDate.fromString('1000-01-01');
    const end = LocalDate.fromString(endYear + '-12-31');
    let x = r.sameOrAfter(start);
    let timeOfDay = '';
    if (r.hasTime()) {
      timeOfDay = ' ' + r.timeDisplay();
    }
    while (x?.isSameOrBefore(end)) {
      result.push({
        occurrenceUuid: event.uuid,
        dateTime: x.toString() + timeOfDay,
        durationMinutes: event.durationMinutes,
        classified: event.classification !== 'NONE',
        restricted: isRestricted(event, currentEmployeeUuid),
      });
      x = r.after(x);
    }
  } else {
    let dateTime = '';
    if (event.doneDate) {
      dateTime = event.doneDate;
    }
    return [
      {
        uuid: event.uuid,
        dateTime,
        classified: event.classification !== 'NONE',
        restricted: isRestricted(event, currentEmployeeUuid),
      },
    ];
  }
  return result;
}

export function taskEventInstancesFunction(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState,
  employeesAndContactsOptions: SelectDropdownOption[],
  employeeNamesI: { uuid: string; status: string; name: string }[],
  contacts: ContactsAsUsers[],
  functions: FunctionViewModel[],
  organizationId: number,
  currentUserUuid: string,
  today: LocalDate,
  singleUserVersion: boolean,
  context: string,
): EventInstance[] {
  const result: EventInstance[] = [];
  events.forEach((event: EventOccurrenceViewModel): void => {
    if (event.date !== null && event.date !== undefined) {
      let functionName = '';
      let employeeNames = '';
      let myTask = true;
      if (!singleUserVersion) {
        const employeesList = event.employees ?? [];
        if (event.functionUuid) {
          functionName = organization.functionsById[event.functionUuid].name ?? '';
        }
        const employeeNamesList = listNamesAsText(employeeNamesI, employeesList);
        employeeNames = employeeNamesList.charAt(0).toUpperCase() + employeeNamesList.slice(1);
        myTask =
          employeesList.includes(currentUserUuid) ||
          (event.contacts && event.contacts.length > 0 && event.contacts.includes(currentUserUuid)) ||
          event.functionUuid !== '';
      }
      const locked = false;
      const accessible = true;
      const dayTask = event.time === undefined;
      let hour = event.time ?? '';
      if (hour !== '') {
        hour = ' kl. ' + hour.replace(':', '.');
      }
      const date = LocalDate.fromString(event.date).toStringForDisplayWithDayOfWeekAndYear();
      const dateAndTimeDisplay = date.charAt(0).toUpperCase() + date.slice(1) + hour;
      let sortTime = event.date ?? '';
      let doneText = '';
      if (event.doneDate) {
        sortTime = event.doneDate;
        doneText = 'Utført av ' + eventDoneText(event, employeesAndContactsOptions, singleUserVersion);
      }
      let overdue = false;
      if (
        event.persistent &&
        LocalDate.fromString(event.date).isBefore(today) &&
        !event.doneDate &&
        !event.doneByEmployeeUuid
      ) {
        overdue = true;
      }
      result.push({
        event,
        uuid: '', // TODO
        hasDraft: false, // TODO
        eventUuid: event.uuid,
        eventName: event.name !== undefined ? event.name : '',
        eventNotes: '', // TODO event.notes,
        href: '/account/' + organizationId + '/' + context + '/eventOccurrences/' + event.uuid,
        time: (event.date ?? '') + event.time,
        sortTime,
        dateAndTimeDisplay,
        dayTask,
        overdue,
        employees: event.employees,
        employeeNames,
        functionUuid: event.functionUuid,
        functionName,
        contacts: event.contacts,
        assignee: eventAssigneesText(event, functions, employeeNamesI, contacts),
        myTask,
        timeOfDayDisplay: dayTask ? '' : event.time ?? '', // TODO r.timeDisplay(),
        dateTimeForOrdering: (event.date ?? '') + event.time,
        doneDate: event.doneDate,
        doneByEmployeeUuid: event.doneByEmployeeUuid,
        doneByContactPersonUuid: event.doneByEmployeeUuid,
        doneText,
        locked,
        accessible,
      });
    }
  });
  return sortBy(result, [(item) => item.time]);
}
export function taskEventsInstances(
  events: EventOccurrenceViewModel[],
  organization: OrganizationState | undefined,
  employeeNamesI: ShortNameEntry[],
  functions: FunctionViewModel[],
  contacts: ContactsAsUsers[],
  organizationid: number,
  currentUserUuid: string | undefined,
  singleUserVersion: boolean,
  today: LocalDate,
  employeesAndContacts: SelectDropdownOption[],
): EventInstance[] {
  if (organization === undefined) {
    return [];
  }
  const todoItems = taskEventTodoItemsFunction(
    events,
    organization,
    employeesAndContacts,
    employeeNamesI,
    functions,
    contacts,
    organizationid,
    currentUserUuid ? currentUserUuid : '',
    singleUserVersion,
  );
  const datedItems = taskEventInstancesFunction(
    events,
    organization,
    employeesAndContacts,
    employeeNamesI,
    contacts,
    functions,
    organizationid,
    currentUserUuid ? currentUserUuid : '',
    today,
    singleUserVersion,
    '318434',
  );
  return todoItems.concat(datedItems);
}
