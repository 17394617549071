import { html, nothing, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/lists/d-list-section-attachment.js';
import './d-edit-rotation';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition, toLookup } from '../entity-content.js';
import { DEditRotation, RotationEmployeeWeek } from './d-edit-rotation.js';
import './d-view-rotation-schedule.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';

export interface FunctionTask {
  name: string;
  functionUuid: string;
  responsibleFunctionUuid: string;
  locked: boolean;
  uuid: string;
  templateDeleted: string;
  hasTemplateUpdate: boolean;
  templateUpdated: string;
  contentLastModified: string;
  templateDeclined: string;
  hasDraft: boolean;
  href: string;
}

export interface FunctionView extends AbstractEntityViewWithRevisions<FunctionViewEditItem>, UpdateSectionItem {
  pages: string[];
  employees: string[];
  responsibilityLocked: boolean;
  availableEmployees: SelectTagOption[];
  type: 'functions';
  name: string;
  description: string;
  functionType: 'COMMON' | 'ROTATED' | 'SIMULTANEOUS' | 'SINGLE';
  assignedEmployeeNames: string;
  rotation?: {
    count: number;
    type: string;
    employees: { uuid: string; firstName: string; lastName: string }[];
    rotations: RotationEmployeeWeek[];
    selectedEmployees: string[];
  };
  assignedTasks: ListSectionItemInput[];
  responsibleTasks: ListSectionItemInput[];
  availablePages: SelectTagOption[];
  isCommon: boolean;
  isRotated: boolean;
}

export interface FunctionViewEditItem {
  functionType: string;
  rotations: RotationEmployeeWeek[];
  rotationType: string;
  employees: string[];
  description: string;
  pages: string[];
  name: string;
}

/**
 *
 */
@customElement('d-function-view')
export class DFunctionView extends EntityContent<FunctionView, FunctionViewEditItem> {
  static readonly styles = EntityContent.styles;
  private static functionTypeLookup = toLookup({
    SINGLE: 'Fast (én person)',
    ROTATED: 'Roterbar (én om gangen)',
    SIMULTANEOUS: 'Simultan (flere samtidig)',
  });
  private static functionTypeOptions = [
    { value: 'SINGLE', text: 'Fast (én person)' },
    { value: 'ROTATED', text: 'Roterbar (én om gangen)' },
    {
      value: 'SIMULTANEOUS',
      text: 'Simultan (flere samtidig)',
    },
  ];
  @query('#rotations')
  rotations!: DEditRotation;
  @property({ type: Object })
  organization: any = {};
  @property({ type: String })
  entityType = 'functions';
  @property({ type: String })
  employeeId = '';
  @property({ type: Array })
  employees = [];
  @property({ type: Array })
  tasks = [];

  protected get preventDelete(): boolean {
    return this.entityView.isCommon;
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'description',
        type: 'view-html',
        class: 'fullWidth',
      },
      {
        field: 'functionType',
        type: 'view-text',
        options: DFunctionView.functionTypeOptions,
        class: 'minWidth300',
        condition: this.entityView.functionType !== 'COMMON',
      },
      {
        field: 'assignedEmployeeNames',
        type: 'view-text',
        class: 'minWidth300',
        condition: this.entityView.functionType !== 'COMMON',
      },
      {
        field: 'rotations',
        type: 'custom',
        render: () => this.renderRotationSchedule(),
        condition: this.entityView.rotation !== undefined,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'functions_ownTasks',
        type: 'custom',
        icon: 'tasks',
        items: 'assignedTasks',
        render: () => this.renderAssignedTasks(),
      },
      {
        field: 'functions_ownTasks',
        icon: 'tasks',
        items: 'assignedTasks',
        condition: false,
      },
      {
        field: 'tasks_responsible',
        type: 'custom',
        icon: 'tasks',
        items: 'responsibleTasks',
        render: () => this.renderResponsibleTasks(),
        condition: this.entityView.responsibleTasks.length > 0,
      },
      {
        field: 'tasks_responsible',
        icon: 'tasks',
        items: 'responsibleTasks',
        condition: false,
      },
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
      {
        field: 'generalFields_attachments',
        icon: 'attachments',
        items: 'attachments',
        condition: false,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('functions', this.viewFields(), this.entityView)} `;
  }
  renderRotationSchedule(): TemplateResult<1> {
    return html`
      ${this.entityView.rotation === undefined
        ? nothing
        : html` <d-section>
            <d-view-rotation-schedule
              .count=${this.entityView.rotation.count}
              .employees=${this.entityView.rotation.employees}
              .rotationType=${this.entityView.rotation.type}
              .rotations=${this.entityView.rotation.rotations}
              .selectedEmployees=${this.entityView.rotation.selectedEmployees}
              .today=${LocalDate.fromString(this.today)}
            >
            </d-view-rotation-schedule
          ></d-section>`}
    `;
  }

  renderAssignedTasks(): TemplateResult<1> {
    return html` <d-list-section
      .items=${this.entityView.assignedTasks}
      page-id="${this.pageId}"
      employee-id="${this.employeeId}"
      field="functions_ownTasks"
      icon="tasks"
      .writeAccess="${this.entityView.currentUserHasWriteAccess}"
      .contentStickyTop=${this.contentStickyTop}
    >
    </d-list-section>`;
  }

  renderResponsibleTasks(): TemplateResult<1> {
    return html`<d-list-section
      .items=${this.entityView.responsibleTasks}
      .limitItems=${3}
      page-id="${this.pageId}"
      icon="tasks"
      employee-id="${this.employeeId}"
      field="tasks_responsible"
      .writeAccess="${this.entityView.currentUserHasWriteAccess}"
      .contentStickyTop=${this.contentStickyTop}
    >
    </d-list-section>`;
  }

  async initializeEditItem() {
    this.editItem = {
      functionType: this.entityView.functionType,
      rotationType: this.entityView.rotation === undefined ? 'R-0' : this.entityView.rotation.type,
      rotations: this.entityView.rotation?.rotations || [],
      description: this.entityView.description,
      employees: this.entityView.employees,
      pages: this.entityView.pages,
      name: this.entityView.name,
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(item: FunctionViewEditItem): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
        markIfEmpty: true,
        class: 'fullWidth',
      },
      {
        field: 'description',
        type: 'edit-html',
        docsForLinking: this.entityView.docsForLinking,
      },
      {
        field: 'functionType',
        type: 'edit-dropdown',
        options: DFunctionView.functionTypeOptions,
        disabled: this.entityView.responsibilityLocked,
        class: 'fullWidth',
        condition: this.entityView.functionType !== 'COMMON',
      },
      {
        field: 'employees',
        type: 'edit-tags',
        options: this.entityView.availableEmployees,
        single: item.functionType === 'SINGLE',
        class: 'fullWidth',
        condition: this.entityView.functionType !== 'COMMON',
      },
      {
        type: 'custom',
        render: () => this.renderEditRotation(item),
        condition: item.functionType === 'ROTATED' && item.employees.length > 1,
      },
    ];
  }

  private renderEditRotation(item: FunctionViewEditItem) {
    return html`<d-edit-rotation
      .employees=${this.entityView.availableEmployees}
      id="rotations"
      .rotationType=${item.rotationType}
      .rotations=${item.rotations}
      .selectedEmployees=${this.entityView.availableEmployees
        .filter((e) => item.employees.includes(e.value))
        .map((e) => ({ uuid: e.value, name: e.text }))}
      @rotation-changed=${(e: CustomEvent<{ rotationType: string; rotations: RotationEmployeeWeek[] }>) => {
        this.editItem = { ...item, rotationType: e.detail.rotationType, rotations: e.detail.rotations };
      }}
    >
    </d-edit-rotation>`;
  }

  renderEditItem(item: FunctionViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('functions', this.editFields(item), item)}`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-function-view': DFunctionView;
  }
}
