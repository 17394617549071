import { html, TemplateResult } from 'lit';
import { AbstractEntityViewWithRevisions, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import '../../library/fields/index.js';
import '../../library/editors/index.js';
import '../../library/lists/d-list-section-attachment.js';
import '../../library/editors/components/d-edit-classification.js';
import { customElement, property } from 'lit/decorators.js';
import type { SelectTagOption } from 'src/library/editors/elements/d-select-tag.js';
import type { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { SectionField } from 'src/library/components/d-field-section';

export interface GuidelineViewEditItem {
  pages: string[];
  content: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  name: string;
}

export interface GuidelineView extends AbstractEntityViewWithRevisions<GuidelineViewEditItem>, UpdateSectionItem {
  pages: string[];
  availablePages: SelectTagOption[];
  type: 'guidelines';
  name: string;
  classification: 'NONE' | 'CONFIDENTIAL' | 'PRIVATE';
  accessControl: string[];
  accessControlOptions: SelectTagOption[];
  classificationText: string;
  content: string;
}

/**
 *
 */
@customElement('d-guideline-view')
export class DGuidelineView extends EntityContent<GuidelineView, GuidelineViewEditItem> {
  static readonly styles = EntityContent.styles;
  @property({ type: String })
  entityType = 'guidelines';
  @property({ type: Boolean })
  helpOpened = false;

  get helpOnly() {
    return (
      this.helpContent !== '' &&
      (!this.entityView.content || this.entityView.content === '<p><br></p>') &&
      this.mode === 'normal'
    );
  }

  protected viewFields(): SectionField[] {
    return [
      {
        field: 'classificationText',
        fieldForLabel: 'generalFields_access',
        type: 'view-text',
        class: 'fullWidth',
        condition: this.entityView.classificationText !== '',
      },
      {
        field: 'content',
        type: 'view-html',
        hideLabel: true,
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('documents', this.viewFields(), this.entityView)} `;
  }

  async initializeEditItem() {
    this.editItem = {
      pages: this.entityView.pages,
      content: this.entityView.content,
      classification: this.entityView.classification,
      accessControl: this.entityView.accessControl,
      name: this.entityView.name,
    };
  }

  _setHelpOpened(newValue) {
    setTimeout(() => {
      if (newValue) {
        this.helpOpened = true;
      } else {
        this.helpOpened = false;
      }
    }, 0);
  }

  createNewItem() {
    return {
      content: '',
      template: '',
      category: '',
      status: '',
      name: '',
      accessControl: [],
      classification: 'NONE',
      pages: [this.pageId],
    };
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  private editFields(): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        class: 'fullWidth',
        placeholder: 'Dette feltet må fylles ut',
        markIfEmpty: true,
        autofocus: !this.entityView.isConfirmedEntity,
        selectOnFocus: !this.entityView.isConfirmedEntity,
      },
      {
        field: 'content',
        type: 'edit-html',
        docsForLinking: this.entityView.docsForLinking,
      },
    ];
  }

  renderEditItem(item: GuidelineViewEditItem): TemplateResult<1> {
    return html`
      ${this.renderEditClassification(item)} ${this.renderFieldSection('guidelines', this.editFields(), item)}
    `;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return this.entityView;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-guideline-view': DGuidelineView;
  }
}
