import { css, html, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { EventList } from 'src/library/abstracts/event-list.js';
import type { ActionInput } from 'src/library/elements/d-action.js';
import { displayTime, ListItemIcon, ListSectionItemInput, TaskEvent } from 'src/library/lists/utilities.js';
import { LocalDate } from 'src/utilities/local-date.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../elements/d-tooltip.js';
import '../fields/d-expansion.js';
import './d-list-header.js';
import { DListSection } from './d-list-section.js';
import { uuid } from 'src/utilities/text';
import { CreateEntityInput, CreateEventOccurrenceInput } from 'src/layout/parts/d-new-documents-list';

/**
 *
 */
@customElement('d-list-section-task-events')
export class DListSectionTaskEvents extends EventList<ListSectionItemInput> {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
        margin-bottom: 6px;
      }

      .noItems {
        height: 26px;
      }

      d-action {
        margin-top: 6px;
        margin-left: calc(var(--listPaddingLeft) - 8px);
      }

      .popup-card {
        flex-basis: 720px;
      }

      @media only screen and (max-width: 600px) {
        d-action {
          margin-left: -8px;
        }
      }
    `,
  ];
  @property({ type: Array })
  items!: TaskEvent[];
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: String })
  href = '';
  @property({ type: String })
  taskUuid = '';
  @property({ type: Object })
  task: any = {};
  @property({ type: Array })
  events: any[] = [];
  @property({ type: Boolean })
  showMoreInstances = false;
  @property({ type: Object })
  item: any = {};
  @property({ type: Object })
  editItem: any = {};
  @property({ type: Boolean })
  newItem = false;
  @property({ type: String })
  instance = '';
  @property({ type: String })
  currentEmployeeUuid?: string = '';
  @property({ type: String })
  defaultEmployee = '';
  @property({ type: String })
  defaultFunction = '';
  @property({ type: Boolean })
  showNotes = true;
  @property({ type: Boolean })
  startTask = false;
  @property({ type: String })
  startTaskText = '';
  @property({ type: String })
  now = '';
  @property({ type: Boolean })
  showPast = false;

  private get pastInstances() {
    return this.items.filter((item) => {
      return (
        ((LocalDate.fromString(item.sortTime).isBefore(LocalDate.fromString(this.now)) && item.time) ||
          (LocalDate.fromString(item.sortTime).isBefore(LocalDate.fromString(this.now)) &&
            !item.time &&
            item.doneDate)) &&
        !item.overdue
      );
    });
  }

  private get futureInstances() {
    return this.items.filter((item) => {
      return (
        LocalDate.fromString(item.sortTime).isSameOrAfter(LocalDate.fromString(this.now)) ||
        (!item.time && !item.doneDate) ||
        item.overdue
      );
    });
  }

  private get firstInstances() {
    return this.futureInstances.slice(0, 5);
  }

  private get moreInstances() {
    return this.futureInstances.slice(5);
  }

  private get showToggleNotes() {
    return (
      this._hasNotes(this.firstInstances) ||
      (this.showPast && this._hasNotes(this.pastInstances)) ||
      (this.showMoreInstances && this._hasNotes(this.moreInstances))
    );
  }

  private get noItemText() {
    if (this.pastInstances.length) {
      return 'Ingen fremtidige oppgaver knyttet til denne rutinen';
    }
    return 'Ingen oppgaver knyttet til denne rutinen';
  }

  _toggleMoreInstances() {
    this.showMoreInstances = !this.showMoreInstances;
  }

  _actions() {
    const actions: ActionInput[] = [];
    if (this.showToggleNotes) {
      let toggleShowNotesLabel = 'Vis notater';
      if (this.showNotes) {
        toggleShowNotesLabel = 'Skjul notater';
      }
      actions.push({
        name: toggleShowNotesLabel,
        action: 'toggleShowNotes',
        slot: 'top-right',
      });
    }
    if (this.pastInstances.length) {
      let toggleShowPastLabel = 'Vis tidligere';
      if (this.showPast) {
        toggleShowPastLabel = 'Skjul tidligere';
      }
      actions.push({
        name: toggleShowPastLabel,
        action: 'toggleShowPast',
        slot: 'top-right',
      });
    }
    if (this.writeAccess) {
      actions.push({
        name: 'Legg til',
        action: 'createEventOccurrence',
        slot: 'top-right',
      });
    }
    return [...actions];
  }

  _fireAction(action: string) {
    if (action === 'toggleShowNotes') {
      this.showNotes = !this.showNotes;
    }
    if (action === 'toggleShowPast') {
      this.showPast = !this.showPast;
    }
    if (action === 'createEventOccurrence') {
      this.onCreateEventOccurrence();
    }
  }

  asListSectionItemInputs(list: TaskEvent[]): ListSectionItemInput[] {
    return list.map((l) => {
      let label = displayTime(l);
      if (l.eventName) {
        label += ' (' + l.eventName + ')';
      }
      let mainIcon: ListItemIcon | undefined = undefined;
      if (l.overdue) {
        mainIcon = 'alert';
      } else if (l.doneDate !== undefined && l.doneDate !== '') {
        mainIcon = 'checkGray';
      }
      return {
        label,
        accessible: true,
        updateStatus: 'none',
        hidden: false,
        eventCurrentUser: l.eventCurrentUser,
        href: l.href,
        secondaryLabel: l.secondaryLabel,
        notes: l.notes,
        hasDraft: l.hasDraft,
        rightLabel: l.rightLabel,
        rightLabelClass: 'assignee',
        eventOverdue: l.overdue,
        mainIcon,
      };
    });
  }

  render() {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header
        icon="events"
        label="Oppgaver"
        .actions=${this._actions()}
        @action=${(e) => this._fireAction(e.detail)}
        style=${styleMap(listHeaderStyles)}
      ></d-list-header>
      <d-expansion ?opened=${this.showPast}>
        <d-list-section
          no-header
          ?showNotes=${this.showNotes}
          .items=${this._eventsWithFilterProperties(this.asListSectionItemInputs(this.pastInstances))}
        ></d-list-section>
      </d-expansion>
      <d-list-section
        no-header
        no-item-text="${this.noItemText}"
        ?showNotes=${this.showNotes}
        .items=${this._eventsWithFilterProperties(this.asListSectionItemInputs(this.firstInstances))}
      ></d-list-section>
      <d-expansion ?opened=${this.showMoreInstances}>
        <d-list-section
          no-header
          no-item-text=""
          ?showNotes=${this.showNotes}
          .items=${this._eventsWithFilterProperties(this.asListSectionItemInputs(this.moreInstances))}
        ></d-list-section>
      </d-expansion>
      ${this.moreInstances.length
        ? html`<d-action @click=${() => this._toggleMoreInstances()}
            >${this.showMoreInstances ? 'Vis færre' : 'Vis flere'}</d-action
          >`
        : nothing}
    `;
  }

  private onCreateEventOccurrence() {
    const id = uuid();
    const date = LocalDate.now().toString();
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      taskUuid: this.taskUuid,
      instance: date,
      targetUrl: this.href + '/eventOccurrences/' + id + '?edit',
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-task-events': DListSectionTaskEvents;
  }
}
