import { LocalDate } from 'src/utilities/local-date';

export function timespanType(startDate: string, endDate: string): 'YEARS' | 'QUARTERS' | 'MONTHS' | 'DAYS' {
  const start = LocalDate.fromString(startDate);
  const end = LocalDate.fromString(endDate);
  let startYear: number | undefined = undefined;
  let endYear: number | undefined = undefined;
  if (start.day() === 1 && start.month() === 1) {
    startYear = start.year();
  }
  if (end.day() === 31 && end.month() === 12) {
    endYear = start.year();
  }
  if (startYear && endYear) {
    return 'YEARS';
  }
  let startQuarter: number | undefined = undefined;
  let endQuarter: number | undefined = undefined;
  if (start.day() === 1 && Number.isInteger((start.month() - 1) / 3)) {
    startQuarter = (start.month() - 1) / 3 + 1;
  }
  if (end.day() === end.daysInMonth() && Number.isInteger(end.month() / 3)) {
    endQuarter = end.month() / 3;
  }
  if (startQuarter && endQuarter) {
    return 'QUARTERS';
  }
  if (start.day() === 1 && end.day() === end.daysInMonth()) {
    return 'MONTHS';
  }
  return 'DAYS';
}

function timespanTextYears(start: LocalDate, end: LocalDate) {
  if (start.year() === end.year()) {
    return start.year() + '';
  }
  return start.year() + '-' + end.year();
}

function timespanTextQuarters(start: LocalDate, end: LocalDate) {
  const startQuarter = (start.month() - 1) / 3 + 1;
  const endQuarter = end.month() / 3;
  if (start.year() === end.year()) {
    if (startQuarter === endQuarter) {
      return startQuarter + '. kvartal ' + start.year();
    }
    return startQuarter + '.-' + endQuarter + '. kvartal ' + start.year();
  }
  return startQuarter + '. kvartal ' + start.year() + ' - ' + endQuarter + '. kvartal ' + end.year();
}

function timespanTextMonths(start: LocalDate, end: LocalDate) {
  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      return start.nameOfMonth() + ' ' + start.year();
    }
    return start.nameOfMonth() + ' - ' + end.nameOfMonth() + ' ' + start.year();
  }
  return start.nameOfMonth() + ' ' + start.year() + ' - ' + end.nameOfMonth() + ' ' + end.year();
}

export function timespanText(startDate: string | undefined, endDate: string | undefined): string {
  if (startDate && endDate) {
    const type = timespanType(startDate, endDate);
    const start = LocalDate.fromString(startDate);
    const end = LocalDate.fromString(endDate);
    if (type === 'YEARS') {
      return timespanTextYears(start, end);
    }
    if (type === 'QUARTERS') {
      return timespanTextQuarters(start, end);
    }
    if (type === 'MONTHS') {
      return timespanTextMonths(start, end);
    }
    if (start.isSame(end)) {
      return start.toStringForDisplay();
    }
    return start.toStringForDisplay() + ' - ' + end.toStringForDisplay();
  }
  return '';
}

export function zeropad(n: number) {
  return ('00' + n).slice(-2);
}

export function dateToMonths(day: string): number {
  const date = LocalDate.fromString(day);
  const month = date.month();
  return date.year() * 12 + month;
}

function monthEnd(year: number, month: number) {
  const m = zeropad(month);
  const days = LocalDate.fromString(year + '-' + m + '-01').daysInMonth();
  const d = zeropad(days);
  return year + '-' + m + '-' + d;
}

export function monthsToDateFirst(months: number): string {
  const years = months / 12;
  let year = Math.floor(years);
  let month = Math.round((years - year) * 12);
  if (month === 0) {
    year = year - 1;
    month = 12;
  }
  return year + '-' + zeropad(month) + '-01';
}

export function monthsToDateLast(months: number): string {
  const datish = LocalDate.fromString(monthsToDateFirst(months));
  return monthEnd(datish.year(), datish.month());
}
