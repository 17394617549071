import { html, css, nothing, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { AbstractEntityView, EntityContent, ListDefinition } from 'src/content/entity-content.js';
import type { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown.js';
import '../../library/editors/index.js';
import '../../library/fields/index.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-smooth-resize';
import '../../library/lists/d-list-section-attachment.js';
import { UpdateSectionItem } from 'src/content/d-update-section.js';
import { isEmptyOrInvalidString, uuid } from 'src/utilities/text';
import { EventDataForAsset } from 'src/store';
import { classMap } from 'lit/directives/class-map.js';
import { shortenPath } from 'src/utilities/path';
import { LocalDate } from 'src/utilities/local-date';
import { ActionInput } from 'src/library/elements/d-action';
import { SectionField } from 'src/library/components/d-field-section';
import { CreateEntityInput, CreateEventOccurrenceInput } from 'src/layout/parts/d-new-documents-list';

export interface ItemAssetTaskInstance {
  status: '' | 'OK' | 'NOT_OK';
  time?: string;
  doneDate?: string;
  overdue: boolean;
  href: string;
  dateAndTimeDisplay: string;
  statusDatePerson: string;
  statusComments: string;
}

export interface ItemAssetTask {
  instances: ItemAssetTaskInstance[];
  name: string;
  href: string;
  uuid: string;
}

export interface AssetRelatedTaskOption {
  uuid: string;
  label: string;
}

export interface AssetViewEditItem {
  name: string;
  number: string;
  category: string;
  owner: string;
  requiresElectricity: boolean;
  radiation: boolean;
  radiationType: string;
  dsaRegistered: boolean;
  dsaApproved: boolean;
  manufacturer: string;
  model: string;
  location: string;
  userManual: string;
  supplier: string;
  acquiredYear: string;
  discardedYear: string;
  accessory: string;
  competency: string;
  maintenance: string;
  maintenanceRequired: boolean;
  complianceDeclaration: string;
  assetVerified: boolean;
  documentationVerified: boolean;
  documented: boolean;
}

export interface AssetView extends AbstractEntityView<AssetViewEditItem> {
  radiation: boolean;
  radiationType: string;
  radiationCategory1: boolean;
  radiationCategory2: boolean;
  radiationCategory3: boolean;
  owner: string;
  dsaApproved: boolean;
  dsaRegistered: boolean;
  documented: boolean;
  documentationVerified: boolean;
  assetVerified: boolean;
  maintenanceRequired: boolean;
  requiredEventInstanceMissing: boolean;
  requiresElectricity: boolean;
  hasPersonalDataItem: boolean;
  maintenance: string;
  competency: string;
  accessory: string;
  complianceDeclaration: string;
  discardedYear: string;
  acquiredYear: string;
  supplier: string;
  userManual: string;
  ownerDisplay: string;
  category: string;
  location: string;
  model: string;
  manufacturer: string;
  number: string;
  type: 'assets';
  name: string;
  assetEvents: EventDataForAsset[];
  relatedTasks: ItemAssetTask[];
  availableRelatedTasks: AssetRelatedTaskOption[];
  availableAssetCategories: SelectDropdownOption[];
  availableAssetEmployees: SelectDropdownOption[];
  editAssetsSimplified: boolean;
  organizationName: string;
}

/**
 *
 */
@customElement('d-asset-view')
export class DAssetView extends EntityContent<AssetView, AssetViewEditItem> {
  static readonly styles = EntityContent.styles.concat(css`
    .event-wrapper * {
      line-height: 140%;
    }
    .event-wrapper .event {
      padding: 12px 0 10px 0;
      border-bottom: 1px solid var(--borderColor);
    }
    .event-wrapper:last-child .event {
      border-bottom: none;
    }
    .event-header {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      align-items: baseline;
      justify-content: space-between;
    }
    .event-wrapper:hover .event-header d-label {
      opacity: 1;
    }
    .event-header > *:last-child {
      flex-grow: 1;
      margin-right: 12px;
      margin-bottom: 2px;
    }
    .event-header .actions {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      margin-right: -8px;
      margin-left: -8px;
    }
    .event-prev-next {
      margin-top: 4px;
    }
    .event-prev-next > div {
      display: flex;
      align-items: baseline;
      padding: 2px 0;
    }
    .event-prev-next > div > * {
      margin-right: 12px;
    }
    .event-prev-next > div > d-label {
      width: 60px;
      margin-right: 6px;
    }
    .prev-next-text {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      background-position: -4px -1px;
      background-repeat: no-repeat;
      background-size: 22px;
      padding-left: 18px;
      color: var(--linkColorGray);
      text-decoration: none;
    }
    a.prev-next-text.ok {
      background-image: url(/images/check-mini-blue.svg);
    }
    a.prev-next-text.not-ok {
      background-image: url(/images/x-fat-orange.svg);
    }
    a.prev-next-text.done {
      background-image: url(/images/check-mini-gray.svg);
    }
    a.prev-next-text .date {
      margin-right: 6px;
    }
    .done-text {
      font-size: 14px;
      font-weight: 200;
    }
    span.prev-next-text {
      font-style: italic;
      font-weight: 200;
    }
    .occurrences a.prev-next-text {
      background-position-y: 3px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    @media only screen and (min-width: 600px) {
      .event-wrapper {
        padding-left: var(--listPaddingLeft);
      }
      .event-wrapper[classified] .meeting-header {
        padding-left: 0;
      }
    }
    @media (hover: hover) {
      .event-header .actions {
        visibility: hidden;
      }
      .event:hover .event-header .actions {
        visibility: visible;
        display: flex;
      }
      .event:hover a.prev-next-text {
        color: var(--themeColorDarkerOne);
      }
      .event:hover a.prev-next-text:hover {
        color: black;
      }
      .event:hover a.prev-next-text[disabled],
      .event:hover a.prev-next-text[disabled]:hover {
        color: black;
        cursor: default;
      }
    }
  `);
  @property({ type: Array })
  radiationTypes = [
    { value: 'NONE', text: 'Velg type' },
    {
      value: 'category1',
      text: 'Ultralyd / laser klasse 3B og lavere',
    },
    {
      value: 'category2',
      text: 'Tannrøntgen / laser klasse 4',
    },
    { value: 'category3', text: 'Røntgen / MR / CT' },
  ];

  @property({ type: Number })
  showAllOccurrencesIndex = -1;

  protected get currentItemLabel() {
    return this.entityView.name + ' ' + this.entityView.number;
  }
  protected viewFields(): SectionField[] {
    return [
      {
        field: 'number',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'manufacturer',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'model',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'location',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'category',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'owner',
        options: this._combinedList(this.entityView.organizationName, this.entityView.availableAssetEmployees),
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'userManual',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'supplier',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'acquiredYear',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'discardedYear',
        type: 'view-text',
        class: 'minWidth300',
      },
      {
        field: 'complianceDeclaration',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'accessory',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'competency',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: 'maintenance',
        type: 'view-text',
        class: 'fullWidth',
      },
      {
        field: '',
        type: 'view-conditional-bullet-list',
        listItems: [
          {
            field: 'hasPersonalDataItem',
            elseField: 'hasPersonalDataItemNOT',
          },
          {
            field: 'requiresElectricity',
          },
          {
            field: 'radiationCategory1',
          },
          {
            field: 'radiationCategory2',
          },
          {
            field: 'radiationCategory3',
          },
          {
            field: 'maintenanceRequired',
            elseField: 'maintenanceRequiredNOT',
          },
        ],
      },
      {
        field: '',
        type: 'view-conditional-bullet-list',
        listItems: [
          {
            field: 'assetVerified',
            elseField: 'assetVerifiedNOT',
            alert: true,
          },
          {
            field: 'documentationVerified',
            elseField: 'documentationVerifiedNOT',
            alert: true,
          },
          {
            field: 'documented',
            elseField: 'documentedNOT',
            alert: true,
          },
          {
            field: 'dsaRegistered',
            elseField: 'dsaRegisteredNOT',
            alert: true,
            condition: this._registrationRequired(
              this.entityView.requiresElectricity ?? false,
              this.entityView.radiation,
              this.entityView.radiationType,
            ),
          },
          {
            field: 'dsaApproved',
            elseField: 'dsaApprovedNOT',
            alert: true,
            condition: this._approvalRequired(
              this.entityView.requiresElectricity ?? false,
              this.entityView.radiation,
              this.entityView.radiationType,
            ),
          },
        ],
      },
    ];
  }

  protected lists(): ListDefinition[] {
    return [
      {
        field: '',
        type: 'custom',
        icon: '',
        items: '',
        render: () => this.renderEventsList(),
      },
      {
        field: 'generalFields_attachments',
        type: 'custom',
        icon: 'attachments',
        items: 'attachments',
        render: () => this.renderAttachments(),
        condition: this.entityView.featureStates.core,
      },
    ];
  }

  renderViewMode() {
    return html` ${this.renderFieldSection('assets', this.viewFields(), this.entityView)} `;
  }

  private get assetEventsHeaderActions(): ActionInput[] {
    return [
      {
        name: 'Legg til',
        action: 'create-event-occurrence',
        slot: 'top-right',
      },
    ];
  }

  hasMoreOccurrences(event: EventDataForAsset): boolean {
    return (
      event.occurrences.filter((e) => {
        return e.eventUuid !== event.prev?.eventUuid && e.eventUuid !== event.next?.eventUuid;
      }).length > 0
    );
  }

  _combinedList(organizationName: string, list: SelectDropdownOption[]): SelectDropdownOption[] {
    return [
      {
        value: '',
        text: organizationName,
      },
      ...list,
    ];
  }

  _registrationRequired(electricity: boolean, radiation: boolean, radiationType: string): boolean {
    return electricity && radiation && radiationType === 'category2';
  }

  _approvalRequired(electricity: boolean, radiation: boolean, radiationType: string): boolean {
    return electricity && radiation && radiationType === 'category3';
  }

  doneDisabled(): boolean {
    return isEmptyOrInvalidString(this.editItem?.name);
  }

  eventClassMap(event) {
    return {
      ok: event.status === 'OK',
      'not-ok': event.status === 'NOT_OK',
      done: event.status === 'DONE',
    };
  }

  toggleShowAllOccurrences(index) {
    if (this.showAllOccurrencesIndex === index) {
      this.showAllOccurrencesIndex = -1;
    } else {
      this.showAllOccurrencesIndex = index;
    }
  }

  renderAllOccurrences(occurrences) {
    return html`
      ${occurrences.map(
        (event) => html`
          <div class="occurrences">
            <a
              class="prev-next-text ${classMap(this.eventClassMap(event))}"
              href="${shortenPath(this.entityView.href + '/' + event.href)}"
            >
              <span class="date">${event.dateTimeDisplay}</span>
              ${event.statusText ? html` <span class="done-text">${event.statusText}</span> ` : nothing}
            </a>
          </div>
        `,
      )}
    `;
  }

  renderPrevNextOccurrence(event) {
    return html`<div class="event-prev-next">
      <div>
        <d-label semibold label="Forrige"></d-label>
        ${event.prev
          ? html`
              <a
                class="prev-next-text ${classMap(this.eventClassMap(event.prev))}"
                href="${shortenPath(this.entityView.href + '/' + event.prev.href)}"
              >
                <span class="date">${event.prev.dateTimeDisplay}</span>
                ${event.prev.statusText ? html` <span class="done-text">${event.prev.statusText}</span> ` : nothing}
              </a>
            `
          : html` <span class="prev-next-text">Ingen tidligere</span> `}
      </div>
      <div>
        <d-label semibold label="Neste"></d-label>
        ${event.next
          ? html`
              <a class="prev-next-text" href="${shortenPath(this.entityView.href + '/' + event.next.href)}"
                >${event.next.dateTimeDisplay}</a
              >
            `
          : html` <span class="prev-next-text">Ingen kommende</span> `}
      </div>
    </div> `;
  }
  renderEventsList() {
    return html`<d-list-header
        label="Oppgaver"
        icon="events"
        bordered
        style="top:${this.contentStickyTop - 1}px"
        .actions=${this.assetEventsHeaderActions}
        @action=${(e) => this.onAction(e)}
      ></d-list-header>
      <div>
        ${this.entityView.assetEvents.map(
          (event, index) => html`
            <div class="event-wrapper">
              <div class="event">
                <div class="event-header">
                  <div class="actions">
                    ${
                      this.hasMoreOccurrences(event)
                        ? html`
                            <d-action theme-page mini @click=${() => this.toggleShowAllOccurrences(index)}>
                              ${this.showAllOccurrencesIndex === index ? 'Vis færre' : 'Vis alle'}
                            </d-action>
                          `
                        : nothing
                    }                    
                    ${
                      this.entityView.currentUserHasWriteAccess
                        ? html`
                            <d-action
                              @click=${(e) => this.onCreateEventOccurrenceWithName(e, event.name)}
                              href="#"
                              theme-page
                              mini
                              >Legg til</d-action
                            >
                          `
                        : nothing
                    }
                  </div>
                  <div>
                    <d-label big allow-wrap .label=${event.name}></d-label>
                  </div>
                </div>
                <d-smooth-resize>
                ${
                  this.showAllOccurrencesIndex === index && this.hasMoreOccurrences(event)
                    ? html` ${this.renderAllOccurrences(event.occurrences)}`
                    : html`${this.renderPrevNextOccurrence(event)}`
                }
                </d-smooth-resize>
              </div>
            </div>
            </div>
          `,
        )}
      </div> `;
  }

  async initializeEditItem() {
    this.editItem = {
      accessory: this.entityView.accessory,
      acquiredYear: this.entityView.acquiredYear,
      assetVerified: this.entityView.assetVerified,
      category: this.entityView.category,
      competency: this.entityView.competency,
      complianceDeclaration: this.entityView.complianceDeclaration,
      discardedYear: this.entityView.discardedYear,
      documentationVerified: this.entityView.documentationVerified,
      documented: this.entityView.documented,
      dsaApproved: this.entityView.dsaApproved,
      dsaRegistered: this.entityView.dsaRegistered,
      location: this.entityView.location,
      maintenance: this.entityView.maintenance,
      maintenanceRequired: this.entityView.maintenanceRequired,
      manufacturer: this.entityView.manufacturer,
      model: this.entityView.model,
      name: this.entityView.name,
      number: this.entityView.number,
      owner: this.entityView.owner,
      radiation: this.entityView.radiation,
      radiationType: this.entityView.radiationType,
      requiresElectricity: this.entityView.requiresElectricity,
      supplier: this.entityView.supplier,
      userManual: this.entityView.userManual,
    };
  }

  private editFields(item: AssetViewEditItem): SectionField[] {
    return [
      {
        field: 'name',
        type: 'edit-text',
        placeholder: 'Dette feltet må fylles ut',
        markIfEmpty: true,
        class: 'minWidth300',
      },
      {
        field: 'number',
        type: 'edit-text',
        class: 'minWidth300',
      },
      {
        field: 'category',
        type: 'edit-dropdown',
        options: this.entityView.availableAssetCategories,
        class: 'minWidth300',
      },
      {
        field: 'owner',
        type: 'edit-dropdown',
        options: this._combinedList(this.entityView.organizationName, this.entityView.availableAssetEmployees),
        class: 'minWidth300',
      },
      {
        field: 'requiresElectricity',
        optionField: 'requiresElectricity',
        type: 'edit-checkbox',
        class: 'fullWidth',
        condition: !this.entityView.editAssetsSimplified,
      },
      {
        type: 'expansion',
        opened: item.requiresElectricity,
        resetHiddenFields: true,
        fields: [
          {
            field: 'radiation',
            optionField: 'radiation',
            type: 'edit-checkbox',
            class: 'fullWidth',
          },
          {
            type: 'expansion',
            opened: item.radiation,
            resetHiddenFields: true,
            fields: [
              {
                field: 'radiationType',
                type: 'edit-dropdown',
                hideLabel: true,
                options: this.radiationTypes,
                class: 'fullWidth',
              },
              {
                type: 'expansion',
                opened: this._registrationRequired(
                  item.requiresElectricity ?? false,
                  item.radiation,
                  item.radiationType,
                ),
                resetHiddenFields: true,
                fields: [
                  {
                    field: 'dsaRegistered',
                    optionField: 'dsaRegistered',
                    type: 'edit-checkbox',
                    class: 'fullWidth',
                  },
                ],
              },
              {
                type: 'expansion',
                opened: this._approvalRequired(item.requiresElectricity ?? false, item.radiation, item.radiationType),
                resetHiddenFields: true,
                fields: [
                  {
                    field: 'dsaApproved',
                    optionField: 'dsaApproved',
                    type: 'edit-checkbox',
                    class: 'fullWidth',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        field: 'manufacturer',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'model',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'location',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'userManual',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'supplier',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'acquiredYear',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'discardedYear',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'accessory',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
      {
        field: 'competency',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
      {
        field: 'maintenance',
        type: 'edit-textarea',
        class: 'fullWidth',
      },
      {
        field: 'maintenanceRequired',
        optionField: 'maintenanceRequired',
        type: 'edit-checkbox',
        class: 'fullWidth',
      },
      {
        field: 'complianceDeclaration',
        placeholder: 'Angi hvor samsvarserklæringen finnes',
        type: 'edit-text',
        class: 'minWidth200',
      },
      {
        field: 'initialInspection',
        type: 'header',
      },
      {
        field: 'assetVerified',
        optionField: 'assetVerified',
        type: 'edit-checkbox',
        class: 'fullWidth',
      },
      {
        field: 'documentationVerified',
        optionField: 'documentationVerified',
        type: 'edit-checkbox',
        class: 'fullWidth',
      },
      {
        field: 'documented',
        optionField: 'documented',
        type: 'edit-checkbox',
        class: 'fullWidth',
      },
    ];
  }

  renderEditItem(item: AssetViewEditItem): TemplateResult<1> {
    return html` ${this.renderFieldSection('assets', this.editFields(item), item)}`;
  }

  protected asUpdateSectionItem(): UpdateSectionItem | undefined {
    return undefined;
  }

  private onAction(e: CustomEvent<string>) {
    e.stopPropagation();
    if (e.detail === 'create-event-occurrence') {
      this.fireCreateEventOccurrence(undefined);
    }
  }

  private onCreateEventOccurrenceWithName(e: MouseEvent, name: string) {
    e.preventDefault();
    e.stopPropagation();
    this.fireCreateEventOccurrence(name);
  }

  private fireCreateEventOccurrence(name: string | undefined) {
    const id = uuid();
    let nameParam = '';
    if (name) {
      nameParam = '&name=' + encodeURI(name);
    }
    const input: CreateEventOccurrenceInput = {
      entityType: 'eventOccurrences',
      entityUuid: id,
      assetUuid: this.entityView.uuid,
      instance: LocalDate.now().toString(),
      targetUrl: this.entityView.href + '/eventOccurrences/' + id + '?edit' + nameParam,
    };
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-entity', {
        bubbles: true,
        composed: true,
        detail: input,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-asset-view': DAssetView;
  }
}
