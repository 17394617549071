import { css, html, LitElement, nothing } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import type { ListSectionItemInput } from 'src/library/lists/utilities.js';
import '../elements/d-action.js';
import '../elements/d-label.js';
import '../elements/d-tooltip.js';
import '../fields/d-expansion.js';
import './d-list-header.js';
import './d-list-section-item.js';
import { DListSection } from 'src/library/lists/d-list-section.js';
import { CreateEntityInput } from 'src/layout/parts/d-new-documents-list';

export interface EmployeeFunctionItem {
  uuid?: string;
  href?: string;
  name: string;
  type: string;
  assignedEmployeeNames: string;
  hasDraft: boolean;
}

/**
 *
 * STATUS OK
 */
@customElement('d-list-section-employee-functions')
export class DListSectionEmployeeFunctions extends LitElement {
  static readonly styles = [
    ...DListSection.styles,
    css`
      :host {
        display: block;
      }
    `,
  ];
  @query('#functionsList')
  functionsList!: HTMLTemplateElement & { itemForElement: (e: EventTarget) => any };
  @property({ type: String })
  pageId = '';
  @property({ type: String })
  employeeId = '';
  @property({ type: String })
  token = '';
  @property({ type: String })
  employeeStatus = '';
  @property({ type: Boolean })
  writeAccess = false;
  @property({ type: Array })
  assignedFunctions: EmployeeFunctionItem[] = [];
  @property({ type: Array })
  unassignedFunctions: EmployeeFunctionItem[] = [];
  @property({ type: String })
  label = '';
  @property({ type: Boolean })
  showAdd = false;
  @property({ type: Number })
  organizationId = 0;
  @property({ type: Array })
  employees: any[] = [];
  @property({ type: Number })
  contentStickyTop = 0;

  private get canAddItem() {
    return this.writeAccess && this.employeeStatus === 'ACTIVE';
  }

  _toggleString(showAdd, a, b) {
    return showAdd ? a : b;
  }

  _assignFunction(event: CustomEvent<ListSectionItemInput>) {
    event.preventDefault();
    event.stopPropagation();
    if (event.detail.clickData) {
      this.dispatchEvent(
        new CustomEvent<{ functionUuid: string }>('assign-function', {
          bubbles: true,
          composed: true,
          detail: {
            functionUuid: event.detail.clickData,
          },
        }),
      );
      this.showAdd = false;
    }
  }

  _listItems(items, assigned) {
    return items.map((item) => {
      const listItem: ListSectionItemInput = {
        label: item.name,
        accessible: true,
        hasDraft: item.hasDraft,
      };
      if (item.type === 'ROTATED') {
        listItem.functionRotated = true;
      }
      if (!assigned || item.type === 'ROTATED' || item.type === 'COMMON' || item.type === 'SIMULTANEOUS') {
        listItem.secondaryLabel = item.assignedEmployeeNames;
      }
      if (!assigned) {
        listItem.clickData = item.uuid;
      } else {
        listItem.href = item.href;
      }
      return listItem;
    });
  }

  _toggleShowAdd() {
    this.showAdd = !this.showAdd;
  }

  render() {
    const listHeaderStyles = { top: this.contentStickyTop - 1 + 'px' };
    return html`
      <d-list-header icon="functions" field="organization_functions" style=${styleMap(listHeaderStyles)}>
        ${this.canAddItem
          ? html`
              <d-action slot="top-right" @click=${() => this._toggleShowAdd()}>
                ${this._toggleString(this.showAdd, 'Avbryt', 'Tildel')}
              </d-action>
            `
          : nothing}
      </d-list-header>

      <d-expansion ?opened=${this.showAdd}>
        <div class="add-header">
          <d-action @click=${() => this.fireCreateResponsibility()}>Lag nytt ansvarsområde</d-action>
          <span class="explanation">eller&nbsp;velg&nbsp;et eksisterende</span>
        </div>
        <d-list-section
          no-header
          .items=${this._listItems(this.unassignedFunctions, false)}
          @item-clicked=${(e) => this._assignFunction(e)}
        ></d-list-section>
      </d-expansion>

      <d-expansion ?opened=${!this.showAdd}>
        <d-list-section no-header .items=${this._listItems(this.assignedFunctions, true)}></d-list-section>
      </d-expansion>
    `;
  }

  private fireCreateResponsibility() {
    this.dispatchEvent(
      new CustomEvent<CreateEntityInput>('create-responsibility', {
        bubbles: true,
        composed: true,
      }),
    );
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-list-section-employee-functions': DListSectionEmployeeFunctions;
  }
}
