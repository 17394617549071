import type { DayItem } from 'src/library/lists/d-calendar-list-section';
import type { ListItemIcon, ListSectionItemInput } from 'src/library/lists/utilities';
import type { TodoListItem } from 'src/library/lists/d-todo-list-section';
import type { EventsPageViewModel } from 'src/pages/events-page/events-page-view-model';
import type { State } from 'src/store/types';
import {
  calendarTaskInstances,
  employeesNotDeleted,
  isCurrentUserEmployee,
  leavePeriodsConfirmed,
  singleUserVersion,
  startTaskInstances,
  todoList,
  todoListHideAfterDaysOption,
  weekStart,
} from 'src/store';
import { entityNameForLists } from 'src/utilities/text';
import { AbstractPageView } from 'src/pages/abstract-page-view';

interface DayItemTask {
  eventNotes: string;
  myTask: boolean;
  href: string;
  accessible: boolean;
  locked: boolean;
  eventType?: string;
  timeOfDayDisplay: string;
  doneText: string;
  assignee: string;
  name: string;
  hasDraft: boolean;
  meeting?: boolean;
  isConfirmedEntity: boolean;
  draftName?: string;
}

function toListItems(todoList: TodoListItem[]): ListSectionItemInput[] {
  return todoList.map((e): ListSectionItemInput => {
    let mainIcon: ListItemIcon | undefined = undefined;
    if (e.done) {
      mainIcon = 'checkGray';
    } else if (e.overdue) {
      mainIcon = 'alert';
    }
    return {
      label: entityNameForLists(e.name, e.draftName, e.isConfirmedEntity),
      eventCurrentUser: e.myTask ?? false,
      href: e.href,
      accessible: true,
      secondaryLabel: e.doneText,
      rightLabel: e.assignee,
      rightLabelClass: 'assignee',
      notes: e.eventNotes ?? '',
      updateStatus: 'none',
      mainIcon,
      hasDraft: e.hasDraft,
    };
  });
}

function toDayItems(
  calendarTaskInstances: {
    tasks: DayItemTask[];
    date: string;
    holiday: string;
  }[],
): DayItem[] {
  return calendarTaskInstances.map((e) => ({
    date: e.date,
    holiday: e.holiday,
    tasks: e.tasks.map((t): ListSectionItemInput => {
      let mainIcon: ListItemIcon | undefined = undefined;
      if (t.locked) {
        mainIcon = 'lock';
      } else if (t.meeting) {
        mainIcon = 'meetings';
      } else if (t.doneText !== '') {
        mainIcon = 'checkGray';
      }
      const name = entityNameForLists(t.name, t.draftName, t.isConfirmedEntity);
      return {
        mainIcon,
        accessible: t.accessible,
        notes: t.eventNotes,
        eventCurrentUser: t.myTask,
        href: t.href,
        updateStatus: 'none',
        label: (t.timeOfDayDisplay + ' ' + name).trim(),
        secondaryLabel: t.doneText,
        rightLabelClass: 'assignee',
        rightLabel: t.assignee,
        hasDraft: t.hasDraft,
      };
    }),
  }));
}

export function eventsPageView(hrefPrefix: string, viewModel: AbstractPageView, state: State): EventsPageViewModel {
  return {
    ...viewModel,
    type: 'events-page',
    icon: 'calendar',
    href: hrefPrefix,
    singleUserVersion: singleUserVersion(state),
    employees: employeesNotDeleted(state),
    isEmployee: isCurrentUserEmployee(state),
    todoList: toListItems(todoList(state)),
    hideAfterDays: todoListHideAfterDaysOption(state),
    weekStart: weekStart(state),
    leavePeriodsConfirmed: leavePeriodsConfirmed(state),
    startTaskInstances: startTaskInstances(state),
    calendarTaskInstances: toDayItems(calendarTaskInstances(state)),
  };
}
