import { State } from 'src/store/types';
import { DocForLinking, docsForLinking } from 'src/store/selectors/linking';

export type { DocForLinking } from 'src/store/selectors/linking';

export function getDocsForLinking(state: State): DocForLinking[] {
  return docsForLinking(state);
}

function convertInternalLink(
  a: globalThis.HTMLAnchorElement,
  doc: DocForLinking | undefined,
): globalThis.HTMLAnchorElement {
  if (doc) {
    if (doc.classified) {
      a.classList.add('classified');
    } else {
      a.classList.remove('classified');
    }
    if (doc.classes) {
      doc.classes.split(' ').forEach((c) => a.classList.add(c));
    }
    if (doc.noAccess) {
      a.setAttribute('href', 'restricted');
    } else {
      a.setAttribute('href', doc.href);
    }
    a.setAttribute('target', '_self');
    a.innerHTML = doc.name;
  } else {
    a.setAttribute('href', '');
    a.innerHTML = 'Finner ikke dokumentet: ' + a.innerHTML;
  }
  return a;
}

export function convertInternalLinks(string: string, docsForLinking: DocForLinking[]): string {
  const doc = new DOMParser().parseFromString('<div id="temp">' + string + '</div>', 'text/html');
  const elm = doc.getElementById('temp') as HTMLElement;
  const anchors = elm.getElementsByTagName('a');
  for (let a of anchors) {
    const id = a.getAttribute('data-internal-link-id');
    if (id) {
      const doc: DocForLinking | undefined = docsForLinking.find((item) => {
        return item.templateId === Number(id) || item.uuid === id;
      });
      a = convertInternalLink(a, doc);
    }
  }
  return elm.innerHTML;
}
